import * as React from "react";
import Button from "@carfax-stencils/button";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import {observer} from "mobx-react";
import { Table, THead, TBody, TD, TH, TR } from '@carfax-stencils/table'
import {UserEmailHistoryRecord} from "../../../../types/acctMgmt/userEmailHistoryRecord";
import "../../../../styles/main.scss";
import './UserEmailHistoryModal.scss';
import UserEmailHistoryStore from "../../../../stores/acctMgmt/userEmailHistory";
import {AlertCircle, InfoCircleOutline} from "@carfax-stencils/icon";
import {SpinnerFox} from "@carfax-stencils/spinner";

export type UserEmailHistoryProps = {
    isVisible : boolean;
    store : UserEmailHistoryStore;
}

const UserEmailHistoryModal = observer((props: UserEmailHistoryProps) => {
    return (
        <Modal
            id="user-email-history-modal"
            isVisible={true}
            isWideFormat={true}
            className="user-email-history-modal"
            onClose={props.store.closeUserEmailHistoryModal}
        >
            <ModalHeader>
                <div id="user-email-history-modal-header-div" className="user-email-history-modal__header">Email History for {props.store.selectedUser?.firstName} {props.store.selectedUser?.lastName}</div>
            </ModalHeader>
            <ModalBody>
                {props.store.commonStore.isProcessingApi && (
                    <div id="user-email-history-spinner-container-div">
                        <div id="user-email-history-spinner-div" className="user-email-history-spinner-div">
                            <SpinnerFox id="search-spinner" size={150}/>
                        </div>
                        <div id="user-email-history-search-spinner-text-div"
                             className="user-email-history-search-spinner-text-div">
                            <div id="user-email-history-search-spinner-email-history-text-div"
                                 className="user-email-history-search-spinner-email-history-text-div">Loading
                                Email History...
                            </div>
                            <div id="user-email-history-search-spinner-div"
                                 className="user-email-history-search-spinner-div">(This may
                                take up to 10 seconds)
                            </div>
                        </div>
                    </div>
                )}
                {props.store.userEmailHistoryRecords.length > 0 &&
                    <UserEmailHistoryDetails {...props}/>
                }
                {props.store.userEmailHistoryRecords.length === 0 && !props.store.commonStore.isProcessingApi &&
                    <div id="user-email-history-no-history-text-div" className="user-email-history-no-history-text-div">
                        No email history found.
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <div id="user-email-footer-div">
                    <Button
                        id="dismiss-button"
                        size="small"
                        theme="black"
                        onClick={() => props.store.closeUserEmailHistoryModal()}
                    >
                        Dismiss
                    </Button>
                </div>
                <div id="user-email-history-footer-refresh-button-div" className="user-email-history-footer-refresh-button-div">
                    <Button
                        id="refresh-data-button"
                        theme="blue-outline"
                        size="large"
                        children={`Refresh Data`}
                        onClick={() => props.store.refresh()}
                    />
                </div>
            </ModalFooter>
        </Modal>
    )
});

const UserEmailHistoryDetails = observer((props: UserEmailHistoryProps) => {
    return (
        <div id="user-email-history-details-div">
            <div className="user-email-history-info-div" id="user-email-history-info-div">
                <InfoCircleOutline size={25} className="info-circle-outline"/>
                <div id="user-email-history-info-text-div">Email history is limited to the past 14 days. Real-time email events can have a several minute delay; refresh for the latest email data.</div>
            </div>
            <div id="user-email-history-last-refresh-div" className="user-email-history-last-refresh-div">
                <div id="user-email-history-last-refresh-date-text-div" className="user-email-history-last-refresh-date-text-div">Last Refreshed </div>
                <div id="user-email-history-last-refresh-date-div">{props.store.lastRefreshDate} EST</div>
            </div>
            <Table aria-describedby="user-email-history-details-table">
            <THead className="user-email-history-table__heading">
                    <TH className="user-email-history-table__heading__TH" width={200}>Event Date</TH>
                    <TH className="user-email-history-table__heading__TH" width={300}>Subject</TH>
                    <TH className="user-email-history-table__heading__TH" width={200}>Event</TH>
                </THead>
                <TBody>
                    {props.store.userEmailHistoryRecords.map((record: UserEmailHistoryRecord, index) => (
                        <TR className="user-email-history-table__row" key={index}>
                            <TD className="user-email-history-table__column">{record.eventDate}</TD>
                            <TD className="user-email-history-table__column">{record.subject}</TD>
                            <TD className="user-email-history-table__column">
                                <div id="user-email-history-event-div" className="user-email-history-event-div">
                                    {record.eventName === "Not Delivered"
                                        ? <AlertCircle size={20} id="user-email-history-event-icon" className="user-email-history-event-alert-icon-visible info-circle-outline"/>
                                        : <AlertCircle size={20} id="user-email-history-event-icon" className="user-email-history-event-alert-icon-hidden info-circle-outline"/>
                                    }
                                    <div id="user-email-history-table-eventName" title={props.store.renderTooltipText(record)}>
                                        {record.eventName}
                                    </div>
                                </div>
                            </TD>
                        </TR>
                    ))}
                </TBody>
            </Table>
        </div>
    )
});

export default UserEmailHistoryModal;
