import { AddPhoto, Trash } from "@carfax-stencils/icon";
import { SpinnerFox } from "@carfax-stencils/spinner";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import Dropzone from "react-dropzone";
import {Images, ImageScalingMode, ProfileImageDisplay} from "../../../../types/profile/images";
import DealerDetailsStore from "../../../../stores/profile/dealerDetails";
import "./ImageDropZone.scss";
import CommonUtils from "../../../../utils/commonUtils";

export interface IImageDropZoneProps {
    id: string;
    type: Images;
    photo: ProfileImageDisplay;
    key: string;
    index: number;
    dealerDetailsStore: DealerDetailsStore;
}

const RemoveIconOverlay = observer((props: { hasPhoto: boolean; pending: boolean; handleDeleteEvent: any }) => {
    return props.hasPhoto && !props.pending ? (
        <Trash id={`remove-icon-overlay-trash-${Math.random()}`} size={20} className="image-remove" onClick={props.handleDeleteEvent} title="remove" />
    ) : null;
});

const SpinnerOverlay = observer((props: { isUploading: boolean }) => {
    return props.isUploading ? (
        <div id={`dropzone-activity-overlay-${Math.random()}`} className="dropzone-activity-overlay">
            <SpinnerFox id={`dropzone-activity-overlay-spinnergears-${Math.random()}`} size={50} />
        </div>
    ) : null;
});

const AddPhotoOverlay = observer((props: { index: number; hasPhoto: boolean; handlePreviewModal: any; imageUrl: any, isUploading: boolean}) => {
    if (!props.isUploading) {
        return !props.hasPhoto ?
            (
                <div id={`add-photo-overlay-div-${props.index}`}>
                    <AddPhoto
                        id={`add-photo-overlay-component-${props.index}`}
                        size={40}
                        className="add-photo-overlay-component"
                        onClick={props.hasPhoto ? props.handlePreviewModal : undefined}
                    />
                    <div id={`upload-photo-label-${props.index}`} className="upload-photo-label">Upload Photo</div>
                </div>) : (
                <img
                    id={`image-component-${props.index}`}
                    className="image"
                    src={props.imageUrl}
                    onClick={props.hasPhoto ? props.handlePreviewModal : undefined}
                    alt="uploaded"
                />
            );
    }
    return <div/>
});


@observer
export default class PhotoDropZone extends React.Component<IImageDropZoneProps> {

    @observable isUploading: boolean = false;

    private handlePreviewModal = () => {
        if (this.props.photo) {
            this.props.dealerDetailsStore.photo = this.props.photo;
            this.props.dealerDetailsStore.displayPreviewImageModal();
        }
    };

    private handleRemoveIcon = () => {

        if(this.props.type === "logo") {
            CommonUtils.logNewRelicPageAction("Profile - Dealer Details", {userAction: 'Delete Logo'})
        } else {
            CommonUtils.logNewRelicPageAction("Profile - Dealer Details", {userAction: 'Delete Photo'})
        }

        if (this.props.photo) {
            this.props.dealerDetailsStore.photo = this.props.photo;
            this.props.dealerDetailsStore.displayDeleteImageModal();
        };
    };

    private handleImageOnDrop = (droppedImage: any) => {

        if(this.props.type === "logo") {
            CommonUtils.logNewRelicPageAction("Profile - Dealer Details", {userAction: 'Upload Logo'})
        } else {
            CommonUtils.logNewRelicPageAction("Profile - Dealer Details", {userAction: 'Upload Photo'})
        }

        if (droppedImage.length > 0) {
            this.isUploading = true;
            const imageType = this.props.type === "logo" ? this.props.type : "photo";
            const scalingMode:ImageScalingMode = "aspectFit";
            this.props.dealerDetailsStore.photo = this.props.photo;
            this.props.dealerDetailsStore.uploadImage(droppedImage[0], imageType, scalingMode);
        }
    };

    render() {
        const hasPhoto = this.props.photo.id !== undefined;
        const imageUrl = this.props.photo.url || AddPhoto;
        const containerClassPrefix = this.props.type;
        const containerClass = containerClassPrefix + "-profile-image-container";

        const inputProps = {
            disabled: hasPhoto
        };

        const rootProps = {
            className: "dropzone-root-div",
            activeclassname: "dropzone-root-div"
        };

        return (
            <div
                id={`image-drop-zone-main-div-${this.props.index}`}
                className={containerClass}
            >
                <Dropzone key={`dropzone-key-${this.props.index}`} accept={{"image/jpeg": [".jpeg"] , "image/png": [".png"]}} onDrop={this.handleImageOnDrop} multiple={false} disabled={hasPhoto}>
                    {({ getRootProps, getInputProps }) => {
                        return (
                            <div {...getRootProps(rootProps)} id={`drop-zone-div-${this.props.index}`}>
                                <div id={`image-remove-icon-div-${this.props.index}`} className="image-remove-icon-div">
                                    <RemoveIconOverlay
                                        hasPhoto={hasPhoto}
                                        pending={this.props.photo.pending}
                                        handleDeleteEvent={this.handleRemoveIcon}
                                    />
                                </div>
                                <div id={`add-photo-overlay-div-${this.props.index}`} className="add-photo-overlay-div">
                                    <input {...getInputProps(inputProps)} id={`add-photo-overlay-div-input-${this.props.index}`} />
                                    <AddPhotoOverlay index={this.props.index} hasPhoto={hasPhoto} handlePreviewModal={this.handlePreviewModal} imageUrl={imageUrl} isUploading={this.props.dealerDetailsStore.isUploadingImage}/>
                                </div>
                            </div>
                        )
                    }}
                </Dropzone>
                <SpinnerOverlay isUploading={this.props.dealerDetailsStore.isUploadingImage} />
            </div>
        );
    }
}
