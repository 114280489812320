import RootStore from "../root";
import {makeAutoObservable, runInAction} from "mobx";
import {NotificationThemeType} from "@carfax-stencils/notification";


export default class CommonStore {

    // Variables
    currentFeature: string = "";
    formErrorsMap: Map<string, string> = new Map();

    pageMsg: string = "";
    pageMsgTheme: NotificationThemeType | undefined;

    featureMsg: string = "";
    featureMsgTheme: NotificationThemeType | undefined;

    showUnsavedModalForHeaderNavigation: boolean = false;
    pathForHeaderNavigation: string = "";

    isProcessingApi: boolean = true;

    // Stores
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    public delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    public clearFormErrors() {
        this.formErrorsMap = new Map<string, string>();
    }

    public clearPageMsg() {
        this.pageMsg = ""
        this.pageMsgTheme = undefined
    };

    public clearFeatureMsg() {
        this.featureMsg = ""
        this.featureMsgTheme = undefined
    };

    public displayPageNotification(message: string, theme: NotificationThemeType) {
        this.pageMsg = message;
        this.pageMsgTheme = theme;
    };

    public displayFeatureNotification(message: string, theme: NotificationThemeType) {
        this.featureMsg = message;
        this.featureMsgTheme = theme;
    }

    public initializeStore = () => {
        this.clearPageMsg()
        this.clearFeatureMsg()
        this.currentFeature = ""
    };

    public getFormError(fieldName: string): string{
        let formError = this.formErrorsMap.get(fieldName)
        return formError ? formError : "";
    }

    public get isFormValid() {
        return (this.formErrorsMap.size === 0)
    }

    public processingApiCall(isProcessing: boolean) {
        this.isProcessingApi = isProcessing
    };

    public removeFormError(fieldName: string) {
        runInAction(() => {
            this.formErrorsMap.delete(fieldName);
        });
    };

    public setFormError(fieldName: string, errorMessage: string) {
        runInAction(() => {
            this.formErrorsMap.set(fieldName, errorMessage)
        });
    };
};

export const UCL_RING_TO_FIELD_NAME = "uclRingToPhoneNumber"
export const NEW_LISTINGS_RING_TO_FIELD_NAME = "newListingsRingToPhoneNumber"
export const SMC_RING_TO_FIELD_NAME = "smcRingToPhoneNumber"
export const SRV_APPT_RING_TO_FIELD_NAME = "srvApptRingToPhoneNumber"

