import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {AuditHistoryRecord} from "../../types/profile/auditHistoryRecord";
import {IComplexOption} from "@carfax-stencils/types";
import {apiStatus} from "../../utils/constants";
import {disableUclListingOptions} from "../../types/profile/enums/disableUclListingOptions";
import {getAuditHistory} from "../../clients/dps/dpsApi";
import {makeAutoObservable, runInAction} from "mobx";
import {createRef} from "react";


export default class AuditHistoryStore {

    // Fields / Types
    compCode: string = "";
    token: string = ""
    auditFields: Array<string> = new Array<string>();
    auditFieldSelectedValue: string = "";
    auditUsers: Array<string> = new Array<string>();
    auditUserSelectedValue: string = "";
    selectedHistoryRecords: Array<AuditHistoryRecord> = new Array<AuditHistoryRecord>();
    auditHistoryRecords: Array<AuditHistoryRecord> = new Array<AuditHistoryRecord>();
    paginatedAuditHistoryRecords: Array<AuditHistoryRecord> = new Array<AuditHistoryRecord>();
    isAuditHistoryModalVisible: boolean = false;
    paginationCurrentPage: number = 1;
    paginationPerPageValue: number = 50;
    userNameRefs: Map<string,any> = new Map<string,any>();
    secondaryNameChangedFields: Array<String> = ["make", "secondaryName", "secondaryNameWebsiteUrl"]

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;
        makeAutoObservable(this);
    };

    public initializeStore() {
        this.compCode = this.businessUserStore?.businessUser?.compCode || "";
        this.initializeAuditHistoryModal();
    };

    private initializeAuditHistoryModal() {
        this.auditFieldSelectedValue = "";
        this.auditUserSelectedValue = "";
        this.auditFields = new Array<string>();
        this.auditUsers = new Array<string>();
        this.auditHistoryRecords = new Array<AuditHistoryRecord>();
        this.selectedHistoryRecords = new Array<AuditHistoryRecord>();
        this.paginatedAuditHistoryRecords = new Array<AuditHistoryRecord>();
        this.paginationCurrentPage = 1;
        this.paginationPerPageValue= 50;
    }

    public displayAuditHistoryModal = () => {

        this.isAuditHistoryModalVisible = true
        this.commonStore.processingApiCall(true)
        this.initializeAuditHistoryModal();
        this.isAuditHistoryModalVisible = true;
        this.commonStore.processingApiCall(true);
        let compCode = this.businessUserStore?.businessUser?.compCode || "";

        CommonUtils.logNewRelicPageAction("Profile Audit History", {userAction: 'View Audit History', compCode: compCode})

        getAuditHistory(this.authStore.token, compCode ).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.auditFields = resp.data.fields;
                        this.auditUsers = resp.data.users;
                        this.auditHistoryRecords = resp.data.data;
                        this.selectedHistoryRecords = resp.data.data;
                        this.updatePaginatedRecords(0,this.paginationPerPageValue);
                    });
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve audit history data data for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to load Audit History data for comp code '${compCode}'.`, "red")
                    break;
            };
        });

        this.commonStore.processingApiCall(false)
    };

    public closeAuditHistoryModal = () => {
        this.isAuditHistoryModalVisible = false;
        this.initializeAuditHistoryModal();
    };

    public onFieldNameChange = (changedValue: string) => {
        this.auditFieldSelectedValue = changedValue
        this.filterAuditRecords()
    }

    public onFieldNameSelected = (selectedValue: IComplexOption | undefined) => {
        this.auditFieldSelectedValue = selectedValue?.value!
        this.filterAuditRecords()
    }

    public onUserChange = (changedValue: string) => {
        this.auditUserSelectedValue = changedValue
        this.filterAuditRecords()
    }

    public onUserSelected = (selectedValue: IComplexOption | undefined) => {
        this.auditUserSelectedValue = selectedValue?.value!
        this.filterAuditRecords()
    }

    private filterAuditRecords() {
        if(this.auditFieldSelectedValue && this.auditUserSelectedValue) {
            this.selectedHistoryRecords = this.auditHistoryRecords.filter( auditHistoryRecord => auditHistoryRecord.friendlyFieldChanged === this.auditFieldSelectedValue &&
                                                                           auditHistoryRecord.friendlyUsername === this.auditUserSelectedValue )
        } else {
            if(this.auditFieldSelectedValue) {
                this.selectedHistoryRecords = this.auditHistoryRecords.filter( auditHistoryRecord => auditHistoryRecord.friendlyFieldChanged === this.auditFieldSelectedValue )
            } else {
                if(this.auditUserSelectedValue) {
                    this.selectedHistoryRecords = this.auditHistoryRecords.filter( auditHistoryRecord => auditHistoryRecord.friendlyUsername === this.auditUserSelectedValue )
                } else {
                    this.selectedHistoryRecords = this.auditHistoryRecords
                }
            }
        }

        this.paginationCurrentPage = 1;
        this.updatePaginatedRecords(0,this.paginationPerPageValue)
    }

    public handlePaginationChange = (data: any) => {

        CommonUtils.logNewRelicPageAction("Profile Audit History", {userAction: 'Paginate Through Audit Records'})

        this.paginationCurrentPage = data.currentPage;
        this.paginationPerPageValue = data.perPageValue;

        let currentPage = this.paginationCurrentPage;
        let perPage = this.paginationPerPageValue;
        let offset = (currentPage - 1) * perPage;

        this.updatePaginatedRecords(offset, offset + perPage)
    }

    protected updatePaginatedRecords(start: number, end: number) {
        runInAction(() => {
            this.paginatedAuditHistoryRecords = this.selectedHistoryRecords.slice(start, end)
            this.createRefsForUserName()
            this.transformFieldValues()
        });
    };

    public transformFieldValues() {
        this.paginatedAuditHistoryRecords.forEach( (record: AuditHistoryRecord) => {
            record.createDate = CommonUtils.convertEpochDateToMMDDYYYY(record.createDate);

            if(record.fieldChanged === 'disableAllUclListings') {
                this.transformDisableUclListings(record)
            }

            if(record.fieldChanged === 'penaltyDate' ) {
                if(!CommonUtils.isDateInMonthDayYearFormat(record.fieldValue)) {
                    record.fieldValue = CommonUtils.convertFormattedDateToMMDDYYYY(record.fieldValue)
                }
            }

            if(record.fieldChanged.includes('DEL_EMAIL')) {
                if(record.oldFieldValue === "") {
                    record.fieldValue = "Removed Email"
                } else {
                    record.fieldValue = "Removed : " + record.oldFieldValue
                }
            }
            else if(record.fieldChanged.includes('INS_EMAIL')) {
                if(record.fieldValue === "") {
                    record.fieldValue = "Added Email"
                } 
                else if(record.fieldValue.startsWith("Added") === false) {
                    record.fieldValue = "Added : " + record.fieldValue
                }
            }
            else if(record.fieldChanged.includes('UPD_EMAIL')) {
                if(record.fieldValue === "") {
                    record.fieldValue = "Updated Email"
                } 
                else if(record.fieldValue.startsWith("Updated") === false) {
                    record.fieldValue = "Updated : " + record.fieldValue
                }
            }

            if(record.fieldChanged.includes('EMAIL') === false){
                if((record.oldFieldValue === " " || record.oldFieldValue === "" || record.oldFieldValue == null) && record.fieldValue) {
                    if(record.fieldValue.startsWith("Added") === false && record.fieldValue !== "Removed"){
                        record.fieldValue = "Added : " + record.fieldValue
                    }
                }
                else if(record.oldFieldValue != null && record.fieldValue != null && (record.fieldValue != null && record.fieldValue !== "" && record.fieldValue !== " ")){
                    if(record.fieldValue.startsWith("Updated") === false && record.fieldValue !== "Removed"){
                        record.fieldValue = "Updated : " + record.fieldValue
                    }
                }
                else{
                    if(this.secondaryNameChangedFields.includes(record.fieldChanged)) {
                        record.fieldValue = "Removed : " + record.oldFieldValue
                    } else {
                        record.fieldValue = "Removed"
                    }
                }
            }
        })
    }

    public transformDisableUclListings(record: AuditHistoryRecord) {
        runInAction(() => {
            switch (record.fieldValue) {
                case '0' :
                    record.fieldValue = disableUclListingOptions.ENABLED
                    break;
                case '1' :
                    record.fieldValue = disableUclListingOptions.DISABLED_HACKER_FEED
                    break;
                case '2' :
                    record.fieldValue = disableUclListingOptions.DISABLED_PRICING_ISSUES
                    break;
                case '3' :
                    record.fieldValue = disableUclListingOptions.DISABLED_INVENTORY_ISSUES
                    break;
                case '4' :
                    record.fieldValue = disableUclListingOptions.DISABLED_DEALER_REQUEST
                    break;
                case '5' :
                    record.fieldValue = disableUclListingOptions.DISABLED_OTHER
                    break;
            }
        })
    }

    private createRefsForUserName() {
        this.userNameRefs = new Map<string,any>();
        if(this.paginatedAuditHistoryRecords?.length > 0) {
            this.paginatedAuditHistoryRecords.forEach((auditRecord: AuditHistoryRecord, index: number) => {
                if(auditRecord.friendlyUsername !== auditRecord.username) {
                    this.userNameRefs.set("userNameRefs" + index, createRef())
                }
            });
        }
    }
}
