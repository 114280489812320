import { observer } from "mobx-react";
import React from "react";
import AcctMgmtStore from "../../../../stores/acctMgmt/acctMgmt";
import Button from "@carfax-stencils/button";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import {ProcessingApiSpinner} from "../../../../shared-components/processingApiSpinner/ProcessingApiSpinner";

import "../../../../styles/main.scss";
import "./EditLegacyUserModal.scss";
import TextInput from "@carfax-stencils/input-text";
import SelectInput from "@carfax-stencils/input-select";

export type EditLegacyUserProps = {
    isVisible : boolean;
    store : AcctMgmtStore;
}

const EditLegacyUserModal = observer((props: EditLegacyUserProps) => {

    return (
        <Modal
            id="edit-legacy-user-modal"
            isVisible={true}
            onClose={props.store.closeEditLegacyUserModal}
        >
            <ModalHeader>
                <div id='edit-legacy-user-modal-header-div' className="edit-legacy-user-modal__header">Edit Legacy User</div>
            </ModalHeader>
            <ModalBody className="edit-legacy-user-modal__body">
                <EditLegacyUserDetails {... props}/>
            </ModalBody>
            <ModalFooter>
                <Button
                    id="edit-legacy-user-modal-update-button"
                    theme={"blue"}
                    size="large"
                    onClick={() => props.store.editLegacyUser()}
                >
                    Update
                </Button>
                <Button
                    id="edit-user-modal-cancel-button"
                    theme={"black"}
                    size="large"
                    onClick={props.store.closeEditLegacyUserModal}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
});

const EditLegacyUserDetails = observer( (props: EditLegacyUserProps) => {
    return (
        <div>
            {props.store.commonStore.isProcessingApi && (
                <ProcessingApiSpinner/>
            )}
            <div id="edit-legacy-user-modal-username-div" className="edit-legacy-user-input-div">
                <div id="edit-legacy-user-modal-username-label-div" className="left-padding">
                    <p id="edit-legacy-user-modal-username-label-text" className="input-label ">
                        Username
                    </p>
                </div>
                <TextInput
                    id="edit-legacy-user-modal-username-text"
                    className="left-padding"
                    disabled={true}
                    value={props.store.editingLegacyUser?.username}
                    onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {}}
                    maxLength={50}
                />
            </div>
            <div id="edit-legacy-user-modal-name-div" className="edit-legacy-user-name-div">
                <div id="edit-legacy-user-modal-name-label-div" className="left-padding">
                    <p id="edit-legacy-user-modal-name-label-text" className="input-label ">
                        Name on Report
                    </p>
                </div>
                <TextInput
                    id="edit-legacy-user-modal-name-text"
                    className="left-padding"
                    value={props.store.editingLegacyUser?.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        props.store.onLegacyUserNameChange(event.target.value)
                    }}
                    maxLength={50}
                />
            </div>
            <div id="edit-legacy-user-modal-password-div" className="edit-legacy-user-password-div">
                <div id="edit-legacy-user-modal-password-label-div" className="left-padding">
                    <p id="edit-legacy-user-modal-password-label-text" className="input-label ">
                        Password
                    </p>
                </div>
                <div id="edit-legacy-user-modal-password-input-div" className="edit-legacy-user-modal-password-input-div">
                    <TextInput
                        id="edit-legacy-user-modal-password-text"
                        className="left-padding edit-legacy-user-modal-password-input"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            props.store.onLegacyUserPasswordChange(event.target.value)
                        }}
                        value={props.store.editingLegacyUser?.password}
                        maxLength={50}
                    />
                    <Button
                        id="edit-user-modal-cancel-button"
                        className="edit-legacy-user-modal-password-generate-button"
                        theme={"blue"}
                        size="medium"
                        onClick={props.store.generatePassword}
                    >
                        Generate Password
                    </Button>
                </div>
            </div>
            <div id="edit-legacy-user-modal-status-div" className="edit-legacy-user-status-div">
                <div id="edit-legacy-user-modal-password-status-div" className="left-padding">
                    <p id="edit-legacy-user-modal-password-status-text" className="input-label ">
                        Status
                    </p>
                </div>
                <SelectInput
                    id="edit-legacy-user-modal-status-input"
                    value={props.store.legacyUserStatus}
                    onChange={(option : any) => {
                        props.store.onSelectLegacyUserStatus(option.target.value);
                    }}
                >
                    {["Active", "Inactive"].map((item: string, index: number) =>
                        <option key={index} value={item}>
                            {item}
                        </option>      
                    )}
                </SelectInput>
            </div>
        </div>
    )
});

export default EditLegacyUserModal;
