import BusinessUserStore from "../businessUser/businessUser";
import RootStore from "../root";
import CommonUtils from "../../utils/commonUtils";
import {apiStatus} from "../../utils/constants";
import {makeAutoObservable} from "mobx";
import {User} from "../../types/acctMgmt/user";
import {BusinessUser} from "../../types/businessUser";
import {resendInvite} from "../../clients/uasApi";

export default class ReinviteUserStore {

    // Stores
    rootStore: RootStore;
    businessUserStore: BusinessUserStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.businessUserStore = this.rootStore.businessUserStore

        makeAutoObservable(this)
    };

    public resendInvite = async (user: User, businessUser: BusinessUser ) => {

        this.rootStore.commonStore.processingApiCall(true);
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Reinvite User', compCode: user.compCode, email: user.email})

        await resendInvite(this.rootStore.authStore.token, user).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.rootStore.acctMgmtStore.getUsersByCompCode().then(() => undefined)
                    this.rootStore.commonStore.displayFeatureNotification(`Success! Your invitation has been sent to ${user.email}`, "green");
                    break;
                case apiStatus.forbidden :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                case apiStatus.conflict :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "red")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to resend invitation for userId: ${user.userId}.  Response: ` + JSON.stringify(resp));
                    this.rootStore.commonStore.displayPageNotification(`Severe error occurred trying to resend invitation for '${user.email}'.`, "red")
                    break;
            };
        });

        this.rootStore.commonStore.processingApiCall(false);
    };
}
