import * as React from "react";
import {observer} from "mobx-react";
import Header from "../../shared-components/layout/header/Header";
import Page from "../../shared-components/layout/page/Page";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import Button from "@carfax-stencils/button";
import ButtonBar from "@carfax-stencils/button-bar";
import FormElement from "@carfax-stencils/form-element";
import {Notification, NotificationCloseButton, NotificationIcon, NotificationMessage} from "@carfax-stencils/notification";
import Store from "../../stores/";
import TextInput from "@carfax-stencils/input-text";
import "../../styles/main.scss";
import "./PreferencesSelection.scss";
import {ITableProps, Table,} from "../../shared-components/table/TableComponent";
import {withOktaAuth} from "@okta/okta-react";
import PreferencesSearchResults from "../../shared-components/search/results/preferences/PreferencesSearchResults";
import LocationSelectionModal from "./LocationSelectionModal";
import AddLocationModal from "./AddLocationModal";
import ConfirmModal, {ConfirmProps} from "../../shared-components/confirm/ConfirmModal";
import {Search,Trash} from "@carfax-stencils/icon";

type PreferenceSelectionProps = {
  oktaAuth: any;
  authState: any;
  match:any;
}

const columns = [
  {
    key: "dealership",
    label: "Dealership",
    sortable: true,
    width: "25%",
  },
  {
    key: "compCode",
    label: "Comp Code",
    sortable: true,
    width: "15%",
  },
  {
    key: "status",
    label: "Status",
    sortable: true,
    width: "15%",
  },
  {
    key: "lastModified",
    label: "Last Modified",
    sortable: true,
    width: "10%",
  },
  {
    key: "deleteId",
    label: " ",
    sortable: false,
    width: "10%",
  },
];

const srs = Store.search.searchResults;
const pss = Store.preferences.preferencesSelection;

export default withOktaAuth(
  class PreferencesSelectionPage extends React.Component<PreferenceSelectionProps> {

    async componentDidMount() {
      this.initializePage();
      if (this.props.match?.params?.compCode) {
        pss.handleCompCodeParam(this.props.match.params.compCode)
      }
      this.setState({
          ready: true,
      });
    }

    initializePage = () => {
        srs.initializeStore();
        pss.initializeStore();
    };

    handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        pss.handleSearcKeyDown();
      }
    };

    formatStatus = (status: string) : string => {

      let dbStatusText = status.charAt(0).toLowerCase() + status.slice(1);
      let displayStatus = ""

      if(dbStatusText === "created") {
        displayStatus = "Not Started";
      }

      if(dbStatusText === "updated") {
        displayStatus = "In Progress";
      }

      if(dbStatusText === "submitted") {
        displayStatus = "Submitted";
      }

      return displayStatus;
    }

    determineStatusColor = (status: string) : string => {

      let classNameStatus = ""

      if(status === "Not Started") {
        classNameStatus = "status-not-started"
      }

      if(status === "In Progress") {
        classNameStatus = "status-in-progress"
      }

      if(status === "Submitted") {
        classNameStatus = "status-submitted"
      }

      return classNameStatus
    }

    render() {
      return (
        <Page id="preferences-selection-page" title="Dealer Account Management | Preferences Selection">
          <div id="preferences-selection-page-div">
            <this.SelectLocationsModal />
            <this.AddLocationModal />
            <this.ConfirmCloseRequestModal />
            <this.ConfirmDeleteModal />
            <Header />
            <this.PageContent />
          </div>
        </Page>
      );
    }

    SelectLocationsModal = observer(() => {
      return pss.isSelectionModalVisible ? (
        <LocationSelectionModal />
      ) : null;
    });

    AddLocationModal = observer(() => {
      return pss.isAddModalVisible ? (
          <AddLocationModal />
      ) : null;
    });

    ConfirmCloseRequestModal = observer( () => {
        const confirmProps: ConfirmProps = {
            confirmButtonMessage: "Close Request",
            headerTitle: "Close Preference Request",
            isVisible : pss.isConfirmModalVisible,
            onCloseMethod : pss.handleConfirmationClose,
            theme: "blue",
            confirmModalMessageJsx: (<div>Would you like to close the current preference request?</div>)
        }
        return pss.isConfirmModalVisible ? (
            <ConfirmModal { ...confirmProps} />
        ) : null;
    });

    ConfirmDeleteModal = observer( () => {
      const confirmProps: ConfirmProps = {
          confirmButtonMessage: "Remove Location",
          headerTitle: "Remove Preference Location",
          isVisible : pss.isConfirmDeleteModalVisible,
          onCloseMethod : pss.handleDeleteConfirmation,
          theme: "red",
          confirmModalMessageJsx: (<div>Would you like to delete the Location from the current preference request?</div>)
      }
      return pss.isConfirmDeleteModalVisible ? (
          <ConfirmModal { ...confirmProps} />
      ) : null;
    });

    SearchResultsArea = observer(() => {
      return Store.search.searchResults.isSearchResultsVisible ? (
        <PreferencesSearchResults />
      ) : null;
    });

    PageContent = observer(() => {
      const validData: ITableProps = {
        columns: columns,
        rowData: pss.preferences?.preferenceDetails?.map((pref) => {
          const dealershipName =
            pss.ownerGroup?.ownerGroup.find(
              (location) => location.compCode === pref.compCode
            )?.companyName || "n/a";

          const unformattedStatus =
              pss.preferences?.preferenceDetails?.find(
                  (location) => location.compCode === pref.compCode
              )?.status || "N/A";
          const formattedStatus = this.formatStatus(unformattedStatus);
          const deleteColumn = <div >
                  <Trash  size={24}
                    className="delete-trash-icon"
                    onClick={() => {
                        pref.isMarkedForDeletion = true;
                        pss.handleDeleteModel();
                    }
                    }/>
                 </div>
           const deleteColumnNada = <div> </div>;
           const formattedDeleteColumn = (formattedStatus !== "Submitted" ? deleteColumn : deleteColumnNada) ;

          return {
            dealership: dealershipName,
            compCode: pref.compCode,
            status: <div
                        className="status-cell">
                          <div className={this.determineStatusColor(formattedStatus)}/>
                          <div className="status-text">{formattedStatus}</div>
                    </div>,
            lastModified: new Date(pref.lastChangeDate).toLocaleDateString("en-US"),
            deleteId: formattedDeleteColumn
          };
        }),
      };
      return (
        <div id="preferences-selection-outer-div">
          <div id="preferences-selection-breadcrumbs-div" className="breadcrumb-trail">
            <Breadcrumbs
              id="preferences-selection-breadcrumbs"
              crumbs={[
                { value: "Home", href: "#" },
                { value: "Dealership Preferences" },
              ]}
            />
          </div>
          <div id="preferences-selection-page-heading-div" className="page__heading">
              Dealership Preferences
          </div>

          {/*  COMMON PAGE-LEVEL NOTIFICATIONS  */}
          {pss.notificationMessage && (
            <div id="preferences-selection-notification-div" className="page-notification-div">
                <Notification
                    id="preferences-selection-notification"
                    role={"alert"}
                    scrollIntoView={true}
                    theme={pss.notificationTheme}>
                    <NotificationIcon/>
                    <NotificationMessage>
                        {pss.notificationMessage}
                    </NotificationMessage>
                    <NotificationCloseButton
                        onClick={() => {pss.clearNotificationMessage()}}
                    />
                </Notification>
            </div>
          )}

          <div id="preferences-selection-layout-search-outer-div" className="dealer-preferences-layout">
            <div id="preferences-selection-layout-search-inner-div" className="dealer-preferences-layout-search">
              <FormElement
                id="preferences-selection-layout-search-formElement"
                children={
                  <TextInput
                    id="preferences-selection-search-compCode-textInput"
                    icon={<Search onClick={() => {this.handleKeyDown({ key: "Enter" })}} className='search-icon' size={22} /> }
                    name="ownergroupCompCode"
                    placeholder="Search by owner group comp code..."
                    className="dealer-preferences-layout-search-input"
                    required={true}
                    isSmall={false}
                    type="text"
                    value={pss.ownerGroupCompCode}
                    onChange={pss.handleTextInput}
                    onKeyDown={this.handleKeyDown}
                  />
                }
              />
              {<SearchResultsArea />}
            </div>

            <div id="preferences-selection-layout-body-outer-div" className="dealer-preferences-layout-body">
                {pss.resultsLabelText &&
                    <div id="preferences-selection-layout-body-message-div" className="dealer-preferences-layout-message">
                        <Notification
                            id="preferences-selection-body-notification"
                            role={"alert"}
                            scrollIntoView={true}
                            theme={pss.notificationTheme}>
                            <NotificationIcon/>
                            <NotificationMessage>
                                {pss.resultsLabelText}
                            </NotificationMessage>
                            <NotificationCloseButton
                                onClick={() => {pss.clearNotificationMessage()}}
                            />
                        </Notification>
                    </div>
                }

                <div id="preferences-selection-layout-table-outer-div" className="dealer-preferences-layout-table">
                    <div id="preferences-selection-layout-table-row-buttonBar-div" className="flex-row">
                        <ButtonBar
                            id="preferences-selection-layout-table-buttonBar"
                            className="dealer-preferences-layout-tabs"
                            options={["Active Dealership Requests"]}
                            selected={pss.buttonBarSelected}
                            role="navigation"
                            onClick={(el) => this.setState({ selected: el.option })}
                        />
                    </div>
                    <div id="preferences-selection-layout-table-row-div" className="flex-row dealer-preferences-layout-table-row-div" >
                        <p id="preferences-selection-layout-table-heading-p" className="dealer-preferences-layout-table-heading">
                            Preferences Set-up Request
                        </p>
                        <Button
                            id="preferences-selection-layout-select-locations-button"
                            theme={"blue"}
                            size="large"
                            disabled={!pss.selectLocationsButtonEnabled}
                            className="dealer-preferences-button"
                            onClick={pss.handleSelectLocationsButton}>
                            Select Locations
                        </Button>
                        <Button
                            id="preferences-selection-layout-close-group-button"
                            theme={"blue"}
                            size="large"
                            disabled={!pss.closeRequestButtonEnabled}
                            className="dealer-preferences-button"
                            onClick={pss.handleCloseGroupButton}>
                            Close Request
                        </Button>
                        <Button
                            id="preferences-selection-layout-add-location-button"
                            theme={"blue"}
                            size="large"
                            disabled={!pss.addLocationButtonEnabled}
                            className="dealer-preferences-button"
                            onClick={pss.handleAddLocationButton}>
                            Add Location
                        </Button>
                    </div>

                    <Table {...validData} />
                </div>
            </div>
          </div>
        </div>
      );
    });
  }
);

const SearchResultsArea = observer(() => {
  return Store.search.searchResults.isSearchResultsVisible ? <PreferencesSearchResults /> : null;
});


