const HOST = window.location.host;
const PROTOCAL = window.location.protocol;
const CALLBACK_PATH = '/implicit/callback';

const DAM_STAGING_OKTA_CLIENT_ID = '0oa1ico10bqGVbhxq0h8'
const DAM_PRODUCTION_OKTA_CLIENT_ID = '0oa1ki1xjdmCicduR0h8'
const DAM_DEVELOPMENT_OKTA_CLIENT_ID = '0oa1o25mellT50RC10h8'
const CLIENT_ID = getClient()

function getClient () {
  switch (window.location.hostname) {
    case 'dam.carfax.net':
      return DAM_PRODUCTION_OKTA_CLIENT_ID
    case 'stagingdam.carfax.net':
      return DAM_STAGING_OKTA_CLIENT_ID
    default :
      return DAM_DEVELOPMENT_OKTA_CLIENT_ID
  }
}

const ISSUER = 'https://carfax.okta.com/oauth2/aus1icq3b4ydyuR2P0h8';
const REDIRECT_URI = `${PROTOCAL}//${HOST}${CALLBACK_PATH}`
const SCOPES = ['openid', 'profile', 'email', 'groups'];

export const DEV = window.location.hostname === 'localhost';

if (DEV) {
  // console.log("window.location", window.location)
  // console.log("REDIRECT_URI", REDIRECT_URI)
}

const config =  {
  oidc: {
    // Required config
    issuer: ISSUER

    // Required for login flow using getWithRedirect()
    ,clientId: CLIENT_ID
    ,redirectUri: REDIRECT_URI

    ,scopes: SCOPES

    //this is the default for pkce
    ,pkce: true

    //Local okta testing config when localhost
    ,devMode: DEV
    //Configure TokenManager to renew tokens within 50 minutes of expiry for testing
    // ,tokenManager: {
    //   expireEarlySeconds: 3480
    // }
  }
  ,CALLBACK_PATH: CALLBACK_PATH
};


export default config
