import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {apiStatus} from "../../utils/constants";
import {makeAutoObservable, runInAction} from "mobx";
import {Reputation} from "../../types/features";
import {ReputationPartner} from "../../types/reputationPartner/reputationPartner";
import "../../utils/newRelic/newrelic";

import {
    reputationGetAssociation,
    reputationGetPartners,
    reputationMakeOrRemoveAssociation
} from "../../clients/damBackendApi";


export default class ReputationPartnerStore {

    // Variables
    associateWithChildLocations: boolean = false;
    associatedReputationPartner = {} as ReputationPartner;
    processingApiCall: boolean = false;
    reputationPartners:  Map<string, ReputationPartner> = new Map();
    managerNames: Array<string> = [];
    selectedPartner = {} as ReputationPartner;
    isReputationPartnerAreaVisible: boolean = false;
    selectedCompCode: string | undefined = "";

    // Stores
    rootStore: RootStore
    authStore: AuthStore
    commonStore: CommonStore
    businessUserStore: BusinessUserStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authStore = this.rootStore.authStore;
        this.commonStore = this.rootStore.commonStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        makeAutoObservable(this);
    };

    public initializeStore = () => {
        this.clearNotificationMessage();
        this.associateWithChildLocations = false;
        this.managerNames = [];
        this.isReputationPartnerAreaVisible = false;
        this.selectedCompCode = "";
        this.commonStore.currentFeature = Reputation;
    };

    public initializeAssociatedPartner = () => {
        this.selectedPartner = {} as ReputationPartner;
        this.associatedReputationPartner = {} as ReputationPartner;
    };

    public initializeApiCall = () => {
        this.clearNotificationMessage();
        this.processingApiCall = true;
    };

    public addOrRemoveAssociation = () => {
        if(this.associationExists()) {
            this.removeAssociation();
        } else {
            this.addAssociation();
        }
    };

    public associationExists = () => {
        return this.associatedReputationPartner?.partnerCode?.length > 0;
    };

    public determineAssociationLabel = () => {
        if(this.associationExists()) {
            return "Remove the Reputation Partner from all child locations below this Owner Group";
        } else {
            return "Associate this Reputation Partner with all child locations below this Owner Group";
        }
    };

    public clearNotificationMessage() {
        runInAction(() => {
            this.commonStore.clearPageMsg();
            this.commonStore.clearFeatureMsg();
        })
    };

    public addAssociation = () => {

        this.initializeApiCall();
        let selectedPartner = this.reputationPartners.get(this.selectedPartner.managerName) as ReputationPartner;
        let processChildren = this.associateWithChildLocations;

        let body: any = {
            "associateChildren": this.associateWithChildLocations,
            "clientCompCode": this.businessUserStore.businessUser?.compCode,
            "createdBy": "",
            "partnerCode": selectedPartner.partnerCode,
            "partnerCompCode": selectedPartner.compCode,
            "action": "make"
        };

        CommonUtils.logNewRelicPageAction("Reputation Partner", {
            userAction: 'Add Association',
            compCode: this.businessUserStore.businessUser?.compCode,
            selectedCompCodeToAssociate: body.clientCompCode,
            selectedParterCode: body.partnerCode,
            associatedWithChildren: body.associateChildren,
            action: body.action
        });

        reputationMakeOrRemoveAssociation(this.authStore.token, body).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.associatedReputationPartner.partnerCode = this.selectedPartner.partnerCode;
                        this.associatedReputationPartner.partnerCompCode = selectedPartner.compCode;
                        this.associatedReputationPartner.managerName = selectedPartner.managerName;
                        if(processChildren) {
                            this.commonStore.displayFeatureNotification(this.selectedPartner.managerName + " has been successfully added!  Association of all child locations is currently in progress.", "green")
                        } else {
                            this.commonStore.displayFeatureNotification(this.selectedPartner.managerName + " has been successfully added!", "green")
                        }
                        this.associateWithChildLocations = false;
                        this.processingApiCall = false;
                    })
                    break
                case 'BAD REQUEST' :
                    runInAction(() => {
                        this.managerNames = [];
                        this.commonStore.displayFeatureNotification(resp?.data?.status, "orange")
                        CommonUtils.displayConsoleLogError(" *** Error calling IA Partner Service to Add an association.  Response: " + JSON.stringify(resp));
                        this.processingApiCall = false;
                    })
                    break;
                default :
                    runInAction(() => {
                        this.managerNames = [];
                        this.commonStore.displayPageNotification(`Severe error occurred trying to add reputation association for "${this.selectedPartner.managerName}".`, "red")
                        CommonUtils.displayConsoleLogError(" *** Error calling IA Partner Service to Add an association.  Response: " + JSON.stringify(resp));
                        this.processingApiCall = false;
                    })
                    break;
            }
        });
    };


    public removeAssociation = () => {

        this.initializeApiCall();
        let associatedPartner = this.associatedReputationPartner;
        let processChildren = this.associateWithChildLocations;

        let body: any = {
            "associateChildren": this.associateWithChildLocations,
            "clientCompCode": this.businessUserStore.businessUser?.compCode,
            "createdBy": "",
            "partnerCode": associatedPartner.partnerCode,
            "partnerCompCode": associatedPartner.partnerCompCode,
            "action": "remove"
        };

        CommonUtils.logNewRelicPageAction("Reputation Partner", {
            userAction: 'Remove Association',
            compCode: this.businessUserStore.businessUser?.compCode,
            selectedCompCodeToAssociate: body.clientCompCode,
            selectedParterCode: body.partnerCode,
            associatedWithChildren: body.associateChildren,
            action: body.action
        });

        reputationMakeOrRemoveAssociation(this.authStore.token, body).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    if(resp.data.status === 400) {
                        runInAction(() => {
                            this.commonStore.displayPageNotification("Error calling IA Partner Service to remove the association.", "red")
                            CommonUtils.displayConsoleLogError(" *** Error calling IA Partner Service to Remove association.  Response: " + JSON.stringify(resp));
                            this.processingApiCall = false;
                        })
                        break;
                    } else {
                        runInAction(() => {
                            this.initializeAssociatedPartner();
                            if (processChildren) {
                                this.commonStore.displayFeatureNotification(associatedPartner.managerName + " has been successfully removed!  Removal of all child locations is currently in progress.", "green")
                            } else {
                                this.commonStore.displayFeatureNotification(associatedPartner.managerName + " has been successfully removed!", "green")
                            }
                            this.associateWithChildLocations = false;
                            this.processingApiCall = false;
                        })
                        break
                    }
                case 'BAD REQUEST' :
                    runInAction(() => {
                        this.commonStore.displayFeatureNotification(resp?.data?.status, "orange")
                        CommonUtils.displayConsoleLogError(" *** Error calling IA Partner Service to Remove association.  Response: " + JSON.stringify(resp));
                        this.processingApiCall = false;
                    })
                    break;
                default :
                    runInAction(() => {
                        this.commonStore.displayPageNotification(`Severe error occurred trying to remove associated partner "${this.selectedPartner.managerName}".`, "red")
                        CommonUtils.displayConsoleLogError(" *** Error calling IA Partner Service to Remove association.  Response: " + JSON.stringify(resp));
                        this.processingApiCall = false;
                    })
                    break;
            }
        });
    };


    public getReputationPartners = () => {

        CommonUtils.logNewRelicPageAction("Reputation Partner", {userAction: 'Get Reputation Partners'});

        this.initializeApiCall()

        reputationGetPartners(this.authStore.token).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.processPartnersList(resp);
                        this.processingApiCall = false;
                    })
                    break;
                case 'NOT FOUND' :
                    runInAction(() => {
                        this.managerNames = [];
                        this.commonStore.displayFeatureNotification(`No reputation partners exist.`, "orange")
                        this.processingApiCall = false;
                    })
                    break;
                case apiStatus.forbidden :
                    runInAction(() => {
                        this.managerNames = [];
                        this.processingApiCall = false;
                        this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    })
                    break;
                default :
                    runInAction(() => {
                        this.managerNames = [];
                        this.processingApiCall = false;
                        this.commonStore.displayPageNotification(`Severe error occurred trying to retrieve reputation partners for ${this.businessUserStore.businessUser?.compCode}`, "red")
                        CommonUtils.displayConsoleLogError(" *** Error calling IA Partner Service to get a list of partners.  Response: " + JSON.stringify(resp));
                    })
                    break;
            }
        });
    };


    protected processPartnersList = (resp: any) => {

        let managerNamesList: Array<string> = [];
        let reputationPartnersMap = new Map();

        resp?.data?.partners?.forEach(function(reputationPartner : ReputationPartner) {
            managerNamesList.push(reputationPartner.managerName);
            reputationPartnersMap.set(reputationPartner.managerName, reputationPartner);
        })

        if(managerNamesList.length > 0 ) {
            managerNamesList.sort();
            this.managerNames = managerNamesList;
            this.reputationPartners = reputationPartnersMap;
        } else {
            this.commonStore.displayFeatureNotification(`No reputation partners were loaded.`, "orange")
        }
    }


    public getAssociation = (compCode: any) => {

        reputationGetAssociation(this.authStore.token, compCode).then((resp : any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.associatedReputationPartner = resp?.data.associatedPartner;
                        this.selectedPartner = this.associatedReputationPartner;
                    })
                    break;
                case apiStatus.forbidden :
                    runInAction(() => {
                        this.managerNames = [];
                        this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    })
                    break;
                default :
                    runInAction(() => {
                        this.managerNames = [];
                        this.commonStore.displayPageNotification(`Severe error occurred trying to retrieve association for comp code ${this.businessUserStore.businessUser?.compCode}`, "red")
                        CommonUtils.displayConsoleLogError(` *** Error calling IA Partner Service to get retrieve association for comp code ${this.businessUserStore.businessUser?.compCode}\`.  Response: ` + JSON.stringify(resp));
                    })
                    break;
            }
        });
    };


    public handleAssociateChildLocationsChecked = (checked: boolean) => {
        this.associateWithChildLocations = checked;
    };


    public handleSelectPartner = (selectedPartnerManagerName :any) => {
        if(selectedPartnerManagerName !== "")
        {
            this.selectedPartner = this.reputationPartners.get(selectedPartnerManagerName) as ReputationPartner;
        }
    };
}
