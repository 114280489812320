import * as React from "react";
import CommonUtils from "../../../utils/commonUtils";
import Button from "@carfax-stencils/button";
import Store from "../../../stores";
import {Table, TBody, TD, TH, THead, TR} from "@carfax-stencils/table";
import {LegacyUser} from "../../../types/acctMgmt/legacyUser";
import {NoResults} from "../../../shared-components/noResults/NoResults";
import {observer} from "mobx-react";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import "../../../utils/newRelic/newrelic";
import "../../../styles/main.scss";
import "./LegacyAcctMgmtDetailsTab.scss";
import {Menu, MenuButton, MenuList, MenuListItem} from "@carfax-stencils/menu";
import { DotsVertical } from "@carfax-stencils/icon";
import CreateLegacyUserModal, {CreateLegacyUserProps} from "./createUser/CreateLegacyUserModal";
import EditLegacyUserModal, {EditLegacyUserProps} from "./editUser/EditLegacyUserModal";
import {CheckboxToggle} from "@carfax-stencils/input-checkbox";
import ConfirmModal, {ConfirmProps} from "../../../shared-components/confirm/ConfirmModal";

// Stores
const rootStore = Store.root.root;
const acctMgmtStore = rootStore.acctMgmtStore;
const businessUserStore = rootStore.businessUserStore;
const commonStore = rootStore.commonStore;

interface LegacyAcctMgmtTabProps {
    compCode:any
}


export default observer (
    class LegacyAcctMgmtTab extends React.Component<LegacyAcctMgmtTabProps> {

        state = {
            ready: false
        };

        async componentDidMount() {
            this.setState(
                {ready:true}
            )
            if(businessUserStore.businessUser?.compCode) {
                await this.loadData()
            }
        };

        async componentDidUpdate() {
            if(businessUserStore.businessUser?.compCode !== acctMgmtStore.compCode) {
                await this.loadData()
            }
        };

        initializePage = async () => {
            acctMgmtStore.initializeStore()
        };

        private async loadData() {

            commonStore.processingApiCall(true)

            await Promise.all([
                acctMgmtStore.getLegacyUsersAuthStatus(),
                acctMgmtStore.getLegacyUsersByCompCode()
            ]);

            commonStore.processingApiCall(false)
        };

        determineStatusColor = (status: string) : string => {

            let classNameStatus = ""

            if(status === "A") {
                classNameStatus = "user-status-active"
            }

            if(status === "I") {
                classNameStatus = "user-status-inactive"
            }

            return classNameStatus
        }

        determineStatus = (status: string) : string => {
            let userStatus = "";

            switch(status) {
                case "A": {
                    userStatus = "Active"
                    break;
                }
                case "I": {
                    userStatus = "Inactive"
                    break;
                }
                default: {
                    userStatus = status
                    break;
                }
            }
            return userStatus;
        }

        CreateLegacyUserModalComponent = observer( () => {
            const createLegacyUserProps: CreateLegacyUserProps = {
                isVisible: acctMgmtStore.isCreateLegacyUserModalVisible,
                store: acctMgmtStore
            }
            return acctMgmtStore.isCreateLegacyUserModalVisible ? (
                <CreateLegacyUserModal { ...createLegacyUserProps } />
            ) : null;
        });

        EditLegacyUserModalComponent = observer( () => {
           const editLegacyUserProps: EditLegacyUserProps = {
               isVisible: acctMgmtStore.isEditLegacyUserModalVisible,
               store: acctMgmtStore
           }
           return acctMgmtStore.isEditLegacyUserModalVisible ? (
               <EditLegacyUserModal { ...editLegacyUserProps } />
           ) : null;
        });

        CnameUserAuthStatusToggleModal = observer(() => {
            const confirmProps: ConfirmProps = {
                headerTitle: "Turn Off Login with Username",
                confirmButtonMessage: "Disable",
                isVisible : acctMgmtStore.isCnameAuthStatusModalVisible,
                onCloseMethod : acctMgmtStore.disableCnameAuthStatus,
                theme: "red",
                confirmModalMessageJsx: (
                    <div>
                        <div> Are you sure you want to disable login with username for <b>{businessUserStore.businessUser?.companyName}?</b></div>
                        <br/><div>If you turn this feature off, all users at this dealership will only be able to login to CFO and the CARFAX for Dealers mobile app with an email address.</div>
                        <br/><div>Dealers will still be able to use their CNAME/DNAME with partner tools.</div>
                    </div>
                )
            }
            return acctMgmtStore.isCnameAuthStatusModalVisible ? (
                <ConfirmModal { ...confirmProps} />
            ) : null;
        });

        render() {
            if (!this.state.ready) {
                return (
                    <NoResults/>
                )
            }
            return (
                <div id="legacy-acct-mgmt-tab-div" className="left-padding dealer-acct-mgmt-tab-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner/>
                    )}
                    <div className="legacy-acct-mgmt-heading-section">
                        <div id="legacy-acct-mgmt-create-user-button-div">
                            <Button
                                id="legacy-acct-mgmt-create-user-button"
                                theme="blue"
                                size="medium"
                                children={`Create`}
                                disabled={false}
                                onClick={acctMgmtStore.displayCreateLegacyUserModal}
                            />
                        </div>
                        <div id="legacy-acct-mgmt-cname-toggle-div" className="legacy-acct-mgmt-cname-toggle">
                            <CheckboxToggle
                                id="legacy-acct-mgmt-cname-toggle"
                                label="Allow Login with Legacy Credentials"
                                labelAlignment="left"
                                checked={!acctMgmtStore.cnameAuthStatus.disabled}
                                onChange={event => {
                                    acctMgmtStore.onCnameLoginToggle(event.target.checked)
                                }}
                            />
                        </div>
                    </div>
                    <div className="legacy-acct-mgmt-cname-last-updated">
                        {acctMgmtStore.getLegacyUserLastUpdatedAuthStatus()}
                    </div>

                    <Table aria-describedby="legacy-acct-mgmt-users-table">
                        <THead  className="legacy-acct-mgmt-users-table__heading">
                            <TH className="legacy-acct-mgmt-users-table__heading__TH" width={90}>Username</TH>
                            <TH className="legacy-acct-mgmt-users-table__heading__TH" width={140}>Name on Report</TH>
                            <TH className="legacy-acct-mgmt-users-table__heading__TH" width={100}>Password</TH>
                            <TH className="legacy-acct-mgmt-users-table__heading__TH" width={113}>Status</TH>
                            <TH className="legacy-acct-mgmt-users-table__heading__TH" width={175}>Modified By</TH>
                            <TH className="legacy-acct-mgmt-users-table__heading__TH" width={110}>Modified Date</TH>
                            <TH className="legacy-acct-mgmt-users-table__heading__TH" width={10}></TH>
                        </THead>
                        <TBody>
                            {acctMgmtStore.legacyUserRecords.map((legacyUserRecord: LegacyUser, index: number) => (
                                <TR className="legacy-acct-mgmt-users-table__row" key={index}>
                                    <TD className="legacy-acct-mgmt-users-table__column">{legacyUserRecord.username}</TD>
                                    <TD className="legacy-acct-mgmt-users-table__column">{legacyUserRecord.name}</TD>
                                    <TD className="legacy-acct-mgmt-users-table__column">{legacyUserRecord.password}</TD>
                                    <TD className="legacy-acct-mgmt-users-table__column">
                                        <div className="legacy-user-status-cell">
                                            <div className={this.determineStatusColor(legacyUserRecord.status)}></div>
                                            <div className="legacy-user-status-text">{this.determineStatus(legacyUserRecord.status)}</div>
                                        </div>
                                    </TD>
                                    <TD className="legacy-acct-mgmt-users-table__column">{legacyUserRecord.modifiedBy}</TD>
                                    <TD className="legacy-acct-mgmt-users-table__column">{CommonUtils.convertEpochDateToMMDDYYYY(legacyUserRecord.dateModified)}</TD>
                                    <TD className="legacy-acct-mgmt-users-table__column">
                                        <div id="legacy-acct-mgmt-users-table-user-elipses-menu">
                                            <EllipsesLegacyUserMenuComponent legacyUser={legacyUserRecord}/>
                                        </div>
                                    </TD>
                                </TR>
                            ))}
                        </TBody>
                    </Table>
                    <this.CreateLegacyUserModalComponent/>
                    <this.EditLegacyUserModalComponent/>
                    <this.CnameUserAuthStatusToggleModal/>
                </div>
            );
        };
    }
);


const EllipsesLegacyUserMenuComponent = observer( (props: {legacyUser: LegacyUser}) => {
    return (
        <div id="legacy-acct-mgmt-ellipses-menu-main-div">
            <Menu id="legacy-acct-mgmt-ellipses-menu" className="legacy-acct-mgmt-ellipses-menu">
                <MenuButton id="legacy-acct-mgmt-ellipses-menu-button" disabled={false} theme="blue-flat" size="small">
                    <DotsVertical />
                </MenuButton>
                <MenuList id="legacy-acct-mgmt-ellipses-menu-list" className="legacy-acct-mgmt-ellipses-menu-list" width={250} position="left">
                    <MenuListItem id="legacy-acct-mgmt-ellipses-menu-item-edit" className="legacy-acct-mgmt-ellipses-menu-list-item" onClick={() => acctMgmtStore.displayEditLegacyUserModal(props.legacyUser)}>Edit User</MenuListItem>
                </MenuList>
            </Menu>
        </div>
    )
});