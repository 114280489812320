import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import React from "react";
import {makeAutoObservable, runInAction} from "mobx";
import {apiStatus} from "../../utils/constants";
import {BusinessHours, Day, DayNames, daysList} from "../../types/profile/businessHours";
// import {SelectValue} from "@carfax-stencils/input-select/lib/src/parts/types";

import {
    getDealerStoreHours,
    updateDealerStoreHours
} from "../../clients/dps/dpsApi";


export default class BusinessHoursStore {

    // Variables
    businessHours: BusinessHours;
    originalBusinessHours: BusinessHours;

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authStore = this.rootStore.authStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;
        makeAutoObservable(this);

        this.businessHours = this.initializeStore();
        this.originalBusinessHours = this.initializeStore();
    };

    public initializeStore = () : BusinessHours => {
        const defaultDay = {id:undefined, open:null, close:null, byAppointment: false, hoursToggle: false}
        const businessHours = {
            compCode: this.businessUserStore?.businessUser?.compCode || "",
            monday: defaultDay,
            tuesday: defaultDay,
            wednesday: defaultDay,
            thursday: defaultDay,
            friday: defaultDay,
            saturday: defaultDay,
            sunday: defaultDay
        }
        this.initializeHoursToggle(businessHours)
        return businessHours
    };

    public initializeHoursToggle(hours:BusinessHours) {
        daysList.forEach((day:DayNames) => {
            hours[day].hoursToggle = hours[day].open != null && hours[day].close != null
        })
    };

    public getBusinessHours = async () => {

        let compCode = this.businessUserStore.businessUser?.compCode || "";

        await getDealerStoreHours(this.authStore.token, compCode).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.businessHours = resp.data;
                        this.originalBusinessHours = resp.data;
                    });
                    this.initializeHoursToggle(this.businessHours);
                    this.initializeHoursToggle(this.originalBusinessHours);
                    break;
                case 'NOT FOUND' :
                    runInAction(() => {
                        this.businessHours.compCode = compCode
                        this.originalBusinessHours.compCode = compCode
                    });
                    CommonUtils.displayConsoleLogError(`*** No store hours found for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve store hours for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    break;
            };
        });
    };

    public async updateBusinessHours() {

        CommonUtils.logNewRelicPageAction("Profile - Dealer Details", {userAction: 'Update Business Hours', compCode: this.businessHours.compCode})

        let updateSuccess: boolean = false;
        await updateDealerStoreHours(this.authStore.token, this.businessHours).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    updateSuccess = true;
                    runInAction(() => {
                        this.originalBusinessHours = CommonUtils.cloneObject(this.businessHours);
                    });
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to update business hours for comp code ${this.businessHours.compCode}.  Response: ` + JSON.stringify(resp));
                    updateSuccess = false;
                    break;
            };
        });

        return updateSuccess;
    };

    public handleHoursToggleUpdate = (event: React.ChangeEvent<HTMLInputElement>, day: DayNames) => {
        this.businessHours[day].hoursToggle = event.currentTarget.checked;
        if (!event.currentTarget.checked) {
            this.businessHours[day].open = null
            this.businessHours[day].close = null
        } else {
            this.businessHours[day].open = this.originalBusinessHours[day].open
            this.businessHours[day].close = this.originalBusinessHours[day].close
        }
    };

    public handleByApptToggleUpdate = (event: React.ChangeEvent<HTMLInputElement>, day: DayNames) => {
        this.businessHours[day].byAppointment = event.currentTarget.checked;
    };

    public handleSelectOpenHoursInputChanges = (day: DayNames) => (option: any) => {
        runInAction(() => {
            this.businessHours[day].open = CommonUtils.convertTime12to24(option.target.value)
        })
    };

    public handleSelectCloseHoursInputChanges = (day: DayNames) => (option?: any) => {
        runInAction(() => {
            this.businessHours[day].close = CommonUtils.convertTime12to24(option.target.value)
        })
    };

    isBusinessHoursValid = (businessHoursDay : Day, day: string) => {
        const openDate = businessHoursDay.open ? this.convertTimeToDate(businessHoursDay.open) : null;
        const closeDate = businessHoursDay.close ? this.convertTimeToDate(businessHoursDay.close) : null;
        if (businessHoursDay.hoursToggle && (!openDate || !closeDate || closeDate <= openDate) ) {
            this.commonStore.setFormError(`${day}BusinessHours`, "Business Hours");
            return false
        }
        this.commonStore?.removeFormError(`${day}BusinessHours`);
        return true
    };

    private convertTimeToDate(time24h: string) {
        const hours = Number(time24h.split(":")[0]);
        const minutes = Number(time24h.split(":")[1]);
        return new Date(2000, 0, 1, hours, minutes);
    };

    public determineChanges = () : boolean => {
        if (CommonUtils.fieldsChanged(this.businessHours, this.originalBusinessHours)) {
            return true;
        }
        return false;
    };

};
