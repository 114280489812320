import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import EmailsStore from "./emails";
import RootStore from "../root";
import Validator from "../helpers/validator";
import {
    ERROR_MSG_DUPLICATE_EMAILS,
    ERROR_MSG_EMAIL_INVALID,
    resolveInvalidCarfaxEmailMessage
} from "./messaging/errorMessagesProfile";
import {Email} from "../../types/email";
import {emailTypeEnum} from "../../types/profile/enums/emailTypeEnum";
import {makeAutoObservable, runInAction} from "mobx";
import {NotificationMessages} from "../common/messaging/notificationMessages";
import React, {createRef} from "react";


export default class RatingsAndReviewsStore {

    // Fields / Types
    compCode: string = "";
    salesRatingEmails: Array<Email> = new Array<Email>();
    focusOnEmailTextInput: boolean = false;
    emailInputRef: React.RefObject<HTMLInputElement> = createRef();

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;
    emailsStore: EmailsStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authStore = this.rootStore.authStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;
        this.emailsStore = this.rootStore.emailsStore;
        this.initializeRatingsAndReviews();
        makeAutoObservable(this);
    };

    public initializeRatingsAndReviews = () => {
        this.compCode = this.businessUserStore?.businessUser?.compCode || "";
        this.initializeEmails();
    };

    public initializeEmails() {
        this.salesRatingEmails = new Array<Email>();
    }

    public resetStoreToOriginalData() {
        this.emailsStore.resetEmailsToOriginalData([emailTypeEnum.SALES_RATINGS]);
    };

    public onEmailAdd = (emailType: any) => {
        this.commonStore.setFormError(`${emailType}-${this.salesRatingEmails.length}`, ERROR_MSG_EMAIL_INVALID);
        this.salesRatingEmails = this.emailsStore.addEmail(emailType, this.compCode);
        this.focusOnEmailTextInput = true;
    };

    public onEmailRemove = (emailType: string, index: number) => {
        this.salesRatingEmails = this.emailsStore.removeEmail(emailType, index);
        this.commonStore.removeFormError(`${emailType}-${index}`);
    };

    public onEmailChange = (emailType: string, email: string, index: number) => {
        let emailErrorMsg = this.validateEmail(email, emailType)
        let inlineErrorMsg = (emailErrorMsg === ERROR_MSG_DUPLICATE_EMAILS) ? emailErrorMsg : "";
        this.salesRatingEmails = this.emailsStore.changeEmail(emailType, email, inlineErrorMsg, index);
        this.emailsStore.setOrResetEmailsFormError(emailErrorMsg, `${emailType}-${index}`);
    }

    public onEmailBlur = (emailType: string, index: number) => {
        let emailObj = this.salesRatingEmails[index];
        if(emailObj.action) {
            let email = emailObj.email;
            let emailErrorMsg = this.commonStore.getFormError(`${emailType}-${index}`)
            this.salesRatingEmails = this.emailsStore.changeEmail(emailType, email, emailErrorMsg, index);
        }
    }

    private validateEmail(email: string, emailType: string) {

        if(!Validator.isValidEmail(email)) {
            return ERROR_MSG_EMAIL_INVALID
        }

        if(Validator.isCarfaxEmail(email)) {
            return resolveInvalidCarfaxEmailMessage(emailType)
        }

        if(this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.SALES_RATINGS])) {
            return ERROR_MSG_DUPLICATE_EMAILS;
        }

        return "";
    }

    private validateEmailOnLoad(email: string, index: number): string {
        let emailErrorMsg = ""

        if(!Validator.isValidEmail(email)) {
            emailErrorMsg = ERROR_MSG_EMAIL_INVALID;
        }

        this.emailsStore.setOrResetEmailsFormError(emailErrorMsg, `${emailTypeEnum.SALES_RATINGS}-${index}`);
        return emailErrorMsg;
    }

    public getEmails = async (emailType: string) => {

        this.initializeRatingsAndReviews();

        await this.emailsStore.getEmailsFromDPS(this.authStore.token, this.compCode, emailType).then(() => {
            runInAction(() => {
                this.salesRatingEmails = this.emailsStore.getEmails(emailTypeEnum.SALES_RATINGS);
                this.salesRatingEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, index);
                });
            });
        });
    }

    private insertEmails = async () => {

        CommonUtils.logNewRelicPageAction("Profile Ratings & Reviews", {userAction: 'Add R&R Emails', compCode: this.compCode})

        let insertEmailsSuccess: boolean = false;

        await this.emailsStore.insertEmails(this.authStore.token, this.compCode).then((resp) => {
            runInAction(() => {
                insertEmailsSuccess = resp
                this.salesRatingEmails = this.emailsStore.getEmails(emailTypeEnum.SALES_RATINGS);
            })
        });

        return insertEmailsSuccess;
    }

    private deleteEmails = async () => {

        CommonUtils.logNewRelicPageAction("Profile Ratings & Reviews", {userAction: 'Delete R&R Emails', compCode: this.compCode})

        let deleteEmailsSuccess: boolean = false;

        await this.emailsStore.deleteEmails(this.authStore.token, this.compCode).then((resp) => {
            runInAction(() => {
                deleteEmailsSuccess = resp
                this.salesRatingEmails = this.emailsStore.getEmails(emailTypeEnum.SALES_RATINGS);
            })
        });

        return deleteEmailsSuccess;
    }

    public hasChanges = () : boolean => {
        return this.emailsStore.hasEmailsChanged;
    }

    public submitRatingsAndReviewsPreferences = async () => {

        this.commonStore.processingApiCall(true);

        let insertEmailsSuccess: boolean = true;
        let deleteEmailsSuccess: boolean = true;
        if(this.hasChanges()) {
            if(this.emailsStore.emailsToBeInserted.length > 0) {
                insertEmailsSuccess = await this.insertEmails();
            }
            if(this.emailsStore.emailsToBeDeleted.length > 0) {
                deleteEmailsSuccess = await this.deleteEmails();
            }
            if(insertEmailsSuccess && deleteEmailsSuccess) {
                await this.getEmails(emailTypeEnum.SALES_RATINGS);
            }
        }

        this.commonStore.processingApiCall(false);

        if(insertEmailsSuccess && deleteEmailsSuccess) {
            this.commonStore.displayFeatureNotification(NotificationMessages.UPDATE_SUCCESSFUL, "green");
        }
        else {
            if(!insertEmailsSuccess && !deleteEmailsSuccess) {
                this.commonStore.displayPageNotification(NotificationMessages.UPDATE_FAILED, "red")
            }
            else {
                this.commonStore.displayPageNotification(NotificationMessages.UPDATE_SUCCESSFUL_PARTIAL, "red")
            }
        }
    };

};
