import React from "react"
import noResultsFound from "../assets/images/no-results-found.svg"
import "./noResults.scss"

type NoResultsProps = {
  icon?: any
  subtitle?: string
}

export const NoResults = ({
  icon = noResultsFound,
  subtitle = ""
}: NoResultsProps) => (
  <div id="noResults-div" className="no-results">
    <img id="noResults-img" src={icon} alt="Empty table." className="no-results__img" />
    <div id="noResults-subtitle-div" className="no-results__subtitle">{subtitle}</div>
  </div>
)
