import * as React from "react";
import {observer} from "mobx-react";
import "./MoveProfilePreferencesModal.scss";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "@carfax-stencils/modal";
import moveProfilePreferences, {MoveProfilePreferencesSectionEnum} from "../../stores/profile/moveProfilePreferences";
import {
    Notification,
    NotificationCloseButton,
    NotificationIcon,
    NotificationMessage
} from "@carfax-stencils/notification";
import TextInput from "@carfax-stencils/input-text";
import CheckboxInput from "@carfax-stencils/input-checkbox";
import Button from "@carfax-stencils/button";
import {ProcessingApiSpinner} from "../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import CommonStore from "../../stores/common/common";
import {profileTabs} from "../../types/profile/enums/profileTabs";
import CommonUtils from "../../utils/commonUtils";

export type MoveProfilePreferencesProps = {
    headerTitle: string;
    isVisible: boolean;
    store: moveProfilePreferences
    sourceCompCode: string
    sourceCompanyName: string
    commonStore: CommonStore
}

const MoveProfilePreferencesModal = observer((props: MoveProfilePreferencesProps) => {
    return (
        <Modal
            id="move-profile-preferences-modal"
            isVisible={true}
            className="move-profile-preferences-modal"
            onClose={props.store.handleMoveProfilePreferenceCancel}
        >
            <ModalHeader className="bold">
                {props.headerTitle}
            </ModalHeader>
            <ModalBody>
                {props.commonStore.isProcessingApi && (
                    <ProcessingApiSpinner/>
                )}
                {props.store.moveProfilePreferenceSection === MoveProfilePreferencesSectionEnum.Initial &&
                    (<MoveProfilePreferencesInitialSection sourceCompCode={props.sourceCompCode}
                                                           sourceCompanyName={props.sourceCompanyName}
                                                           store={props.store}/>)}
                {props.store.moveProfilePreferenceSection === MoveProfilePreferencesSectionEnum.Confirm &&
                    (<MoveProfilePreferencesConfirmSection sourceCompCode={props.sourceCompCode}
                                                           sourceCompanyName={props.sourceCompanyName}
                                                           store={props.store}/>)}
            </ModalBody>
            <ModalFooter>
                {props.store.moveProfilePreferenceSection === MoveProfilePreferencesSectionEnum.Initial &&
                    <>
                        <Button
                            id="move-profile-preferences-initial-modal-basic-close-button"
                            theme={"blue-outline"}
                            size="large"
                            onClick={props.store.handleMoveProfilePreferenceCancel}
                        >
                            Close
                        </Button>
                        <Button
                            id="move-profile-preferences-initial-basic-next-button"
                            theme={"blue"}
                            size="large"
                            disabled={props.store.disableMoveProfilePreferencesNextButton()}
                            onClick={props.store.handleMoveProfilePreferencesNextButton}
                        >
                            Next Step
                        </Button>
                    </>
                }
                {props.store.moveProfilePreferenceSection === MoveProfilePreferencesSectionEnum.Confirm &&
                    <>
                        <Button
                            id="move-profile-preferences-confirm-modal-basic-close-button"
                            theme={"blue-outline"}
                            size="large"
                            onClick={props.store.handleMoveProfilePreferenceBack}
                        >
                            Back
                        </Button>
                        <Button
                            id="move-profile-preferences-confirm-submit-button"
                            theme={"blue"}
                            size="large"
                            onClick={props.store.handleMoveProfilePreferencesSubmitButton}
                        >
                            Move Preferences
                        </Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )
})

const MoveProfilePreferencesInitialSection = observer((props: {
    sourceCompCode: string,
    sourceCompanyName: string,
    store: moveProfilePreferences
}) => {
    return (
        <div id="move-profile-preferences-section-div" className="move-profile-preferences">
            <div id="move-profile-preferences-message" className="move-profile-preferences__message">
                <div id="move-profile-preferences-oval" className="move-profile-preferences__oval">1</div>
                <div id="move-profile-preferences-text" className="move-profile-preferences__step-text">
                    Tell us about the destination and preferences you want to move.
                </div>
            </div>
            <div id="move-profile-preferences-notificaiton-div" className="top-padding">
                <Notification
                    id="move-profile-preferences-notification-component"
                    role={"alert"}
                    theme={"orange"}>
                    <NotificationIcon/>
                    <NotificationMessage>
                        Emails and phone numbers moved to a destination comp code will be removed
                        from {props.sourceCompanyName} - {props.sourceCompCode}.
                    </NotificationMessage>
                </Notification>
            </div>
            {props.store.compCodeErrorMessage &&
                <div id="move-profile-preferences-error-message" className="top-padding">
                    <Notification
                        id="move-profile-preferences-notification-component"
                        role={"alert"}
                        theme={"red"}>
                        <NotificationIcon/>
                        <NotificationMessage>
                            {props.store.compCodeErrorMessage}
                        </NotificationMessage>
                        <NotificationCloseButton
                            onClick={() => {
                                props.store.setCompCodeError("")
                            }}
                        />
                    </Notification>
                </div>}
            <div id="move-profile-preferences-target-compcode-div" className="top-padding">
                <p id="move-profile-preferences-target-compcode-p" className="input-label">Destination Comp
                    Code</p>
                <TextInput
                    id="move-profile-preferences-target-compcode-input"
                    value={props.store.targetCompCode}
                    type={"string"}
                    onChange={props.store.setTargetCompCode}
                />
            </div>
            <div id="move-profile-preferences-checkbox" className="top-padding">
                <p id="move-profile-preferences-checkbox-p" className="input-label">Select which preferences you want to
                    move</p>
                <CheckboxInput
                    id="move-profile-preferences-checkbox-select-all"
                    className="toggle move-profile-preferences__top-padding"
                    checked={props.store.selectAllChecked}
                    name={`select All`}
                    label={"Select All"}
                    onChange={event => {
                        props.store.setSelectAllChecked(event);
                    }}
                    aria-label={`select-all-toggle`}
                />
                <hr id="move-profile-preferences-checkbox-separator-line"
                    className="move-profile-preferences__separator-line move-profile-preferences__top-margin"/>
                <CheckboxInput
                    id="move-profile-preferences-checkbox-dealer-details"
                    className="toggle move-profile-preferences__top-padding"
                    checked={props.store.checkBoxes.get(profileTabs[0]) || false}
                    name={profileTabs[0]}
                    label={CommonUtils.toTitleCase(profileTabs[0])}
                    onChange={event => {
                        props.store.setDealerDetailsChecked(event);
                    }}
                    aria-label={`dealer-details-toggle`}
                />
                <CheckboxInput
                    id="move-profile-preferences-checkbox-ccl"
                    className="toggle move-profile-preferences__top-padding"
                    checked={props.store.checkBoxes.get(profileTabs[1]) || false}
                    name={profileTabs[1]}
                    label={profileTabs[1]}
                    onChange={event => {
                        props.store.setCCLChecked(event);
                    }}
                    aria-label={`ccl-toggle`}
                />
                <CheckboxInput
                    id="move-profile-preferences-checkbox-c4l"
                    className="toggle move-profile-preferences__top-padding"
                    checked={props.store.checkBoxes.get(profileTabs[2]) || false}
                    name={profileTabs[2]}
                    label={profileTabs[2]}
                    onChange={event => {
                        props.store.setC4LChecked(event);
                    }}
                    aria-label={`c4l-toggle`}
                />
                <CheckboxInput
                    id="move-profile-preferences-checkbox-RnR"
                    className="toggle move-profile-preferences__top-padding"
                    checked={props.store.checkBoxes.get(profileTabs[3]) || false}
                    name={profileTabs[3]}
                    label={profileTabs[3]}
                    onChange={event => {
                        props.store.setRnRChecked(event)
                    }}
                    aria-label={`rnr-toggle`}
                />
                <CheckboxInput
                    id="move-profile-preferences-checkbox-call-mgmt"
                    className="toggle move-profile-preferences__top-padding"
                    checked={props.store.checkBoxes.get(profileTabs[4]) || false}
                    name={profileTabs[4]}
                    label={CommonUtils.toTitleCase(profileTabs[4])}
                    onChange={event => {
                        props.store.setCallMgmtChecked(event)
                    }}
                    aria-label={`call-mgmt-toggle`}
                />
            </div>

        </div>
    )
})

const MoveProfilePreferencesConfirmSection = observer((props: {
    sourceCompCode: string,
    sourceCompanyName: string,
    store: moveProfilePreferences
}) => {
    return (
        <div id="move-profile-preferences-confirm-modal">
            <div id="move-profile-preference-modal__message" className="move-profile-preferences__message">
                <div className="move-profile-preferences__oval">2</div>
                <div id="move-profile-preference-confirmation-message-div"
                     className="move-profile-preferences__step-text">
                    Confirm details and move preferences.
                </div>
            </div>
            <div id="move-profile-preferences-confirmation-detail-div"
                 className="top-padding move-profile-preferences__table-details move-profile-preferences__top-margin">
                <table>
                    <tbody>
                    <tr>
                        <td className="move-profile-preferences__table-column1">Original Location:</td>
                        <td>{props.sourceCompanyName} - {props.sourceCompCode}</td>
                    </tr>
                    <tr>
                        <td className="move-profile-preferences__table-column1">Destination Location:</td>
                        <td>{props.store.targetCompanyName} - {props.store.targetCompCode}</td>
                    </tr>
                    <tr>
                        <td className="move-profile-preferences__table-column1">Moving Preferences:</td>
                        <td>{props.store.returnSelectedPreferences()}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )

})
export default MoveProfilePreferencesModal;