import * as React from "react";
import "../../../styles/main.scss";
import "../../../utils/newRelic/newrelic";
import "./UsedCarListings.scss";
import Button from "@carfax-stencils/button";
import SelectInput from "@carfax-stencils/input-select";
import Store from "../../../stores";
import TextInput from "@carfax-stencils/input-text";
import {observer} from "mobx-react";
import {Plus} from '@carfax-stencils/icon';
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import {Trash} from '@carfax-stencils/icon'
import InlineErrorMessage from "../../../shared-components/layout/error/InlineErrorMessage";
import {Email} from "../../../types/email";
import {CheckboxToggle} from "@carfax-stencils/input-checkbox";
import {DB_ACTION_DELETE} from "../../../utils/constants";
import {EmailTextInput} from "../../../shared-components/email/EmailTextInput";
import {emailTypeEnum} from "../../../types/profile/enums/emailTypeEnum";
import AutoCompleteInput from "@carfax-stencils/input-auto-complete";
import {EnhancedEmailHeader} from "../../../shared-components/email/EnhancedEmailHeader";
import {EnhancedEmailTextInput} from "../../../shared-components/email/EnhancedEmailTextInput";
import {SecondaryName} from "../../../types/secondaryName";

// Props
interface UsedCarListingsTabProps {
    compCode:any
}

// Stores
const rootStore = Store.root.root;
const authStore = rootStore.authStore;
const businessUserStore = rootStore.businessUserStore;
const commonStore = rootStore.commonStore;
const uclSettingsStore = rootStore.uclSettingsStore;
const secondaryNamesStore = rootStore.secondaryNamesStore;

interface UsedCarListingsTabProps {
    compCode:any
}

export default observer (

    class UsedCarListingsTab extends React.Component<UsedCarListingsTabProps> {

        state = {
            ready: false
        };

        async componentDidMount() {
            this.setState(
                {ready:true}
            );
            await this.loadData()

            /*
                Perform these validation checks when this tab is selected. If the values for these fields in the database
                aren't valid according to the rules, these fields will be flagged in error on the form so the user can
                correct them.
             */
            uclSettingsStore.validAllowBackfillAndCpoListings("allowBackfill" );
            uclSettingsStore.validSyndicateUclOnly("carfaxPartnerSyndicate");
        };

        async componentDidUpdate() {
            if(businessUserStore.businessUser?.compCode !== uclSettingsStore.uclSettings?.compCode) {
                await this.loadData()
            }

            this.focusOnEmailAfterAdd();
        };

        private async loadData() {
            commonStore.processingApiCall(true);
            await Promise.all([
                uclSettingsStore.getUclSettings(),
                uclSettingsStore.getEmails(),
                uclSettingsStore.getSecondaryNames()
            ]);
            commonStore.processingApiCall(false);
        };

        private focusOnEmailAfterAdd() {
            if(uclSettingsStore.focusOnEmailTextInput) {
                uclSettingsStore.emailInputRef?.current?.focus();
                uclSettingsStore.focusOnEmailTextInput = false;
            }
        }

        render() {
            return (
                <div id="ucl-settings-tab-div" className="used-car-listings-tab-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner/>
                    )}
                    <p id="ucl-settings-tab-main-heading" className="main-heading">
                        Car Listings Preferences
                    </p>


                    {/*  " Allow Backfill " Section   */}
                    <hr id="allow-backfill-separator-line" className="separator-line"/>
                    <p id="allow-backfill-sub-heading" className="sub-heading">
                        Backfill
                    </p>
                    <div id="allow-backfill-description-div" className="left-padding">
                        <p id="allow-backfill-description-text" className="primary_text">
                            When enabled, all eligible dealership inventory displays on CARFAX Used Car Listings.
                        </p>
                    </div>
                    <div id="allow-backfill-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="allow-backfill-toggle-label" className="toggle-label">Enable Backfill Inventory</p>
                        <CheckboxToggle
                            id="allow-backfill-toggle"
                            checked={uclSettingsStore?.uclSettings.allowBackfill === true ? true : false}
                            onChange={event => {
                                uclSettingsStore.onToggleBackfill(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>
                    <div id="backfill-inline-error-div" className="backfill-inline-error-div">
                        <InlineErrorMessage id="backfill-inline-error" errorMessage={uclSettingsStore.allowBackfillErrorMessage}/>
                    </div>
                    <div id="allow-backfill-description-div" className="top-padding left-padding">
                        <p id="allow-backfill-subtext" className="tertiary_text bottom-padding">
                            Note: If Backfill=yes and CPO=yes, only CPO inventory will appear.
                        </p>
                    </div>


                    {/*  " CPO Listings " Section   */}
                    <hr id="cpo-listings-separator-line" className="separator-line"/>
                    <p id="cpo-listings-sub-heading" className="sub-heading">
                        CPO Listings
                    </p>
                    <div id="cpo-listings-description-div" className="left-padding">
                        <p id="cpo-listings-description-text" className="primary_text">
                            When enabled, only CPO inventory displays on CARFAX Used Car Listings.
                        </p>
                    </div>
                    <div id="cpo-listings-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="allow-backfill-toggle-label" className="toggle-label">Enable CPO-Only Inventory</p>
                        <CheckboxToggle
                            id="cpo-listings-toggle"
                            checked={uclSettingsStore?.uclSettings.cpoListing === true ? true : false}
                            onChange={event => {
                                uclSettingsStore.onToggleCpoListing(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>
                    <div id="cpo-listing-inline-error-div" className="cpo-listing-inline-error-div">
                        <InlineErrorMessage id="cpo-listing-inline-error" errorMessage={uclSettingsStore.cpoListingErrorMessage}/>
                    </div>

                    {/*  " Inventory Information " Section   */}
                    <hr id="inventory-information-separator-line" className="separator-line"/>
                    <p id="inventory-information-sub-heading" className="sub-heading">
                        Inventory Information
                    </p>
                    <div id="inventory-information-description-div" className="left-padding">
                        <p id="inventory-information-description-text" className="primary_text">
                            This is the partner sending the dealership's inventory information for Carfax Car Listings.
                        </p>
                    </div>
                    {businessUserStore.isDealerNew() &&
                        <div id="inventory-provider-toggle-div" className="left-padding top-padding inline-flex">
                            <p id="toggle-car-listings-label" className="toggle-label">Use separate providers for used & new car listings</p>
                            <CheckboxToggle
                                id="car-listings-toggle"
                                checked={uclSettingsStore?.useSeparateProvidersCarListings === true ? true : false}
                                onChange={event => {
                                    uclSettingsStore.onToggleSeparateInventoryProvider(event.target.checked)
                                }}
                                disabled={authStore.isNonAdminGroup()}
                            />

                        </div>
                    }
                    {!uclSettingsStore.useSeparateProvidersCarListings || !businessUserStore.isDealerNew() ?
                    <>
                        <div id="inventory-information-provider-div" className="left-padding">
                            <p id="inventory-information-provider-header" className="input-label ">
                                Inventory Provider
                            </p>
                        </div>
                        <div id="inventory-information-provider-dropdown-div" className="left-padding inventory-information-input-width">
                            <AutoCompleteInput
                                options={uclSettingsStore.partnerStore.partnerNames}
                                value={uclSettingsStore.selectedInventoryProvider}
                                onSelect={(option : any) => {
                                    uclSettingsStore.onInventoryProviderChange(option?.value)
                                }}
                                onChange={(inventoryProviderName : string) => {
                                    uclSettingsStore.onInventoryProviderChange(inventoryProviderName)
                                }}
                                placeholder="Select an option"
                                noMatchesMessage="No matches found"
                                disableSort
                            />
                            <InlineErrorMessage id="inventory-provider-inline-error" errorMessage={uclSettingsStore.inventoryProviderErrorMsg}/>
                        </div>
                    </>
                    :
                    <>
                        <br></br>
                        <br></br>
                        <div id="dealer-new-inventory-provider-toggle-div" className="left-padding new-used-car-listings-container">
                            <p id="used-car-listings-only-sub-heading" className="sub-heading">
                                Used Car Listings Only
                            </p>
                            <div id="used-inventory-information-provider-div" className="left-padding">
                                <p id="used-inventory-information-provider-header" className="input-label required-field">
                                    Inventory Provider
                                </p>
                            </div>
                            <div id="used-inventory-information-provider-dropdown-div" className="left-padding inventory-information-input-width">
                                <AutoCompleteInput
                                    options={uclSettingsStore.partnerStore.partnerNames}
                                    value={uclSettingsStore.selectedInventoryProvider}
                                    onSelect={(option : any) => {
                                        uclSettingsStore.onInventoryProviderChange(option?.value, true)
                                    }}
                                    onChange={(inventoryProviderName : string) => {
                                        uclSettingsStore.onInventoryProviderChange(inventoryProviderName, true)
                                    }}
                                    placeholder="Select an option"
                                    noMatchesMessage="No matches found"
                                    disableSort
                                />
                                <InlineErrorMessage id="used-car-inventory-provider-inline-error" errorMessage={uclSettingsStore.inventoryProviderErrorMsg}/>
                            </div>
                            <br></br>
                            <hr id="inventory-provider-separator-line" className="horizontal-line"/>
                            <p id="new-car-listings-only-sub-heading" className="sub-heading">
                                New Car Listings Only
                            </p>
                            <div id="new-car-inventory-information-provider-div" className="left-padding">
                                <p id="new-car-inventory-information-provider-header" className="input-label required-field">
                                    Inventory Provider
                                </p>
                            </div>
                            <div id="new-car-inventory-information-provider-dropdown-div" className="left-padding inventory-information-input-width">
                                <AutoCompleteInput
                                    options={uclSettingsStore.partnerStore.partnerNames}
                                    value={uclSettingsStore.selectedInventoryProviderNcl}
                                    onSelect={(option : any) => {
                                        uclSettingsStore.onInventoryProviderChange(option?.value, true, true)
                                    }}
                                    onChange={(inventoryProviderName : string) => {
                                        uclSettingsStore.onInventoryProviderChange(inventoryProviderName, true, true)
                                    }}
                                    placeholder="Select an option"
                                    noMatchesMessage="No matches found"
                                    disableSort
                                />
                                <InlineErrorMessage id="new-car-inventory-provider-inline-error" errorMessage={uclSettingsStore.inventoryProviderNclErrorMsg}/>
                            </div>
                        </div>
                    </>
                    }
                    <div id="inventory-information-variables-div" className="left-padding">
                        <p id="inventory-information-variables-header" className="input-label ">
                            Inventory Key Variable(s)
                        </p>
                    </div>
                    <div id="inventory-information-variables-dropdown-div" className="inventory-information-input-width">
                        {uclSettingsStore.inventoryKeys.map((value:any, index:number) => (
                            <InventoryKeyComponent key={`InventoryKeyComponent-${index}`} inventoryKey={value} index={index}/>))
                        }
                    </div>
                    <div id="inventory-key-variable-button-div" className="left-padding bottom-padding inventory-information-input-width">
                        <Button
                            id="inventory-information-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add Inventory Key Variable`}
                            onClick={() => {
                                uclSettingsStore.onInventoryKeyAdd()
                            }}
                            theme="blue-outline"
                            disabled={uclSettingsStore.inventoryKeys.length >= 10}
                        />
                    </div>


                    {/*  " Aggregate To " Section   */}
                    <hr id="aggregate-to-separator-line" className="separator-line"/>
                    <p id="aggregate-to-sub-heading" className="sub-heading">
                        Aggregate To
                    </p>
                    <div id="aggregate-to-description-div" className="left-padding">
                        <p id="aggregate-to-description-text" className="primary_text">
                            When a comp code is provided, all of a dealership's CCL inventory will display at one location based on that comp code.
                        </p>
                    </div>
                    <div id="aggregate-to-live-through-comp-code-div" className="left-padding">
                        <p id="aggregate-to-live-through-comp-code-header" className="input-label ">
                            Live Through Comp Code
                        </p>
                    </div>
                    <div id="aggregate-to-live-through-comp-code-input-text-div" className="bottom-padding aggregate-to-input-width">
                        <TextInput
                            id="aggregate-to-live-through-comp-code-input-text"
                            className="left-padding dealer-penalty-date-input-text"
                            value={uclSettingsStore.uclSettings.aggregatedTo ? uclSettingsStore.uclSettings.aggregatedTo : "" }
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                uclSettingsStore.onAggregateToChange(event.target.value);
                            }}
                            type="text"
                            maxLength={10}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>


                    {/*  " ADF Lead Emails " Section   */}
                    <hr id="adf-emails-separator-line" className="separator-line"/>
                    <p id="adf-emails-sub-heading" className="sub-heading">
                        ADF Lead Email(s)
                    </p>
                    <div id="adf-emails-description-div" className="left-padding">
                        <p id="adf-emails-description-text" className="primary_text">
                            This email is the address for all CRM online leads.
                        </p>
                    </div>
                    <InlineErrorMessage id="adf-lead-enhanced-required-inline-error" errorMessage={uclSettingsStore.enhancedRequiredMsg}/>

                    {!businessUserStore.isDealerNew() && (
                        <div id="adf-emails-div" className="email-input">
                            {uclSettingsStore.adfLeadEmails?.map((email:Email, index:number) => (
                                email.action !== DB_ACTION_DELETE &&
                                <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={uclSettingsStore}
                                                textInputRef={uclSettingsStore.emailInputRef}/>))
                            }
                        </div>
                    )}

                    {businessUserStore.isDealerNew() && (
                        <div id="ucl-adf-emails-div-enhanced" className="enhanced-email-input-row">
                            {uclSettingsStore.hasAdfLeadEmails() &&
                                <EnhancedEmailHeader/>
                            }
                            {uclSettingsStore.adfLeadEmails?.map((email:Email, index:number) => (
                                email.action !== DB_ACTION_DELETE &&
                                <EnhancedEmailTextInput key={`EnhancedEmailComponent-${index}`} email={email} index={index} store={uclSettingsStore}
                                                        textInputRef={uclSettingsStore.emailInputRef}/>))
                            }
                        </div>
                    )}

                    <div id="adf-emails-add-button-div" className="left-padding bottom-padding email-input">
                        <Button
                            id="adf-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add ADF Lead Email`}
                            onClick={() => {
                                uclSettingsStore.onEmailAdd(emailTypeEnum.ADF_LEAD)
                            }}
                            theme="blue-outline"
                        />
                    </div>


                    {/*  " Text Lead Emails " Section   */}
                    <hr id="text-emails-separator-line" className="separator-line"/>
                    <p id="text-emails-sub-heading" className="sub-heading">
                        Text Lead Email(s)
                    </p>
                    <div id="text-emails-description-div" className="left-padding">
                        <p id="text-emails-description-text" className="primary_text">
                            This email is the address for all text online leads.
                        </p>
                    </div>
                    <InlineErrorMessage id="adf-lead-enhanced-required-inline-error" errorMessage={uclSettingsStore.enhancedRequiredMsg}/>

                    {!businessUserStore.isDealerNew() && (
                        <div id="text-emails-div" className="email-input">
                            {uclSettingsStore.textLeadEmails?.map((email:Email, index:number) => (
                                email.action !== DB_ACTION_DELETE &&
                                <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={uclSettingsStore}
                                                textInputRef={uclSettingsStore.emailInputRef}/>))
                            }
                        </div>
                    )}

                    {businessUserStore.isDealerNew() && (
                        <div id="ucl-text-emails-div-enhanced" className="enhanced-email-input-row">
                            {uclSettingsStore.hasTextLeadEmails() &&
                                <EnhancedEmailHeader/>
                            }
                            {uclSettingsStore.textLeadEmails?.map((email:Email, index:number) => (
                                email.action !== DB_ACTION_DELETE &&
                                <EnhancedEmailTextInput key={`EnhancedEmailComponent-${index}`} email={email} index={index} store={uclSettingsStore}
                                                        textInputRef={uclSettingsStore.emailInputRef}/>))
                            }
                        </div>
                    )}

                    <div id="text-emails-add-button-div" className="left-padding bottom-padding email-input">
                        <Button
                            id="text-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add Text Lead Email`}
                            onClick={() => {
                                uclSettingsStore.onEmailAdd(emailTypeEnum.TEXT_LEAD)
                            }}
                            theme="blue-outline"
                        />
                    </div>


                    {/*  " CRM Information " Section   */}
                    <hr id="crm-information-separator-line" className="separator-line"/>
                    <p id="crm-information-sub-heading" className="sub-heading">
                        CRM Information
                    </p>
                    <div id="crm-information-description-div" className="left-padding">
                        <p id="crm-information-description-text" className="primary_text">
                            This is the CRM partner that the dealership has partnered with.
                        </p>
                    </div>
                    <div id="crm-information-div" className="left-padding">
                        <p id="crm-information-header" className="input-label ">
                            Please Select CRM Partner
                        </p>
                    </div>
                    <div id="crm-information-dropdown-div" className="left-padding bottom-padding crm-information-input-width">
                        <AutoCompleteInput
                            options={uclSettingsStore.partnerStore.partnerNames}
                            value={uclSettingsStore.selectedCrmProvider}
                            onSelect={(option : any) => {
                                uclSettingsStore.onCrmProviderChange(option?.value)
                            }}
                            onChange={(crmProviderName : string) => {
                                uclSettingsStore.onCrmProviderChange(crmProviderName)
                            }}
                            placeholder="Select an option"
                            noMatchesMessage="No matches found"
                            disableSort
                        />
                        <InlineErrorMessage id="crm-provider-inline-error" errorMessage={uclSettingsStore.crmProviderErrorMsg}/>
                    </div>


                    {/*  " Syndicate " Section   */}
                    <hr id="syndicate-separator-line" className="separator-line"/>
                    <p id="syndicate-sub-heading" className="sub-heading">
                        Syndicate
                    </p>
                    <div id="syndicate-description-div" className="left-padding">
                        <p id="syndicate-description-text" className="primary_text">
                            When enabled, a dealership’s CCL inventory may also be listed on other listings platforms.
                        </p>
                    </div>
                    <div id="syndicate-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="allow-backfill-toggle-label" className="toggle-label">Enable Inventory Syndication</p>
                        <CheckboxToggle
                            id="syndicate-toggle"
                            checked={uclSettingsStore?.uclSettings.carfaxPartnerSyndicate === true}
                            onChange={event => {
                                uclSettingsStore.onToggleSyndicate(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>
                    <div id="cpo-listing-inline-error-div" className="syndicate-inline-error-div">
                        <InlineErrorMessage id="syndicate-inline-error" errorMessage={uclSettingsStore.carfaxPartnerSyndicateErrorMessage}/>
                    </div>


                    {/*  " CARFAX Car Listings Only " Section   */}
                    <hr id="cfx-ucl-only-separator-line" className="separator-line"/>
                    <p id="cfx-ucl-only-sub-heading" className="sub-heading">
                        CARFAX Car Listings Only
                    </p>
                    <div id="cfx-ucl-only-description-div" className="left-padding">
                        <p id="cfx-ucl-only-description-text" className="primary_text">
                            When enabled, a dealership’s CCL inventory only appears on CARFAX Car Listings.
                        </p>
                    </div>
                    <div id="cfx-ucl-only-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="allow-backfill-toggle-label" className="toggle-label">Limit Inventory to CARFAX.com</p>
                        <CheckboxToggle
                            id="cfx-ucl-only-toggle"
                            checked={uclSettingsStore?.uclSettings.cfxUclOnly === true}
                            onChange={event => {
                                uclSettingsStore.onToggleCfxUclOnly(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>
                    <div id="cpo-listing-inline-error-div" className="syndicate-inline-error-div">
                        <InlineErrorMessage id="syndicate-inline-error" errorMessage={uclSettingsStore.cfxUclOnlyErrorMessage}/>
                    </div>

                    {/* " Secondary Business Name(s) " Section */}
                    <hr id="secondary-name-separator-line" className="separator-line"/>
                    <p id="secondary-name-sub-heading" className="sub-heading">
                        Secondary Business Name(s)
                    </p>
                    <div id="secondary-name-description-div" className="left-padding">
                        <p id="secondary-name-description-text" className="primary_text">
                            This is used when an OEM requires a dealership to display their name a certain way to abide by OEM guidelines.
                        </p>
                    </div>
                    <div id="secondary-names-div" className="left-padding top-padding">
                        {uclSettingsStore.secondaryNames.map((secondaryName:SecondaryName, index:number) => (
                            secondaryName.action !== DB_ACTION_DELETE &&
                            <SecondaryNameTextInput key={`SecondaryNameTextInput-${index}`} secondaryName={secondaryName} index={index}/>))
                        }
                    </div>
                    <div id="secondary-name-add-button-div" className="left-padding bottom-padding">
                        <Button
                            id="secondary-name-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add Secondary Business Name`}
                            onClick={() => {
                                uclSettingsStore.onAddSecondaryNameToForm()
                            }}
                            theme="blue-outline"
                        />
                    </div>


                    {/*  " Dealer Penalty " Section   */}
                    <hr id="dealer-penalty-separator-line" className="separator-line"/>
                    <p id="dealer-penalty-sub-heading" className="sub-heading">
                        Dealer Penalty
                    </p>
                    <div id="dealer-penalty-description-div" className="left-padding">
                        <p id="dealer-penalty-description-text" className="primary_text">
                            This indicates whether we have identified a dealer has bad business practices and the penalty date indicates when they were notified and penalized.
                        </p>
                    </div>
                    <div id="dealer-penalty-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="dealer-penalty-toggle-label" className="toggle-label">Enable Dealer Penalty</p>
                        <CheckboxToggle
                            id="dealer-penalty-toggle"
                            checked={uclSettingsStore?.uclSettings.penaltySettings === true}
                            onChange={event => {
                                uclSettingsStore.onToggleDealerPenalty(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>
                    <div id="dealer-penalty-date-div" className="left-padding">
                        <p id="dealer-penalty-date-header" className="input-label ">
                            Enter Penalty Date
                        </p>
                    </div>

                    <div id="dealer-penalty-date-input-div" className="dealer-penalty-input-width bottom-padding">
                         <TextInput
                             id="dealer-penalty-date-input-text"
                             className="left-padding dealer-penalty-date-input-text"
                             disabled={authStore.isNonAdminGroup() || !uclSettingsStore.uclSettings.penaltySettings}
                             value={uclSettingsStore?.uclSettings.penaltySettings === true ? uclSettingsStore.formattedPenaltyDate : ""}
                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                 uclSettingsStore.onPenaltyDateChange(event.target.value)
                             }}
                             // isValid={!commonStore.formErrorsMap.has("penaltyDate")}
                             // icon={{ position: 'right', element: <Calendar/> }}
                             type="date"
                        />
                        <InlineErrorMessage id="dealer-penalty-date-inline-error" errorMessage={uclSettingsStore.penaltyDateErrorMsg}/>
                    </div>


                    {/*  " Online Only Retailer " Section   */}
                    <hr id="online-only-retailer-separator-line" className="separator-line"/>
                    <p id="online-only-retailer-sub-heading" className="sub-heading">
                        Online Only Retailer
                    </p>
                    <div id="online-only-retailer-description-div" className="left-padding">
                        <p id="online-only-retailer-description-text" className="primary_text">
                            "On" indicates the dealership is an online retailer with no physical store. No address is provided on the dealer's listings.
                        </p>
                    </div>
                    <div id="online-only-retailer-toggle-div" className="left-padding bottom-padding top-padding inline-flex">
                        <p id="online-only-retailer-toggle-label" className="toggle-label">Hide Dealer Address on CCL</p>
                        <CheckboxToggle
                            id="online-only-retailer-toggle"
                            checked={uclSettingsStore?.uclSettings.onlineOnlyRetailer === true}
                            onChange={event => {
                                uclSettingsStore.onToggleOnlineOnlyRetailer(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>


                    {/*  " Disable UCL Listings Settings " Section   */}
                    <hr id="disable-ucl-listings-settings-separator-line" className="separator-line"/>
                    <p id="disable-ucl-listings-settings-sub-heading" className="sub-heading">
                        CCL Listings Settings
                    </p>
                    <div id="disable-ucl-listings-settings-description-div" className="left-padding">
                        <p id="disable-ucl-listings-settings-description-text" className="primary_text">
                            When enabled, CARFAX has the ability to remove all CCL listings for a dealership due to technical, security or other reasons.
                        </p>
                    </div>
                    <div id="disable-ucl-listings-settings-div" className="left-padding">
                        <p id="disable-ucl-listings-settings-header" className="input-label ">
                            Select Listings Setting
                        </p>
                    </div>
                    <div id="disable-ucl-listings-settings-toggle-div" className="left-padding bottom-padding disable-ucl-listings-settings-input-width">
                        <SelectInput
                            id="disable-ucl-listings-settings-input"
                            value={uclSettingsStore.disableUclListingsOption}
                            onChange={(option : any) => {
                                uclSettingsStore.onSelectDisableUclListings(option.target.value);
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        >
                            {uclSettingsStore.disableUclListingsOptions.map((item: string, index: number) =>
                                <option key={index} value={item}>
                                    {item}
                                </option>      
                            )}
                        </SelectInput>
                    </div>
                </div>
            );
        };
    }
);

const InventoryKeyComponent = observer((props: {inventoryKey: any, index: number}) => {
    return (
        <div id={`inventory-key-div-${props.index}`} className="bottom-padding inventory-information-input-width">
            <div className="inventory-input-container">
                <TextInput
                    id={`inventory-key-field-${props.index}`}
                    className="left-padding"
                    value={props.inventoryKey}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        uclSettingsStore.onInventoryKeyChange(event.target.value, props.index);
                    }}
                    type="text"
                    maxLength={300}
                />
                <Trash
                    id={`inventory-key-remove-button-${props.index}`}
                    size={40}
                    className="inventory-key-remove-button"
                    onClick={() => {
                        uclSettingsStore.onInventoryKeyRemove(props.index)
                    }}
                    title="Remove inventory key"
                />
            </div>
            <InlineErrorMessage id={`inventory-key-inline-error-${props.index}`} errorMessage={uclSettingsStore.validInventoryKeyErrorMessages[props.index]}/>
        </div>
    )
});

const SecondaryNameTextInput = observer((props: {secondaryName: SecondaryName, index: number}) => {
    return (
        <div id={`secondary-name-text-input-div-${props.index}`} className="bottom-padding">
            <div id={`secondary-name-header-input-div-${props.index}`} className="secondary-name-header-input-row">
                <p id={`secondary-name-header-oem-label-${props.index}`} className="input-label nowrap" >OEM</p>
                <p id={`secondary-name-header-oem-label-asterisk-${props.index}`} className="input-label-star secondary-name-oem-label">*</p>
                <p id={`secondary-name-header-name-label-${props.index}`} className="input-label nowrap" >Please Enter a Secondary Business Name</p>
                <p id={`secondary-name-header-name-asterisk-label-${props.index}`} className="input-label-star secondary-name-label">*</p>
                <p id={`secondary-name-header-website-url-label-${props.index}`} className="input-label nowrap" >Please Enter a Website URL</p>
                <p id={`secondary-name-header-website-url-asterisk-label-${props.index}`} className="input-label-star website-url-label">*</p>
                <p id={`secondary-name-header-delete-${props.index}`} className="input-label nowrap" >Delete</p>
            </div>
            <div id={`secondary-name-input-container-div-${props.index}`} className="secondary-name-input-container">
                <AutoCompleteInput
                    id={`make-field-${props.index}`}
                    className="secondary-name-oem-input"
                    options={secondaryNamesStore.oemList}
                    value={props.secondaryName.make}
                    onSelect={(option : any) => {
                        uclSettingsStore.onSecondaryNameMakeChange(option?.value, props.index)
                    }}
                    onChange={(oem : string) => {
                        uclSettingsStore.onSecondaryNameMakeChange(oem, props.index)
                    }}
                    placeholder="Select an OEM"
                    noMatchesMessage="No matches found"
                    disableSort
                />
                <TextInput
                    id={`secondary-name-field-${props.index}`}
                    className="left-padding secondary-name-input-width"
                    value={props.secondaryName.secondaryName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        uclSettingsStore.onSecondaryNameBusinessNameChange(event.target.value, props.index);
                    }}
                    type="text"
                    maxLength={300}
                    placeholder="Ex: Cripple Creek Acura"
                />
                <TextInput
                    id={`website-url-field-${props.index}`}
                    className="left-padding website-url-input-width"
                    value={props.secondaryName.websiteUrl}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        uclSettingsStore.onSecondaryNameWebsiteUrlChange(event.target.value, props.index);
                    }}
                    type="text"
                    aria-invalid={!uclSettingsStore.validWebsiteUrl}
                    maxLength={255}
                />
                <Trash
                    id={`secondary-name-remove-button-${props.index}`}
                    size={40}
                    className="secondary-name-remove-button"
                    onClick={() => {
                        uclSettingsStore.onRemoveSecondaryNameFromForm(props.index)
                    }}
                    title="Remove secondary name"
                />
            </div>
            <InlineErrorMessage id={`secondary-name-inline-error-${props.index}`} errorMessage={!props.secondaryName.inlineErrorMsg ? "" : props.secondaryName.inlineErrorMsg} />
        </div>
    )
});