import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import config from './components/security/config';
import SecureInit from "./components/security/SecureInit";
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';

const oktaClient = new OktaAuth(config.oidc);

function App() {

    const history = useHistory();
    const restoreOriginalUri = (_oktaAuth: any,  originalUri: string) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        <Security
            oktaAuth={oktaClient}
            restoreOriginalUri={restoreOriginalUri}
        >
            <SecureRoute path="/" component={SecureInit}/>
            <Switch>
                <Route path={config.CALLBACK_PATH} component={LoginCallback} />
                <Route path="/" component={LoginCallback} />
            </Switch>
        </Security>
  );
}

export default App;
