import { ApiStatus } from '../types/index'

export const apiStatus = {
    success: 'SUCCESS' as ApiStatus,
    badRequest: 'BAD REQUEST' as ApiStatus,
    error: 'ERROR' as ApiStatus,
    notFound: 'NOT FOUND' as ApiStatus,
    noPriceData: 'NO PRICE DATA' as ApiStatus,
    unauthorized: 'UNAUTHORIZED' as ApiStatus,
    forbidden: 'FORBIDDEN' as ApiStatus,
    invalidZip: 'INVALID ZIP' as ApiStatus,
    canadian: 'CANADIAN' as ApiStatus,
    oldVin: 'OLD VIN' as ApiStatus,
    nullTrim: 'NULL TRIM' as ApiStatus,
    notAllowed: 'METHOD NOT ALLOWED' as ApiStatus,
    conflict: 'CONFLICT' as ApiStatus,
    unprocessableContent: 'UNPROCESSABLE CONTENT' as ApiStatus
  }

export const HTTP_STATUS_400_MESSAGE = "Bad Request";
export const HTTP_STATUS_401_MESSAGE = "Not Authorized";
export const HTTP_STATUS_403_MESSAGE = "Your session may have expired. Please try refreshing the page or logging in again.";
export const HTTP_STATUS_404_MESSAGE = "Data not found";
export const HTTP_STATUS_405_MESSAGE = "Method not allowed";
export const HTTP_STATUS_409_MESSAGE = "Conflict";
export const HTTP_STATUS_422_MESSAGE = "Unprocessable content";
export const HTTP_STATUS_500_MESSAGE = "Severe error encountered";

export const DB_ACTION_INSERT = "INSERT";
export const DB_ACTION_UPDATE = "UPDATE";
export const DB_ACTION_DELETE = "DELETE";

export const DEFAULT_LEADS_ADF_PROVIDER = "CARFAX, INC";

export const TWILIO_WHISPER = "Incoming%20call%20from%20Carfax";
