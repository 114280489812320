import * as React from "react";
import { withOktaAuth } from "@okta/okta-react";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import Header from "../../../shared-components/layout/header/Header";
import Page from "../../../shared-components/layout/page/Page";
import Store from "../../../stores";
import { observer } from "mobx-react";
import "./RegenerateTopRatedDealers.scss"
import "../../../styles/main.scss";
import {
    Notification,
    NotificationCloseButton,
    NotificationIcon,
    NotificationMessage
} from "@carfax-stencils/notification";
import TextInput from "@carfax-stencils/input-text";
import Button from "@carfax-stencils/button";
import { ProcessingApiSpinner } from "src/shared-components/processingApiSpinner/ProcessingApiSpinner";
import InlineErrorMessage from "src/shared-components/layout/error/InlineErrorMessage";


// Stores
const rootStore = Store.root.root;
const commonStore = rootStore.commonStore;
const regenerateTopRatedDealersStore = rootStore.regenerateTopRatedDealersStore;


export default withOktaAuth(

    class RegenerateTopRatedDealersPage extends React.Component {

        state = {
            ready: false
        };

        async componentDidMount() {
            this.setState(
                { ready: true }
            )
            await this.initializePage()
        };

        initializePage = async () => {
            regenerateTopRatedDealersStore.initializeStore()
        };

        render() {
            return (
                <Page id="regenerate-top-rated-page" title="Dealer Account Management | Top-Rated Dealers">
                    <div id="regenerate-top-rated-page-div">
                        <Header id="regenerate-top-rated-page-header" />
                        <this.PageContent />
                    </div>
                </Page>
            );
        };

        PageContent = observer(() => {
            return (
                <div id="regenerate-top-rated-outer-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner />
                    )}
                    <div id="regenerate-top-rated-breadcrumbs-div" className="breadcrumb-trail">
                        <Breadcrumbs
                            id="regenerate-top-rated-breadcrumbs"
                            crumbs={[
                                { value: "Home", href: "#" },
                                { value: "Utilities", href: "/utilities" },
                                { value: "Top-Rated Dealers" },
                            ]}
                        />
                    </div>
                    <div id="regenerate-top-rated-page-heading-div" className="page__heading">
                        Top-Rated Dealers
                    </div>

                    {/*  COMMON PAGE-LEVEL NOTIFICATIONS  */}
                    {commonStore.pageMsg && (
                        <div id="regenerate-top-rated-notification-div" className="page-notification-div">
                            <Notification
                                id="regenerate-top-rated-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.pageMsgTheme}>
                                <NotificationIcon />
                                <NotificationMessage>
                                    {commonStore.pageMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => { commonStore.clearPageMsg() }}
                                />
                            </Notification>
                        </div>
                    )}

                    <div id="regenerate-top-rated-outer-div" className={regenerateTopRatedDealersStore.activeCompCode == null ? "regenerate-top-rated__layout-wrapper" : "regenerate-top-rated__layout-confirmation-wrapper"}>
                        <div id="regenerate-top-rated-content-div" className="regenerate-top-rated__content-div">
                            <h3 className="regenerate-top-rated__title-text"> Top-Rated Dealers</h3>
                            <p id="regenerate-top-rated-description-text-1" className="regenerate-top-rated__description-text">
                                This will regenerate digital Top-Rated Dealer assets on CFO for a chosen comp code.
                                Regeneration of assets is typically needed after a dealership name change.
                            </p>
                            <div id="regenerate-top-rated-label-div" className="regenerate-top-rated__input-div">
                                {
                                    regenerateTopRatedDealersStore.activeCompCode == null &&
                                    <>
                                        <p id="regenerate-top-rated-comp-code-input-text" className="primary_label">
                                            Comp Code
                                        </p>

                                        <TextInput
                                            id="regenerate-top-rated-input-text"
                                            value={regenerateTopRatedDealersStore.compCode}
                                            className="regenerate-top-rated__test-invalid"
                                            type="text"
                                            onChange={(e) => regenerateTopRatedDealersStore.onCompCodeChange(e.target.value)}
                                            aria-invalid={!regenerateTopRatedDealersStore.isValidCompCode()}
                                        />
                                        <div style={{ position: "absolute" }} id="regenerate-top-rated-error-message-div">
                                            <InlineErrorMessage id="regenerate-top-rated-inline-error-message" errorMessage={regenerateTopRatedDealersStore.invalidCompCodeMessage} />
                                        </div>
                                        <Button
                                            id="regenerate-top-rated-next-button"
                                            className="regenerate-top-rated__next-button"
                                            theme="blue"
                                            size="large"
                                            disabled={regenerateTopRatedDealersStore.disableSubmitButton()}
                                            onClick={() => regenerateTopRatedDealersStore.setActiveCompCode()}>
                                            Next
                                        </Button>
                                    </>
                                }
                                {
                                    regenerateTopRatedDealersStore.activeCompCode != null &&
                                    <>
                                        <p className="primary_label" id="regenerate-top-rated-prompt-tag">
                                            Would you like to regenerate assets for this dealer?
                                        </p>
                                        <div className="regenerate-top-rated__compCode-info-div" id="regenerate-top-rated-info-div">
                                            <p id="regenerate-top-rated-info-div-comp-code-tag">CompCode:</p>
                                            <p id="regenerate-top-rated-info-div-comp-code-value-tag">{regenerateTopRatedDealersStore.activeCompCode.compCode}</p>
                                            <p id="regenerate-top-rated-info-div-dealer-name-tag">DealerName:</p>
                                            <p id="regenerate-top-rated-info-div-dealer-name-value-tag">{regenerateTopRatedDealersStore.activeCompCode.companyName}</p>
                                        </div>
                                        <div className="regenerate-top-rated__regenerate-assets-div">
                                            <Button
                                                id="regenerate-top-rated-back-button"
                                                className="regenerate-top-rated__submit-button"
                                                theme="blue-outline"
                                                size="large"
                                                disabled={regenerateTopRatedDealersStore.disableSubmitButton()}
                                                onClick={() => regenerateTopRatedDealersStore.resetActiveCompCode()}>
                                                Back
                                            </Button>
                                            <Button
                                                id="regenerate-top-rated-regenerate-button"
                                                className="regenerate-top-rated__submit-button"
                                                theme="blue"
                                                size="large"
                                                disabled={regenerateTopRatedDealersStore.disableSubmitButton()}
                                                onClick={() => regenerateTopRatedDealersStore.submitCompCode()}>
                                                Yes, Regenerate Assets
                                            </Button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }
);