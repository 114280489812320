import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {apiStatus} from "../../utils/constants";
import {DealerProfileInfo} from "../../types/profile/dealerProfileInfo"
import {makeAutoObservable, runInAction} from "mobx";
import {getDealerInfo, updateDealerInfo} from "../../clients/dps/dpsApi";


export default class DealerProfileInfoStore {

    // Variables
    dealerProfileInfo: DealerProfileInfo;
    originalDealerProfileInfo: DealerProfileInfo;

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;

    constructor(root: RootStore ) {
        this.rootStore = root;
        this.authStore = this.rootStore.authStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;

        this.dealerProfileInfo = this.initializeStore();
        this.originalDealerProfileInfo = this.initializeStore();
        makeAutoObservable(this);
    };

    public initializeStore = () : DealerProfileInfo => {
        const dealerProfileInfo = {
            compCode: this.businessUserStore?.businessUser?.compCode || "",
            valueBadgeOptOut: false
        };

        return dealerProfileInfo;
    };

    public resetStoreToOriginalData() {
        this.dealerProfileInfo = CommonUtils.cloneObject(this.originalDealerProfileInfo);
    };

    public onToggleValueBadge = (isChecked: boolean) => {
        this.dealerProfileInfo.valueBadgeOptOut = isChecked;
    };

    public getDealerProfileInfo = async () => {

        let compCode = this.businessUserStore.businessUser?.compCode || "";

        this.dealerProfileInfo = this.initializeStore();
        this.originalDealerProfileInfo = this.initializeStore();

        await getDealerInfo(this.authStore.token, compCode ).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.dealerProfileInfo = resp.data;
                        this.originalDealerProfileInfo = resp.data;
                    });
                    this.commonStore.clearPageMsg();
                    break;
                case 'NOT FOUND' :
                    runInAction(() => {
                        this.originalDealerProfileInfo.compCode = compCode
                    })
                    CommonUtils.displayConsoleLogError(`*** No record found on dealer_profile_info for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve dealer_profile_info data for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to load Dealer Profile Info data for comp code '${compCode}'.`, "red");
                    break;
            };
        });
    };

    public updateDealerProfileInfo = async (patchDealerProfileInfo: any) : Promise<boolean> => {

        CommonUtils.logNewRelicPageAction("Profile - Dealer Details", {userAction: 'Update Dealer Profile Info', compCode: this.dealerProfileInfo.compCode})

        let updateSuccess: boolean = false;
        await updateDealerInfo(this.authStore.token, this.dealerProfileInfo, patchDealerProfileInfo ).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.originalDealerProfileInfo = CommonUtils.cloneObject(this.dealerProfileInfo);
                    });
                    updateSuccess = true;
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve images for comp code ${this.dealerProfileInfo.compCode}.  Response: ` + JSON.stringify(resp));
                    break;
            };
        });

        return updateSuccess;
    };

    public determineChanges = () : boolean => {
        if (CommonUtils.fieldsChanged(this.dealerProfileInfo, this.originalDealerProfileInfo)) {
            return true;
        }
        return false;
    };

    public async submitChanges() : Promise<boolean> {
        let patchDealerProfileMap:Map<string,string> = CommonUtils.getFieldsChanged(this.dealerProfileInfo, this.originalDealerProfileInfo);
        return await this.updateDealerProfileInfo(Object.fromEntries(patchDealerProfileMap));
    };
}
