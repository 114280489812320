import React from "react"
import { ArrowDown, ArrowUp } from "@carfax-stencils/icon";
import TablePagination from "@carfax-stencils/table-pagination"
import { NoResults } from "../noResults/NoResults"
import "./table.scss"

export interface ITableProps {
  columns: IColumnType[]
  rowData?: object[]
  emptyView?: JSX.Element
  paginate?: paginationOptions
}

export interface IColumnType {
  key: string
  label: string
  sortable?: boolean
  sortFunction?: (a: any, b: any) => SortResult
  cellRenderer?: (value: any) => JSX.Element
  width?: string
}

export type paginationOptions = 10 | 50 | 100
export type SortResult = -1 | 0 | 1
export type SortDirection = "asc" | "desc"

export type GenericSort = {
  key: string
  direction: SortDirection
}

export type TableComponentState = {
  sortedRowData: object[] | undefined
  currentPage?: number
  perPageValue?: number
}

export class Table extends React.Component<ITableProps, TableComponentState> {
  sort: GenericSort = { key: this.props.columns[0].key, direction: "asc" }

  constructor(props: ITableProps) {
    super(props)

    this.state = {
      sortedRowData: props.rowData,
      perPageValue: props.paginate ? props.paginate : undefined
    }
  }

  componentDidMount() {
    this.applySort(this.sort.key)
  }

  componentWillReceiveProps(nextProps: ITableProps) {
    this.setState({ sortedRowData: nextProps.rowData }, () => {
      this.applySort(this.sort.key)
    })
  }

  render() {
    const hasNoData =
      this.state.sortedRowData && this.state.sortedRowData.length === 0
    return (
      <div className="stencils table-component">

        {this.renderPaginator()}

        <table>
          {this.renderTableHeader()}
          {this.renderTableBody()}
        </table>

        {this.renderPaginator()}

        {hasNoData && this.renderNoData()}
      </div>
    )
  }

  renderPaginator() {
    const itemCount = (this.state.sortedRowData && this.state.sortedRowData.length) || 0;
    const shouldDisplay = (this.props.paginate || false) && itemCount > 0;
    return (
        shouldDisplay && (
            <div className="table-pager">
              <TablePagination
                  onChange={this.handleTablePageChange}
                  currentPage={this.state.currentPage || 0}
                  perPageValue={this.state.perPageValue ?? 10}
                  totalItems={itemCount}
                  tooltipTheme={"light"}
              />
            </div>
        )
    );
  }

  private handleTablePageChange = (data: any) => {
    this.setState({
      currentPage: data.currentPage,
      perPageValue: data.perPageValue
    });
  };

  renderTableHeader() {
    return (
      <thead>
        <tr>
          {this.props.columns.map((headerCell: any, index: number) =>
            this.renderHeaderCell(headerCell, index)
          )}
        </tr>
      </thead>
    )
  }

  renderHeaderCell(headerCell: IColumnType, index: number) {
    let attributes = {}

    if (headerCell.width) {
      attributes = { ...attributes, width: headerCell.width }
    }
    if (headerCell.sortable) {
      const isActive = this.sort.key === headerCell.key ? " active" : ""

      attributes = {
        ...attributes,
        className: `sortable${isActive}`,
        onClick: () => this.handleHeaderClick(headerCell.key)
      }
    }
    return (
      <th key={index} {...attributes}>
        {headerCell.label}
        {headerCell.sortable && ( this.sortArrowIcon(headerCell.key))}
      </th>
    )
  }

  paginatedSortedRowData(): any[] {
    const currentPage = this.state.currentPage || 1
    const perPage = this.state.perPageValue || 10
    const offset = (currentPage - 1) * perPage
    const rowData = this.state.sortedRowData || []
    return rowData.slice(offset, offset + perPage)
  }

  renderTableBody() {

    const data = this.props.paginate
      ? this.paginatedSortedRowData()
      : this.state.sortedRowData

    return (
      <tbody>
        {data &&
          data.map((row: any, index: number) => (
            <tr key={index}>{this.renderBodyRow(row)}</tr>
          ))}
      </tbody>
    )
  }

  renderBodyRow(row: any) {
    const renderedRow: any[] = []
    Object.keys(row).forEach((key: any, index: number) => {
      const header = this.props.columns.find(
        (it: { key: any }) => it.key === key
      )
      const formatter = header && header.cellRenderer
      renderedRow.push(this.renderBodyCell(row[key], index, formatter))
    })
    return renderedRow
  }

  renderBodyCell(item: any, index: number, formatter?: any) {
    const cellItem = formatter ? formatter(item) : item
    return <td key={index}>{cellItem}</td>
  }

  renderNoData() {
    return this.props.emptyView || <NoResults />
  }

  protected handleHeaderClick = (key: string) => {
    if (this.sort.key === key) {
      this.sort.direction = this.sort.direction === "desc" ? "asc" : "desc"
    } else {
      this.sort.direction = "asc"
    }
    this.sort.key = key
    this.applySort(this.sort.key)
  }

  protected sortArrowIcon = (key: string) => {
    const sortArrow = this.sort.key === key && this.sort.direction === "desc" ? "arrowUp" : "arrowDown"
    return sortArrow === "arrowUp" ? <ArrowUp /> : <ArrowDown />
  }

  protected applySort(key: string) {
    const column = this.props.columns.find(
      (it: { key: string }) => it.key === key
    )
    if (!column) {
      return
    }

    const defaultSortFunction = (a: any, b: any) =>
      a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0

    const sortedRows = this.state.sortedRowData || []
    sortedRows.sort(column.sortFunction || defaultSortFunction)

    if (this.sort.direction === "desc") {
      sortedRows.reverse()
    }
    this.setState({
      sortedRowData: sortedRows,
      currentPage: 1
    })
  }
}
