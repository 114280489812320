import * as React from "react"
import { withOktaAuth } from "@okta/okta-react"
import Header from "../../shared-components/layout/header/Header"
import { PartnerContextProvider } from "../context/PartnerContextProvider"
import PartnerMaintenance from "./PartnerMaintenance"
import Store from "../../stores";
import {Redirect} from "react-router-dom";
import {observer} from "mobx-react";
import { SpinnerDots } from "@carfax-stencils/spinner";
import {DAM_Super_Admin} from "../../types/groups";

type localState = {ready: boolean};

export default withOktaAuth( observer (
  class PartnerMaintenancePage extends React.Component<{ oktaAuth: any },localState> {

    constructor(props: any) {
        super(props);
        this.state = {ready: false}
    }

    async componentDidMount() {
        this.setState({
            ready: true
        })
    }

    render() {
        if (!this.state.ready) {
            return (
                <div id="partner-maintenance-spinner-div">
                    <SpinnerDots id="partner-maintenance-spinner" />
                </div>
            )
        }
        return (
            <div>
                {Store.auth.groups && Store.auth.groups.includes(DAM_Super_Admin) ?
                    <div>
                        <Header id="partner-maintenance-page-header"/>
                        <div id="partner-maintenance-page-div" className="partner-maintenance-page">
                            <PartnerContextProvider>
                                <PartnerMaintenance id="partner-maintenance-component" />
                            </PartnerContextProvider>
                        </div>
                    </div>
                : <Redirect to="/" />
                }
            </div>
      )
    }
  })
)
