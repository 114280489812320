import { Modal, ModalHeader, ModalBody, ModalFooter } from '@carfax-stencils/modal';
import { observer } from "mobx-react";
import Button from "@carfax-stencils/button";
import CommonUtils from "../../../../utils/commonUtils";
import React from "react";
import Store from "../../../../stores";


export default observer(() => {

    const dealerDetailsStore = Store.profile.dealerDetails;

    return (
        <Modal
            id="delete-image-modal"
            isVisible={true}
            onClose={dealerDetailsStore.handleDeleteImageModalCancel}>
            <ModalHeader>
                <div id="delete-image-modal-header-div" className="modal-header-title">Delete {CommonUtils.capitalizeFirstCharacter(dealerDetailsStore.photo.type)}</div>
            </ModalHeader>
            <ModalBody>
                <div id="delete-image-modal-body-div">
                    Are you sure you want to delete this {dealerDetailsStore.photo.type}?
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    id="delete-image-modal-confirm-button"
                    theme="red"
                    size="small"
                    onClick={() => {dealerDetailsStore.handleDeleteImageModalConfirm(dealerDetailsStore.photo.id) }}>
                    Confirm
                </Button>
                <Button
                    id="delete-image-modal-cancel-button"
                    theme="black"
                    size="small"
                    onClick={dealerDetailsStore.handleDeleteImageModalCancel}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
});
