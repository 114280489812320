import DamAxiosClient from "./DamAxiosClient";
import UrlBuilder from "../utils/urlBuilder";
const CTS_API_URI = UrlBuilder.buildBaseUrl("/call-tracking-service/provision")
const damAxiosClient: DamAxiosClient = new DamAxiosClient()


export const getAvailableTwilioNumber = async(token: string, tollFree: boolean, areaCode?: string, prefix?: string) : Promise<any> => {
    const path : string = CTS_API_URI + `/numbers`
    const params :any = {tollFree: tollFree, areaCode: areaCode, prefix: prefix}
    return damAxiosClient.get(path, params, token );
};

export const provisionTwilioTrackingNumber = async (token: string, twilioNumberToProvision: string) : Promise<any> => {
    const path : string = CTS_API_URI + `/number`
    return damAxiosClient.post(path, {}, token, {trackingPhoneNumber: twilioNumberToProvision})
}

export const deprovisionTwilioTrackingNumber = async (token: string, twilioSid: string) : Promise<any> => {
    const path : string = CTS_API_URI + `/number/${twilioSid}`
    const params : string = ''
    return damAxiosClient.delete( path, params, token );
};
