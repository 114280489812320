import {BusinessUser} from "../../types/businessUser";
import {makeAutoObservable} from "mobx"
import RootStore from "../root";
import {InviteTemplateEnum} from "../../types/acctMgmt/inviteUserTypes";

export default class BusinessUserStore {

    // Variables
    businessUser: BusinessUser | null = null;
    businessUsersList: any;
    vrss: boolean = false;
    isSearchResultsVisible: boolean = false;

    // Stores
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.businessUsersList = [];
        makeAutoObservable(this);
    }

    public initializeStore = () => {
        this.businessUser = null;
        this.businessUsersList = [];
        this.isSearchResultsVisible = false;
        this.vrss = false;
    };

    public setBusinessUser(selectedCompCode: string) {
        this.businessUsersList.forEach((user: BusinessUser) => {
            if(user.compCode === selectedCompCode) {
                this.businessUser = user;
                this.determineVrss();
                this.handleDisplaySearchResults(true);
            }
        });
    }

    public determineVrss = () => {
        this.vrss = false; if (this.businessUser?.products?.includes("RSP")) { this.vrss = true; }
    };

    public isCfoUser = (products: Array<string>): boolean => {

        const productCip = products.includes("CIP");
        const productNmv = products.includes("NMV");
        const productVhr = products.includes("VHR");
        const productWca = products.includes("WCA");

        if (productCip || productNmv || productVhr || productWca) {
            return true;
        }

        return false;
    };

    public isDealerNew = () => {
        return this.businessUser?.industry === "Dealer - New"
    }

    public handleDisplaySearchResults = (display: boolean) => {
        this.isSearchResultsVisible = display;
    }

    public get inviteTemplateDisplayNames() {
        if (!this.businessUser?.inviteTemplates) {
            return []
        }

        return this.businessUser?.inviteTemplates.map((templateName) => {
            return InviteTemplateEnum[templateName as keyof typeof InviteTemplateEnum]
        })

    }

}
