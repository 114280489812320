import AuthStore from "../../auth";
import CommonStore from "../../common/common";
import CommonUtils from "../../../utils/commonUtils";
import RootStore from "../../root";
import {BulkInvite} from "../../../types/features";
import {apiStatus} from "../../../utils/constants";
import {getS3PresignedUrl, submitBatchTask, uploadFileToS3} from "../../../clients/damBackendApi";
import {makeAutoObservable, runInAction} from "mobx";
import {getEnvironment} from "../../../utils/environment";
import * as React from "react";
import Validator from "../../helpers/validator";

export enum EmailTemplatesEnum {
    DEALER = 'Dealer Invite (CFO)',
    C4LIFE = 'CARFAX4Life',
    RATINGS = 'R&R (Shop Console)',
    LOYALTY = 'Shop Loyalty Invite (Shop Console)',
    RECALL = 'VRSS (VRSS)',
    SHC = 'SHC (SHC)',
    PARTNER = 'Partner Invite'
}

export default class BulkInviteStore {

    // Fields
    email: string = "";
    selectedEmailTemplate: any = "";

    // Stores
    authStore: AuthStore;
    commonStore: CommonStore;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore
        this.commonStore = this.rootStore.commonStore

        makeAutoObservable(this)
    }

    public initializeStore() {
        runInAction(() => {
            this.commonStore.currentFeature = BulkInvite
            this.commonStore.processingApiCall(false)
            this.commonStore.clearPageMsg()
        })
    };

    private get emailTemplateKey() {
        return Object.keys(EmailTemplatesEnum)[Object.values(EmailTemplatesEnum).indexOf(this.selectedEmailTemplate)];
    }

    public get templatesList() {
        return Object.values(EmailTemplatesEnum)
    }

    public isValidEmail = () : boolean => {
        return Validator.isValidEmail(this.email)
    }

    public onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.email = event.target.value
    }

    public onTemplateSelection = (templateSelected: any) => {
        this.selectedEmailTemplate = templateSelected.target.value
        //Pre-populate the okta sign-in email
        this.email = this.authStore.user?.email!
    }

    public processFileUpload = async (file: any) => {

        this.commonStore.processingApiCall(true)

        CommonUtils.logNewRelicPageAction("Bulk Invite", {
            userAction: 'Upload File',
            user: this.authStore.user
        });

        let presignedS3Url = ""
        let uploadFileName = ""
        await getS3PresignedUrl(this.authStore.token, "bulk-invite").then((resp: any) => {
            switch(resp?.status) {
                case apiStatus.success :
                    presignedS3Url = resp?.data.body.fileUploadURL
                    uploadFileName = resp?.data.body.uploadFileName
                    break;
                default:
                    CommonUtils.displayConsoleLogError(`*** Error getting S3 Presigned URL from AWS.  Response: ` + JSON.stringify(resp));
                    break;
            }
        });

        let fileToUpload = file?.length > 0 ? file[0] : undefined

        if(!presignedS3Url || !uploadFileName) {
            CommonUtils.displayConsoleLogError(`*** Error retrieving S3 Presigned URL.  presignedS3Url: ${presignedS3Url}  uploadFileName: ${uploadFileName} `);
            this.commonStore.displayPageNotification(`Error retrieving S3 Presigned URL and Upload File Name.`, "red");
        } else {
            if(!fileToUpload) {
                CommonUtils.displayConsoleLogError(`*** Error in fetching file for upload: ` + JSON.stringify(file));
                this.commonStore.displayPageNotification(`Error in fetching file for upload.`, "red");
            }
        }

        if(presignedS3Url && fileToUpload && uploadFileName) {
            await uploadFileToS3(
                presignedS3Url,
                fileToUpload
            ).then(async (response) => {
                switch (response?.status) {
                    case 'SUCCESS' :
                        await this.submitBulkInviteJob(uploadFileName);
                        break;
                    default :
                        CommonUtils.displayConsoleLogError(`*** Error uploading file to S3 Response: ` + response.data);
                        this.commonStore.displayPageNotification(`Severe error trying to upload file to S3.`, "red")
                        break;
                }
            })
        }

        // Reset the stencil file uploader so we can re-upload without refreshing the page
        const fileUploaderInput = document.getElementById("file-uploader_input_bulk-invite-uploader") as HTMLInputElement
        if(fileUploaderInput) {
            fileUploaderInput.value = ""
        }

        this.commonStore.processingApiCall(false)
    };

    private submitBulkInviteJob = async (uploadedFileName: string) => {
        let dataToSend = {
            fargateTaskName: "dxd-platforms-batch-bulk-invite-task",
            s3Bucket: getEnvironment() === 'PROD' ?  "dxd-platforms-batch-production" : "dxd-platforms-batch-staging",
            s3File: uploadedFileName,
            emailAddress: this.email,
            emailTemplate: this.emailTemplateKey
        }

        const data: any = JSON.stringify(dataToSend);
        await submitBatchTask(this.authStore.token, data).then((resp: any) => {
            switch(resp?.status) {
                case apiStatus.success :
                    this.commonStore.displayPageNotification(`The CSV file you uploaded was received and is now processing.`, "green")
                    this.selectedEmailTemplate = ""
                    break;
                default:
                    this.commonStore.displayPageNotification(`Error in Submitting Bulk Invite Job.`, "red")
                    break;
            }
        })
    }

    public showUploadButton = () : boolean => {
        return this.isValidEmail() && this.selectedEmailTemplate !== ""
    }
};