import * as React from "react";
import {withOktaAuth} from "@okta/okta-react";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import FileUploaderInput from '@carfax-stencils/input-file-uploader'
import Header from "../../../shared-components/layout/header/Header";
import Page from "../../../shared-components/layout/page/Page";
import Store from "../../../stores";
import {observer} from "mobx-react";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import "./BulkInvite.scss"
import "../../../styles/main.scss";

import {
    Notification,
    NotificationCloseButton,
    NotificationIcon,
    NotificationMessage
} from "@carfax-stencils/notification";
import TextInput from "@carfax-stencils/input-text";
import SelectInput from "@carfax-stencils/input-select";
import {EmailTemplatesEnum} from "../../../stores/utilities/bulkInvite/bulkInvite";


// Stores
const rootStore = Store.root.root;
const commonStore = rootStore.commonStore;
const bulkInviteStore = rootStore.bulkInviteStore;


export default withOktaAuth (

    class BulkInvitePage extends React.Component {

        state = {
            ready: false
        };

        async componentDidMount() {
            this.setState(
                {ready:true}
            )
            await this.initializePage()
        };

        initializePage = async () => {
            bulkInviteStore.initializeStore()
        };

        render() {
            return (
                <Page id="bulk-invite-page" title="Dealer Account Management | Bulk Invite">
                    <div id="bulk-invite-page-div">
                        <Header id="bulk-invite-page-header" />
                        <this.PageContent />
                    </div>
                </Page>
            );
        };

        PageContent = observer(() => {
            return (
                <div id="bulk-invite-outer-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner/>
                    )}
                    <div id="bulk-invite-breadcrumbs-div" className="breadcrumb-trail">
                        <Breadcrumbs
                            id="bulk-invite-breadcrumbs"
                            crumbs={[
                                { value: "Home", href: "#" },
                                { value: "Utilities", href: "/utilities" },
                                { value: "Bulk Invite" },
                            ]}
                        />
                    </div>
                    <div id="bulk-invite-page-heading-div" className="page__heading">
                        Bulk Invite
                    </div>

                    {/*  COMMON PAGE-LEVEL NOTIFICATIONS  */}
                    {commonStore.pageMsg && (
                        <div id="bulk-invite-notification-div" className="page-notification-div">
                            <Notification
                                id="bulk-invite-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.pageMsgTheme}>
                                <NotificationIcon/>
                                <NotificationMessage>
                                    {commonStore.pageMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => {commonStore.clearPageMsg()}}
                                />
                            </Notification>
                        </div>
                    )}

                    <div id="bulk-invite-outer-div" className="bulk-invite__layout-wrapper">
                        <div id="bulk-invite-content-div" className="bulk-invite__content-div">
                            <p id="bulk-invite-description-text-1" className="bulk-invite__title-text">
                                The Bulk Invite functionality allows you to upload a single csv file of email addresses for
                                users who should receive account invite emails.
                            </p>

                            <div id="bulk-invite-label-div" className="bulk-invite__input-div">
                                <p id="bulk-invite-select-email-template-text" className="primary_label">
                                    Select Email Template:
                                </p>
                                <div id ="bulk-invite-dropdown-div">
                                    <SelectInput
                                        id="bulk-invite-email-template-dropdown"
                                        value={bulkInviteStore.selectedEmailTemplate}
                                        onChange={bulkInviteStore.onTemplateSelection}
                                        placeholder="Select the report"
                                    >
                                        {
                                            bulkInviteStore.selectedEmailTemplate === "" &&
                                            <option value="">Select the report</option>
                                        }
                                        {bulkInviteStore.templatesList.map((item: string, index: number) =>
                                            <option key={index} value={item}>
                                                {item}
                                            </option>      
                                        )}
                                    </SelectInput>
                                </div>
                            </div>

                            {bulkInviteStore.selectedEmailTemplate &&
                                <>
                                    <div id="bulk-invite-email-div-id" className="bulk-invite__input-div">
                                        <p id="bulk-invite-email-label" className="primary_label">
                                            Enter Email Address:
                                        </p>
                                        <p className="bulk-invite__description-text">
                                            The email address will receive progress notification of the bulk invite.
                                        </p>
                                        <TextInput
                                            id="bulk-invite-email-input"
                                            value={bulkInviteStore.email}
                                            onChange={bulkInviteStore.onEmailChange}
                                            type="text"
                                            aria-invalid={!bulkInviteStore.isValidEmail()}
                                        />
                                    </div>
                                    {bulkInviteStore.showUploadButton() &&
                                        <>
                                            <div id="bulk-invite-file-uploader-label-div" className="bulk-invite__description-text top-padding">
                                                {bulkInviteStore.selectedEmailTemplate === EmailTemplatesEnum.PARTNER &&
                                                    <p id="bulk-invite-upload-text">
                                                        Upload file should include columns labeled  <strong>First Name, Last Name, Email, Comp Code, Role, and Partner.</strong>
                                                    </p>
                                                }
                                                {bulkInviteStore.selectedEmailTemplate !== EmailTemplatesEnum.PARTNER &&
                                                    <p id="bulk-invite-upload-text">
                                                        Upload file should include columns labeled  <strong>First Name, Last Name, Email, Comp Code and Role.</strong>
                                                    </p>
                                                }
                                            </div>
                                            <div id="bulk-invite-file-uploader-div" className="bulk-invite__file-uploader-div">
                                                <FileUploaderInput
                                                    name="bulk-invite-uploader"
                                                    children="Upload and Submit Bulk Invite"
                                                    theme="blue"
                                                    validFileTypes={".csv"}
                                                    onFileChange={(file) => bulkInviteStore.processFileUpload(file)}
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                            }

                        </div>
                    </div>
                </div>
            );
        });
    }
);