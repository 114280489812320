export enum emailTypeEnum {
    ADF_LEAD = "adf",
    TEXT_LEAD = "lead",
    ADF_CALL_ALERT = "call_alert_adf",
    TEXT_CALL_ALERT = "contact",
    TRADE_IN_ADF_LEAD = "smc_adf",
    TRADE_IN_TEXT_LEAD = "smc_lead",
    TRADE_IN_ADF_CALL_ALERT = "smc_call_alert_adf",
    TRADE_IN_CALL_ALERT = "smc_contact",
    SERVICE_APPT_TEXT_LEAD = "sa_lead",
    SALES_RATINGS = "sales_rating",
};
