import AuthStore from "../auth";
import BusinessHoursStore from "./businessHours";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import DealerProfileInfoStore from "./dealerProfileInfo";
import RootStore from "../root";
import UclSettingsStore from "./uclSettings";
import {makeAutoObservable, runInAction} from "mobx";
import {NotificationMessages} from "../common/messaging/notificationMessages";
import {IProfileImages, ImageScalingMode, ImageType, ProfileImage} from "../../types/profile/images";
import {Profile} from "../../types/features";
import loadImage from "blueimp-load-image";

import {
    deleteImage,
    getImages,
    uploadImage
} from "../../clients/dps/dpsApi";

const ORI_NORMAL: number = 1;
const ORI_HORIZONTAL_FLIP: number = 2;
const ORI_180_ROTATE_RIGHT: number = 3;
const ORI_VERTICAL_FLIP: number = 4;
const ORI_VERTICAL_FLIP_90_ROTATE_RIGHT: number = 5;
const ORI_90_ROTATE_RIGHT: number = 6;
const ORI_HORIZONTAL_FLIP_90_ROTATE_RIGHT: number = 7;
const ORI_90_ROTATE_LEFT: number = 8;
const RADIANS_TO_DEGREES = Math.PI / 180;


export default class DealerDetailsStore {

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessHoursStore: BusinessHoursStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;
    dealerProfileInfoStore: DealerProfileInfoStore;
    uclSettingsStore: UclSettingsStore;

    images: IProfileImages;  // This is the result from DPS
    photo: ProfileImage;  // This will be either a logo or photo

    isDeleteImageModalVisible: boolean = false;
    isPreviewImageModalVisible: boolean = false;
    isUploadingImage: boolean = false;

    // Variables
    isProcessingApi: boolean = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authStore = this.rootStore.authStore
        this.businessHoursStore = this.rootStore.businessHoursStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;
        this.dealerProfileInfoStore = this.rootStore.dealerProfileInfoStore;
        this.uclSettingsStore = this.rootStore.uclSettingsStore;

        this.images = this.initializeImages();
        this.photo = {};

        makeAutoObservable(this);
    };

    public initializeStore = () => {
        this.commonStore.clearPageMsg();
        this.commonStore.currentFeature = Profile;
    };

    public initializeImages() : IProfileImages {
        this.isDeleteImageModalVisible = false;
        this.images = {acceptedImages: {logo:[], photo:[]}, pendingImages: {logo:[], photo:[]} };
        return this.images;
    }

    public hasChanges() : boolean {
        let changes: boolean = false;
        if( this.dealerProfileInfoStore.determineChanges() || this.uclSettingsStore.determineUclSettingsChanges()  || this.businessHoursStore.determineChanges()) {
            changes = true
        }
        return changes;
    };

    public submitDealerDetails = async () => {

        this.commonStore.processingApiCall(true);

        let updateDealerProfile: boolean = true;
        let updateUclSettings: boolean = true;
        let updateBusinessHours: boolean = true;

        this.commonStore.clearFeatureMsg();

        if(this.dealerProfileInfoStore.determineChanges()) {
            updateDealerProfile = await this.dealerProfileInfoStore.submitChanges();
        };

        if (this.uclSettingsStore.determineUclSettingsChanges()) {
            updateUclSettings = await this.uclSettingsStore.submitUclSettingsChanges()
        }

        if (this.businessHoursStore.determineChanges()) {
            updateBusinessHours = await this.businessHoursStore.updateBusinessHours()
        }

        this.commonStore.processingApiCall(false);

        if (updateDealerProfile && updateUclSettings && updateBusinessHours) {
            this.commonStore.displayFeatureNotification(NotificationMessages.UPDATE_SUCCESSFUL, "green");
        } else if (!updateDealerProfile && !updateUclSettings && !updateBusinessHours) {
            this.commonStore.displayPageNotification(NotificationMessages.UPDATE_FAILED, "red")
        } else {
            this.commonStore.displayPageNotification(NotificationMessages.UPDATE_SUCCESSFUL_PARTIAL, "red")
        }
    };

    // ****************************************************************************************************************
    // Everything below here pertains to Logo and Photos.
    // ****************************************************************************************************************
    public getImages = async () => {
        this.displayImageSpinner();
        this.initializeImages();
        let compCode = this.businessUserStore.businessUser?.compCode || "";

        await getImages(this.authStore.token, compCode ).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS':
                    runInAction(() => {
                        this.images = resp.data;
                    });
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve images for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to load images for comp code '${compCode}'.`, "red")
                    break;
            };
        });

        this.hideImageSpinner();
    }

    public uploadImage = (image: any, imageType: ImageType, scalingMode: ImageScalingMode) => {
        this.displayImageSpinner();
        this.addToBlobPolyfill();
        this.conditionAndUploadImage(image, imageType, scalingMode);
    };

    public displayDeleteImageModal = () => {
        this.isDeleteImageModalVisible = true;
    };

    public hideDeleteImageModal() {
        this.isDeleteImageModalVisible = false;
    };

    public handleDeleteImageModalCancel = () => {
        this.hideDeleteImageModal();
    };

    public displayImageSpinner() {
        this.isUploadingImage = true;
    };

    public hideImageSpinner() {
        this.isUploadingImage = false;
    };

    public displayPreviewImageModal = () => {
        this.isPreviewImageModalVisible = true;
    };

    public hidePreviewImageModal() {
        this.isPreviewImageModalVisible = false;
    };

    public handlePreviewImageModalClose = () => {
        this.hidePreviewImageModal();
    };

    public handleDeleteImageModalConfirm = (imageId: any) => {

        const compCode:string = this.businessUserStore.businessUser?.compCode || "";
        const username = CommonUtils.getUserFromToken(this.authStore.token);

        deleteImage(this.authStore.token, compCode, username, imageId)
            .then(( resp: any) => {
                this.getImages().then( resp =>
                    CommonUtils.displayConsoleLogError(`Successfully removed ${this.photo.type}.`)
                );
            })
            .catch((error: any) => {
                    this.commonStore.displayPageNotification(`An error occurred trying to remove the ${this.photo.type}, please try again.  If the problem persists, please contact the tool administrator.`, "red");
                    CommonUtils.displayConsoleLogError(`An error occurred trying to remove the ${this.photo.type} - ${error}`);
                }
            );

        this.hideImageSpinner();
        this.hideDeleteImageModal();
    }

    private addToBlobPolyfill() {
        if (!HTMLCanvasElement.prototype.toBlob) {
            Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
                value(callback: any, type: any, quality: any) {
                    const canvas = this as HTMLCanvasElement;
                    setTimeout(() => {
                        const binStr = atob(canvas.toDataURL(type, quality).split(",")[1]),
                            len = binStr.length,
                            arr = new Uint8Array(len);

                        for (let i = 0; i < len; i++) {
                            arr[i] = binStr.charCodeAt(i);
                        }
                        callback(new Blob([arr], { type: type || "image/png" }));
                    });
                },
            });
        }
    }

    private conditionAndUploadImage = (imageFile: any, imageType: ImageType, scalingMode: ImageScalingMode) => {
        let orientation: number = ORI_NORMAL;
        const imageUrl = window.URL.createObjectURL(imageFile);

        loadImage(
            imageUrl,
            (img: any, data: any) => {
                if (data.exif && data.exif.get("Orientation")) {
                    orientation = data.exif.get("Orientation");
                }
                this.resizeRotateAndUploadImage(img, imageFile, imageType, scalingMode, orientation);
            },
            { meta: undefined }
        );
    };

    private resizeRotateAndUploadImage(
        image: any,
        imageFile: any,
        imageType: ImageType,
        scalingMode: ImageScalingMode,
        orientation: number
    ) {
        const canvas = document.createElement("canvas");
        const size = this.sizeForImageType(imageType);
        const maxHeight = size.height;
        const maxWidth = size.width;

        // set proper canvas dimensions before transform
        if (this.isImageRotated90Degrees(orientation)) {
            canvas.width = maxHeight;
            canvas.height = maxWidth;
        } else {
            canvas.width = maxWidth;
            canvas.height = maxHeight;
        }

        const context = canvas.getContext("2d");
        if (!context) {
            return;
        }
        context.fillStyle = "#EBEBEB";
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Source
        const sx = 0;
        const sy = 0;
        const sWidth = image.width;
        const sHeight = image.height;

        // Destination
        let dx = 0;
        let dy = 0;
        let dWidth = maxWidth;
        let dHeight = maxHeight;

        // transform context before drawing image
        switch (orientation) {
            case ORI_HORIZONTAL_FLIP: {
                context.translate(maxWidth, 0);
                context.scale(-1, 1);
                break;
            }

            case ORI_180_ROTATE_RIGHT: {
                context.translate(maxWidth, maxHeight);
                context.rotate(180 * RADIANS_TO_DEGREES);
                break;
            }

            case ORI_VERTICAL_FLIP: {
                context.translate(0, maxHeight);
                context.scale(1, -1);
                break;
            }

            case ORI_VERTICAL_FLIP_90_ROTATE_RIGHT: {
                context.rotate(90 * RADIANS_TO_DEGREES);
                context.scale(1, -1);
                break;
            }

            case ORI_90_ROTATE_RIGHT: {
                context.rotate(90 * RADIANS_TO_DEGREES);
                context.translate(0, -maxHeight);
                break;
            }

            case ORI_HORIZONTAL_FLIP_90_ROTATE_RIGHT: {
                context.rotate(90 * RADIANS_TO_DEGREES);
                context.translate(maxWidth, -maxHeight);
                context.scale(-1, 1);
                break;
            }

            case ORI_90_ROTATE_LEFT: {
                context.rotate(-90 * RADIANS_TO_DEGREES);
                context.translate(-maxWidth, 0);
                break;
            }

            case ORI_NORMAL:
            default: {
                const ratio =
                    scalingMode === "aspectFit"
                        ? Math.min(maxWidth / image.width, maxHeight / image.height)
                        : Math.max(maxWidth / image.width, maxHeight / image.height);
                dx = (canvas.width - image.width * ratio) / 2;
                dy = (canvas.height - image.height * ratio) / 2;
                dWidth = image.width * ratio;
                dHeight = image.height * ratio;
                break;
            }
        }

        context.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

        canvas.toBlob(
            (blob: Blob | null) => {
                if (!blob) {
                    return;
                }
                this.actuallyUploadImage(imageFile, blob, imageType);
            },
            "image/jpeg",
            this.compressionLevelForImageType(imageType)
        );
    }

    private sizeForImageType(imageType: ImageType) {
        switch (imageType) {
            case "photo":
                return { width: 360, height: 270 };
            case "logo":
                return { width: 120, height: 80 };
        }
    }

    private compressionLevelForImageType(imageType: ImageType) {
        switch (imageType) {
            case "photo":
                return 0.75;
            case "logo":
                return 1.0;
        }
    }

    private isImageRotated90Degrees(orientation: number) {
        if (
            orientation === ORI_VERTICAL_FLIP_90_ROTATE_RIGHT ||
            orientation === ORI_90_ROTATE_RIGHT ||
            orientation === ORI_HORIZONTAL_FLIP_90_ROTATE_RIGHT ||
            orientation === ORI_90_ROTATE_LEFT
        ) {
            return true;
        }
        return false;
    }

    private actuallyUploadImage(originalImage: any, resizedImage: any, imageType: any) {
        const compCode:string = this.businessUserStore.businessUser?.compCode || "";

        const username = CommonUtils.getUserFromToken(this.authStore.token);

        if (!compCode || !username) {
            return;
        }
        const formData = new FormData();
        formData.append("file", resizedImage, `image.${originalImage.name}`);
        formData.append("origFile", originalImage, `origImage.${originalImage.name}`);

        uploadImage(this.authStore.token, compCode, username, imageType, formData)
            .then(( resp: any) => {
                this.getImages().then( resp =>
                    CommonUtils.displayConsoleLogError(`Successfully uploaded ${imageType}.`)
                );
            })
            .catch((error: any) => {
                    this.commonStore.displayPageNotification(`An error occurred trying to upload the ${imageType}, please try again.  If the problem persists, please contact the tool administrator.`, "red");
                    CommonUtils.displayConsoleLogError(`An error occurred trying to upload the ${imageType} - ${error}`);
                }
            );

        this.hideImageSpinner();
    }

};
