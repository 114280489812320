import RootStore from "../root";
import AuthStore from "../auth";
import CommonStore from "../common/common";
import {makeAutoObservable} from "mobx";
import React from "react";
import {getDealershipByCompCode} from "../../clients/damBackendApi";
import {apiStatus} from "../../utils/constants";
import CommonUtils from "../../utils/commonUtils";
import BusinessUserStore from "../businessUser/businessUser";
import {moveProfilePreferences} from "../../clients/dps/dpsApi";
import {profileTabs} from "../../types/profile/enums/profileTabs";

export enum MoveProfilePreferencesSectionEnum {
    Initial,
    Confirm
}

export default class MoveProfilePreferences {

    //Fields
    isMoveProfilePreferencesModalVisible: boolean = false;
    moveProfilePreferenceSection: MoveProfilePreferencesSectionEnum = MoveProfilePreferencesSectionEnum.Initial;
    targetCompCode: string = "";
    targetCompanyName: string = "";
    checkBoxes: Map<string, boolean> = new Map();
    compCodeErrorMessage: string = ""
    preferencesToMove: Array<string> = []
    preferencesNotMoved: Array<string> = []
    selectAllChecked: boolean = false;

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    commonStore: CommonStore;
    businessUserStore: BusinessUserStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authStore = this.rootStore.authStore;
        this.commonStore = this.rootStore.commonStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        makeAutoObservable(this);
        this.checkBoxes.set(profileTabs[0], false);
        this.checkBoxes.set(profileTabs[1], false);
        this.checkBoxes.set(profileTabs[2], false);
        this.checkBoxes.set(profileTabs[3], false);
        this.checkBoxes.set(profileTabs[4], false);
    }


    public displayMoveProfilePreferencesModal = () => {
        this.isMoveProfilePreferencesModalVisible = true;
    }

    public setTargetCompCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.targetCompCode = event.target.value
    }

    // TODO: Can be refactor the code to set checkboxes dynamically/
    public setDealerDetailsChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.checkBoxes.set(profileTabs[0], event.currentTarget.checked);
        this.uncheckSelectAll()
    }

    public setCCLChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.checkBoxes.set(profileTabs[1], event.currentTarget.checked)
        this.uncheckSelectAll()
    }

    public setC4LChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.checkBoxes.set(profileTabs[2], event.currentTarget.checked)
        this.uncheckSelectAll()
    }

    public setRnRChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.checkBoxes.set(profileTabs[3], event.currentTarget.checked)
        this.uncheckSelectAll()
    }

    public setCallMgmtChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.checkBoxes.set(profileTabs[4], event.currentTarget.checked)
        this.uncheckSelectAll()
    }

    public setSelectAllChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setDealerDetailsChecked(event);
        this.setCCLChecked(event);
        this.setC4LChecked(event);
        this.setRnRChecked(event);
        this.setCallMgmtChecked(event)
    }

    public uncheckSelectAll = () => {
        this.selectAllChecked = Array.from(this.checkBoxes.values()).reduce((ans: boolean, currentValue: boolean) => ans && currentValue)

    }

    public setCompCodeError = (msg: string) => {
        this.compCodeErrorMessage = msg;
    }

    public returnSelectedPreferences = () => {
        this.preferencesToMove =Array.from(this.checkBoxes.entries()).filter((pref) => pref[1]).map(pref => pref[0]);
        return this.preferencesToMove.join(", ");
    }

    public handleMoveProfilePreferenceCancel = () => {
        this.isMoveProfilePreferencesModalVisible = false;
        this.targetCompCode = "";
        Array.from(this.checkBoxes.keys()).forEach((key) => {
            this.checkBoxes.set(key, false);
        })
        this.moveProfilePreferenceSection = MoveProfilePreferencesSectionEnum.Initial;
        this.selectAllChecked = false;
        this.preferencesToMove = []
        this.preferencesNotMoved = []
        this.compCodeErrorMessage = ""
    }

    public handleMoveProfilePreferenceBack = () => {
        this.moveProfilePreferenceSection = MoveProfilePreferencesSectionEnum.Initial;
    }

    public disableMoveProfilePreferencesNextButton = () => {
        if (!this.targetCompCode.trim()) {
            return true;
        } else if (this.targetCompCode.trim().toUpperCase() === this.businessUserStore.businessUser?.compCode) {
            return true;
        } else if (!Array.from(this.checkBoxes.values()).reduce((ans: boolean, currentValue: boolean) => ans || currentValue)) {
            return true;
        } else {
            return false;
        }
    }

    public handleMoveProfilePreferencesNextButton = async () => {
        this.rootStore.commonStore.processingApiCall(true);

        const compCode = this.targetCompCode?.trim().toUpperCase();
        this.compCodeErrorMessage = "";

        if (compCode) {
            await getDealershipByCompCode(this.authStore.token, compCode).then((resp) => {
                switch (resp?.status) {
                    case apiStatus.success :
                        this.targetCompanyName = resp.data?.companyName
                        this.setCompCodeError("")
                        this.moveProfilePreferenceSection = MoveProfilePreferencesSectionEnum.Confirm;
                        break;
                    case apiStatus.notFound :
                        CommonUtils.displayConsoleLogError(`*** comp code ${compCode} not found. Response: ` + JSON.stringify(resp));
                        this.setCompCodeError("This comp code could not be found.")
                        break;
                    default :
                        this.setCompCodeError(resp.damDefaultErrorMessage)
                        CommonUtils.displayConsoleLogError(`*** Error calling BIS for comp code ${compCode}. Response: ` + JSON.stringify(resp));
                        break;
                }
            });
        }

        this.rootStore.commonStore.processingApiCall(false);
    }

    public moveProfilePreferencesDPSCall = async (preferences: string[]) => {
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: `Move Profile Preferences`});
        let response: any;
        response = await moveProfilePreferences(this.authStore.token, {
            compCodes: {
                source: this.businessUserStore.businessUser?.compCode,
                target: this.targetCompCode
            },
            selectedPrefs: preferences
        })
        return response;
    }

    public handleMoveProfilePreferencesSubmitButton = async () => {
        this.rootStore.commonStore.processingApiCall(true);
        await this.moveProfilePreferencesDPSCall(this.preferencesToMove).then((resp: any) => {
            let msg: string;
            switch (resp?.status) {
                case apiStatus.success:
                    if (this.preferencesToMove.length === 5) {
                        msg = `Success! You have moved all preferences from ${this.businessUserStore.businessUser?.companyName} - ${this.businessUserStore.businessUser?.compCode} to ${this.targetCompanyName} - ${this.targetCompCode}.`
                    }
                    else {
                        msg = `Success! You have moved ${this.preferencesToMove.join(", ")} from ${this.businessUserStore.businessUser?.companyName} - ${this.businessUserStore.businessUser?.compCode} to ${this.targetCompanyName} - ${this.targetCompCode}.`
                    }
                    this.rootStore.commonStore.displayFeatureNotification(msg, "green");
                    break;
                case apiStatus.unprocessableContent:
                    this.preferencesNotMoved = resp?.data.selectedPrefs
                    if (this.preferencesNotMoved === this.preferencesToMove) {
                        msg = `Preferences failed to move from ${this.businessUserStore.businessUser?.companyName} - ${this.businessUserStore.businessUser?.compCode} to ${this.targetCompanyName} - ${this.targetCompCode}. Please move preferences manually.`;
                    }
                    else {
                        let preferencesMoved: Array<string> = this.preferencesToMove.filter(preferences => !this.preferencesNotMoved.includes(preferences))
                        msg = `You have moved ${preferencesMoved.join(", ")} successfully to ${this.targetCompanyName} - ${this.targetCompCode}. ${this.preferencesNotMoved.join(", ")} experienced failures. Please confirm preferences manually.`;
                    }
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to move ${this.preferencesNotMoved.join(", ")} from  ${this.businessUserStore.businessUser?.compCode} to comp code ${this.targetCompCode}.  Response: ` + JSON.stringify(resp));
                    this.rootStore.commonStore.displayFeatureNotification(msg, "red");
                    break;
                default:
                    msg = `Severe error occurred trying to move preferences to target compCode ${this.targetCompCode}`
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to move preferences to target comp code ${this.targetCompCode}.  Response: ` + JSON.stringify(resp));
                    this.rootStore.commonStore.displayFeatureNotification(msg, "red");
                    break;
            }
        })
        this.rootStore.commonStore.processingApiCall(false);
        this.isMoveProfilePreferencesModalVisible = false;
        this.handleMoveProfilePreferenceCancel();
    }
}