import {Environment, getEnvironment} from "./environment";

let apiUrlArray: {[key: string]: string} = {
    BASE_STAGING : 'https://stagingdam.carfax.net/api',
    BASE_PRODUCTION : 'https://dam.carfax.net/api',

    CFO_REMOTE_LOGIN_STAGING: 'https://beta.carfaxonline.com',
    CFO_REMOTE_LOGIN_PROD: 'https://www.carfaxonline.com',

    CONSUMER_STAGING: 'https://consumerapi-staging.carfax.com/smc/smb',
    CONSUMER_PROD: 'https://consumerapi.carfax.com/smc/smb',

    DPS_STAGING : 'https://stagingdam.carfax.net/api/dam/profile-service',
    DPS_PRODUCTION : 'https://dam.carfax.net/api/dam/profile-service',
    DPS_LOCAL : 'http://localhost:8443/profile-service'
}

const origin = window.location.origin

class UrlBuilder {

    /*
        TODO - Should buildBaseUrl(); buildConsumerUrl(); and buildDpsUrl() be refactored to utilize getEnvironment instead of looking at 'origin' ?
     */

    public static buildBaseUrl(path: string) : string {
        if(origin.includes('staging') || origin.includes('localhost')) {
            return apiUrlArray['BASE_STAGING'] + path;
        } else {
            return apiUrlArray['BASE_PRODUCTION'] + path;
        }
    }

    public static buildCfoRemoteLoginUrl(user: any) : string {
        if (getEnvironment() === Environment.PROD) {
            return `${apiUrlArray['CFO_REMOTE_LOGIN_PROD']}/login?id=${user.oneAccountId}`;
        } else {
            return `${apiUrlArray['CFO_REMOTE_LOGIN_STAGING']}/login?id=${user.oneAccountId}`;
        }
    };

    public static buildDevRemoteUrl(user: any, env: string) : string {
        //this feature is only available in beta
        return `https://${env}.beta.carfaxonline.com/login?id=${user.oneAccountId}`
    };

    public static buildConsumerUrl() : string {
        if(origin.includes('staging') || origin.includes('localhost')) {
            return apiUrlArray['CONSUMER_STAGING'];
        } else {
            return apiUrlArray['CONSUMER_PROD'];
        }
    }

    public static buildDpsUrl(path: string) : string {
        if(origin.includes('staging') || origin.includes('localhost')) {
            return apiUrlArray['DPS_STAGING'] + path;
             // return apiUrlArray['DPS_LOCAL'] + path;
        } else {
            return apiUrlArray['DPS_PRODUCTION'] + path;
        }
    }

}

export default UrlBuilder;
