import * as React from "react"
import {observer} from "mobx-react"
import Store from "../../stores"
import "../../styles/main.scss"
import "./searchResults.scss"
import {BusinessUser} from "../../types/businessUser";

export default observer(
    (props:{businessUser:BusinessUser, store: any}) => {

    const businessUser = props.businessUser
    const store = props.store
    const searchStore = Store.search.search;
    const businessUserStore = Store.search.businessUser;

    return(
        <div id="search-results-div" className={(businessUser.compCode === searchStore.selectedCompCode) ? "search-container-selected" : "search-container-white"}
             onClick={() => {store.onCompCodeClick(businessUser)}} hidden={false}>
            <div>
                <div id="search-results-company-name-div" className="flex-row">
                    <p id="search-results-company-name-p" className="search-results-company-name">{businessUser?.companyName}
                        {(businessUser.turnedOff === true)
                            && <span id="search-results-suspended-label" className="search-results-suspended-label">(Suspended)</span>
                        }
                    </p>

                </div>
                <div id="search-results-street-address-div" >
                    <p id="search-results-street-address-p" className="search-results-street-address">{businessUser?.address?.streetAddress1}</p>
                </div>
                <div id="search-results-city-state-zip-div">
                    <p id="search-results-city-state-zip-p" className="search-results-city-state-zip">{businessUser?.address?.city}, {businessUser?.address?.state} {businessUser?.address?.zip}</p>
                </div>
                <div id="search-results-salesforceId-row-div" className="flex-row">
                    <div id="search-results-salesforceId-label-div" className="flex-col, search-results-salesforceId-label">Salesforce ID:</div>
                    <p id="search-results-salesforceId-label-p" className="flex-col, search-results-salesforceId-value,  ">{businessUser?.salesforceId}</p>
                </div>

                <div id="search-results-comp-code-account-row-div" className="flex-row">
                    <div id="search-results-comp-code-label-div" className="flex-col, search-label-comp-code">Comp Code:</div>
                    <p id="search-results-comp-code-label-p" className="flex-col, search-value-comp-code">{businessUser?.compCode}</p>
                    <div id="search-results-account-status-label-div" className="flex-col">
                        <div id="search-results-account-status-label" className="flex-col, search-label-account-status">Account Status:</div>
                    </div>
                    <div id="search-results-account-status-value-div" className="flex-col">
                        <p id="search-results-account-status-value-p" className="flex-col, search-value-account-status">{businessUser?.active ? "Active" : "Inactive"}</p>
                    </div>
                </div>

                <div id="search-results-ucl-status-phone-row-div" className="flex-row">
                    <div id="search-results-ucl-status-label-div" className="flex-col, search-label-ucl-status">CCL Status:</div>
                    <p id="search-results-ucl-status-label-p" className="flex-col, search-value-ucl-status">{businessUser?.uclStatus}</p>
                    <div id="search-results-phone-label-div" className="flex-col">
                        <div id="search-results-phone-label-p" className="flex-col, search-label-phone">Phone:</div>
                    </div>
                    <div id="search-results-phopne-value-div"  className="flex-col">
                        <p id="search-results-phone-value-p" className="flex-col, search-value-phone">{businessUser?.contact?.phone}</p>
                    </div>
                </div>

                <div id="search-results-til-vrss-row-div" className="flex-row">
                    <div id="search-results-til-label-div" className="flex-col, search-label-til-dealer">Trade-In Lead Dealer:</div>
                    <p id="search-results-til-label-p" className="flex-col, search-value-til-dealer ">{businessUser?.sellMyCarEligible ? "Yes" : "No"}</p>
                    <div id="search-results-vrss-label-div" className="flex-col">
                        <div id="search-results-vrss-label" className="flex-col, search-label-vrss">VRSS:</div>
                    </div>
                    <div id="search-restuls-vrss-value-div" className="flex-col">
                        <p id="search-results-vrss-value-p" className="flex-col, search-value-vrss">{businessUserStore?.vrss ? "Yes" : "No"}</p>
                    </div>
                </div>

                <div id="search-results-industry-row-div" className="flex-row">
                    <div id="search-results-industry-label-div" className="flex-col, search-results-industry-label">Industry:</div>
                    <p id="search-results-industry-label-p" className="flex-col, search-results-industry-value">{businessUser?.industry}</p>
                </div>
            </div>
        </div>)
})
