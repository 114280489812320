import {Notification, NotificationIcon, NotificationMessage, NotificationThemeType} from "@carfax-stencils/notification";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import {PropsWithChildren, ReactNode} from "react";
import "./page.scss";

interface PageProps extends PropsWithChildren {
  id: string;
  title?: any;
  includeFooter?: boolean;
  className?: string;
  snackbar?: ReactNode;
  fullWidth?: boolean;
  pageContentClass?: string; // applies to area between header & footer
};

@observer
class Page extends React.Component<PageProps> {

  @observable flashMessage: string | undefined;
  @observable theme: NotificationThemeType | undefined;
  @observable role: "alert" | undefined;

  static defaultProps: Partial<PageProps> = {
    includeFooter: true,
    fullWidth: false,
  };

  componentDidCatch(error: Error | null, info: object) {
    console.log("componentDidCatch error:", error);
    console.log("componentDidCatch info: ", info);
  }

  componentDidMount() {
    if (this.props.title) {
      window.document.title = this.props.title;
    } else {
      window.document.title = "Dealer Account Management";
    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  render() {
    const pageContentClass = this.props.fullWidth
      ? "page__content-full-width"
      : "page__content";

    return (
      <div id="page-div" className="page_wrapper">
        <div id="page-frame-div" className={`page__frame ${this.props.className || ""}`}>
          <div
              id="page-frame-content-div"
              className={
              pageContentClass + " " + this.props.pageContentClass || ""
            }
          >
            {this.flashMessage &&
                <Notification
                    id="page-notification"
                    role={"alert"}
                    theme={this.theme}>
                  <NotificationIcon/>
                  <NotificationMessage>
                    {this.flashMessage}
                  </NotificationMessage>
                </Notification>
            }
            {this.props.children}
          </div>
          {this.props.snackbar}
        </div>
      </div>
    );
  }
}

export default Page;
// export default withRouter(Page);
