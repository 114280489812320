import Icon_Access_Mgmt from "../../images/home/Icon_Access_Management.svg";
import Icon_Dealer_Profile_Management from "../../images/home/Icon_Dealer_Profile_Management.svg";
import Icon_Utilities from "../../images/home/Icon_Utilities.svg";
import Icon_Group_Setup from "../../images/home/Icon_Group_Setup.svg";
import Icon_Partner_Maintenance from "../../images/home/Icon_Partner_Maintenance.svg";
import Icon_Reputation_Partner from "../../images/home/Icon_Reputation_Partner.svg";
import Header from "../../shared-components/layout/header/Header";
import React from "react";
import { SpinnerDots } from "@carfax-stencils/spinner";
import Store from "../../stores";
import {checkUser} from "../security/helpers";
import {DAM_Admin, DAM_Super_Admin} from "../../types/groups";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react";
import {withOktaAuth} from "@okta/okta-react";
import "./HomePage.scss";
import {getEnvironment} from "../../utils/environment";

// Variables
type localState = { userInfo: any };

// Stores
const rootStore = Store.root.root;
const authStore = rootStore.authStore;
const searchStore = rootStore.searchStore;

export default withOktaAuth(
  class HomePage extends React.Component<{ authState: any }, localState> {
    checkUser: any;

    constructor(props: any) {
      super(props);
      this.state = { userInfo: null };
      this.checkUser = checkUser.bind(this);
    }

    async componentDidMount() {
      await this.checkUser();
      searchStore.initializeSearch();
      searchStore.initializeStore();
      searchStore.initializeSearchValue();
      console.log(`%c > Environment: ${getEnvironment()}`, 'background: #fff2cc; color: #001a00')
    }

    async componentDidUpdate() {
      await this.checkUser();
    }

    render() {
      return (
        <div id="home-page-main-outer-div">
        <Header />
        <div id="home-page-main-inner-div">
          {this.state.userInfo && (
            <div id="home-page-wrapper-div" className="home-page-wrapper">
              <br />
              <div id="home-page-title-div" className="home-title">Dealer Account Management</div>
              <br />
              <div id="home-page-description-div" className="home-description">
                The Dealer Account Management (DAM) tool is an internal tool
                utilized for both the management of a dealership/service
                center's preferences as well as a user's account access.
              </div>
              <Deck />
            </div>
          )}
        </div>
        </div>
      );
    }
  }
);

const Deck = observer(() => {
  return (
    <div id="home-page-wrapper">
        {authStore.groups === undefined ?
            <div id="home-page-card-spinner-div">
                <SpinnerDots id="home-page-card-spinner" />
            </div>
        :
            <div id="home-page-card-container" className="home-card-container">
                <Card
                    id="home-page-partner-maintenance-card"
                    image={Icon_Dealer_Profile_Management}
                    linkText="Profile Management >"
                    linkHref="/profile"
                    linkId="profile-link"
                />
                {authStore.groups.includes(DAM_Super_Admin) &&
                    <Card
                        id="home-page-group-preferences-card"
                        image={Icon_Group_Setup}
                        linkText="Group Preferences Setup &nbsp;&nbsp;&nbsp;>"
                        linkHref="/preferences"
                        linkId="preferences-link"
                    />
                }
                {authStore.groups.includes(DAM_Admin) &&
                    <Card
                        id="home-page-group-preferences-card"
                        image={Icon_Group_Setup}
                        linkText="Group Preferences Setup &nbsp;&nbsp;&nbsp;>"
                        linkHref="/preferences"
                        linkId="preferences-link"
                    />
                }
                {authStore.groups.includes(DAM_Super_Admin) &&
                    <Card
                        id="home-page-partner-maintenance-card"
                        image={Icon_Partner_Maintenance}
                        linkText="Partner Maintenance &nbsp;&nbsp;&nbsp;>"
                        linkHref="/partners"
                        linkId="partners-link"
                    />
                }
                {(authStore.groups.includes(DAM_Super_Admin) || authStore.groups.includes(DAM_Admin)) &&
                    <Card
                        id="home-page-utilities-card"
                        image={Icon_Utilities}
                        linkText="Utilities &nbsp;&nbsp;&nbsp;>"
                        linkHref="/utilities"
                        linkId="utilities-link"
                    />
                }
                <Card
                    id="home-page-reputation-partner-card"
                    image={Icon_Reputation_Partner}
                    linkText="Reputation Partner Tool &nbsp;&nbsp;&nbsp;>"
                    linkHref="/reputationPartner"
                    linkId="reputation-partner-link"
                />
                <Card
                    id="home-page-acct-mgmt-card"
                    image={Icon_Access_Mgmt}
                    linkText="Account Management &nbsp;&nbsp;&nbsp;>"
                    linkHref="/accountMgmt"
                    linkId="account-mgmt-link"
                />
            </div>
        }
    </div>
  );
});

type CardProps = {
  id: string;
  image: string;
  linkText: string;
  linkHref: string;
  linkId: string;
};

const Card = (props: CardProps) => {
  return (
    <div id="home-page-card" className={`home-card cfx-card`}>
      <div>
        <NavLink to={props.linkHref}>
            <div id="home-page-card-image-container" className={`home-card__image-container`}>
                <img id="home-page-card-image" src={props.image} alt={props.linkText}/>
            </div>
            <div id="home-page-card-link" className={'home-card__link'}>
                {props.linkText}
            </div>
        </NavLink>
      </div>
    </div>
  );
};
