import * as React from "react";
import Button from "@carfax-stencils/button";
import InlineErrorMessage from "../../../shared-components/layout/error/InlineErrorMessage";
import SelectInput from "@carfax-stencils/input-select";
import Store from "../../../stores";
import TextInput from "@carfax-stencils/input-text";
import {Accordion, Expander, ExpanderBody, ExpanderHeader} from "@carfax-stencils/accordion";
import {CheckboxToggle} from "@carfax-stencils/input-checkbox";
import {DB_ACTION_DELETE} from "../../../utils/constants";
import {Email} from "../../../types/email";
import {EmailTextInput} from "../../../shared-components/email/EmailTextInput";
import {emailTypeEnum} from "../../../types/profile/enums/emailTypeEnum";
import AutoCompleteInput from "@carfax-stencils/input-auto-complete";
import {useEffect} from "react";

import {Lock, Plus} from "@carfax-stencils/icon";
import {observer} from "mobx-react";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import "../../../utils/newRelic/newrelic";
import "../../../styles/main.scss";
import "./Carfax4LifePrefs.scss";

// Props
interface Carfax4LifePrefsTabProps {
    compCode:any
}

// Stores
const rootStore = Store.root.root;
const authStore = rootStore.authStore;
const commonStore = rootStore.commonStore;
const businessUserStore = rootStore.businessUserStore;
const partnerStore = rootStore.partnersStore;
const smcSettingsStore = rootStore.smcSettingsStore;


export default observer (

    class Carfax4LifePrefsTab extends React.Component<Carfax4LifePrefsTabProps> {

        state = {
            ready: false
        };

        async componentDidMount() {
            this.setState({ready:true});
            await this.loadData()
        };

        async componentDidUpdate() {
            if(businessUserStore.businessUser?.compCode !== smcSettingsStore.smcSettings?.compCode) {
                await this.loadData()
            };
        };

        private async loadData() {
            commonStore.processingApiCall(true)
            await Promise.all([
                smcSettingsStore.getSmcSettings(),
                smcSettingsStore.getEmails(),
                smcSettingsStore.getConsumerWidgetData()
            ]);
            commonStore.processingApiCall(false)
        }

        private focusOnEmailAfterAdd() {
            if(smcSettingsStore.focusOnEmailTextInput) {
                smcSettingsStore.emailInputRef?.current?.focus();
                smcSettingsStore.focusOnEmailTextInput = false;
            }
        }

        render() {
            return (
                <div>
                    <div>
                        {commonStore.isProcessingApi && (
                            <ProcessingApiSpinner/>
                        )}
                        <div id="c4l-settings-tab-div" className="c4l-prefs-tab-div">
                            <p id="c4l-settings-tab-main-heading" className="main-heading">
                                CARFAX for Life Preferences
                            </p>
                        </div>
                    </div>
                    <Accordion>
                        <Expander open={true}>
                            <ExpanderHeader className="c4l-blue-sub-heading">CARFAX for Life</ExpanderHeader>
                            <ExpanderBody>
                                <this.CARFAX4LifeSection/>
                            </ExpanderBody>
                        </Expander>
                    </Accordion>
                    <div className="c4l-accordion-spacer-div"/>
                    <Accordion>
                        <Expander open={true}>
                            <ExpanderHeader className="c4l-blue-sub-heading">Trade-In Lead</ExpanderHeader>
                            <ExpanderBody>
                                <this.TradeInLeadSection/>
                            </ExpanderBody>
                        </Expander>
                    </Accordion>
                    <div className="c4l-accordion-spacer-div"/>
                </div>
            )
        }

        CARFAX4LifeSection = observer(() => {
            return (
                <div id="c4l-settings-tab-div" className="c4l-accordion-body-div">

                    {/*  " Customer List First Load Date " Section   */}
                    <p id="customer-list-first-load-date-sub-heading" className="sub-heading">
                        Customer List First Load Date
                    </p>
                    <div id="customer-list-first-load-date-description-div" className="left-padding">
                        <p id="customer-list-first-load-date-description-text" className="primary_text">
                            The date of the first CARFAX for Life customer list upload from the dealer either manually or via a partner.
                        </p>
                    </div>
                    <TextInput
                        id="customer-list-first-load-date"
                        className="customer-list-load-date"
                        icon={<Lock className='padlock-icon' size={24} /> }
                        disabled={true}
                        value={smcSettingsStore.getFirstLoadedDate()}
                    />

                    {/*  " Customer List Last Load Date " Section   */}
                    <p id="customer-list-last-load-date-sub-heading" className="sub-heading">
                        Customer List Last Load Date
                    </p>
                    <div id="customer-list-last-load-date-description-div" className="left-padding">
                        <p id="customer-list-last-load-date-description-text" className="primary_text">
                            The date of the most recent CARFAX for Life customer list upload from the dealer.
                        </p>
                    </div>
                    <TextInput
                        id="customer-list-last-load-date"
                        className="customer-list-load-date"
                        icon={<Lock className='padlock-icon' size={24} /> }
                        disabled={true}
                        value={smcSettingsStore.getLastLoadedDate()}
                    />
                    <div className="c4l-accordion-spacer-div"/>

                    {/*  " DMS Provider " Section   */}
                    <hr id="dms-provider-separator-line" className="padding-top separator-line"/>
                    <p id="dms-provider-sub-heading" className="sub-heading">
                        DMS
                    </p>
                    <div id="dms-provider-description-div" className="left-padding">
                        <p id="dms-provider-description-text" className="primary_text">
                            The Dealer Management System provided to CARFAX in the dealer contract.
                        </p>
                    </div>
                    <div id="dms-provider-div" className="left-padding">
                        <p id="dms-provider-header" className="input-label ">
                            Please Select DMS
                        </p>
                    </div>
                    <div id="dms-provider-dropdown-div" className="left-padding bottom-padding provider-input-width">
                        <AutoCompleteInput
                            options={partnerStore.partnerNames}
                            value={smcSettingsStore.selectedDmsProvider}
                            onSelect={(option : any) => {
                                smcSettingsStore.onDmsProviderChange(option?.value)
                            }}
                            onChange={(dmsProviderName : string) => {
                                smcSettingsStore.onDmsProviderChange(dmsProviderName)
                            }}
                            placeholder="Select an option"
                            noMatchesMessage="No matches found"
                            disableSort
                        />
                        <InlineErrorMessage id="dms-provider-inline-error" errorMessage={smcSettingsStore.dmsProviderErrorMsg}/>
                    </div>


                    {/*  " CRM Provider " Section   */}
                    <hr id="crm-provider-separator-line" className="separator-line"/>
                    <p id="crm-provider-sub-heading" className="sub-heading">
                        CRM
                    </p>
                    <div id="crm-provider-description-div" className="left-padding">
                        <p id="crm-provider-description-text" className="primary_text">
                            The Customer Relationship Management system provided to CARFAX in the dealer contract.
                        </p>
                    </div>
                    <div id="crm-provider-div" className="left-padding">
                        <p id="crm-provider-header" className="input-label ">
                            Please Select CRM
                        </p>
                    </div>
                    <div id="crm-provider-dropdown-div" className="left-padding bottom-padding provider-input-width">
                        <AutoCompleteInput
                            options={partnerStore.partnerNames}
                            value={smcSettingsStore.selectedCrmProvider}
                            onSelect={(option : any) => {
                                smcSettingsStore.onCrmProviderChange(option?.value)
                            }}
                            onChange={(crmProviderName : string) => {
                                smcSettingsStore.onCrmProviderChange(crmProviderName)
                            }}
                            placeholder="Select an option"
                            noMatchesMessage="No matches found"
                            disableSort
                        />
                        <InlineErrorMessage id="crm-provider-inline-error" errorMessage={smcSettingsStore.crmProviderErrorMsg}/>
                    </div>


                    {/*  " C4L Data Provider " Section   */}
                    <hr id="c4l-data-provider-separator-line" className="separator-line"/>
                    <p id="c4l-data-provider-sub-heading" className="sub-heading">
                        C4L Data Provider
                    </p>
                    <div id="c4l-data-provider-description-div" className="left-padding">
                        <p id="c4l-data-provider-description-text" className="primary_text">
                            The data provider(s) associated with the dealer.
                        </p>
                    </div>
                    <div id="c4l-data-provider-div" className="left-padding">
                        <p id="c4l-data-provider-header" className="input-label ">
                            Please Select C4L Data Provider
                        </p>
                    </div>
                    <div id="c4l-data-provider-dropdown-div" className="left-padding provider-input-width">
                        <AutoCompleteInput
                            options={partnerStore.partnerNames}
                            value={smcSettingsStore.selectedC4lDataProvider}
                            onSelect={(option : any) => {
                                smcSettingsStore.onC4lDataProviderChange(option?.value)
                            }}
                            onChange={(c4lDataProviderName : string) => {
                                smcSettingsStore.onC4lDataProviderChange(c4lDataProviderName)
                            }}
                            placeholder="Select an option"
                            noMatchesMessage="No matches found"
                            disableSort
                        />
                        <InlineErrorMessage id="c4l-provider-inline-error" errorMessage={smcSettingsStore.c4lProviderErrorMsg}/>
                    </div>
                </div>
            );
        });

        // Trade-In Lead
        TradeInLeadSection = observer(() => {

            useEffect(() => {
                this.focusOnEmailAfterAdd();
            });

            return (
                <div id="trade-in-lead-section-div" className="c4l-accordion-body-div">
                    {/*  " Trade-In Lead Dealer " Section   */}
                    <p id="trade-in-lead-dealer-sub-heading" className="sub-heading">
                        Trade-In Lead Dealer
                    </p>
                    <div id="trade-in-lead-dealer-description-div" className="left-padding">
                        <p id="trade-in-lead-dealer-description-text" className="primary_text">
                            "On" indicates the dealership is participating in the CARFAX For Life trade-in feature.
                        </p>
                    </div>
                    <div id="trade-in-lead-dealer-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="allow-backfill-toggle-label" className="toggle-label">Enable Trade-In Leads</p>
                        <CheckboxToggle
                            id="trade-in-lead-dealer-toggle"
                            disabled={authStore.isNonAdminGroup() || !smcSettingsStore.smcSettings.eligible}
                            checked={smcSettingsStore.smcSettings.eligible}
                            onChange={event => {
                                smcSettingsStore.onToggleTradeInLeadDealer(event.target.checked)
                            }}
                        />
                    </div>
                    <div id="trade-in-lead-dealer-toggle-error-div">
                        <InlineErrorMessage id="til-adf-lead-required-inline-error" errorMessage={smcSettingsStore.tradeInDealerEmailRequiredMsg}/>
                    </div>
                    <div id="trade-in-lead-dealer-source-message" className="left-padding top-padding">
                        <p id="trade-in-lead-dealer-source-message-text" className="primary_text">
                            Please choose at least one <b>Trade-In Lead Source</b> for a dealer participating in the <b>Trade-In Lead Program</b>.
                        </p>
                    </div>

                    {/*  " Trade-In Lead Toggles Alerts/Widgets/WebApp " Section   */}
                    <br/>
                    <div className="til-toggles-div">
                        <div id="trade-in-lead-sub-toggles-header-div" className="til-toggles-sub-toggles-heading">
                            <p id="trade-in-lead-sub-toggles-header-text" className="sub-heading">
                                Trade-In Lead Sources
                            </p>
                        </div>
                        <div id="trade-in-lead-alerts-toggle-div" className="til-toggles-left-padding top-padding">
                            <CheckboxToggle
                                id="trade-in-lead-alerts-toggle" className="til-toggles-bold"
                                label="Trade-In Lead Alerts"
                                checked={smcSettingsStore.smcSettings.sendCustomerAlerts === true ? true : false}
                                onChange={event => {
                                    smcSettingsStore.onToggleTradeInLeadAlerts(event.target.checked)
                                }}
                                disabled={authStore.isNonAdminGroup()}
                            />
                            <p id="trade-in-lead-alerts-toggles-description" className="til-toggles-description-left-padding primary_text">
                                Trade-In Lead Alerts will be sent out to the dealer's CARFAX For Life customers & Car Care favorites.
                            </p>
                        </div>
                        <div id="til-toggles-alerts-separator-line-div" className="top-padding">
                            <hr id="til-toggles-alerts-separator-line" className="til-toggles-separator-line"/>
                        </div>
                        <div id="trade-in-lead-widget-toggle-div" className="til-toggles-left-padding top-padding">
                            <CheckboxToggle
                                id="trade-in-lead-widget-toggle" className="til-toggles-bold"
                                label="Trade-In Lead Widget"
                                checked={smcSettingsStore.smcSettings.includeWidget === true ? true : false}
                                onChange={event => {
                                    smcSettingsStore.onToggleTradeInLeadWidget(event.target.checked)
                                }}
                                disabled={authStore.isNonAdminGroup()}
                            />
                            <p id="trade-in-lead-widget-toggles-description" className="til-toggles-description-left-padding primary_text">
                                Enables the Trade-In Lead widget to be used on a dealer's website. The widget must be added to their website separately by the website partner or the dealer's website administrator.
                            </p>
                        </div>
                        <div id="trade-in-lead-widget-live-msg-div" className="til-widget-msg-left-padding primary_text">
                            <br/><p>{smcSettingsStore.widgetLastUsed}</p>
                        </div>
                        <div id="til-toggles-webapp-separator-line-div" className="top-padding">
                            <hr id="til-toggles-webapp-separator-line" className="til-toggles-separator-line"/>
                        </div>
                        <div id="trade-in-lead-webapp-toggle-div" className="til-toggles-left-padding top-padding">
                            <CheckboxToggle
                                id="trade-in-lead-webapp-toggle" className="til-toggles-bold"
                                label="Trade-In Lead Web App"
                                checked={smcSettingsStore.smcSettings.webAppEnabled === true ? true : false}
                                onChange={event => {
                                    smcSettingsStore.onToggleTradeInLeadWebApp(event.target.checked)
                                }}
                                disabled={authStore.isNonAdminGroup()}
                            />
                            <p id="trade-in-lead-webapp-toggles-description" className="til-toggles-description-left-padding primary_text">
                                This dealership will be included as an option for users to sell their car at <span className="til-color-blue">
                                www.carfax.com/sell-my-car</span>
                                {' '} and throughout <span className="til-color-blue">www.carfax.com</span>.
                            </p>
                        </div>
                    </div>

                    {/*  " ADF Lead Emails " Section   */}
                    <div id="til-adf-emails-separator-line-div" className="top-padding">
                        <hr id="til-adf-emails-separator-line" className="separator-line"/>
                    </div>
                    <p id="til-adf-emails-sub-heading" className="sub-heading">
                        ADF Lead Email(s)
                    </p>
                    <div id="til-adf-emails-description-div" className="left-padding">
                        <p id="til-adf-emails-description-text" className="primary_text">
                            This email is the address for all CRM online leads.
                        </p>
                    </div>
                    <InlineErrorMessage id="til-adf-lead-required-inline-error" errorMessage={smcSettingsStore.tradeInDealerEmailRequiredMsg}/>
                    <div id="til-adf-emails-div" className="email-input">
                        {smcSettingsStore.tradeInAdfLeadEmails?.map((email:Email, index:number) => (
                            email.action !== DB_ACTION_DELETE &&
                            <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={smcSettingsStore}
                                            textInputRef={smcSettingsStore.emailInputRef}/>))
                        }
                    </div>
                    <div id="adf-emails-add-button-div" className="left-padding bottom-padding email-input">
                        <Button
                            id="adf-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add ADF Lead Email`}
                            onClick={() => {
                                smcSettingsStore.onEmailAdd(emailTypeEnum.TRADE_IN_ADF_LEAD)
                            }}
                            theme="blue-outline"
                        />
                    </div>

                    {/*  " DMS Provider " Section   */}
                    <hr id="til-leads-adf-provider-separator-line" className="separator-line"/>
                    <p id="til-leads-adf-sub-heading" className="sub-heading">
                        Leads ADF Provider
                    </p>
                    <div id="til-leads-adf-provider-description-div" className="left-padding">
                        <p id="til-leads-adf-provider-description-text" className="primary_text">
                            This identifies the source of the lead and is included in the ADF lead sent to a dealer.
                        </p>
                    </div>
                    <div id="til-leads-adf-provider-div" className="left-padding">
                        <p id="til-leads-adf-provider-header" className="input-label ">
                            Please Select a Leads ADF Provider
                        </p>
                    </div>
                    <div id="til-leads-adf-provider-dropdown-div" className="left-padding bottom-padding provider-input-width">
                        <SelectInput
                            value={smcSettingsStore.smcSettings.leadsAdfProvider}
                            onChange={(option : any) => {
                                smcSettingsStore.onLeadsAdfProviderChange(option.target.value)
                            }}
                        >
                            {["CARFAX, INC", "CARFAX - Trade-In Leads"].map((item: string, index: number) =>
                                <option key={index} value={item}>
                                    {item}
                                </option>      
                            )}
                        </SelectInput>
                    </div>

                    {/*  " Text Lead Emails " Section   */}
                    <hr id="til-text-emails-separator-line" className="separator-line"/>
                    <p id="til-text-emails-sub-heading" className="sub-heading">
                        Text Lead Email(s)
                    </p>
                    <div id="til-text-emails-description-div" className="left-padding">
                        <p id="til-text-emails-description-text" className="primary_text">
                            This email is the address for all text online leads.
                        </p>
                    </div>
                    <InlineErrorMessage id="til-text-lead-required-inline-error" errorMessage={smcSettingsStore.tradeInDealerEmailRequiredMsg}/>
                    <div id="til-text-emails-div" className="email-input">
                        {smcSettingsStore.tradeInTextLeadEmails?.map((email:Email, index:number) => (
                            email.action !== DB_ACTION_DELETE &&
                            <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={smcSettingsStore}
                                            textInputRef={smcSettingsStore.emailInputRef}/>))
                        }
                    </div>
                    <div id="til-text-emails-add-button-div" className="left-padding bottom-padding email-input">
                        <Button
                            id="til-text-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add Text Lead Email`}
                            onClick={() => {
                                smcSettingsStore.onEmailAdd(emailTypeEnum.TRADE_IN_TEXT_LEAD)
                            }}
                            theme="blue-outline"
                        />
                    </div>

                </div>
            );
        });

    }
);
