import _ from "lodash";
import {Environment, getEnvironment} from "./environment";
import jwt_decode from "jwt-decode";

export default class CommonUtils {

    public static displayConsoleLogError(message: string) {
        if(getEnvironment() !== Environment.DEVELOPMENT &&
           getEnvironment() !== undefined) {
            console.log(message);
        }
    }

    public static fieldsChanged(newObj: any, originalObj: any) : boolean {
        let newObjClone: any = this.cloneObjExcludingFields(newObj)
        let orgObjClone: any = this.cloneObjExcludingFields(originalObj)
        return !_.isEqual(newObjClone, orgObjClone);
    }

    public static equalArray(array1: Array<any>, array2: Array<any>) : boolean {
        if (array1.length !== array2.length) return false
        const sort1 = [...array1].sort()
        const sort2 = [...array2].sort()
        return sort1.every((element, index) => {
            return element === sort2[index];
        });
    }

    public static cloneObjExcludingFields(obj: any) : any {
        let excludeFields = ['dealerProfileInfoId', 'phoneDataId', 'smcSettingsId', 'uclSettingsId' ];

        let clonedObj = this.cloneObject(obj);
        for (let prop in clonedObj) {
            if(excludeFields.includes(prop)) {
                delete clonedObj[prop]
            }
        }

        return clonedObj;
    };

    public static cloneObject(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    public static formatPhoneNumber(phoneNumber: string | null | undefined) : string{

        let formattedNumber = ""

        if(phoneNumber) {
            let match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (phoneNumber.length === 10 && match) {
                formattedNumber =  '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
        }

        return formattedNumber;
    }

    public static unFormatPhoneNumber(phoneNumber: string ) : string {
        return phoneNumber.replace(/[(),\s/,-]/g, '');
    }

    public static capitalizeFirstCharacter(value: any) : string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    public static convertTime24to12(time24h: string | null): string {
        let time12h = "";
        if (time24h === null) return time12h;
        if (time24h) {
            const hours = Number(time24h.split(":")[0]);
            const minutes = time24h.split(":")[1];
            const amPm = hours >= 12 ? "PM" : "AM";

            let adjustedHours = "";
            if (hours > 0 && hours <= 12) {
                adjustedHours = "" + hours;
            } else if (hours > 12) {
                adjustedHours = "" + (hours - 12);
            } else if (hours === 0) {
                adjustedHours = "12";
            }
            time12h = `${adjustedHours}:${minutes} ${amPm}`;
        }
        // console.log(`time in ${time24h} converted to ${time12h}`)
        return time12h;
    }

    public static convertTime12to24(time12h: string | undefined): string | null {
        let time24h = null;
        if (time12h) {
            const time = time12h.split(" ")[0].trim();
            const amPm = time12h.split(" ")[1].trim();
            const hours = Number(time.split(":")[0]);
            const minutes = time.split(":")[1];

            let adjustedHours = hours;
            if (amPm === "AM" && hours === 12) {
                adjustedHours = 0;
            } else if (amPm === "PM") {
                if (hours === 12) {
                    adjustedHours = 12;
                } else {
                    adjustedHours = hours + 12;
                }
            }

            let hoursPadded = adjustedHours.toString();
            if (adjustedHours < 10) {
                hoursPadded = "0" + adjustedHours;
            }

            time24h = hoursPadded + ":" + minutes;
        }
        // console.log(`time in ${time12h} converted to ${time24h}`)
        return time24h;
    }

    public static convertDateToUtc(epochDate: number): string {
        let formattedDate = new Date(epochDate).toISOString().split('T')[0];
        return formattedDate;
    }

    public static convertDateToEpoch(inputDate: string): number {
        let utcDate = new Date(inputDate); //Date object a day behind
        let localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000 + 60*60*1000); //local Date 1AM - to fix EST date conversion issue
        return localDate.getTime();
    }

    public static convertEpochDateToMMDDYYYY(value: string | number | null | undefined) : string {
        if(value) {
            return new Date(value).toLocaleString().split(',', 1)[0];   // Split ignores the time.  toLocalString() will return this for example "2/17/2022, 1:45:28 PM".
        }
        return "";
    }

    public static convertFormattedDateToMMDDYYYY(value: string) : string {
        if(value) {
            return value.substring(5,7) + "/" + value.substring(8,10) + "/" + value.substring(0,4);
        } else {
            return ""
        }
    }

    public static isDateInMonthDayYearFormat(value: string) : boolean {
        if(/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) {
            return true
        }
        return false
    }

    public static getCurrentDate(): string {
        let today = new Date()
        let dd = String(today.getDate()).padStart(2, '0')
        let mm = String(today.getMonth() + 1).padStart(2, '0')
        let yyyy = today.getFullYear()
        return mm + '/' + dd + '/' + yyyy;
    }

    public static getCurrentDateTimeLong(): number {
        return Math.floor(Date.now())
    };

    public static getFieldsChanged(newObj: any, origObj: any): Map<string, string> {
        let fieldsChangedMap : Map<string,string> = new Map<string, string>();
        for (const field in newObj) {
            if (newObj.hasOwnProperty(field) && newObj[field] !== origObj[field]) {
                fieldsChangedMap.set(field, newObj[field]);
            }
        }
        return fieldsChangedMap;
    }

    public static getUserFromToken(authorizerToken: any) : string {
        const decodedToken: any  = jwt_decode(authorizerToken);
        return decodedToken?.sub;
    }

    public static logNewRelicPageAction(name: string, attributes: any) {
        if(getEnvironment() !== 'DEVELOPMENT') {
            console.log(` ~~ New Relic Page Action - ${name} : ${JSON.stringify(attributes)}`)
            newrelic.addPageAction(name, attributes)
        }
    };

    public static toTitleCase(str: string) {
        return str.toLowerCase().split(" ").map((l) => l[0].toUpperCase() + l.substring(1)).join(" ");
    }
};
