import {InviteSectionEnum} from "../../types/acctMgmt/inviteUserTypes";
import {User} from "../../types/acctMgmt/user";
import React from "react";
import CommonUtils from "../../utils/commonUtils";
import {apiStatus} from "../../utils/constants";
import RootStore from "../root";
import {makeAutoObservable} from "mobx";
import {inviteUser} from "../../clients/uasApi";
import Validator from "../helpers/validator";
import {getOwnerLocations} from "../../clients/damBackendApi";
import {BusinessUser} from "../../types/businessUser";
import BusinessUserStore from "../businessUser/businessUser";
import {OwnerLocation} from "../../types/owner-group";

export default class InviteUserStore {

    // Stores
    rootStore: RootStore;
    businessUserStore: BusinessUserStore;

    isInviteModalVisible: boolean = false;
    inviteModalSection: InviteSectionEnum = InviteSectionEnum.Detail;
    invitingUser: User = {} as User;
    inviteTemplate: string = "";
    emailErrorMessage: string = ""
    associatedLocations: Array<OwnerLocation> = []
    associatedLocationFilterValue: string = ""
    filteredAssociatedLocations: Array<OwnerLocation> = []
    associateLocationsChecked: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.businessUserStore = this.rootStore.businessUserStore

        makeAutoObservable(this)
    };

    public handleInviteModalVisibility = (open : boolean) => {
        this.isInviteModalVisible = open
    }

    public handleInviteUserCancel = () => {
        this.handleInviteModalVisibility(false)
    }

    public handleInviteNextButton = () => {
        switch (this.inviteModalSection) {
            case InviteSectionEnum.Detail: {
                if (this.filteredAssociatedLocations.length > 0 && this.associateLocationsChecked) {
                    this.inviteModalSection = InviteSectionEnum.Locations
                }
                else {
                    this.inviteModalSection = InviteSectionEnum.Confirm
                }
                break;
            }
            case InviteSectionEnum.Locations: {
                this.inviteModalSection = InviteSectionEnum.Confirm
                break;
            }
            default: {
                this.inviteModalSection = InviteSectionEnum.Detail
                break;
            }
        }
    }

    public handleInviteBackButton = () => {
        switch (this.inviteModalSection) {
            case InviteSectionEnum.Locations: {
                this.inviteModalSection = InviteSectionEnum.Detail
                break;
            }
            case InviteSectionEnum.Confirm: {
                if (this.filteredAssociatedLocations.length > 0 && this.associateLocationsChecked) {
                    this.inviteModalSection = InviteSectionEnum.Locations
                }
                else {
                    this.inviteModalSection = InviteSectionEnum.Detail
                }
                break;
            }
        }
    }

    public inviteUserFirstNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.invitingUser.firstName = event.target.value
    }

    public inviteUserLastNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.invitingUser.lastName = event.target.value
    }

    public inviteUserRoleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.invitingUser.role = event.target.value
    }

    public inviteUserEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.invitingUser.email = event.target.value
        this.emailErrorMessage = ""
        if (!this.isValidEmail()) {
            this.emailErrorMessage = "Please enter a valid email address."
        }
    }

    public handleInviteModelOpen = async () => {
        this.handleInviteModalVisibility(true)
        this.inviteModalSection = InviteSectionEnum.Detail
        this.invitingUser = {firstName : '', lastName: '', email: '', role: '',
            compCode: this.rootStore.acctMgmtStore.compCode, accessLocations: new Array<string>()} as User
        this.inviteTemplate = ""
        this.associatedLocationFilterValue = ""
        this.associatedLocations = []
        this.filteredAssociatedLocations = []
        this.associateLocationsChecked = false
        this.emailErrorMessage= ""
        await this.getAssociatedLocations()
    }

    public handleSendInviteButton = async () => {
        this.handleInviteModalVisibility(false)
        await this.sendUserInvite()
        await this.rootStore.acctMgmtStore.getUsersByCompCode()
    }

    public sendUserInvite = async () => {
        this.rootStore.commonStore.processingApiCall(true);
        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Invite User', compCode: this.invitingUser.compCode, email: this.invitingUser.email})
        await inviteUser(this.rootStore.authStore.token, this.invitingUser, this.inviteTemplate).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.rootStore.commonStore.clearPageMsg();
                    this.rootStore.commonStore.displayFeatureNotification(this.successMessage(), "green");
                    break;
                case apiStatus.forbidden :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                case apiStatus.conflict :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "red")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to update users for comp code ${this.invitingUser.compCode}.  Response: ` + JSON.stringify(resp));
                    this.rootStore.commonStore.displayPageNotification(`Severe error occurred trying to invite user for comp code '${this.invitingUser.compCode}'.`, "red")
                    break;
            };
        });
        this.rootStore.commonStore.processingApiCall(false);
    };

    private successMessage = () : string => {
        const tog = this.businessUserStore.businessUser?.topLevelOwnergroup;
        const rootSalesforceId = this.businessUserStore?.businessUser?.rootSalesforceId;

        let successTextMessage = `Success! You have invited ${this.invitingUser.firstName} ${this.invitingUser.lastName} to ${this.businessUserStore.businessUser?.companyName} with ${this.invitingUser.accessLocations.length} associated location(s). You should now see the new user in the table below.`
        if (rootSalesforceId === null || rootSalesforceId === undefined || tog === true) {
            successTextMessage = `Success! You have invited ${this.invitingUser.firstName} ${this.invitingUser.lastName} to ${this.businessUserStore.businessUser?.companyName}. You should now see the new user in the table below.`
        }

        return successTextMessage;
    }

    public handleInviteTemplateSelection = (selectedTemplate : string) => {
        this.inviteTemplate = selectedTemplate
    }

    public validStep = (section : InviteSectionEnum) : boolean => {
        switch (section) {
            case InviteSectionEnum.Detail :
                return this.validateDetail()
            default :
                return true;
        }
    }

    public validateDetail = () : boolean => {
        if (this.invitingUser?.firstName.trim().length === 0) {
            return false
        }
        if (this.invitingUser?.lastName.trim().length === 0) {
            return false
        }
        if (!Validator.isValidEmail(this.invitingUser.email)) {
            return false
        }
        if (this.invitingUser?.role.length === 0) {
            return false
        }
        if (this.inviteTemplate.length === 0) {
            return false
        }

        return true;
    }

    public isValidEmail = () : boolean => {
        return Validator.isValidEmail(this.invitingUser.email)
    }


    public getAssociatedLocations = async () => {
        const businessUser = this.businessUserStore.businessUser
        // getOwnerLocations shouldn't be called, if there is no parentSalesforceId
        if (!businessUser?.parentSalesforceId) {
            return
        }

        this.rootStore.commonStore.processingApiCall(true);
        await getOwnerLocations(this.rootStore.authStore.token, businessUser?.parentSalesforceId).then((resp: any) => {
            switch (resp?.status) {
                case apiStatus.success :
                    this.associatedLocations = resp.data.businessUsers.map((businessUser: BusinessUser) => {
                        return {status: businessUser.active ? "Active" : "Inactive",
                            address: businessUser.address,
                            companyName: businessUser.companyName,
                            compCode: businessUser.compCode,
                            selected: false}
                    }).filter((loc: OwnerLocation) => {
                        return loc.compCode !== this.invitingUser.compCode
                    });
                    this.filteredAssociatedLocations = this.associatedLocations;
                    break;
                case apiStatus.notFound :
                    break;
                case apiStatus.forbidden :
                    this.rootStore.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling BIS to get owner locations for parentSalesforceId ${businessUser?.parentSalesforceId}.  Response: ` + JSON.stringify(resp));
                    this.rootStore.commonStore.displayPageNotification(`Error occurred trying to get owner locations for comp code '${businessUser?.compCode}'.`, "red")
                    break;
            };
        });
        this.rootStore.commonStore.processingApiCall(false);
    };

    public handleLocationSelect = (location: OwnerLocation) => {
        if (location.selected) {
            location.selected = false
            this.invitingUser.accessLocations.splice(this.invitingUser.accessLocations.indexOf(location.compCode),1)
        } else {
            location.selected = true
            this.invitingUser.accessLocations.push(location.compCode)
        }
    }

    public handleLocationSelectAll = () => {
        const allSelected: boolean = this.isAllSelected()
        this.invitingUser.accessLocations = []

        this.filteredAssociatedLocations.forEach((location) => {
            if (allSelected) {
                location.selected = false
            } else {
                location.selected = true
                this.invitingUser.accessLocations.push(location.compCode)
            }
        })
    }

    public isAllSelected = () => {
        return !(this.filteredAssociatedLocations.length === 0) && !(this.filteredAssociatedLocations.some((loc) => {return !loc.selected}))
    }

    public searchLocations = () => {
        if (this.associatedLocationFilterValue.length > 0) {
             this.filteredAssociatedLocations = this.associatedLocations.filter((loc) => {
                return (
                    loc.selected
                    || loc.companyName.toLowerCase().includes(this.associatedLocationFilterValue.toLowerCase())
                    || loc.compCode.toLowerCase().includes(this.associatedLocationFilterValue.toLowerCase()))
            });
        } else {
            this.filteredAssociatedLocations = this.associatedLocations
        }
    }

    public onChangeLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.associatedLocationFilterValue = event.target.value
        this.searchLocations()
    }

    public handleAssociateLocationsChecked = (checked: boolean) => {
        this.associateLocationsChecked = checked;
    };

}