import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CallMgmtSettingsStore from "./callMgmtSettings";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import DealerDetailsStore from "./dealerDetails";
import DealerProfileInfoStore from "./dealerProfileInfo";
import SearchStore from "../search/search";
import RatingsAndReviewsStore from "./ratingsAndReviews";
import RootStore from "../root";
import SmcSettingsStore from "./smcSettings";
import UclSettingsStore from "./uclSettings";
import {BusinessUser} from "../../types/businessUser";
import {getOwnerGroups} from "../../clients/damBackendApi";
import {makeAutoObservable} from "mobx";
import {OwnerGroup} from "../../types/owner-group";
import {profileTabs} from "../../types/profile/enums/profileTabs";
import {refreshBuiData} from "../../clients/dps/dpsApi";


export default class ProfileMgmtStore {

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    callMgmtSettingsStore: CallMgmtSettingsStore;
    commonStore: CommonStore;
    dealerDetailsStore: DealerDetailsStore;
    dealerProfileInfoStore: DealerProfileInfoStore;
    ratingsAndReviewsStore: RatingsAndReviewsStore;
    searchStore: SearchStore;
    smcSettingsStore: SmcSettingsStore;
    uclSettingsStore: UclSettingsStore;

    // Variables
    businessUser: BusinessUser | null = null;
    currentTab : number = 0;
    selectedTab : number = 0;
    isUnsavedChangesModalVisible: boolean = false;
    showUnsavedModalForCompCodeChange: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore
        this.businessUserStore = this.rootStore.businessUserStore
        this.callMgmtSettingsStore = this.rootStore.callMgmtSettingsStore
        this.commonStore = this.rootStore.commonStore
        this.dealerDetailsStore = this.rootStore.dealerDetailsStore
        this.dealerProfileInfoStore = this.rootStore.dealerProfileInfoStore
        this.searchStore = this.rootStore.searchStore
        this.ratingsAndReviewsStore = this.rootStore.ratingsAndReviewsStore
        this.smcSettingsStore = this.rootStore.smcSettingsStore
        this.uclSettingsStore = this.rootStore.uclSettingsStore

        makeAutoObservable(this)
    };

    public initializeStore = () => {
        this.currentTab = profileTabs["DEALER DETAILS"]
    };

    public onClickCloseModal = () => {
        this.hideUnsavedChangesModal()
    };

    public onClickSaveUpdates = () => {
        this.hideUnsavedChangesModal();
        this.updateProfileData().then(r =>
            this.setCurrentTab(this.selectedTab)
        );
    };

    public updateProfileData = async () => {
        switch (this.currentTab) {
            case profileTabs["DEALER DETAILS"]: {
                await this.dealerDetailsStore.submitDealerDetails().then(() => undefined)
                break;
            }
            case profileTabs["CCL"]: {
                await this.uclSettingsStore.submitUclPreferences().then(() => undefined)
                break;
            }
            case profileTabs["C4L"]: {
                await this.smcSettingsStore.submitSmcSettings().then(() => undefined)
                break;
            }
            case profileTabs["R&R"]: {
                await this.ratingsAndReviewsStore.submitRatingsAndReviewsPreferences().then(() => undefined)
                break;
            }
            case profileTabs["CALL MGMT."]: {
                await this.callMgmtSettingsStore.submitCallMgmtSettings().then(() => undefined)
                break;
            }
        }
    }

    public onClickContinueWithoutSaving = () => {
        this.commonStore.clearFormErrors()
        this.resetProfileOriginalData()
        this.setCurrentTab(this.selectedTab)
        this.hideUnsavedChangesModal()
    };

    public resetProfileOriginalData() {
        this.dealerProfileInfoStore.resetStoreToOriginalData()
        this.uclSettingsStore.resetStoreToOriginalData()
        this.smcSettingsStore.resetStoreToOriginalData()
        this.ratingsAndReviewsStore.resetStoreToOriginalData()
        this.callMgmtSettingsStore.resetStoreToOriginalData()
    };

    public setCurrentTab(selectedTab: number) {
        this.currentTab = selectedTab;
    };

    public setSelectedTab(selected: number) {
        this.selectedTab = selected
    };

    public displayUnsavedChangesModal() {
        this.isUnsavedChangesModalVisible = true
    };

    public hideUnsavedChangesModal() {
        this.isUnsavedChangesModalVisible = false
    };

    public showChangesModalForCompCodeChange(compCode: string) {
        this.showUnsavedModalForCompCodeChange = true;
        this.searchStore.setCompCodeOnClick(compCode);
    };

    public hideChangesModalForCompCodeChange() {
        this.showUnsavedModalForCompCodeChange = false;
    };

    public onCompCodeClick = (businessUser: any) => {
        if(businessUser.compCode && this.searchStore.selectedCompCode
            && businessUser.compCode !== this.searchStore.selectedCompCode && this.didProfileDataChange()) {
            this.showChangesModalForCompCodeChange(businessUser.compCode);
        }
        else {
            this.searchStore.compCodeChange(businessUser.compCode);
        }
    };

    public onClickCloseModalForCompCode = () => {
        this.hideChangesModalForCompCodeChange();
    };

    public onClickSaveModalForCompCode = async () => {
        this.hideChangesModalForCompCodeChange();
        await this.updateProfileData();
        this.searchStore.compCodeChange(this.searchStore.compCodeOnClick);
    };

    public onClickContinueModalForCompCode = () => {
        this.commonStore.clearFormErrors();
        this.hideChangesModalForCompCodeChange();
        this.searchStore.compCodeChange(this.searchStore.compCodeOnClick);
    };

    public didProfileDataChange() {
        return this.dealerDetailsStore.hasChanges() || this.uclSettingsStore.hasChanges() ||
            this.smcSettingsStore.hasChanges() || this.ratingsAndReviewsStore.hasChanges() || this.callMgmtSettingsStore.hasChanges()
    }

    public refreshData = async () => {

        this.commonStore.clearFeatureMsg()

        let compCode = this.businessUserStore.businessUser?.compCode || ""
        let token = this.authStore.token
        const username = CommonUtils.getUserFromToken(token);

        CommonUtils.logNewRelicPageAction("Profile Refresh Data", {userAction: 'Submit Refresh Data Request', compCode: compCode})

        this.commonStore.processingApiCall(true);

        await refreshBuiData(token, compCode, username).then((resp) => {
            switch(resp?.data.status) {
                case 200:
                    console.log(`Submitted refresh for ${compCode}`)
                    this.commonStore.displayFeatureNotification(`Data refresh submitted for comp code ${compCode} and its child locations.`, "green");
                    this.refreshChildLocations(token, compCode, username)
                    break;
                default:
                    console.log(`Refresh NOT Successful for ${compCode}. DPS Response: ${resp}`)
                    this.commonStore.displayFeatureNotification(`Error encountered submitting data refresh for comp code ${compCode}.`, "red");
                    this.commonStore.processingApiCall(false);
                    break;
            }
        });

        this.commonStore.processingApiCall(false)
    };

    protected refreshChildLocations = async (token: string, compCode: string, username: string) => {
        console.log("Retrieving child locations for refresh ~~ ...")
        let childLocations = await getOwnerGroups(token, compCode)
        childLocations.data.ownerGroup.forEach((location: OwnerGroup) => {
            refreshBuiData(token, location.compCode, username).then((resp) => {
                if(resp?.data) {
                    switch(resp?.data.status) {
                        case 200:
                            console.log(`Submitted refresh for Child Location ${location.compCode}`)
                            break;
                        default:
                            console.log(`Refresh NOT Successful for Child Location ${location.compCode}. DPS Response: ${resp}`)
                            break;
                    }
                } else {
                    console.log(`Refresh NOT Successful for ${location.compCode}. DPS Response: ${JSON.stringify(resp)}`)
                }
            });
        });
    };
};
