import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import EmailsStore from "./emails";
import PartnersStore from "../partners/partners";
import RootStore from "../root";
import Validator from "../helpers/validator";
import {disableUclListingOptions} from "../../types/profile/enums/disableUclListingOptions";
import {emailTypeEnum} from "../../types/profile/enums/emailTypeEnum";
import {getEnvironment} from "../../utils/environment";
import {getUclSettings, updateUclSettings} from "../../clients/dps/dpsApi";
import {makeAutoObservable, runInAction} from "mobx";
import {DB_ACTION_DELETE, DB_ACTION_INSERT, DB_ACTION_UPDATE} from "../../utils/constants";
import {Email} from "../../types/email";
import {NotificationMessages} from "../common/messaging/notificationMessages";
import {Partner} from "../../types/partner";
import {UclSettings} from "../../types/profile/uclSettings";
import {UclStatus} from "../../types/businessUser/enums/uclStatus";
import React, {createRef} from "react";

import {
    ERROR_MSG_ALLOW_BACK_FILL_CPO,
    ERROR_MSG_CFX_PARTNER_SYNDICATE,
    ERROR_MSG_CFX_UCL_ONLY_SYNDICATE,
    ERROR_MSG_DUPLICATE_EMAILS,
    ERROR_MSG_EMAIL_ADF_TEXT_DUPLICATE,
    ERROR_MSG_EMAIL_INVALID,
    ERROR_MSG_ENHANCED_EMAIL_REQUIRED,
    ERROR_MSG_INVALID_CRM_PARTNER,
    ERROR_MSG_INVALID_INVENTORY_PROVIDER,
    ERROR_MSG_INVENTORY_KEY,
    ERROR_MSG_MISSING_INVENTORY_PROVIDER,
    ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE,
    ERROR_MSG_USED_AND_NEW_WEBSITE_URL_DUPLICATE,
    ERROR_MSG_USED_AND_NEW_DISCLAIMER_DUPLICATE,
    ERROR_MSG_SECONDARY_NAME_OEM_REQUIRED,
    ERROR_MSG_SECONDARY_NAME_BUSINESS_NAME_REQUIRED,
    ERROR_MSG_SECONDARY_NAME_WEBSITE_URL_REQUIRED,
    ERROR_MSG_WEBSITE_URL_START,
    ERROR_MSG_WEBSITE_URL_END,
    ERROR_MSG_WEBSITE_URL_SPACES, ERROR_MSG_WEBSITE_URL_INVALID,
    resolveInvalidCarfaxEmailMessage, ERROR_MSG_REQUIRED_FIELDS
} from "./messaging/errorMessagesProfile";
import {emailListingsType} from "../../types/profile/enums/emailListingsType";
import SecondaryNamesStore from "./secondaryNames";
import {SecondaryName} from "../../types/secondaryName";

export default class UclSettingsStore {

    // Variables
    allowBackfillErrorMessage: string = "";
    cpoListingErrorMessage: string = "";
    excessiveDocumentFee: boolean = false;
    carfaxPartnerSyndicateErrorMessage: string = "";
    cfxUclOnlyErrorMessage: string = "";
    isProcessingApi: boolean = true;
    validInventoryKeyErrorMessages: Array<string> = new Array<string>(10);
    validWebsiteUrlErrorMessage: string = "";
    validNclWebsiteUrlErrorMessage: string = "";
    enhancedRequiredMsg: string = "";
    penaltyDateErrorMsg: string = "";
    inventoryProviderSearchString: string = "";
    inventoryProviderNclSearchString: string = "";
    inventoryProviderErrorMsg: string = "";
    inventoryProviderNclErrorMsg: string = "";
    crmProviderErrorMsg: string = "";
    crmProviderSearchString: string = "";
    focusOnEmailTextInput: boolean = false;
    emailInputRef: React.RefObject<HTMLInputElement> = createRef();
    useSeparateCarListingUrl: boolean = false;
    useSeparateDisclaimer: boolean = false;
    validUclDisclaimerErrorMessage: string = "";
    validNclDisclaimerErrorMessage: string = "";
    nclDisclaimerLive: string = "";
    nclWebsiteUrlLive: string = "";
    useSeparateProvidersCarListings: boolean = false;

    // Fields / Types
    uclSettings: UclSettings;
    originalUclSettings: UclSettings;
    disableUclListingsOptions: Array<string> = new Array<string>();
    adfLeadEmails: Array<Email> = new Array<Email>();
    textLeadEmails: Array<Email> = new Array<Email>();
    secondaryNames: Array<SecondaryName> = new Array<SecondaryName>();

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;
    emailsStore: EmailsStore;
    partnerStore: PartnersStore;
    secondaryNamesStore: SecondaryNamesStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authStore = this.rootStore.authStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;
        this.partnerStore = this.rootStore.partnersStore;
        this.emailsStore = this.rootStore.emailsStore;
        this.secondaryNamesStore = this.rootStore.secondaryNamesStore;

        this.uclSettings = this.initializeUclSettings();
        this.originalUclSettings = this.initializeUclSettings();
        this.initializeEmails();

        makeAutoObservable(this);
    };

    public initializeStore = async () => {
        this.buildDisableUclListingsOptions();
        if(this.partnerStore.listOfPartners.length === 0) {
            await this.partnerStore.getListOfPartners();
        }
    };

    public initializeUclSettings = () : UclSettings => {
        return {
            uclSettingsId: undefined,
            compCode: this.businessUserStore?.businessUser?.compCode || "",
            websiteUrl: "",
            inventoryProviderId: null,
            inventoryProviderNclId: null,
            additionalFee: 0,
            disclaimer: "",
            cfxUclOnly: false,
            allowBackfill: true,
            vastSyndicate: false,
            aggregatedTo: "",
            carfaxPartnerSyndicate: false,
            penaltySettings: false,
            penaltyDate: null,
            inventoryKey: "",
            cpoListing: false,
            disableAllUclListings: 0,
            onlineOnlyRetailer: false,
            crmPartnerId: 0,
            disableUclTrackingNumber: false,
            disclaimerNcl: "",
            websiteUrlNcl: undefined
        }
    };

    public initializeEmails() {
        this.adfLeadEmails = new Array<Email>();
        this.textLeadEmails = new Array<Email>();
    }

    public initializeErrorMessagesAndVariables() {
        this.allowBackfillErrorMessage = "";
        this.cpoListingErrorMessage = "";
        this.carfaxPartnerSyndicateErrorMessage = "";
        this.cfxUclOnlyErrorMessage = "";
        this.validWebsiteUrlErrorMessage = "";
        this.validNclWebsiteUrlErrorMessage = ""
        this.enhancedRequiredMsg = "";
        this.penaltyDateErrorMsg = "";
        this.inventoryProviderErrorMsg = "";
        this.inventoryProviderNclErrorMsg = "";
        this.crmProviderErrorMsg = "";
        this.validUclDisclaimerErrorMessage = ""
        this.validNclDisclaimerErrorMessage = ""

        // other variables
        this.inventoryProviderSearchString = "";
        this.inventoryProviderNclSearchString = "";
        this.crmProviderSearchString = "";
        this.nclDisclaimerLive = "";
        this.nclWebsiteUrlLive = "";
    }

    public resetStoreToOriginalData() {
        this.uclSettings = CommonUtils.cloneObject(this.originalUclSettings);
        this.emailsStore.resetEmailsToOriginalData([emailTypeEnum.ADF_LEAD, emailTypeEnum.TEXT_LEAD]);
    };

    public buildDisableUclListingsOptions() {
        this.disableUclListingsOptions.push(disableUclListingOptions.ENABLED);
        this.disableUclListingsOptions.push(disableUclListingOptions.DISABLED_HACKER_FEED);
        this.disableUclListingsOptions.push(disableUclListingOptions.DISABLED_PRICING_ISSUES);
        this.disableUclListingsOptions.push(disableUclListingOptions.DISABLED_INVENTORY_ISSUES);
        this.disableUclListingsOptions.push(disableUclListingOptions.DISABLED_DEALER_REQUEST);
        this.disableUclListingsOptions.push(disableUclListingOptions.DISABLED_OTHER);
    }

    public onAggregateToChange = (aggregateTo: string) => {
        this.uclSettings.aggregatedTo = aggregateTo;
    };

    public onDocumentFeeChange = (documentFee: any) => {
        if(documentFee > 0) {
            if (Validator.evaluateDocumentFee(documentFee)) {
                this.commonStore.displayFeatureNotification(`The amount you have entered exceeds $1,000. Please confirm the value entered is correct.`, "orange")
            }
        }
        this.uclSettings.additionalFee = +documentFee;
    };

    public onWebsiteUrlChange = (websiteUrl: string, checkNclUrl: boolean = false, checkingCCL: boolean = false) => {
        let isValidUrl = Validator.validateUrl(websiteUrl);
        checkNclUrl ? this.uclSettings.websiteUrlNcl = websiteUrl : this.uclSettings.websiteUrl = websiteUrl;
        let clWebsiteUrlsAreTheSame = this.uclSettings.websiteUrl === this.uclSettings.websiteUrlNcl
        if(clWebsiteUrlsAreTheSame && isValidUrl){
            this.validNclWebsiteUrlErrorMessage = ERROR_MSG_USED_AND_NEW_WEBSITE_URL_DUPLICATE
            this.validWebsiteUrlErrorMessage = ERROR_MSG_USED_AND_NEW_WEBSITE_URL_DUPLICATE
            this.commonStore.setFormError("websiteUrlNcl", this.validNclWebsiteUrlErrorMessage)
            this.commonStore.setFormError("websiteUrl", this.validWebsiteUrlErrorMessage);
        }else{
            if(this.validNclWebsiteUrlErrorMessage === ERROR_MSG_USED_AND_NEW_WEBSITE_URL_DUPLICATE){
                 this.validNclWebsiteUrlErrorMessage = "";            
                 this.commonStore.removeFormError("websiteUrlNcl");
            }
            if(this.validWebsiteUrlErrorMessage === ERROR_MSG_USED_AND_NEW_WEBSITE_URL_DUPLICATE){
                this.validWebsiteUrlErrorMessage = "";
                this.commonStore.removeFormError("websiteUrl");
            }           
        }

        if(websiteUrl !== "" && !isValidUrl) {
            if(!(websiteUrl.indexOf("http://") === 0) && !(websiteUrl.indexOf("https://") === 0)) {
                checkNclUrl ? this.validNclWebsiteUrlErrorMessage = ERROR_MSG_WEBSITE_URL_START : this.validWebsiteUrlErrorMessage = ERROR_MSG_WEBSITE_URL_START;
            }
            else if(websiteUrl.indexOf(" ") >= 0) {
                checkNclUrl ? this.validNclWebsiteUrlErrorMessage = ERROR_MSG_WEBSITE_URL_SPACES : this.validWebsiteUrlErrorMessage = ERROR_MSG_WEBSITE_URL_SPACES;
            }
            else if(!new RegExp(/(\.[^ "]{1,})$/).test(websiteUrl)) {
                checkNclUrl ? this.validNclWebsiteUrlErrorMessage = ERROR_MSG_WEBSITE_URL_END : this.validWebsiteUrlErrorMessage = ERROR_MSG_WEBSITE_URL_END;
            }
            else {
                checkNclUrl ? this.validNclWebsiteUrlErrorMessage = ERROR_MSG_WEBSITE_URL_INVALID : this.validWebsiteUrlErrorMessage = ERROR_MSG_WEBSITE_URL_INVALID
            }
            checkNclUrl ? this.commonStore.setFormError("websiteUrlNcl", this.validNclWebsiteUrlErrorMessage) : this.commonStore.setFormError("websiteUrl", this.validWebsiteUrlErrorMessage);
        } else if(checkingCCL === true && (websiteUrl === "" || websiteUrl == null)) {
            checkNclUrl ? this.validNclWebsiteUrlErrorMessage = "Website URL cannot be empty." : this.validWebsiteUrlErrorMessage = "Website URL cannot be empty."
            checkNclUrl ? this.commonStore.setFormError("websiteUrlNcl", this.validNclWebsiteUrlErrorMessage) : this.commonStore.setFormError("websiteUrl", this.validWebsiteUrlErrorMessage);
        }
        else {
            if(clWebsiteUrlsAreTheSame === false){
                if(checkNclUrl === true){
                    this.validNclWebsiteUrlErrorMessage = "";
                    this.commonStore.removeFormError("websiteUrlNcl");
                }else {
                    this.validWebsiteUrlErrorMessage = "";
                    this.commonStore.removeFormError("websiteUrl");
                }
            }
        }
    };

    public get validWebsiteUrl() {
        return (this.validWebsiteUrlErrorMessage === "");
    }

    public get validNclWebsiteUrl() {
        return (this.validNclWebsiteUrlErrorMessage === "");
    }

    public onCustomerDisclaimerChange = (disclaimer: string) => {
        this.uclSettings.disclaimer = disclaimer;
    };

    public onCclCustomerDisclaimerChange = (disclaimer: string, checkNclDisclaimer: boolean = false) => {
        if(checkNclDisclaimer === true){
            this.uclSettings.disclaimerNcl = disclaimer
        }else {
            this.uclSettings.disclaimer = disclaimer
        }
        let clDisclaimersAreTheSame = this.uclSettings.disclaimer === this.uclSettings.disclaimerNcl
        if(clDisclaimersAreTheSame && disclaimer !== ""){
            this.validNclDisclaimerErrorMessage = ERROR_MSG_USED_AND_NEW_DISCLAIMER_DUPLICATE
            this.validUclDisclaimerErrorMessage = ERROR_MSG_USED_AND_NEW_DISCLAIMER_DUPLICATE
            this.commonStore.setFormError("disclaimerNcl", this.validNclDisclaimerErrorMessage)
            this.commonStore.setFormError("disclaimer", this.validUclDisclaimerErrorMessage);
        }else{
            if(this.validNclDisclaimerErrorMessage === ERROR_MSG_USED_AND_NEW_DISCLAIMER_DUPLICATE){
                this.validNclDisclaimerErrorMessage = ""
                this.commonStore.removeFormError("disclaimerNcl")
            }
            if(this.validUclDisclaimerErrorMessage === ERROR_MSG_USED_AND_NEW_DISCLAIMER_DUPLICATE){
                this.validUclDisclaimerErrorMessage = ""
                this.commonStore.removeFormError("disclaimer");
            } 
        }

        if(disclaimer === "" || disclaimer == null){
            checkNclDisclaimer ? this.validNclDisclaimerErrorMessage = "Disclaimer cannot be empty" : this.validUclDisclaimerErrorMessage = "Disclaimer cannot be empty";
            checkNclDisclaimer ? this.commonStore.setFormError("disclaimerNcl", this.validNclDisclaimerErrorMessage) : this.commonStore.setFormError("disclaimer", this.validUclDisclaimerErrorMessage);
        }else{
            if(clDisclaimersAreTheSame === false){
                checkNclDisclaimer ? this.validNclDisclaimerErrorMessage = "" : this.validUclDisclaimerErrorMessage = "";
                checkNclDisclaimer ? this.commonStore.removeFormError("disclaimerNcl") : this.commonStore.removeFormError("disclaimer");
            }
            
        }
    };

    public onCrmProviderChange = (selectedCrmProvider: string) => {
        this.commonStore.removeFormError("crmProviderId");
        this.crmProviderErrorMsg = "";

        if(selectedCrmProvider) {
            this.crmProviderSearchString = selectedCrmProvider;
            let partner: Partner = this.partnerStore.findPartnerByName(selectedCrmProvider);
            if(partner) {
                this.uclSettings.crmPartnerId = partner.partnerId;
            }
            else {
                this.uclSettings.crmPartnerId = null;
                this.crmProviderErrorMsg = ERROR_MSG_INVALID_CRM_PARTNER
                this.commonStore.setFormError("crmProviderId", ERROR_MSG_INVALID_CRM_PARTNER)
            }
        }
        else {
            this.uclSettings.crmPartnerId = null;
            this.crmProviderSearchString = "";
        }
    };

    public onInventoryProviderChange = (selectedInventoryProviderName: string, checkOtherProvider: boolean = false, isNcl: boolean = false) => {
        let fieldName: string;
        isNcl ? fieldName = "inventoryProviderNclId" : fieldName = "inventoryProviderId";
        this.commonStore.removeFormError(fieldName);
        isNcl ? this.inventoryProviderNclErrorMsg = "" : this.inventoryProviderErrorMsg = "";

        if(selectedInventoryProviderName) {
            isNcl ? this.inventoryProviderNclSearchString = selectedInventoryProviderName
                : this.inventoryProviderSearchString = selectedInventoryProviderName;
            let partner: Partner = this.partnerStore.findPartnerByName(selectedInventoryProviderName);
            if(partner) {
                isNcl ? this.uclSettings.inventoryProviderNclId = partner.partnerId
                    : this.uclSettings.inventoryProviderId = partner.partnerId.toString();

                if (checkOtherProvider) {
                    if (this.uclSettings.inventoryProviderId === this.uclSettings.inventoryProviderNclId?.toString()) {
                        this.inventoryProviderNclErrorMsg = ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE;
                        this.inventoryProviderErrorMsg = ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE;
                        this.commonStore.setFormError("inventoryProviderNclId", ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE);
                        this.commonStore.setFormError("inventoryProviderId", ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE);
                    }
                    else {
                        if (isNcl && this.inventoryProviderErrorMsg === ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE) {
                            this.inventoryProviderErrorMsg = "";
                            this.commonStore.removeFormError("inventoryProviderId");
                        }
                        if (!isNcl && this.inventoryProviderNclErrorMsg === ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE) {
                            this.inventoryProviderNclErrorMsg = "";
                            this.commonStore.removeFormError("inventoryProviderNclId");
                        }
                    }
                }else {
                    this.uclSettings.inventoryProviderNclId = null;
                }
            }
            else {
                if (isNcl) {
                    this.uclSettings.inventoryProviderNclId = null;
                    this.inventoryProviderNclErrorMsg = ERROR_MSG_INVALID_INVENTORY_PROVIDER;
                }
                else {
                    this.uclSettings.inventoryProviderId = null;
                    this.inventoryProviderErrorMsg = ERROR_MSG_INVALID_INVENTORY_PROVIDER
                }
                this.commonStore.setFormError(fieldName, ERROR_MSG_INVALID_INVENTORY_PROVIDER)
            }
        }
        else {
            if (isNcl && this.inventoryProviderErrorMsg === ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE) {
                this.inventoryProviderErrorMsg = "";
                this.commonStore.removeFormError("inventoryProviderId");
            }
            if (!isNcl && this.inventoryProviderNclErrorMsg === ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE) {
                this.inventoryProviderNclErrorMsg = "";
                this.commonStore.removeFormError("inventoryProviderNclId");
            }
            if (isNcl) {
                this.uclSettings.inventoryProviderNclId = null;
                this.inventoryProviderNclSearchString = "";
                this.inventoryProviderNclErrorMsg = ERROR_MSG_MISSING_INVENTORY_PROVIDER;
                this.commonStore.setFormError(fieldName, ERROR_MSG_MISSING_INVENTORY_PROVIDER)
            }
            else {
                this.uclSettings.inventoryProviderId = null;
                this.inventoryProviderSearchString = "";
                if(this.useSeparateProvidersCarListings) {
                    this.inventoryProviderErrorMsg = ERROR_MSG_MISSING_INVENTORY_PROVIDER
                    this.commonStore.setFormError(fieldName, ERROR_MSG_MISSING_INVENTORY_PROVIDER)
                }
            }
        }
    };

    public onToggleBackfill = (isChecked: boolean) => {
        this.uclSettings.allowBackfill = isChecked;
        this.validAllowBackfillAndCpoListings("allowBackfill");
    };

    public onToggleCpoListing = (isChecked: boolean) => {
        this.uclSettings.cpoListing = isChecked;
        this.validAllowBackfillAndCpoListings("cpoListing");
    };

    public onToggleSyndicate = (isChecked: boolean) => {
        this.uclSettings.carfaxPartnerSyndicate = isChecked;
        this.validSyndicateUclOnly("carfaxPartnerSyndicate");
    };

    public onToggleCfxUclOnly = (isChecked: boolean) => {
        this.uclSettings.cfxUclOnly = isChecked;
        this.validSyndicateUclOnly("cfxUclOnly");
    };

    public onToggleOnlineOnlyRetailer = (isChecked: boolean) => {
        this.uclSettings.onlineOnlyRetailer = isChecked;
    };

    public onToggleDealerPenalty = (isChecked: boolean) => {
        this.uclSettings.penaltySettings = isChecked;
        if(isChecked) {
            //reset to currentDate
            this.uclSettings.penaltyDate = new Date().getTime();
        }
        else {
            this.penaltyDateErrorMsg = "";
            this.uclSettings.penaltyDate = this.originalUclSettings.penaltyDate;
            if(this.commonStore.formErrorsMap.has("penaltyDate")) {
                this.commonStore.removeFormError("penaltyDate");
            }
        }
    };

    public onToggleSeparateInventoryProvider = (isToggled: boolean) => {
        this.useSeparateProvidersCarListings = isToggled;
        if (isToggled) {
            if (this.originalUclSettings.inventoryProviderNclId?.toString() !== this.uclSettings.inventoryProviderId) {
                this.uclSettings.inventoryProviderNclId = this.originalUclSettings.inventoryProviderNclId;
            }
            else {
                this.uclSettings.inventoryProviderNclId = null;
                this.inventoryProviderNclSearchString = "";
            }
            if (this.uclSettings.inventoryProviderNclId === null) {
                this.commonStore.setFormError("inventoryProviderNclId", ERROR_MSG_MISSING_INVENTORY_PROVIDER);
                this.inventoryProviderNclErrorMsg = ERROR_MSG_MISSING_INVENTORY_PROVIDER;
            }
            if (this.uclSettings.inventoryProviderId === null && this.inventoryProviderSearchString === "") {
                this.commonStore.setFormError("inventoryProviderId", ERROR_MSG_MISSING_INVENTORY_PROVIDER);
                this.inventoryProviderErrorMsg = ERROR_MSG_MISSING_INVENTORY_PROVIDER;
            }
        }
        else {
            this.uclSettings.inventoryProviderNclId = null;
            this.inventoryProviderNclSearchString = "";
            this.commonStore.removeFormError("inventoryProviderNclId");
            this.inventoryProviderNclErrorMsg = "";
            if (this.inventoryProviderErrorMsg === ERROR_MSG_MISSING_INVENTORY_PROVIDER) {
                this.commonStore.removeFormError("inventoryProviderId");
                this.inventoryProviderErrorMsg = "";
            }
        }
    };

    public onToggleSeparateCarListingUrl = (isToggled: boolean) => {
        this.useSeparateCarListingUrl = isToggled;
        if(isToggled === false){
            this.nclWebsiteUrlLive = this.uclSettings.websiteUrlNcl ?? ""
            this.uclSettings.websiteUrlNcl = null;
            if(this.commonStore.formErrorsMap.has("websiteUrl")) {
                this.commonStore.removeFormError("websiteUrl");
            }
            if(this.commonStore.formErrorsMap.has("websiteUrlNcl")) {
                this.commonStore.removeFormError("websiteUrlNcl");
            }
            this.onWebsiteUrlChange(this.uclSettings.websiteUrl ?? "", false, false);
        }
        else{
            this.nclWebsiteUrlLive !== "" ? this.uclSettings.websiteUrlNcl = this.nclWebsiteUrlLive : this.uclSettings.websiteUrlNcl = this.originalUclSettings.websiteUrlNcl;
            this.onWebsiteUrlChange(this.uclSettings.websiteUrl ?? "", false, true);
            this.onWebsiteUrlChange(this.uclSettings.websiteUrlNcl ?? "", true, true);
        }
    }

    public onToggleSeparateCarListingDisclaimer = (isToggled: boolean) => {
        this.useSeparateDisclaimer = isToggled;
        if(isToggled === false){
            this.nclDisclaimerLive = this.uclSettings.disclaimerNcl ?? "";
            this.uclSettings.disclaimerNcl = null;
            if(this.commonStore.formErrorsMap.has("disclaimer")) {
                this.commonStore.removeFormError("disclaimer");
            }
            if(this.commonStore.formErrorsMap.has("disclaimerNcl")) {
                this.commonStore.removeFormError("disclaimerNcl");
            }
        }
        else{
            this.nclDisclaimerLive !== "" ? this.uclSettings.disclaimerNcl = this.nclDisclaimerLive : this.uclSettings.disclaimerNcl = this.originalUclSettings.disclaimerNcl
            this.onCclCustomerDisclaimerChange(this.uclSettings.disclaimer, false);
            this.onCclCustomerDisclaimerChange(this.uclSettings.disclaimerNcl ?? "", true);
        }
    }

    public onPenaltyDateChange = (date: any) => {
        let epochDate =  CommonUtils.convertDateToEpoch(date);
        if(isNaN(epochDate) || epochDate < 0) {
            this.penaltyDateErrorMsg = "Enter Valid Penalty Date";
            this.commonStore.setFormError("penaltyDate","Enter Valid Penalty Date");
        }
        else {
            this.penaltyDateErrorMsg = "";
            this.commonStore.removeFormError("penaltyDate");
        }
        this.uclSettings.penaltyDate = epochDate;
    };

    public onSelectDisableUclListings = (option: any) => {
        this.uclSettings.disableAllUclListings = this.disableUclListingsOptions.indexOf(option);
    };

    public onEmailAdd = (emailType: any) => {
        let emailIndex = this.emailsStore.getEmails(emailType).length
        this.commonStore.setFormError(`${emailType}-${emailIndex}`, ERROR_MSG_EMAIL_INVALID);
        this.clearEnhancedError();

        let emailsAfterAdd = this.emailsStore.addEmail(emailType, this.uclSettings.compCode, emailListingsType.ALL);
        this.setLeadEmailsByType(emailType, emailsAfterAdd);
        this.focusOnEmailTextInput = true;
    };

    private setLeadEmailsByType(emailType: string, email: Array<Email>) {

        switch(emailType) {
            case emailTypeEnum.ADF_LEAD : {
                this.adfLeadEmails = email
                break;
            }
            case emailTypeEnum.TEXT_LEAD : {
                this.textLeadEmails = email
                break;
            }
        }
    }

    public clearEnhancedError() {
        this.commonStore.removeFormError("enhancedRequired");
        this.enhancedRequiredMsg = "";
    }

    public onEmailRemove = (emailType: string, index: number) => {

        let emailsAfterRemove = this.emailsStore.removeEmail(emailType, index);

        this.setLeadEmailsByType(emailType, emailsAfterRemove);

        this.uclStatusCheckValid();

        this.commonStore.removeFormError(`${emailType}-${index}`);
    };

    public uclStatusCheckValid() : boolean {

        let valid: boolean = true

        if(this.businessUserStore.businessUser?.uclStatus === UclStatus.ENHANCED) {
            let remainingAdfEmails = this.emailsStore.getEmails(emailTypeEnum.ADF_LEAD).filter(email => email.action !== DB_ACTION_DELETE)
            let remainingTextEmails = this.emailsStore.getEmails(emailTypeEnum.TEXT_LEAD).filter(email => email.action !== DB_ACTION_DELETE)

            if(remainingAdfEmails.length === 0 && remainingTextEmails.length === 0) {
                valid = false;
            }
        }

        if(!valid) {
            this.enhancedRequiredMsg = ERROR_MSG_ENHANCED_EMAIL_REQUIRED
            this.commonStore.setFormError("enhancedRequired", ERROR_MSG_ENHANCED_EMAIL_REQUIRED);
        } else {
            this.enhancedRequiredMsg = ""
            this.commonStore.removeFormError("enhancedRequired")
        }

        return valid;
    }

    public onEmailBlur = (emailType: string, index: number) => {
        let emailObj = this.emailsStore.getEmails(emailType)[index];
        if(emailObj.action) {
            let email = emailObj.email;
            let emailErrorMsg = this.commonStore.getFormError(`${emailType}-${index}`)
            let emailsAfterChange = this.emailsStore.changeEmail(emailType, email, emailErrorMsg, index);
            this.setLeadEmailsByType(emailType, emailsAfterChange);
        }
    }

    public onEmailChange = (emailType: string, email: string, index: number) => {
        let emailErrorMsg = this.validateEmail(email, emailType);
        let inlineErrorMsg = (emailErrorMsg === ERROR_MSG_DUPLICATE_EMAILS ||
                                    emailErrorMsg === ERROR_MSG_EMAIL_ADF_TEXT_DUPLICATE) ? emailErrorMsg : "";
        let emailsAfterChange = this.emailsStore.changeEmail(emailType, email, inlineErrorMsg, index);

        this.setLeadEmailsByType(emailType, emailsAfterChange);

        this.emailsStore.setOrResetEmailsFormError(emailErrorMsg, `${emailType}-${index}`);
    }

    public onEmailListingsTypeChange = (emailType: string, emailListings: string, index: number) => {
        let emailsAfterChange = this.emailsStore.changeListingsType(emailType, emailListings, index);
        this.setLeadEmailsByType(emailType, emailsAfterChange);
    }

    public validateEmail(email: string, emailType: string) {

        let emailErrorMsg = ""

        if(!Validator.isValidEmail(email)) {
            emailErrorMsg = ERROR_MSG_EMAIL_INVALID;
            return emailErrorMsg
        }

        if(Validator.isCarfaxEmail(email)) {
            return resolveInvalidCarfaxEmailMessage(emailType)
        }

        if(emailType === emailTypeEnum.ADF_LEAD) {
            if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.ADF_LEAD])) {
                emailErrorMsg = ERROR_MSG_DUPLICATE_EMAILS;
            } else {
                if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TEXT_LEAD])) {
                    emailErrorMsg = ERROR_MSG_EMAIL_ADF_TEXT_DUPLICATE;
                }
            }
        }

        if(emailType === emailTypeEnum.TEXT_LEAD) {
            if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TEXT_LEAD])) {
                emailErrorMsg = ERROR_MSG_DUPLICATE_EMAILS;
            } else {
                if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.ADF_LEAD])) {
                    emailErrorMsg = ERROR_MSG_EMAIL_ADF_TEXT_DUPLICATE;
                }
            }
        }

        return emailErrorMsg;
    }

    private validateEmailOnLoad(email: string, emailType: string, index: number): string {
        let emailErrorMsg = ""

        if(!Validator.isValidEmail(email)) {
            emailErrorMsg = ERROR_MSG_EMAIL_INVALID;
        }

        if(Validator.isCarfaxEmail(email)) {
            emailErrorMsg = resolveInvalidCarfaxEmailMessage(emailType)
        }

        this.emailsStore.setOrResetEmailsFormError(emailErrorMsg, `${emailType}-${index}`);
        return emailErrorMsg;
    }

    // Computed method to determine url
    public get uclListingsUrl() {
        if(this.businessUserStore.businessUser?.uclStatus === "ENHANCED") {
            let rootUrl = this.buildRootUrl();
            let micrositePath = this.buildMicrositePath();
            return rootUrl + micrositePath;
        } else {
            return "N/A";
        }
    };

    // Computed method to format the Penalty Date value
    public get formattedPenaltyDate() {
        if(this.uclSettings.penaltyDate!== null && !isNaN(this.uclSettings.penaltyDate)) {
            return CommonUtils.convertDateToUtc(this.uclSettings.penaltyDate);
        }
        else {
            return "";
        }
    }

    // Computed method to return the selected Disabled Ucl Listings option
    public get disableUclListingsOption() {
        return this.disableUclListingsOptions[this.uclSettings.disableAllUclListings];
    }

    // Computed method to return the selected Inventory Provider
    public get selectedInventoryProvider() {
        let partnerName : string = "";
        this.partnerStore.listOfPartners.forEach((partner: Partner) => {
            if(partner.partnerId.toString() === this.uclSettings.inventoryProviderId) {
                partnerName = partner.name;
            }
        });
        return partnerName === "" ? this.inventoryProviderSearchString : partnerName;
    }

    // Computed method to return the selected Ncl Inventory Provider
    public get selectedInventoryProviderNcl() {
        let partnerName : string = "";
        this.partnerStore.listOfPartners.forEach((partner: Partner) => {
            if(partner.partnerId === this.uclSettings.inventoryProviderNclId) {
                partnerName = partner.name;
            }
        });
        return partnerName === "" ? this.inventoryProviderNclSearchString : partnerName;
    }

    // Computed method to return the Inventory Keys
    public get inventoryKeys() {
        return this.uclSettings.inventoryKey ? this.uclSettings.inventoryKey.split(",") : [""]
    }

    public setInventoryKeys(inventoryKeys:string[]) : void {
        this.uclSettings.inventoryKey = inventoryKeys.join(",")
    }

    public onInventoryKeyAdd = () => {
        if(this.uclSettings.inventoryKey === null) { this.uclSettings.inventoryKey = ""}
        if(this.inventoryKeys.length < 10) {
            this.uclSettings.inventoryKey += ","
        }
    }

    public onInventoryKeyRemove = (index: number) => {
        let inventoryKeysList = this.inventoryKeys
        inventoryKeysList.splice(index, 1)
        this.setInventoryKeys(inventoryKeysList)
        this.validInventoryKeyErrorMessages[index] = ""
        if(this.isEmptyInventoryKeyErrorMessages()) {
            this.commonStore.removeFormError("inventoryKey")
        }
    };

    public onInventoryKeyChange = (inventoryKey: string, index: number) => {
        if(!Validator.containsSpecialCharacters(inventoryKey)) {
            let inventoryKeysList = this.inventoryKeys
            inventoryKeysList[index] = inventoryKey
            this.setInventoryKeys(inventoryKeysList)
            this.validInventoryKeyErrorMessages[index] = ""
            if(this.isEmptyInventoryKeyErrorMessages()) {
                this.commonStore.removeFormError("inventoryKey")
            }
        }
        else {
            this.validInventoryKeyErrorMessages[index] = ERROR_MSG_INVENTORY_KEY
            this.commonStore.setFormError("inventoryKey", ERROR_MSG_INVENTORY_KEY)
        }
    };

    private isEmptyInventoryKeyErrorMessages(): boolean {
        let inventoryErrorMessages = this.validInventoryKeyErrorMessages.filter(errorMsg =>
            errorMsg !== "" && errorMsg !== undefined
        );
        return inventoryErrorMessages.length === 0;
    }

    public removeEmptyInventoryKeys = () => {
        let filteredInventoryKeys = this.inventoryKeys.filter(inventoryKey =>
            inventoryKey !== "" && inventoryKey !== undefined
        );
        this.setInventoryKeys(filteredInventoryKeys);
        this.uclSettings.inventoryKey = this.uclSettings.inventoryKey === "" ? null : this.uclSettings.inventoryKey
        this.validInventoryKeyErrorMessages = new Array<string>(10);
    };

    // Computed method to return the selected CRM Provider
    public get selectedCrmProvider() {
        let partnerName : string = "";
        this.partnerStore.listOfPartners.forEach((partner: Partner) => {
            if(partner.partnerId === this.uclSettings.crmPartnerId) {
                partnerName = partner.name;
            }
        });
        return partnerName === "" ? this.crmProviderSearchString : partnerName;
    }

    public buildRootUrl = (): string => {
        if(getEnvironment() === 'PROD') {
            return "https://www.carfax.com/";
        } else {
            return "https://beta.carfax.com/";
        }
    };

    public buildMicrositePath = (): string => {
        let path = `Reviews-${this.businessUserStore.businessUser?.companyName.trim()}-${this.businessUserStore.businessUser?.address?.city.trim()}-${this.businessUserStore.businessUser?.address?.state.trim()}_${this.applyDealershipHash(this.businessUserStore.businessUser?.compCode)}`;

        path = path.replace(/\s/g, "-"); // any whitespace
        path = path.replace(/&/g, "-and-");
        path = path.replace(/[)(#<>\\[\].,']/g, "");
        path = path.replace(/-\+/g, "-"); // replace -+ with -
        return path;
    };

    public applyDealershipHash = (compCode: string | undefined) => {
        if (compCode && compCode.length > 3) {
            return compCode.substring(1, 2) +
                compCode.substring(0, 1) +
                compCode.substring(3, 4) +
                compCode.substring(2, 3) +
                compCode.substring(4);
        }
    };

    public validAllowBackfillAndCpoListings = (field: string ) => {
        if(!this.uclSettings.allowBackfill && this.uclSettings.cpoListing) {
            if(field === "allowBackfill") {
                this.allowBackfillErrorMessage = ERROR_MSG_ALLOW_BACK_FILL_CPO;
                this.commonStore.setFormError("allowBackfillCpoListing", this.allowBackfillErrorMessage );
            } else {
                this.cpoListingErrorMessage = ERROR_MSG_ALLOW_BACK_FILL_CPO;
                this.commonStore.setFormError("allowBackfillCpoListing", this.cpoListingErrorMessage );
            }
        } else {
            this.allowBackfillErrorMessage = "";
            this.cpoListingErrorMessage = "";
            this.commonStore.removeFormError("allowBackfillCpoListing");
        }
    };

    public validSyndicateUclOnly = ( field: string ) => {
        if(this.uclSettings.carfaxPartnerSyndicate && this.uclSettings.cfxUclOnly) {
            if(field === "carfaxPartnerSyndicate") {
                this.carfaxPartnerSyndicateErrorMessage = ERROR_MSG_CFX_PARTNER_SYNDICATE;
                this.commonStore.setFormError("carfaxPartnerSyndicateCFXUclOnly", this.carfaxPartnerSyndicateErrorMessage );
            } else {
                this.cfxUclOnlyErrorMessage = ERROR_MSG_CFX_UCL_ONLY_SYNDICATE;
                this.commonStore.setFormError("carfaxPartnerSyndicateCFXUclOnly", this.cfxUclOnlyErrorMessage );
            }
        } else {
            this.carfaxPartnerSyndicateErrorMessage = "";
            this.cfxUclOnlyErrorMessage = "";
            this.commonStore.removeFormError("carfaxPartnerSyndicateCFXUclOnly");
        }
    };

    public getUclSettings = async() => {
        this.initializeErrorMessagesAndVariables();
        this.uclSettings = this.initializeUclSettings();
        this.originalUclSettings = this.initializeUclSettings();

        let compCode = this.businessUserStore.businessUser?.compCode || "";

        await getUclSettings(this.authStore.token, compCode ).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.uclSettings = resp.data;
                        this.originalUclSettings = resp.data;
                        this.useSeparateCarListingUrl = (this.uclSettings.websiteUrlNcl != null && this.uclSettings.websiteUrlNcl !== "");
                        this.useSeparateDisclaimer = (this.uclSettings.disclaimerNcl != null && this.uclSettings.disclaimerNcl !== "");
                        this.useSeparateProvidersCarListings = (this.uclSettings.inventoryProviderNclId != null && this.uclSettings.inventoryProviderNclId.toString() !== this.uclSettings.inventoryProviderId)
                    });
                    break;
                case 'NOT FOUND' :
                    CommonUtils.displayConsoleLogError(`*** No record found on ucl_settings for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve ucl_settings data for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to load Ucl Settings data for comp code '${compCode}'.`, "red")
                    break;
            }
        });
    };

    public getEmails = async () => {
        let compCode = this.businessUserStore.businessUser?.compCode || "";

        this.emailsStore.initializeEmails([emailTypeEnum.ADF_LEAD, emailTypeEnum.TEXT_LEAD]);

        await this.emailsStore.getEmailsFromDPS(this.authStore.token, compCode).then(() => {
            runInAction(() => {
                this.adfLeadEmails = this.emailsStore.getEmails(emailTypeEnum.ADF_LEAD);
                this.adfLeadEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, emailTypeEnum.ADF_LEAD, index);
                });
                this.textLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TEXT_LEAD);
                this.textLeadEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, emailTypeEnum.TEXT_LEAD, index);
                });
                this.uclStatusCheckValid();
            });
        });
    };

    public getSecondaryNames = async () => {
        let compCode = this.businessUserStore.businessUser?.compCode || "";

        this.secondaryNamesStore.initializeSecondaryNames();

        await this.secondaryNamesStore.getSecondaryNamesForCompCode(this.authStore.token, compCode).then(() => {
            runInAction(() => {
                this.secondaryNames = this.secondaryNamesStore.secondaryNames;
            });
        });
    };

    private updateUclSettings = async (patchUclSettings: any) => {
        let updateSuccess: boolean = false;
        await updateUclSettings(this.authStore.token, this.uclSettings, patchUclSettings ).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.originalUclSettings = CommonUtils.cloneObject(this.uclSettings);
                    });
                    updateSuccess = true;
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to update ucl_settings data for comp code ${this.uclSettings.compCode}.  Response: ` + JSON.stringify(resp));
                    updateSuccess = false;
                    break;
            }
        });

        return updateSuccess;
    };

    private insertEmails = async () => {

        CommonUtils.logNewRelicPageAction("Profile - CCL", {userAction: 'Add UCL Emails', compCode: this.uclSettings.compCode})

        let insertEmailsSuccess: boolean = false;
        await this.emailsStore.insertEmails(this.authStore.token, this.uclSettings.compCode).then((resp) => {
            runInAction(() => {
                insertEmailsSuccess = resp
                this.adfLeadEmails = this.emailsStore.getEmails(emailTypeEnum.ADF_LEAD);
                this.textLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TEXT_LEAD);
            })
        });

        return insertEmailsSuccess;
    }

    private updateEmails = async () => {
        let updateEmailSuccess: boolean = false;
        await this.emailsStore.updateEmails(this.authStore.token, this.uclSettings.compCode).then((resp) => {
            runInAction(() => {
                updateEmailSuccess = resp
                this.refreshEmails()
            })
        });

        return updateEmailSuccess;
    }

    private refreshEmails = async () => {
        this.adfLeadEmails = this.emailsStore.getEmails(emailTypeEnum.ADF_LEAD);
        this.textLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TEXT_LEAD);
    }

    private deleteEmails = async () => {

        CommonUtils.logNewRelicPageAction("Profile - CCL", {userAction: 'Delete UCL Emails', compCode: this.uclSettings.compCode})

        let deleteEmailsSuccess: boolean = false;
        await this.emailsStore.deleteEmails(this.authStore.token, this.uclSettings.compCode).then((resp) => {
            runInAction(() => {
                deleteEmailsSuccess = resp
                this.adfLeadEmails = this.emailsStore.getEmails(emailTypeEnum.ADF_LEAD);
                this.textLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TEXT_LEAD);
            })
        });

        return deleteEmailsSuccess;
    }

    public determineUclSettingsChanges = () : boolean => {
        return CommonUtils.fieldsChanged(this.uclSettings, this.originalUclSettings);
    }

    public determineEmailChanges = () : boolean => {
        return this.emailsStore.hasEmailsChanged;
    }

    public determineSecondaryNameChanges = () : boolean => {
        return this.secondaryNamesStore.hasSecondaryNamesChanged;
    }

    public hasChanges = () : boolean => {
        return this.determineUclSettingsChanges() || this.determineEmailChanges() || this.determineSecondaryNameChanges();
    }

    public async submitUclSettingsChanges(): Promise<boolean> {

        CommonUtils.logNewRelicPageAction("Profile - CCL", {userAction: 'Update CCL Preferences', compCode: this.uclSettings.compCode})

        let patchUclSettingsMap:Map<string,string> = CommonUtils.getFieldsChanged(this.uclSettings, this.originalUclSettings);
        return await this.updateUclSettings(Object.fromEntries(patchUclSettingsMap));
    };

    public submitUclPreferences = async () => {

        this.commonStore.processingApiCall(true);
        this.removeEmptyInventoryKeys();

        let updateUclSettings: boolean = true;
        if(this.determineUclSettingsChanges()) {
            updateUclSettings = await this.submitUclSettingsChanges()
        }

        let insertEmailsSuccess: boolean = true;
        let deleteEmailsSuccess: boolean = true;
        let updateEmailsSuccess: boolean = true;
        if(this.determineEmailChanges()) {
            if(this.emailsStore.emailsToBeInserted.length > 0) {
                insertEmailsSuccess = await this.insertEmails();
            }
            if(this.emailsStore.emailsToBeDeleted.length > 0) {
                deleteEmailsSuccess = await this.deleteEmails();
            }
            if(this.emailsStore.emailsToBeUpdated.length > 0) {
                updateEmailsSuccess = await this.updateEmails();
            }
            if(insertEmailsSuccess && deleteEmailsSuccess && updateEmailsSuccess) {
                await this.getEmails();
            }
        }

        let addSecondaryNamesSuccess: boolean = true;
        let deleteSecondaryNamesSuccess: boolean = true;
        let updateSecondaryNamesSuccess: boolean = true;
        if(this.determineSecondaryNameChanges()) {
            if(this.secondaryNamesStore.secondaryNamesToBeInserted.length > 0) {
                addSecondaryNamesSuccess = await this.addSecondaryNames()
            }
            if(this.secondaryNamesStore.secondaryNamesToBeDeleted.length > 0) {
                deleteSecondaryNamesSuccess = await this.deleteSecondaryNames()
            }
            if(this.secondaryNamesStore.secondaryNamesToBeUpdated.length > 0) {
                updateSecondaryNamesSuccess = await this.updateSecondaryNames()
            }
            if(addSecondaryNamesSuccess && deleteSecondaryNamesSuccess && updateSecondaryNamesSuccess) {
                await this.getSecondaryNames()
            }
        };

        this.commonStore.processingApiCall(false);

        if(updateUclSettings && insertEmailsSuccess && deleteEmailsSuccess && addSecondaryNamesSuccess && deleteSecondaryNamesSuccess && updateSecondaryNamesSuccess) {
            this.commonStore.displayFeatureNotification(NotificationMessages.UPDATE_SUCCESSFUL, "green");
        }
        else {
            if(!updateUclSettings && !insertEmailsSuccess && !deleteEmailsSuccess && !addSecondaryNamesSuccess && !deleteSecondaryNamesSuccess && !updateSecondaryNamesSuccess) {
                this.commonStore.displayPageNotification(NotificationMessages.UPDATE_FAILED, "red")
            }
            else {
                this.commonStore.displayPageNotification(NotificationMessages.UPDATE_SUCCESSFUL_PARTIAL, "red")
            }
        }
    };

    public hasAdfLeadEmails = () => {
        if(this.adfLeadEmails == null || this.adfLeadEmails?.length <= 0){
            return false
        }
        else if(!this.adfLeadEmails.find(email => email.action !== DB_ACTION_DELETE)){
            return false
        }
        return true
    };

    public hasTextLeadEmails = () => {
        if(this.textLeadEmails == null || this.textLeadEmails?.length <= 0){
            return false
        }
        else if(!this.textLeadEmails.find(email => email.action !== DB_ACTION_DELETE)){
            return false
        }
        return true
    };

    public onAddSecondaryNameToForm = () => {
        let secondaryNameIndex = this.secondaryNamesStore.secondaryNames.length
        this.rootStore.commonStore.setFormError(`secondaryName_${secondaryNameIndex}`, ERROR_MSG_REQUIRED_FIELDS)
        this.secondaryNames = this.secondaryNamesStore.addSecondaryNameToForm(this.uclSettings.compCode);
    };

    public validateSecondaryNameFields(secondaryName: SecondaryName, index: number) {

        // validate required fields
        if(secondaryName.make === "" && secondaryName.secondaryName === "" && secondaryName.websiteUrl === "" ) {
            secondaryName.inlineErrorMsg = ERROR_MSG_REQUIRED_FIELDS
        } else {
            if (secondaryName.make === "") {
                secondaryName.inlineErrorMsg = ERROR_MSG_SECONDARY_NAME_OEM_REQUIRED
            } else if (secondaryName.secondaryName === "") {
                secondaryName.inlineErrorMsg = ERROR_MSG_SECONDARY_NAME_BUSINESS_NAME_REQUIRED
            } else if (secondaryName.websiteUrl === "") {
                secondaryName.inlineErrorMsg = ERROR_MSG_SECONDARY_NAME_WEBSITE_URL_REQUIRED
            } else {
                // validate website url
                let isValidUrl = Validator.validateUrl(secondaryName.websiteUrl);
                if (isValidUrl) {
                    secondaryName.inlineErrorMsg = ""
                } else {
                    if (!(secondaryName.websiteUrl.indexOf("http://") === 0) && !(secondaryName.websiteUrl.indexOf("https://") === 0)) {
                        secondaryName.inlineErrorMsg = ERROR_MSG_WEBSITE_URL_START
                    } else if (secondaryName.websiteUrl.indexOf(" ") >= 0) {
                        secondaryName.inlineErrorMsg = ERROR_MSG_WEBSITE_URL_SPACES
                    } else if (!new RegExp(/(\.[^ "]{1,})$/).test(secondaryName.websiteUrl)) {
                        secondaryName.inlineErrorMsg = ERROR_MSG_WEBSITE_URL_END
                    } else {
                        secondaryName.inlineErrorMsg = ERROR_MSG_WEBSITE_URL_INVALID
                    }
                }
            }
        }

        if(secondaryName.inlineErrorMsg.length > 0) {
            this.rootStore.commonStore.setFormError(`secondaryName_${index}`, "Secondary Name values invalid")
        } else {
            this.rootStore.commonStore.removeFormError(`secondaryName_${index}`)
        }

    }

    public secondaryNameCheckForChanges(secondaryName: SecondaryName){
        if(secondaryName?.action !== DB_ACTION_INSERT) {
            let changed: boolean = this.secondaryNamesStore.hasSecondaryNameBeenChangedByUser(secondaryName)
            if(changed) {
                secondaryName.action = DB_ACTION_UPDATE
            } else {
                secondaryName.action = ""
            }
        }
    };

    public onSecondaryNameMakeChange = (selectedOem: string, index: number) => {
        let secondaryName = this.secondaryNames[index]

        if(selectedOem) {
            runInAction(() => {
                secondaryName.make = selectedOem
            })
        } else {
            runInAction(() => {
                secondaryName.make = ""
            })
        }

        this.validateSecondaryNameFields(secondaryName, index)
        this.secondaryNameCheckForChanges(secondaryName)
    };

    public onSecondaryNameBusinessNameChange = (name: string, index: number) => {
        let secondaryName = this.secondaryNames[index]
        secondaryName.secondaryName = name

        this.validateSecondaryNameFields(secondaryName, index)
        this.secondaryNameCheckForChanges(secondaryName)
    };

    public onSecondaryNameWebsiteUrlChange = (websiteUrl: string, index: number) => {
        let secondaryName = this.secondaryNames[index]
        secondaryName.websiteUrl = websiteUrl

        this.validateSecondaryNameFields(secondaryName, index)
        this.secondaryNameCheckForChanges(secondaryName)
    };

    public onRemoveSecondaryNameFromForm = (index: number) => {
        this.secondaryNamesStore.removeSecondaryName(index);
        this.rootStore.commonStore.removeFormError(`secondaryName_${index}`)
    };

    private addSecondaryNames = async() => {
        CommonUtils.logNewRelicPageAction("Profile - CCL", {userAction: 'Add Secondary Names', compCode: this.uclSettings.compCode})

        let addSecondaryNamesSuccess: boolean = false;
        await this.secondaryNamesStore.addSecondaryNames(this.authStore.token, this.uclSettings.compCode).then((resp) => {
            runInAction(() => {
                addSecondaryNamesSuccess = resp
                this.secondaryNames = this.secondaryNamesStore.getSecondaryNamesList();
            })
        });

        return addSecondaryNamesSuccess;
    };

    private deleteSecondaryNames = async () => {

        CommonUtils.logNewRelicPageAction("Profile - CCL", {userAction: 'Delete Secondary Names', compCode: this.uclSettings.compCode})

        let deleteSecondaryNamesSuccess: boolean = false;
        await this.secondaryNamesStore.deleteSecondaryNames(this.authStore.token, this.uclSettings.compCode).then((resp) => {
            runInAction(() => {
                deleteSecondaryNamesSuccess = resp
                this.secondaryNames = this.secondaryNamesStore.getSecondaryNamesList();
            })
        });

        return deleteSecondaryNamesSuccess;
    }

    private updateSecondaryNames = async() => {
        CommonUtils.logNewRelicPageAction("Profile - CCL", {userAction: 'Update Secondary Names', compCode: this.uclSettings.compCode})

        let updateSecondaryNamesSuccess: boolean = false;
        await this.secondaryNamesStore.updateSecondaryNames(this.authStore.token, this.uclSettings.compCode).then((resp) => {
            runInAction(() => {
                updateSecondaryNamesSuccess = resp
                this.secondaryNames = this.secondaryNamesStore.getSecondaryNamesList();
            })
        });

        return updateSecondaryNamesSuccess;
    }
};
