import CommonStore from "../../common/common";
import RootStore from "../../root";
import { RegenerateTopRatedDealers } from "../../../types/features";
import { makeAutoObservable, runInAction } from "mobx";
import Validator from "../../helpers/validator";
import { getDealershipByCompCode, regenerateTopRatedDealers } from "src/clients/damBackendApi";
import AuthStore from "src/stores/auth";
import CommonUtils from "src/utils/commonUtils";
import SearchStore from "src/stores/search/search";
import { ERROR_MSG_COMP_CODE_SEARCH_CRITERIA, ERROR_MSG_COMP_CODE_NOT_FOUND } from "src/stores/search/messaging/errorMessagesSearch";

export default class RegenerateTopRatedDealersStore {

    // Fields
    compCode: string = "";
    activeCompCode: { companyName: string, compCode: string } | null = null
    invalidCompCodeMessage: string = ""
    compCodeNotFound = false

    // Stores
    commonStore: CommonStore;
    rootStore: RootStore;
    authStore: AuthStore;
    searchStore: SearchStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.commonStore = this.rootStore.commonStore
        this.authStore = this.rootStore.authStore
        this.searchStore = this.rootStore.searchStore

        makeAutoObservable(this)
    }

    public initializeStore() {
        runInAction(() => {
            this.commonStore.currentFeature = RegenerateTopRatedDealers
            this.commonStore.processingApiCall(false)
            this.commonStore.clearPageMsg()
            this.invalidCompCodeMessage = ""
            this.compCodeNotFound = false
        })
    };

    public onCompCodeChange = (compCode: string) => {
        this.compCode = compCode;
        this.compCodeNotFound = false
    }

    public isValidCompCode = (): boolean => runInAction(() => {
        if (Validator.containsSpecialCharacters(this.compCode)) {
            this.invalidCompCodeMessage = ERROR_MSG_COMP_CODE_SEARCH_CRITERIA;
            return false;
        }
        else if (this.compCodeNotFound) {
            this.invalidCompCodeMessage = ERROR_MSG_COMP_CODE_NOT_FOUND;
            return false;
        }
        this.invalidCompCodeMessage = "";
        return true;
    })

    public getBisResultsByCompCode(compCode: string) {
        if (compCode) {
            this.commonStore.processingApiCall(true)
            getDealershipByCompCode(this.authStore.token, compCode?.trim().toUpperCase()).then((resp) => {
                switch (resp?.status) {
                    case 'SUCCESS':
                        runInAction(() => {
                            this.activeCompCode = resp.data
                        })
                        break;
                    case 'NOT FOUND':
                        runInAction(() => {
                            this.compCodeNotFound = true
                        })
                        break;
                    default:
                        runInAction(() => {
                            this.commonStore.displayPageNotification(`Error occurred trying to query BIS for comp code '${compCode}' | Status: ${resp.status}`, "red")
                        })
                        CommonUtils.displayConsoleLogError(`*** Error calling BIS to retrieve data for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                        break;
                };
                runInAction(() => {
                    this.commonStore.processingApiCall(false);
                });
            });
        }
    }

    public disableSubmitButton = () => {
        if(this.isValidCompCode() && this.compCode.length > 0) {
            return false
        }
        return true
    }

    public setActiveCompCode = () => {
        this.getBisResultsByCompCode(this.compCode)
        this.commonStore.clearPageMsg()
    }

    public resetActiveCompCode = () => {
        this.activeCompCode = null
        this.commonStore.clearPageMsg()
    }

    public submitCompCode = () => {
        CommonUtils.logNewRelicPageAction("Regenerate Top Rated Dealers", {
            userAction: 'Upload File',
            user: this.authStore.user,
            compCode: this.activeCompCode?.compCode
        });

        this.commonStore.processingApiCall(true)
        regenerateTopRatedDealers(this.authStore.token, this.activeCompCode?.compCode!).then((resp) => {
            if (resp.status === "SUCCESS") {
                this.commonStore.displayPageNotification(`Success! The process to regenerate Top-Rated Dealer images for ${this.activeCompCode?.companyName} - ${this.activeCompCode?.compCode} has started!`, "green")
                this.compCode = ""
                this.activeCompCode = null
            }
            else {
                this.commonStore.displayPageNotification(`Error invoking Regenerate Top Rated Dealers Lambda for ${this.activeCompCode?.companyName} - ${this.activeCompCode?.compCode} | Status: ${resp.status}`, "red")

            }
            this.commonStore.processingApiCall(false)
        })
    }
};