import * as React from "react"
import { observer } from "mobx-react"
import Store from "../../../../stores"
import "../../../../styles/main.scss"
import "./preferencesSearchResults.scss"


export default (
  class SearchResults extends React.Component<{}> {

    render() {
      return (
          <this.SearchResults />
      )
    }

    SearchResults = observer(() => {

      const ownerGroup = Store.preferences.preferencesSelection.ownerGroup

      return (
        <div id="search-results-div"  className="preferences-search-container-selected" hidden={false}>
          <div>
              <div id="search-results-company-name-div" className="flex-row">
                  <p id="search-results-company-name-p" className="preferences-search-results-company-name">{ownerGroup?.companyName}</p>
              </div>
              <div id="search-results-street-address-div" >
                  <p id="search-results-street-address-p" className="preferences-search-results-street-address">{ownerGroup?.address.streetAddress1}</p>
              </div>
              <div id="search-results-city-state-zip-div">
                  <p id="search-results-city-state-zip-p" className="preferences-search-results-city-state-zip">{ownerGroup?.address.city}, {ownerGroup?.address.state} {ownerGroup?.address.zip}</p>
              </div>
              <div id="search-results-comp-code-row-div" className="flex-row">
                  <div id="search-results-comp-code-label-div" className="flex-col, preferences-search-label-comp-code">Comp Code:</div>
                  <p id="search-results-comp-code-label-p" className="preferences-search-value-comp-code">{ownerGroup?.compCode}</p>
              </div>
              <div id="search-results-active-row-div" className="flex-row">
                  <div id="search-results-active-label-div" className="flex-col, preferences-search-label-account-status">Account Status:</div>
                  <p id="search-results-active-label-p" className="preferences-search-value-account-status">{ownerGroup?.active ? "Active" : "Inactive"}</p>
              </div>
          </div>
        </div>
      )
    })
  }
)
