import * as React from "react";
import AuditHistoryModal from "./AuditHistoryModal";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import Button from "@carfax-stencils/button";
import Carfax4LifePrefsTab from "./c4lPreferences/Carfax4LifePrefsTab";
import CallMgmtSettingsTab from "./callMgmtSettings/CallMgmtSettingsTab";
import DealerDetailsTab from "./dealerDetails/DealerDetailsTab";
import Header from "../../shared-components/layout/header/Header";
import Page from "../../shared-components/layout/page/Page";
import SearchComponent from "../../shared-components/search/SearchComponent";
import Store from "../../stores";
import RatingsAndReviewsTab from "./ratingsAndReviewsPref/RatingsAndReviewsTab";
import UsedCarListingsTab from "./usedCarListings/UsedCarListingsTab";
import UnsavedChangesModal, {UnsavedChangesProps} from "../../shared-components/unsavedChanges/UnsavedChangesModal";
import {AuditHistoryProps} from "./AuditHistoryModal";
import {NoResults} from "../../shared-components/noResults/NoResults";
import {Notification, NotificationCloseButton, NotificationIcon, NotificationMessage} from "@carfax-stencils/notification";
import {observer} from "mobx-react";
import {runInAction} from "mobx";
import {Tabs, TabList, Tab, TabPanel} from '@carfax-stencils/tabs';
import {withOktaAuth} from "@okta/okta-react";
import IconMoveProfilePreferences from "../../images/icon_move_profile_preferences.svg"

import "./ProfileMgmt.scss";
import "../../styles/main.scss";
import "../../shared-components/noResults/noResults.scss";
import "../../shared-components/search/search.scss";
import "../../utils/newRelic/newrelic";
import {profileTabs} from "../../types/profile/enums/profileTabs";
import DeleteImageModal from "./dealerDetails/images/DeleteImageModalComponent";
import PreviewImageModal from "./dealerDetails/images/PreviewImageModalComponent";
import {ERROR_MSG_ENHANCED_TRACK_NUM_REQUIRED} from "../../stores/profile/messaging/errorMessagesProfile";
import {twilioPhoneDataType} from "../../types/profile/enums/twilioPhoneDataType";
import ConfirmModal, {ConfirmProps} from "../../shared-components/confirm/ConfirmModal";
import ProvisionNumberModal, {ProvisionNumberProps} from "./callMgmtSettings/ProvisionNumberModal";
import MoveProfilePreferencesModal, {MoveProfilePreferencesProps} from "./MoveProfilePreferencesModal";
import {DAM_Admin, DAM_Super_Admin} from "../../types/groups";

// Props
type ProfileMgmtProps = { oktaAuth: any; authState: any; match:any; emptyView?: JSX.Element; }

// Stores
const rootStore = Store.root.root;
const authStore = rootStore.authStore;
const auditHistoryStore = rootStore.auditHistoryStore;
const businessHoursStore = rootStore.businessHoursStore;
const businessUserStore = rootStore.businessUserStore;
const callMgmtSettingsStore = rootStore.callMgmtSettingsStore;
const commonStore = rootStore.commonStore;
const dealerDetailsStore = rootStore.dealerDetailsStore;
const dealerProfileInfoStore = rootStore.dealerProfileInfoStore;
const moveProfilePreferencesStore = rootStore.moveProfilePreferences;
const profileMgmtStore = rootStore.profileMgmtStore;
const ratingsAndReviewsStore = rootStore.ratingsAndReviewsStore;
const secondaryNamesStore = rootStore.secondaryNamesStore;
const searchStore = rootStore.searchStore;
const smcSettingsStore = rootStore.smcSettingsStore;
const uclSettingsStore = rootStore.uclSettingsStore;

export default withOktaAuth(
    class ProfileMgmtPage extends React.Component<ProfileMgmtProps> {

        async componentDidMount() {
            await this.initializePage();
            const compCode = this.props.match.params.compCode
            if(compCode?.length > 0) {
                Store.search.search.handleCompCodeSearch(compCode);
            }
            this.setState({ready:true});
        };

        initializePage = async () => {
            auditHistoryStore.initializeStore();
            commonStore.initializeStore();
            businessHoursStore.initializeStore();
            dealerDetailsStore.initializeStore();
            dealerProfileInfoStore.initializeStore();
            profileMgmtStore.initializeStore();
            searchStore.initializeStore();
            secondaryNamesStore.initializeStore();
            await smcSettingsStore.initializeStore();
            await uclSettingsStore.initializeStore();
        };

        onTabSelect = (selectedTab: any) => {

            runInAction(() => {
                switch (profileMgmtStore.currentTab) {
                    case 0: {
                        if (dealerDetailsStore.hasChanges()) {
                            profileMgmtStore.displayUnsavedChangesModal();
                            profileMgmtStore.setSelectedTab(selectedTab);
                        } else {
                            this.switchTab(selectedTab)
                        }
                        break;
                    }
                    case 1: {
                        if (uclSettingsStore.hasChanges()) {
                            profileMgmtStore.displayUnsavedChangesModal();
                            profileMgmtStore.setSelectedTab(selectedTab);
                        } else {
                            this.switchTab(selectedTab)
                        }
                        break;
                    }
                    case 2: {
                        if (smcSettingsStore.hasChanges()) {
                            profileMgmtStore.displayUnsavedChangesModal();
                            profileMgmtStore.setSelectedTab(selectedTab);
                        } else {
                            this.switchTab(selectedTab)
                        }
                        break;
                    }
                    case 3: {
                        if (ratingsAndReviewsStore.hasChanges()) {
                            profileMgmtStore.displayUnsavedChangesModal();
                            profileMgmtStore.setSelectedTab(selectedTab);
                        } else {
                            this.switchTab(selectedTab)
                        }
                        break;
                    }
                    case 4: {
                        if (callMgmtSettingsStore.hasChanges()) {
                            profileMgmtStore.displayUnsavedChangesModal();
                            profileMgmtStore.setSelectedTab(selectedTab);
                        } else {
                            this.switchTab(selectedTab)
                        }
                        break;
                    }
                }
            })
        };

        protected switchTab = (selectedTab:number) => {
            profileMgmtStore.setCurrentTab(selectedTab)
            commonStore.clearPageMsg()
            commonStore.clearFeatureMsg()
        };

        render() {
            return (
                <Page id="profile-mgmt-page" title="Dealer Account Management | Profile Management">
                    <div id="profile-mgmt-page-div">
                        <Header id="profile-mgmt-page-header" />
                        <this.PageContent />
                    </div>
                </Page>
            );
        }

        AuditHistoryModalComponent = observer(() => {
            const auditHistoryProps: AuditHistoryProps = {
                isVisible: auditHistoryStore.isAuditHistoryModalVisible,
                store: auditHistoryStore
            }
            return auditHistoryStore.isAuditHistoryModalVisible ? (
                <AuditHistoryModal { ...auditHistoryProps} />
            ) : null;
        });

        UnsavedChanges = observer(() => {
            let disableSave = false;
            if (!commonStore.isFormValid) {
                disableSave = true
            }
            const unsavedChangesProps: UnsavedChangesProps = {
                headerTitle: "You have unsaved updates!",
                isVisible : profileMgmtStore.isUnsavedChangesModalVisible,
                onCloseMethod : profileMgmtStore.onClickCloseModal,
                onSaveMethod : profileMgmtStore.onClickSaveUpdates,
                onContinueWithoutSavingMethod: profileMgmtStore.onClickContinueWithoutSaving,
                disableSave: disableSave
            }
            return profileMgmtStore.isUnsavedChangesModalVisible ? (
                <UnsavedChangesModal { ...unsavedChangesProps} />
            ) : null;
        });

        UnsavedModalForCompCodeChange = observer(() => {
            let disableSave = false;
            if (!commonStore.isFormValid) {
                disableSave = true
            }
            const unsavedChangesProps: UnsavedChangesProps = {
                headerTitle: "You have unsaved updates!",
                isVisible : profileMgmtStore.showUnsavedModalForCompCodeChange,
                onCloseMethod : profileMgmtStore.onClickCloseModalForCompCode,
                onSaveMethod: profileMgmtStore.onClickSaveModalForCompCode,
                onContinueWithoutSavingMethod: profileMgmtStore.onClickContinueModalForCompCode,
                disableSave: disableSave
            }
            return profileMgmtStore.showUnsavedModalForCompCodeChange ? (
                <UnsavedChangesModal { ...unsavedChangesProps} />
            ) : null;
        });

        CallMgmtUnsavedChangesModalForTrkNum = observer(() => {
            let disableSave = false;
            if (!commonStore.isFormValid &&
                !(commonStore.formErrorsMap.size === 1 && callMgmtSettingsStore.uclTrackingNumberMessage === ERROR_MSG_ENHANCED_TRACK_NUM_REQUIRED)) {
                disableSave = true
            }
            const unsavedChangesProps: UnsavedChangesProps = {
                headerTitle: "You have unsaved updates!",
                isVisible : callMgmtSettingsStore.showUnsavedModalForTrkNum,
                onCloseMethod : callMgmtSettingsStore.onClickCloseModalForTrkNum,
                onSaveMethod: callMgmtSettingsStore.onClickSaveModalForTrkNum,
                onContinueWithoutSavingMethod: callMgmtSettingsStore.onClickContinueWithoutSavingForTrkNum,
                disableSave: disableSave
            }
            return callMgmtSettingsStore.showUnsavedModalForTrkNum ? (
                <UnsavedChangesModal { ...unsavedChangesProps} />
            ) : null;
        });

        CallMgmtDeprovisionModalForTrkNum = observer( () => {

            let onCloseHandler;
            let confirmButtonMessage = "Delete Tracking Number"
            let headerTitle = "Delete Tracking Phone Number"

            let confirmModalMessageJsx = <div>
                <div>CARFAX will not be able to capture and record phone leads for this dealership without a tracking number.</div>
                <br/><div> Are you sure you want to delete the CARFAX tracking number for {businessUserStore.businessUser?.companyName}?</div>
            </div>

            const OPT_OUT_HEADER_TITLE = "Opt Out of Phone Tracking"
            const OPT_OUT_BUTTON_MSG = "Opt Out & Delete Tracking Number"
            const OPT_OUT_CONFIRMATION_MSG = <div>
                <div>To opt out of phone tracking, the current tracking phone number must be deleted. CARFAX will not
                    be able to capture and record phone leads for this dealership without a tracking number.</div>
                <br/><div> Are you sure you want to delete the CARFAX tracking number for {businessUserStore.businessUser?.companyName}?</div>
            </div>

            switch (callMgmtSettingsStore.deleteTrackingNumberType) {
                case twilioPhoneDataType.UCL :
                    onCloseHandler = callMgmtSettingsStore.deprovisionUCLTwilioNumber;
                    if(callMgmtSettingsStore.uclTwilioPhoneData.disableTrackingNumber) {
                        headerTitle = OPT_OUT_HEADER_TITLE
                        confirmButtonMessage = OPT_OUT_BUTTON_MSG
                        confirmModalMessageJsx = OPT_OUT_CONFIRMATION_MSG
                    }
                    break;
                case twilioPhoneDataType.NEWLISTINGS :
                    onCloseHandler = callMgmtSettingsStore.deprovisionNewListingsTwilioNumber;
                    if(callMgmtSettingsStore.uclTwilioPhoneData.disableTrackingNumber) {
                        headerTitle = OPT_OUT_HEADER_TITLE
                        confirmButtonMessage = OPT_OUT_BUTTON_MSG
                        confirmModalMessageJsx = OPT_OUT_CONFIRMATION_MSG
                    }
                    break;
                case twilioPhoneDataType.SMC :
                    onCloseHandler = callMgmtSettingsStore.deprovisionSMCTwilioNumber;
                    if(callMgmtSettingsStore.smcTwilioPhoneData.disableTrackingNumber) {
                        headerTitle = OPT_OUT_HEADER_TITLE
                        confirmButtonMessage = OPT_OUT_BUTTON_MSG
                        confirmModalMessageJsx = OPT_OUT_CONFIRMATION_MSG
                    }
                    break;
                case twilioPhoneDataType.SERVICE_APPT :
                    onCloseHandler = callMgmtSettingsStore.deprovisionSrvApptTwilioNumber;
                    if(callMgmtSettingsStore.srvApptTwilioPhoneData.disableTrackingNumber) {
                        headerTitle = OPT_OUT_HEADER_TITLE
                        confirmButtonMessage = OPT_OUT_BUTTON_MSG
                        confirmModalMessageJsx = OPT_OUT_CONFIRMATION_MSG
                    }
                    break;
                default:
                    onCloseHandler = () => {};
                    break;
            }

            const confirmProps: ConfirmProps = {
                confirmButtonMessage: confirmButtonMessage,
                headerTitle: headerTitle,
                isVisible : callMgmtSettingsStore.isConfirmDeleteModalVisible,
                onCloseMethod : onCloseHandler,
                theme: "red",
                confirmModalMessageJsx: confirmModalMessageJsx
            }
            return callMgmtSettingsStore.isConfirmDeleteModalVisible ? (
                <ConfirmModal { ...confirmProps} />
            ) : null;
        });

        CallMgmtProvisionTrackingNumberModal = observer( () => {
            const provisionNumberProps: ProvisionNumberProps = {
                headerTitle: "Add " + callMgmtSettingsStore.provisionNumberTypeTitle + " Tracking Phone Number",
                isVisible : callMgmtSettingsStore.isProvisionNumberModalVisible,
                store: callMgmtSettingsStore
            }

            return callMgmtSettingsStore.isProvisionNumberModalVisible ? (
                <ProvisionNumberModal { ...provisionNumberProps } />
            ) : null;
        });

        MoveProfilePreferencesModalComponent = observer(() => {
            const moveProfilePreferenceProps: MoveProfilePreferencesProps = {
                headerTitle: "Move Preferences",
                isVisible: moveProfilePreferencesStore.isMoveProfilePreferencesModalVisible,
                store: moveProfilePreferencesStore,
                sourceCompCode: businessUserStore?.businessUser?.compCode || "",
                sourceCompanyName: businessUserStore?.businessUser?.companyName || "",
                commonStore: commonStore
            }
            return moveProfilePreferencesStore.isMoveProfilePreferencesModalVisible ? (
                <MoveProfilePreferencesModal { ...moveProfilePreferenceProps} />
            ) : null;
        });

        PageContent = observer(() => {

            const hasData = businessUserStore.businessUser;

            return (
                <div id="profile-mgmt-outer-div">
                    <div id="profile-mgmt-breadcrumbs-div" className="breadcrumb-trail">
                        <Breadcrumbs
                            id="profile-mgmt-breadcrumbs"
                            crumbs={[
                                { value: "Home", href: "#" },
                                { value: "Profile Management" },
                            ]}
                        />
                    </div>
                    <div id="profile-mgmt-page-heading-heading-row" className="profile-mgmt-move-pref-div">
                        <div id="profile-mgmt-page-heading-div"
                             className="page__heading profile-mgmt-move-pref-header-fit-width">
                            Dealer Profile Management
                        </div>
                        {(authStore.groups?.includes(DAM_Super_Admin) || authStore.groups?.includes(DAM_Admin)) && hasData &&
                            <div id="profile-mgmt-move-profile-preferences-div">
                                <Button
                                    id="move-preference-button"
                                    theme="blue-outline"
                                    size="large"
                                    children={`Move Preferences`}
                                    iconLeft={<img src={IconMoveProfilePreferences} className="profile-mgmt-move-pref-icon" alt={"Icon Move Profile Preferences"}/>}
                                    visible={false}
                                    onClick={moveProfilePreferencesStore.displayMoveProfilePreferencesModal}
                                />
                            </div>
                        }

                    </div>

                    {/*  COMMON PAGE-LEVEL NOTIFICATIONS  */}
                    {commonStore.pageMsg && (
                        <div id="profile-mgmt-page-notification-div" className="page-notification-div">
                            <Notification
                                id="profile-mgmt-page-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.pageMsgTheme}>
                                <NotificationIcon/>
                                <NotificationMessage>
                                    {commonStore.pageMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => {commonStore.clearPageMsg()}}
                                />
                            </Notification>
                        </div>
                    )}

                    <div id="profile-mgmt-layout-search-outer-div" className="profile-mgmt-layout-wrapper">
                        <div id="profile-mgmt-layout-search-wrapper-div" className="profile-mgmt-layout-search-wrapper-div">
                            <SearchComponent />
                        </div>
                        {hasData && this.renderData()}
                        {!hasData && this.renderNoData()}
                    </div>
                </div>
            );
        });

        renderNoData() {
            return (
                <div id="profile-mgmt-layout-noData-outer-div" className="page__no-data">
                    <NoResults subtitle="Please use the search box on the left to view a specific dealership."/>
                </div>
            )
        }

        renderData() {
            return (
                <div id="profile-mgmt-layout-withData-outer-div" className="profile-mgmt-content-wrapper">

                    {/*  FEATURE-CONTENT NOTIFICATIONS  */}
                    {commonStore.featureMsg &&
                        <div id="profile-mgmt-layout-body-feature-message-div" className="feature-notification-layout">
                            <Notification
                                id="profile-mgmt-feature-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.featureMsgTheme}>
                                <NotificationIcon/>
                                <NotificationMessage>
                                    {commonStore.featureMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => {commonStore.clearFeatureMsg()}}
                                />
                            </Notification>
                        </div>
                    }

                    <div id="profile-mgmt-main-layout" className="page__data  profile-mgmt-page-with-data">
                        <Tabs
                            id="profile-mgmt-main-layout-tabs"
                            selected={profileMgmtStore.currentTab}
                            onSelect={(index) => this.onTabSelect(index)}
                            theme={"bar"}
                        >
                            <TabList className="profile-mgmt-tablist">
                                <Tab>{profileTabs[0]}</Tab>
                                <Tab>{profileTabs[1]}</Tab>
                                <Tab>{profileTabs[2]}</Tab>
                                <Tab>{profileTabs[3]}</Tab>
                                <Tab>{profileTabs[4]}</Tab>
                            </TabList>
                            <TabPanel>{profileMgmtStore.currentTab === 0 && <DealerDetailsTab/>}</TabPanel>
                            <TabPanel>{profileMgmtStore.currentTab === 1 && <UsedCarListingsTab compCode={businessUserStore.businessUser?.compCode}/>}</TabPanel>
                            <TabPanel>{profileMgmtStore.currentTab === 2 && <Carfax4LifePrefsTab compCode={businessUserStore.businessUser?.compCode}/>}</TabPanel>
                            <TabPanel>{profileMgmtStore.currentTab === 3 && <RatingsAndReviewsTab compCode={businessUserStore.businessUser?.compCode}/>}</TabPanel>
                            <TabPanel>{profileMgmtStore.currentTab === 4 && <CallMgmtSettingsTab compCode={businessUserStore.businessUser?.compCode}/>}</TabPanel>
                        </Tabs>
                        <this.UnsavedChanges/>
                        <this.UnsavedModalForCompCodeChange/>
                        <this.AuditHistoryModalComponent/>
                        {dealerDetailsStore.isDeleteImageModalVisible && <DeleteImageModal/>}
                        {dealerDetailsStore.isPreviewImageModalVisible && <PreviewImageModal/>}
                        <this.CallMgmtProvisionTrackingNumberModal/>
                        <this.CallMgmtUnsavedChangesModalForTrkNum/>
                        <this.CallMgmtDeprovisionModalForTrkNum/>
                        <this.MoveProfilePreferencesModalComponent/>

                    </div>

                    {/* PAGE FOOTER*/}
                    <div id="profile-mgmt-main-layout" className="profile-mgmt-footer">
                        <div id="profile-mgmt-footer-blue-buttons-div" className="profile-mgmt-footer-blue-buttons-div">
                            <div id="profile-mgmt-footer-update-button-div">
                                { profileMgmtStore.currentTab === profileTabs["DEALER DETAILS"] &&
                                    <Button
                                        id="update-dealer-details-button"
                                        theme="blue"
                                        size="large"
                                        children={`Update Dealer Details`}
                                        disabled={!commonStore.isFormValid || !dealerDetailsStore.hasChanges() || commonStore.isProcessingApi}
                                        onClick={dealerDetailsStore.submitDealerDetails}
                                    />
                                }
                                { profileMgmtStore.currentTab === profileTabs["CCL"] &&
                                    <Button
                                        id="update-ucl-preferences-button"
                                        theme="blue"
                                        size="large"
                                        children={`Update Car Listings`}
                                        disabled={!commonStore.isFormValid || !uclSettingsStore.hasChanges() || commonStore.isProcessingApi}
                                        onClick={uclSettingsStore.submitUclPreferences}
                                    />
                                }
                                { profileMgmtStore.currentTab === profileTabs["C4L"] &&
                                    <Button
                                        id="update-c4l-preferences-button"
                                        theme="blue"
                                        size="large"
                                        children={`Update CARFAX For Life Preferences`}
                                        disabled={!commonStore.isFormValid || !smcSettingsStore.hasChanges() || commonStore.isProcessingApi}
                                        onClick={smcSettingsStore.submitSmcSettings}
                                    />
                                }
                                { profileMgmtStore.currentTab === profileTabs["R&R"] &&
                                    <Button
                                        id="update-ratings-and-reviews-preferences-button"
                                        theme="blue"
                                        size="large"
                                        children={`Update Ratings & Reviews Preferences`}
                                        disabled={!commonStore.isFormValid || !ratingsAndReviewsStore.hasChanges() || commonStore.isProcessingApi}
                                        onClick={ratingsAndReviewsStore.submitRatingsAndReviewsPreferences}
                                    />
                                }
                                { profileMgmtStore.currentTab === profileTabs["CALL MGMT."] &&
                                    <Button
                                        id="update-call-mgmt-settings-button"
                                        theme="blue"
                                        size="large"
                                        children={`Update Call Management Settings`}
                                        disabled={!commonStore.isFormValid || !callMgmtSettingsStore.hasChanges() || commonStore.isProcessingApi}
                                        onClick={callMgmtSettingsStore.submitCallMgmtSettings}
                                    />
                                }
                            </div>
                        </div>
                        <div id="profile-mgmt-footer-white-buttons-div" className="profile-mgmt-footer-white-buttons-div">
                            <div id="profile-mgmt-footer-audit-button-div" className="profile-mgmt-footer-audit-button-div">
                                <Button
                                    id="audit-history-button"
                                    theme="blue-outline"
                                    size="large"
                                    children={`Audit History`}
                                    onClick={auditHistoryStore.displayAuditHistoryModal}
                                />
                            </div>
                            <div id="profile-mgmt-footer-refresh-button-div"
                                 className={authStore.isNonAdminGroup() ? "profile-mgmt-footer-refresh-button-div__hidden" : "profile-mgmt-footer-refresh-button-div"}>
                                <Button
                                    id="refresh-data-button"
                                    theme="blue-outline"
                                    size="large"
                                    children={`Refresh Data`}
                                    onClick={profileMgmtStore.refreshData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
);

