import * as React from "react";
import AuditHistoryStore from "../../stores/profile/auditHistory";
import AutoCompleteInput from "@carfax-stencils/input-auto-complete";
import Button from "@carfax-stencils/button";
import TablePagination from '@carfax-stencils/table-pagination'
import {AuditHistoryRecord} from "../../types/profile/auditHistoryRecord";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import {observer} from "mobx-react";
import { Table, THead, TBody, TD, TH, TR } from '@carfax-stencils/table'
import "../../styles/main.scss";
import './AuditHistoryModal.scss';
import {isMobile} from "react-device-detect";
import {Tooltip} from "@carfax-stencils/tooltip";

export type AuditHistoryProps = {
    isVisible : boolean;
    store : AuditHistoryStore;
}

const AuditHistoryModal = observer((props: AuditHistoryProps) => {

    return (
        <Modal
            id="audit-history-modal"
            isVisible={true}
            isWideFormat={true}
            className="audit-history-modal"
            onClose={props.store.closeAuditHistoryModal}
        >
            <ModalHeader>
                <div id="modal-header-div" className="audit-history-modal__header">Audit History</div>
            </ModalHeader>
            <ModalBody>
                <AuditHistoryDetails {...props}/>
            </ModalBody>
            <ModalFooter>
                <div id="audit-footer-div">
                    <Button
                        id="dismiss-button"
                        size="small"
                        theme="black"
                        onClick={() => props.store.closeAuditHistoryModal()}
                    >
                        Dismiss
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
});

const RenderPaginator = observer((props: {store: any}) => {
    return (
        props.store.selectedHistoryRecords.length > 0 ? (
            <div className="table-pager">
                <TablePagination
                    onChange={props.store.handlePaginationChange}
                    perPageOptions={[10,20,50]}
                    currentPage={props.store.paginationCurrentPage}
                    perPageValue={props.store.paginationPerPageValue}
                    totalItems={props.store.selectedHistoryRecords.length}
                    tooltipTheme={"light"}
                />
            </div>
        ) : null
    )
});

const AuditHistoryDetails = observer((props: AuditHistoryProps) => {
    return (
        <div id="audit-history-details-div">
            <div id="paginator-div-top" className="paginator-div-top">
                <RenderPaginator store={props.store}/>
            </div>
            <div id="filters-heading-div">
                <p id="filters-heading-text" className="flex-row audit-history-modal__heading">Filters</p>
            </div>
            <div id="filters-div" className="filters-div-row">
                <div id="user-input-div" className="filter-input-user-div">
                    <p id="user-input-label" className="audit-history-modal__subheading font-bold">Username</p>
                    <AutoCompleteInput id="user-auto-complete-input"
                                       className={(isMobile) ? `field-name-auto-complete-input__ipad` : `field-name-auto-complete-input__browser`}
                                       placeholder="Enter user name"
                                       value={props.store.auditUserSelectedValue ?? ""}
                                       onChange={(input) => { props.store.onUserChange(input)}}
                                       onSelect={(input) => { props.store.onUserSelected(input)}}
                                       options={props.store.auditUsers} />
                </div>
                <div id="field-name-input-div">
                    <p id="field-name-input-label" className="audit-history-modal__subheading font-bold">Field Name</p>
                    <AutoCompleteInput id="field-name-auto-complete-input"
                                       className={(isMobile) ? `field-name-auto-complete-input__ipad` : `field-name-auto-complete-input__browser`}
                                       placeholder="Enter audit field"
                                       disableSort={true}
                                       aria-autocomplete={"inline"}
                                       value={props.store.auditFieldSelectedValue ?? ""}
                                       onChange={(input) => { props.store.onFieldNameChange(input)}}
                                       onSelect={(input) => { props.store.onFieldNameSelected(input)}}
                                       options={props.store.auditFields} />
                </div>
            </div>
            <hr id="separator-line" className="seperator-line"/>
            <Table aria-describedby="audit-history-details-table">
                <THead  className="audit-history-table__heading">
                    <TH className="audit-history-table__heading__TH" width={120}>Date</TH>
                    <TH className="audit-history-table__heading__TH" width={230}>Username</TH>
                    <TH className="audit-history-table__heading__TH" width={190}>Field Name</TH>
                    <TH className="audit-history-table__heading__TH" width={240}>New Value</TH>
                </THead>
                <TBody>
                    {props.store.paginatedAuditHistoryRecords.map((record: AuditHistoryRecord, index) => (
                        <TR className="audit-history-table__row" key={record.changeId + "_" + record.friendlyFieldChanged + "_" + index}>
                            <TD className="audit-history-table__column">{record.createDate}</TD>
                            <TD className="audit-history-table__column">
                                {record.friendlyUsername !== record.username && props.store.userNameRefs.has("userNameRefs" + index) &&
                                    <Tooltip anchorRef={props.store.userNameRefs.get("userNameRefs" + index)} theme="dark" position="left">
                                        {record.username}
                                    </Tooltip>
                                }
                                {record.friendlyUsername !== record.username && props.store.userNameRefs.has("userNameRefs" + index) &&
                                    <div id="audit-history-username-sub-div" ref={props.store.userNameRefs.get("userNameRefs" + index)}>
                                        {record.friendlyUsername}
                                    </div>
                                }
                                {record.friendlyUsername === record.username &&
                                    <div id="audit-history-username-sub-div">{record.friendlyUsername}</div>
                                }
                            </TD>
                            <TD className="audit-history-table__column">{record.friendlyFieldChanged}</TD>
                            <TD className="audit-history-table__column">{record.fieldValue}</TD>
                        </TR>
                    ))}
                </TBody>
            </Table>
            <div id="paginator-div" className="paginator-div-bottom">
                <RenderPaginator store={props.store}/>
            </div>
        </div>
    )
});

export default AuditHistoryModal;
