import * as React from "react";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import Button from "@carfax-stencils/button";
import Header from "../../../shared-components/layout/header/Header";
import Page from "../../../shared-components/layout/page/Page";
import Store from "../../../stores";
import TextInput from "@carfax-stencils/input-text";
import {observer} from "mobx-react";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import {withOktaAuth} from "@okta/okta-react";
import { InfoCircleColor } from '@carfax-stencils/icon'
import "./EmailDeletionTool.scss"
import "../../../styles/main.scss";
import "../../../utils/newRelic/newrelic";

import {
    Notification,
    NotificationCloseButton,
    NotificationIcon,
    NotificationMessage
} from "@carfax-stencils/notification";

// Stores
const rootStore = Store.root.root;
const commonStore = rootStore.commonStore;
const emailDeletionToolStore = rootStore.emailDeletionToolStore;


export default withOktaAuth(

    class EmailDeletionToolPage extends React.Component {

        state = {
            ready: false
        };

        async componentDidMount() {
            this.setState(
                {ready:true}
            )
            await this.initializePage()
        };

        initializePage = async () => {
            emailDeletionToolStore.initializeStore()
        };

        render() {
            return (
                <Page id="email-deletion-tool-page" title="Dealer Account Management | Email Deletion Tool">
                    <div id="email-deletion-tool-page-div">
                        <Header id="email-deletion-tool-page-header" />
                        <this.PageContent />
                    </div>
                </Page>
            );
        };

        PageContent = observer(() => {

            return (
                <div id="email-deletion-tool-outer-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner/>
                    )}
                    <div id="email-deletion-tool-breadcrumbs-div" className="breadcrumb-trail">
                        <Breadcrumbs
                            id="email-deletion-tool-breadcrumbs"
                            crumbs={[
                                { value: "Home", href: "#" },
                                { value: "Utilities", href: "/utilities" },
                                { value: "Email Deletion Tool" },
                            ]}
                        />
                    </div>
                    <div id="email-deletion-tool-page-heading-div" className="page__heading">
                        Email Deletion Tool
                    </div>

                    {/*  COMMON PAGE-LEVEL NOTIFICATIONS  */}
                    {commonStore.pageMsg && (
                        <div id="email-deletion-tool-notification-div" className="page-notification-div">
                            <Notification
                                id="email-deletion-tool-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.pageMsgTheme}>
                                <NotificationIcon/>
                                <NotificationMessage>
                                    {commonStore.pageMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => {commonStore.clearPageMsg()}}
                                />
                            </Notification>
                        </div>
                    )}

                    <div id="email-deletion-tool-layout-search-outer-div" className="email-deletion-tool__layout-wrapper">
                        <div id="email-deletion-tool-content-div" className="email-deletion-tool__content-div">
                            <div id="email-deletion-tool-info-msg-div" className="email-deletion-tool__info-text-div">
                                <InfoCircleColor size={50} className="email-deletion-tool__stencils-info-circle"/>
                                <div id="email-deletion-tool-info-msg-text-div" >
                                    When submitted, the email address will be removed from all accounts and all Dealer Profile tabs.
                                </div>
                            </div>
                            <div id="email-label-div" className="email-deletion-tool__email-label-div">
                                <p id="email-description-text" className="primary_label">
                                    Email address to delete:
                                </p>
                                <div className="email-deletion-tool__email-input-text">
                                    <TextInput
                                        id="email-input-text"
                                        value={ emailDeletionToolStore.emailToDelete === null ? "" : emailDeletionToolStore.emailToDelete }
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { emailDeletionToolStore.emailToDelete = event.target.value }}
                                        type="text"
                                        aria-invalid={!emailDeletionToolStore.isValidEmail()}
                                    />
                                </div>
                            </div>
                            <Button
                                id="email-deletion-submit-button"
                                className="email-deletion-tool__delete-email-button"
                                theme="blue"
                                size="large"
                                disabled={!emailDeletionToolStore.isValidEmail() || (emailDeletionToolStore.emailToDelete.length > 0 && !emailDeletionToolStore.isValidEmail()) || emailDeletionToolStore.emailToDelete.length === 0}
                                onClick={() => emailDeletionToolStore.deleteEmail()}>
                                Delete Email
                            </Button>
                        </div>
                    </div>
                </div>
            );
        });
    }
);
