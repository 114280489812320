import * as React from "react";
import {Modal, ModalBody, ModalHeader} from '@carfax-stencils/modal'
import Store from "../../../stores";
import {observer} from "mobx-react";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import "../../../styles/main.scss";
import "./DevCfoRemoteLoginModal.scss";
import TextInput from "@carfax-stencils/input-text";
import Button from "@carfax-stencils/button";
import InlineErrorMessage from "../../../shared-components/layout/error/InlineErrorMessage";

export type DevCfoRemoteLoginProps = {

}

const DevCfoRemoteLoginModal = observer(() => {

    const acctMgmtStore = Store.root.root.acctMgmtStore

    return (
        <Modal
            id="dev-cfo-remote-login-modal"
            isVisible={true}
            isWideFormat={false}
            className="dev-cfo-remote-login-modal"
            onClose={acctMgmtStore.closeDevCfoRemoteLoginModal}
        >
            <ModalHeader>
                <div id="dev-cfo-remote-login-modal-header-div" className="dev-cfo-remote-login-modal__header">Developer CFO Remote Login</div>
            </ModalHeader>
            <ModalBody>
                {acctMgmtStore.commonStore.isProcessingApi && (
                    <ProcessingApiSpinner/>
                )}
                <div>
                    <div id="dev-cfo-remote-login-modal">
                        <p className="dev-cfo-remote-login-label">
                            Enter the name of the CFO environment to login to:
                        </p>
                    </div>
                    <div id="dev-cfo-remote-login-button-div">
                        <TextInput
                            id="dev-cfo-remote-login-text-input"
                            value={acctMgmtStore.devCfoRemoteLoginEnv}
                            aria-invalid={!acctMgmtStore.validDevCfoRemoteLoginEnv}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                acctMgmtStore.onDevRemoteCfoLoginEnvChange(event.target.value)
                            }}
                        />
                        <InlineErrorMessage id="dev-cfo-remote-login-url-inline-error" errorMessage={acctMgmtStore.validDevCfoRemoteLoginEnvErrorMessage}/>
                        <div id="dev-cfo-remote-login-resolved-url">
                            <p id="dev-cfo-remote-login-resolved-heading" className="dev-cfo-remote-login-label">
                                Resolved Url:
                            </p>
                            <p>{acctMgmtStore.devCfoRemoteLoginResolvedUrl} </p>
                        </div>
                        <div id="dev-cfo-remote-login-button-div" className="dev-cfo-remote-login-button-div">
                            <Button
                                id="dev-cfo-remote-login-button"
                                size="small"
                                theme="blue"
                                disabled={acctMgmtStore.validDevCfoRemoteLoginEnvErrorMessage.length !== 0 || acctMgmtStore.devCfoRemoteLoginEnv === ""}
                                onClick={() => acctMgmtStore.handleDevCfoRemoteLogin()}
                            >
                                Login
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
});

export default DevCfoRemoteLoginModal;