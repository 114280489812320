import {observer} from "mobx-react";
import React, {useState} from "react";
import "../../../styles/main.scss";
import "./MoveUserModal.scss"
import {Modal, ModalBody, ModalFooter, ModalHeader} from "@carfax-stencils/modal";
import Store from "../../../stores";
import Button from "@carfax-stencils/button";
import {MoveUserModeEnum, MoveUserSectionEnum} from "../../../stores/acctMgmt/moveUser";
import TextInput from "@carfax-stencils/input-text";
import InlineErrorMessage from "../../../shared-components/layout/error/InlineErrorMessage";
// Auth group imports
import {DAM_Admin, DAM_Super_Admin} from "../../../types/groups";
// New Basic move user imports
import {Table, TBody, TD, TH, THead, TR} from "@carfax-stencils/table";
import {Search} from "@carfax-stencils/icon";
import { RadioPrimitive } from "@carfax-stencils/input-radio-group";
import {InfoCircleOutline} from '@carfax-stencils/icon';


export default observer( () => {

    const moveUserStore = Store.root.root.acctMgmtStore.moveUserStore
    const rootStore = Store.root.root;
    const authStore = rootStore.authStore;

    return (
        <Modal isVisible={true} onClose={moveUserStore.handleMoveUserCancel}>
            <ModalHeader>
                <div id="move-user-modal-header-div"  className="modal-header-title"> Move User</div>
            </ModalHeader>
            {authStore.groups?.includes(DAM_Super_Admin) || authStore.groups?.includes(DAM_Admin) ?
                <>
                    <ModalBody className="move-user-modal-body">
                        {moveUserStore.moveUserSection === MoveUserSectionEnum.Initial && (<MoveInitialSection />)}
                        {moveUserStore.moveUserSection === MoveUserSectionEnum.Confirm && (<ConfirmationSection />)}
                    </ModalBody>
                    <ModalFooter>
                        {moveUserStore.moveUserSection !== MoveUserSectionEnum.Initial &&
                            <Button
                                id="move-user-modal-back-button"
                                theme={"blue-outline"}
                                size="large"
                                onClick={moveUserStore.handleBackButton}
                            >
                                Back
                            </Button>}
                        {moveUserStore.moveUserSection !== MoveUserSectionEnum.Confirm &&
                            <Button
                                id="move-user-modal-next-button"
                                theme={"blue"}
                                size="large"
                                disabled={moveUserStore.disableNextButton()}
                                onClick={moveUserStore.handleNextButton}
                            >
                                Next Step
                            </Button> }
                        {moveUserStore.moveUserSection === MoveUserSectionEnum.Confirm && moveUserStore.moveUserMode === MoveUserModeEnum.Single &&
                            <Button
                                id="invite-user-modal-send-invite-button"
                                theme={"blue"}
                                size="large"
                                onClick={moveUserStore.handleMoveButton}
                            >
                                Move User
                            </Button> }
                        {moveUserStore.moveUserSection === MoveUserSectionEnum.Confirm && moveUserStore.moveUserMode === MoveUserModeEnum.Batch &&
                            <Button
                                id="invite-user-modal-send-invite-button"
                                theme={"blue"}
                                size="large"
                                onClick={moveUserStore.handleMoveUsersButton}
                            >
                                Move Users
                            </Button> }
                    </ModalFooter>
                </>
            :
            <>
                <ModalBody className={"modify-user-modal-body"}>
                    {moveUserStore.moveUserSection === MoveUserSectionEnum.Initial &&<BasicMoveUserModal/>}
                    {moveUserStore.moveUserSection === MoveUserSectionEnum.Confirm && (<ConfirmationSection />)}
                </ModalBody>
            
                <ModalFooter>
                    {moveUserStore.moveUserSection === MoveUserSectionEnum.Initial &&
                    <>
                        <Button
                            id="move-user-modal-basic-close-button"
                            theme={"blue-outline"}
                            size="large"
                            onClick={moveUserStore.handleMoveUserCancel}
                        >
                            Close
                        </Button>
                        <Button
                            id="move-user-modal-basic-next-button"
                            theme={"blue"}
                            size="large"
                            disabled={moveUserStore.disableNextButton()}
                            onClick={moveUserStore.handleNextButton}
                        >
                            Next Step
                        </Button>
                    </>
                    }
                    {moveUserStore.moveUserSection === MoveUserSectionEnum.Confirm &&
                    <>
                        <Button
                            id="move-user-modal-basic-back-button"
                            theme={"blue-outline"}
                            size="large"
                            onClick={moveUserStore.handleBackButton}
                        >
                            Back
                        </Button>
                        {moveUserStore.moveUserMode === MoveUserModeEnum.Single &&
                            <Button
                                id="move-user-modal-basic-move-button"
                                theme={"blue"}
                                size="large"
                                onClick={moveUserStore.handleMoveButton}
                            >
                                Move User
                            </Button>
                        }
                        {moveUserStore.moveUserMode === MoveUserModeEnum.Batch &&
                            <Button
                                id="move-user-modal-basic-move-button"
                                theme={"blue"}
                                size="large"
                                onClick={moveUserStore.handleMoveUsersButton}
                            >
                                Move Users
                            </Button>
                        }
                    </>
                    }
                </ModalFooter>
            </>
            }
        </Modal>
    )
});

const IneligibleUsersSelectedMessage= observer(() => {
    return (
        <div id="ineligible-users-section-container" className="ineligible-users-section__container">
            <div id="ineligible-users-section-div" className="ineligible-users-section__msg_div">
                <div id="ineligible-users-section-content" className="ineligible-users-section__content">
                    <div id="ineligible-users-section-icon-div">
                        <InfoCircleOutline size={30} className="ineligible-users-section__icon"/>
                    </div>
                    <div id="ineligible-users-section-msg-text" className="ineligible-users-section__msg-text">
                        Users in Invited or Reinvited status cannot be moved and will remain on this comp code.
                    </div>
                </div>
            </div>
        </div>
    )
});

const MoveInitialSection = observer(() => {

    const moveUserStore = Store.root.root.acctMgmtStore.moveUserStore
    moveUserStore.acctMgmtStore = Store.root.root.acctMgmtStore
    return (
        <div id="move-user-initial-section-div" className="move-user-modal">
            {moveUserStore.hasSelectedIneligibleUsersToMove() && <IneligibleUsersSelectedMessage />}
            <div className="move-user-modal__message">
                <div className="move-user-modal__oval">1</div>
                <div id="move-initial-section-message-div" className="move-user-modal__step-text">
                    Enter the dealership comp code you wish to move the user(s) to.
                </div>
            </div>
            <div className="move-user-modal__comp-code">
                <TextInput
                    id="move-user-modal-comp-code"
                    value={moveUserStore.toCompCode ? moveUserStore.toCompCode : ""}
                    onChange={moveUserStore.handleCompCodeEntry}
                    placeholder="Enter a Comp Code"
                    aria-invalid={!moveUserStore.isValidCompCode()}
                />
                <div id="move-user-modal-comp-code-inline-error-message">
                    <InlineErrorMessage id="move-user-confirmation-email-error"
                                        errorMessage={moveUserStore.compCodeErrorMessage}/>
                </div>
            </div>
        </div>)
});

const ConfirmationSection = observer(() => {

    const moveUserStore = Store.root.root.acctMgmtStore.moveUserStore
    const businessUser = Store.root.root.businessUserStore.businessUser

    return (
        <div id="move-user-confirmation-div" className="move-user-confirmation">
            <div className="move-user-modal__message">
                <div className="move-user-modal__oval">2</div>
                <div id="move-user-confirmation-message-div" className="move-user-modal__step-text">
                    Confirm user details and move user.
                </div>
            </div>
            <div id="move-user-confirmation-detail-div" className="move-user-confirmation__details">
                <table>
                    <tbody>
                    
                    <tr>
                        <td className="move-user-confirmation__column1">User:</td>
                        {moveUserStore.moveUserMode === MoveUserModeEnum.Single ?
                            <td className="move-user-confirmation__column2">{moveUserStore.selectedUser.firstName} {moveUserStore.selectedUser.lastName}</td>
                            :
                            <td className="move-user-confirmation__column2">{moveUserStore.selectedUsers.length} Users Selected</td>
                        }    
                    </tr>

                    <tr>
                        <td className="move-user-confirmation__column1">Current Location:</td>
                        <td className="move-user-confirmation__column2">{businessUser?.companyName} - {businessUser?.compCode}</td>
                    </tr>
                    <tr>
                        <td className="move-user-confirmation__column1">New Default Location:</td>
                        <td className="move-user-confirmation__column2">{moveUserStore.toBusinessUser?.companyName} - {moveUserStore.toBusinessUser?.compCode}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>)
});

const EmptyTopOwnerGroup = () => {

    return (
        <div id="move-modal-basic-empty-div" className="move-user-modal-basic__empty-body">
            <div className="move-user-modal-basic__empty-header">
                No Associated Locations Available
            </div>
            <div className="move-user-modal-basic__empty-text">
                This user does not have associated locations because
                their default location is a single store dealership.
            </div>
        </div>
    )
}

const BasicMoveUserModal = observer(() => {

    const moveUserStore = Store.root.root.acctMgmtStore.moveUserStore

    const [selectedOption, setSelectedOption] = useState(null)

    const handleOptionChange = (event: any) => {
        moveUserStore.handleTopOwnerGroupSelect(event)
        setSelectedOption(event.target.value)
    }

    return (
        <div id="move-user-modal-div">
            { moveUserStore.topOwnerGroup?.length > 0 &&
            <div id="move-user-modal-basic-body-div"  className="move-user-modal-basic__body">
                <div className="move-user-modal__message">
                    <div className="move-user-modal__oval">1</div>
                    <div id="move-user-modal-basic-info-div" className="move-user-modal__step-text">
                        Choose the top owner group or location to move the user(s) to.
                    </div>
                </div>
                <hr />
                <div className="move-user-modal-basic__search">
                    <TextInput
                        
                        id="move-user-modal-basic-location-search"
                        icon={<Search onClick={() => moveUserStore.searchTopOwnerGroup()} className='move-user-modal-basic__search-icon' size={23} /> }
                        name="dealerName"
                        placeholder="Search by Dealer Name..."
                        required={true}
                        isSmall={true}
                        type="text"
                        value={moveUserStore.topOwnerGroupFilterValue}
                        onChange={moveUserStore.onFilterChange}
                    />
                </div>
                <Table aria-label="Owner Group Comp Codes">
                    <THead>
                        <TH width={20}></TH>
                        <TH width={280}>
                            Dealer Name
                        </TH>
                        <TH width={80}>
                            Status
                        </TH>
                        <TH width={130}>
                            Location
                        </TH>
                    </THead>
                    <TBody>
                        {moveUserStore.filteredTopOwnerGroup.map((loc, key) => (
                            <TR key={key}>
                                <TD><RadioPrimitive onChange={event => handleOptionChange(event)} checked={selectedOption === loc.compCode} children="" value={loc.compCode} /></TD>
                                <TD>
                                    <span>
                                        {loc.companyName}
                                    </span>
                                    <span className="basic-move-user-label-text">
                                        {" - " + loc.compCode}
                                    </span>
                                </TD>
                                <TD>
                                    <div className="move-user-modal-basic__user-location-status">
                                        <div className={loc.status === "Active" ? "move-user-modal-basic__dealer-status-active" : "move-user-modal-basic__dealer-status-inactive"}></div>
                                        <div className="move-user-modal-basic__location-status-text">{loc.status}</div>
                                    </div>
                                </TD>
                                <TD>{loc.address.city}, {loc.address.state}</TD>
                            </TR>
                        ))}
                    </TBody>
                </Table>
            <div id="move-user-modal-basic-comp-code-inline-error-message" >
                <InlineErrorMessage id="move-user-basic-confirmation-email-error" errorMessage={moveUserStore.compCodeErrorMessage}/>
            </div>
            </div> }
            { moveUserStore.topOwnerGroup?.length === 0 &&
            <EmptyTopOwnerGroup/>}
        </div>
    );
});
