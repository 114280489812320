import {observer} from "mobx-react";
import {Email} from "../../types/email";
import TextInput from "@carfax-stencils/input-text";
import * as React from "react";
import {Trash} from "@carfax-stencils/icon";
import InlineErrorMessage from "../layout/error/InlineErrorMessage";
import {RadioGroup, RadioInput} from "@carfax-stencils/input-radio-group";
import {emailListingsType} from "../../types/profile/enums/emailListingsType";
import "./EnhancedEmail.scss";
import { DB_ACTION_UPDATE } from "src/utils/constants";

export const EnhancedEmailTextInput = observer((props: {email: Email, index: number, store:any, textInputRef?:any}) => {
    return (
        <div id={`${props.email.emailType}-email-div-${props.index}`} className="bottom-padding">
            <div className="enhanced-email-input-container">
                <TextInput
                    id={`${props.email.emailType}-email-${props.index}`}
                    className="left-padding enhanced-email-input-text"
                    value={props.email.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        props.store.onEmailChange(props.email.emailType,event.target.value, props.index);
                    }}
                    onBlur={() => {
                        props.store.onEmailBlur(props.email.emailType, props.index);
                    }}
                    type="text"
                    maxLength={300}
                    disabled={props.email.action === undefined || props.email.action === "" || props.email.action === DB_ACTION_UPDATE}
                    ref={props.textInputRef}
                    placeholder="Enter an email address"
                />

                <RadioGroup
                    id="enhanced-emails-listings-type-radio-group"
                    className="enhanced-email-listings-radio-group"
                    value={props.email.emailListings}
                    horizontal={true}
                    onChange={event => props.store.onEmailListingsTypeChange(props.email.emailType, event.target.value, props.index)}
                    name="email-listings-type-radio-group"
                >

                    <RadioInput value={emailListingsType.ALL} key={emailListingsType.ALL} className="all-car-listings-enhanced-radio-input" />
                    <RadioInput value={emailListingsType.USED_ONLY} key={emailListingsType.USED_ONLY} className="used-car-listings-enhanced-radio-input" />
                    <RadioInput value={emailListingsType.NEW_ONLY} key={emailListingsType.NEW_ONLY} className="new-car-listings-enhanced-radio-input" />

                </RadioGroup>


                <Trash
                    id={`${props.email.emailType}-email-remove-button-${props.index}`}
                    size={40}
                    className="email-remove-button"
                    onClick={() => {
                        props.store.onEmailRemove(props.email.emailType, props.index)
                    }}
                    title="Remove Email"
                />
            </div>
            <InlineErrorMessage id={`${props.email.emailType}-email-inline-error-${props.index}`} errorMessage={!props.email.inlineErrorMsg ? "" : props.email.inlineErrorMsg}/>
        </div>
    )
});