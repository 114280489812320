import * as React from "react";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import Button from "@carfax-stencils/button";
import './ConfirmModal.scss';
import {ButtonThemeType} from "@carfax-stencils/button";

export type ConfirmProps = {
    confirmButtonMessage: string;
    headerTitle: string;
    isVisible: boolean;
    onCloseMethod: (confirm?: boolean | undefined) => void;
    confirmModalMessageJsx: JSX.Element;
    theme: ButtonThemeType;
}

const ConfirmModal = ((props: ConfirmProps ) => {

    return (
        <Modal
            id="confirm-modal"
            isVisible={true}
            className="confirmation-modal"
            onClose={() => props.onCloseMethod(false)}
        >
            <ModalHeader>
                <div className="confirmation-modal__header">{props.headerTitle}</div>
            </ModalHeader>
            <ModalBody>
                {props.confirmModalMessageJsx}
            </ModalBody>
            <ModalFooter
                hideSeparator={true}
            >
                 <Button
                     id="confirm-modal-agree-button"
                     size="large"
                     theme={props.theme}
                     onClick={() => props.onCloseMethod(true)}
                 >
                     {props.confirmButtonMessage}
                 </Button>
                 <Button
                     id="confirm-modal-cancel-button"
                     size="large"
                     theme="black"
                     onClick={() => props.onCloseMethod(false)}
                 >
                     <>Cancel</>
                 </Button>
            </ModalFooter>
        </Modal>
    )

});

export default ConfirmModal;
