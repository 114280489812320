import { Modal, ModalHeader, ModalBody } from '@carfax-stencils/modal';
import { observer } from "mobx-react";
import React from "react";
import Store from "../../../../stores";

export default observer(() => {

    const dealerDetailsStore = Store.profile.dealerDetails;

    return (
        <Modal
            id="preview-image-modal"
            isVisible={true}
            onClose={dealerDetailsStore.handlePreviewImageModalClose}>
            <ModalHeader>
                <div id="preview-image-modal-header-div" className="modal-header-title">Image Preview</div>
            </ModalHeader>
            <ModalBody>
                <div className="preview-image-div">
                    <img
                        src={dealerDetailsStore.photo.url}
                        className="preview-image"
                        alt="preview"
                    />
                </div>
            </ModalBody>
        </Modal>
    );
});
