import * as React from "react";
import "./CallMgmtSettings.scss";
import Button from "@carfax-stencils/button";
import { PhoneInput } from "@carfax-stencils/input-text";
import Store from "../../../stores";
import TextInput from "@carfax-stencils/input-text";
import {observer} from "mobx-react";
import {Plus, Trash} from "@carfax-stencils/icon";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import InlineErrorMessage from "../../../shared-components/layout/error/InlineErrorMessage";
import {Email} from "../../../types/email";
import {Accordion, Expander, ExpanderBody, ExpanderHeader} from "@carfax-stencils/accordion";
import {CheckboxToggle} from "@carfax-stencils/input-checkbox";
import {DB_ACTION_DELETE} from "../../../utils/constants";
import {EmailTextInput} from "../../../shared-components/email/EmailTextInput";
import {emailTypeEnum} from "../../../types/profile/enums/emailTypeEnum";
import {twilioPhoneDataType} from "../../../types/profile/enums/twilioPhoneDataType";
import {useEffect} from "react";
import {InfoCircleOutline} from '@carfax-stencils/icon'
import {EnhancedEmailTextInput} from "../../../shared-components/email/EnhancedEmailTextInput";
import {EnhancedEmailHeader} from "../../../shared-components/email/EnhancedEmailHeader";
import {
    NEW_LISTINGS_RING_TO_FIELD_NAME,
    SMC_RING_TO_FIELD_NAME, SRV_APPT_RING_TO_FIELD_NAME,
    UCL_RING_TO_FIELD_NAME
} from "../../../stores/common/common";

// Props
interface CallMgmtSettingsTabProps {
    compCode:any
}

// Stores
const rootStore = Store.root.root;
const authStore = rootStore.authStore;
const businessUserStore = rootStore.businessUserStore;
const callMgmtSettingsStore = rootStore.callMgmtSettingsStore;
const commonStore = rootStore.commonStore;
const uclSettingsStore = rootStore.uclSettingsStore;


export default observer (
    class CallMgmtSettingsTab extends React.Component<CallMgmtSettingsTabProps> {

        async componentDidMount() {
            this.setState({ready:true});
            await this.loadData();
        };

        async componentDidUpdate() {
            if(businessUserStore.businessUser?.compCode !== callMgmtSettingsStore.compCode) {
                await this.loadData();
            };
        };

        private async loadData() {
            commonStore.processingApiCall(true)
            await uclSettingsStore.getUclSettings();
            await callMgmtSettingsStore.getTwilioPhoneData();
            await callMgmtSettingsStore.getEmails();
            commonStore.processingApiCall(false);
        };

        private focusOnEmailAfterAdd() {
            if(callMgmtSettingsStore.focusOnEmailTextInput) {
                callMgmtSettingsStore.emailInputRef?.current?.focus();
                callMgmtSettingsStore.focusOnEmailTextInput = false;
            }
        }

        render() {
            return(
                <div>
                    <div>
                        {commonStore.isProcessingApi && (
                            <ProcessingApiSpinner/>
                        )}
                        <div id="call-mgmt-settings-tab-div" className="call-mgmt-tab-div">
                            <p id="call-mgmt-settings-tab-main-heading" className="main-heading">
                                Call Management Settings
                            </p>
                        </div>
                    </div>
                    <Accordion>
                        <Expander id="ucl-accordion-expander" open={true}>
                            <ExpanderHeader className="call-mgmt-blue-sub-heading">Car Listings</ExpanderHeader>
                            <ExpanderBody>
                                <this.usedCarListingSection/>
                            </ExpanderBody>
                        </Expander>
                    </Accordion>
                    <div className="call-mgmt-accordion-spacer-div"/>
                    <Accordion>
                        <Expander id="til-accordion-expander" open={true}>
                            <ExpanderHeader className="call-mgmt-blue-sub-heading">Trade-In Leads</ExpanderHeader>
                            <ExpanderBody>
                                <this.tradeInLeadsSection/>
                            </ExpanderBody>
                        </Expander>
                    </Accordion>
                    <div className="call-mgmt-accordion-spacer-div"/>
                    <Accordion>
                        <Expander id="serv-appt-accordion-expander" open={true}>
                            <ExpanderHeader className="call-mgmt-blue-sub-heading">Service Appointment</ExpanderHeader>
                            <ExpanderBody>
                                <this.serviceApptSection/>
                            </ExpanderBody>
                        </Expander>
                    </Accordion>
                    <div className="call-mgmt-accordion-spacer-div"/>
                </div>
            );
        }

        usedCarListingSection = observer( () => {

            useEffect(() => {
                this.focusOnEmailAfterAdd();
            });

            return(
                <div id="call-mgmt-ucl-section-div">
                    {/*  "Ring-to Phone Number" Section   */}
                    <p id="ucl-ring-to-phone-sub-heading" className="sub-heading">
                        Ring-to Phone Number
                    </p>
                    <div id="ucl-ring-to-phone-description-div" className="left-padding">
                        <p id="ucl-ring-to-phone-description-text" className="primary_text">
                            The monitored dealer phone number for all leads.
                        </p>
                    </div>
                    { businessUserStore.isDealerNew() &&
                        <div id="ucl-ring-to-phone-toggle-div" className="left-padding top-padding inline-flex">
                            <p id="ucl-ring-to-phone-toggle-label" className="toggle-label">Use separate phone numbers for used & new car listings</p>
                            <CheckboxToggle
                                id="ucl-ring-to-phone-toggle"
                                checked={callMgmtSettingsStore.isRingToToggled === true ? true : false}
                                onChange={event => {
                                    callMgmtSettingsStore.onToggleRingTo(event.target.checked)
                                }}
                                disabled={authStore.isNonAdminGroup()}
                            />
                        </div>
                    }
                    { !callMgmtSettingsStore.isRingToToggled &&
                        <div>
                            <div id="ucl-ring-to-phone-input-div"  className="left-padding">
                                <p id="ucl-ring-to-phone-used-label-text" className="left-padding input-label">
                                    Enter Phone Number
                                </p>
                                <div className="ucl-ring-to-phone-input-div">
                                    <PhoneInput
                                        id="ucl-ring-to-phone-input-text"
                                        value={callMgmtSettingsStore.uclTwilioPhoneData?.ringToPhoneNumber}
                                        isRawValue={true}
                                        placeholder={"Enter Ring-To Number"}
                                        aria-invalid={!callMgmtSettingsStore.isValidPhoneNumber(callMgmtSettingsStore.uclTwilioPhoneData?.ringToPhoneNumber!, twilioPhoneDataType.UCL)}
                                        onChange={(event) => {
                                            callMgmtSettingsStore.uclRingToPhoneHandler(event.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div id="ucl-ring-to-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                                <InlineErrorMessage id="ucl-ring-to-number-error" errorMessage={commonStore.getFormError(UCL_RING_TO_FIELD_NAME)}/>
                            </div>
                        </div>
                    }

                    { callMgmtSettingsStore.isRingToToggled &&
                        <div id="ucl-tracking-num-box-div" className="new-car-listing-box">
                            <p id="ucl-ring-to-phone-used-description-text" className="sub-heading">
                                Used Car Listings Only
                            </p>
                            <p id="ucl-ring-to-phone-used-label-text" className="left-padding input-label required-field">
                                Enter Phone Number
                            </p>
                            <div id="ucl-ring-to-phone-used-input-div"  className="left-padding call-mgmt-ring-to-num">
                                <PhoneInput
                                    id="ucl-ring-to-phone-used-input-text"
                                    value={callMgmtSettingsStore.uclTwilioPhoneData?.ringToPhoneNumber}
                                    isRawValue={true}
                                    placeholder={"Enter Ring-To Number"}
                                    aria-invalid={!callMgmtSettingsStore.isValidPhoneNumber(callMgmtSettingsStore.uclTwilioPhoneData.ringToPhoneNumber!, twilioPhoneDataType.UCL)}
                                    onChange={(event) => {
                                        callMgmtSettingsStore.uclRingToPhoneHandler(event.target.value)
                                    }}
                                />
                            </div>
                            <div id="ucl-ring-to-used-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                                <InlineErrorMessage id="ucl-ring-to-used-number-error" errorMessage={commonStore.getFormError(UCL_RING_TO_FIELD_NAME)}/>
                            </div>

                            <hr id="ucl-tracking-num-separator-line" className="call-mgmt-separator-line"/>

                            <p id="ucl-tracking-num-new-description-text" className="sub-heading">
                                New Car Listings Only
                            </p>
                            <p id="ucl-ring-to-phone-new-label-text" className="left-padding input-label required-field">
                                Enter Phone Number
                            </p>
                            <div id="ucl-ring-to-phone-new-input-div"  className="left-padding call-mgmt-ring-to-num">
                                <PhoneInput
                                    id="ucl-ring-to-phone-new-input-text"
                                    value={callMgmtSettingsStore.newListingsTwilioPhoneData?.ringToPhoneNumber}
                                    isRawValue={true}
                                    placeholder={"Enter Ring-To Number"}
                                    aria-invalid={!callMgmtSettingsStore.isValidPhoneNumber(callMgmtSettingsStore.newListingsTwilioPhoneData.ringToPhoneNumber!, twilioPhoneDataType.NEWLISTINGS)}
                                    onChange={(event) => {
                                        callMgmtSettingsStore.newListingsRingToPhoneHandler(event.target.value)
                                    }}
                                />
                            </div>
                            <div id="ucl-ring-to-new-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                                <InlineErrorMessage id="ucl-ring-to-new-number-error" errorMessage={commonStore.getFormError(NEW_LISTINGS_RING_TO_FIELD_NAME)}/>
                            </div>
                        </div>
                    }

                    {/*  " UCL Tracking Phone Number " Section  */}
                    <br/>
                    <hr id="ucl-tracking-num-separator-line" className="call-mgmt-separator-line"/>
                    <p id="ucl-tracking-num-sub-heading" className="sub-heading">
                        Tracking Phone Number
                    </p>
                    <div id="ucl-tracking-num-desc-div" className="left-padding">
                        <p id="ucl-tracking-num-desc-text" className="primary_text">
                            The CARFAX tracking number that appears on Car Listings.
                        </p>
                    </div>
                    <div id="ucl-tracking-num-opt-out-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="ucl-tracking-num-opt-out-toggle-label" className="toggle-label primary_label_text__bold">Dealer opted out of phone tracking</p>
                        <CheckboxToggle
                            id="ucl-tracking-num-opt-out-toggle"
                            checked={callMgmtSettingsStore?.uclTwilioPhoneData?.disableTrackingNumber === true ? true : false}
                            onChange={event => {
                                callMgmtSettingsStore.onToggleOptOutUclTracking(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>
                    <p id="ucl-tracking-num-opt-out-used-desc-text" className="left-padding primary_text">
                        When on, calls will ring directly to the Ring-to Phone Number.
                    </p>


                    { callMgmtSettingsStore.uclTwilioPhoneData?.disableTrackingNumber &&
                        <div id="ucl-no-tracking-num-used-warning-div"
                             className="no-tracking-number-warning-div">
                            <InfoCircleOutline size={25} className="info-circle-outline"/>
                            <p id="ucl-no-tracking-num-used-warning-text"
                               className="no-tracking-number-warning-text">
                                When opted out, CARFAX cannot track and record phone leads.
                            </p>
                        </div>
                    }

                    { !businessUserStore.isDealerNew() &&
                     <>
                         <div id="ucl-tracking-num-div" className="top-padding">
                             <div id="ucl-tracking-num-add-remove-div">
                                 { !callMgmtSettingsStore.uclTwilioPhoneData?.trackingPhoneNumber &&
                                     <div id="ucl-tracking-num-add-div">
                                         <Button
                                             id="ucl-tracking-num-add-button" className="tracking-num-add-button"
                                             onClick={(event: any) => {
                                                 callMgmtSettingsStore.handleProvisionModal(twilioPhoneDataType.UCL)
                                             }}
                                             iconLeft={<Plus aria-hidden/>}
                                             size="medium"
                                             disabled={callMgmtSettingsStore.disableUclAddTracking()}
                                             theme="blue-outline">
                                             Add Tracking Number
                                         </Button>
                                     </div>
                                 }
                                 {  callMgmtSettingsStore.uclTwilioPhoneData?.trackingPhoneNumber &&
                                     <div id="ucl-tracking-num-remove-div">
                                         <p id="ucl-tracking-number-input-label" className="left-padding primary_label_text__bold">Tracking Phone Number</p>
                                         <div className="trash-can-area">
                                             <div id="ucl-tracking-num-remove-text-input-div" className="call-mgmt-phone-num">
                                                 <TextInput
                                                     id="ucl-tracking-num-remove-input-text"
                                                     className="call-mgmt-phone-num"
                                                     value={callMgmtSettingsStore.displayTrackingNumber(callMgmtSettingsStore.uclTwilioPhoneData.trackingPhoneNumber)}
                                                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                     }}
                                                     disabled={true}
                                                     maxLength={10}
                                                 />
                                             </div>
                                             <Trash
                                                 id="ucl-tracking-num-remove-trash-can"
                                                 size={40}
                                                 className={(authStore.isNonAdminGroup()) ? "tracking-num-remove-button-disabled" : "tracking-num-remove-button"}
                                                 onClick={() => {
                                                     callMgmtSettingsStore.handleDeleteModel(twilioPhoneDataType.UCL);
                                                 }}
                                                 title="Remove Tracking Number"
                                             />
                                             <div id="ucl-tracking-num-remove-label-div" className="trash-label-text">
                                                 Delete Tracking Number
                                             </div>
                                         </div>
                                     </div>
                                 }
                             </div>
                         </div>
                         { callMgmtSettingsStore.uclTwilioPhoneData?.trackingPhoneNumber && callMgmtSettingsStore.uclTwilioPhoneData?.displayDate &&
                             <div id="ucl-tracking-num-display-provision-date-div" className="left-padding display-provision-date-div">
                                 Created on: {callMgmtSettingsStore.uclTwilioPhoneData.displayDate} CST
                             </div>
                         }
                         <div id="ucl-tracking-number-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                             <InlineErrorMessage id="ucl-enhanced-tracking-num-error" errorMessage={callMgmtSettingsStore.uclTrackingNumberMessage}/>
                         </div>
                     </>
                    }

                    { businessUserStore.isDealerNew() &&
                        <div id="ucl-tracking-num-box-div" className="new-car-listing-box">
                            <p id="ucl-tracking-num-used-description-text" className="sub-heading">
                                Used Car Listings Only
                            </p>
                            <div id="ucl-tracking-num-used-div" className="top-padding">
                                <div id="ucl-tracking-num-used-add-remove-div">
                                    { !callMgmtSettingsStore.uclTwilioPhoneData?.trackingPhoneNumber &&
                                        <div id="ucl-tracking-num-used-add-div">
                                            <Button
                                                id="ucl-tracking-num-used-add-button" className="tracking-num-add-button"
                                                onClick={(event: any) => {
                                                    callMgmtSettingsStore.handleProvisionModal(twilioPhoneDataType.UCL)
                                                }}
                                                iconLeft={<Plus aria-hidden/>}
                                                size="medium"
                                                disabled={callMgmtSettingsStore.disableUclAddTracking()}
                                                theme="blue-outline">
                                                Add Tracking Number
                                            </Button>
                                        </div>
                                    }
                                    {  callMgmtSettingsStore.uclTwilioPhoneData?.trackingPhoneNumber &&
                                        <div id="ucl-tracking-num-used-remove-div">
                                            <p id="ucl-tracking-number-used-input-label" className="left-padding primary_label_text__bold">Tracking Phone Number</p>
                                            <div className="trash-can-area">
                                                <div id="ucl-tracking-num-used-remove-text-input-div" className="call-mgmt-phone-num">
                                                    <TextInput
                                                        id="ucl-tracking-num-used-remove-input-text"
                                                        className="call-mgmt-phone-num"
                                                        value={callMgmtSettingsStore.displayTrackingNumber(callMgmtSettingsStore.uclTwilioPhoneData.trackingPhoneNumber)}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        }}
                                                        disabled={true}
                                                        maxLength={10}
                                                    />
                                                </div>
                                                <Trash
                                                    id="ucl-tracking-num-used-remove-trash-can"
                                                    size={40}
                                                    className={(authStore.isNonAdminGroup()) ? "tracking-num-remove-button-disabled" : "tracking-num-remove-button"}
                                                    onClick={() => {
                                                        callMgmtSettingsStore.handleDeleteModel(twilioPhoneDataType.UCL);
                                                    }}
                                                    title="Remove Tracking Number"
                                                />
                                                <div id="ucl-tracking-num-remove-label-div" className="trash-label-text">
                                                    Delete Tracking Number
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            { callMgmtSettingsStore.uclTwilioPhoneData?.trackingPhoneNumber && callMgmtSettingsStore.uclTwilioPhoneData?.displayDate &&
                                <div id="ucl-tracking-num-used-display-provision-date-div" className="left-padding display-provision-date-div">
                                    Created on: {callMgmtSettingsStore.uclTwilioPhoneData.displayDate} CST
                                </div>
                            }
                            <div id="ucl-tracking-number-used-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                                <InlineErrorMessage id="ucl-enhanced-tracking-num-used-error" errorMessage={callMgmtSettingsStore.uclTrackingNumberMessage}/>
                            </div>

                            <hr id="ucl-tracking-num-separator-line" className="call-mgmt-separator-line"/>

                            <p id="ucl-tracking-num-new-description-text" className="sub-heading">
                                New Car Listings Only
                            </p>
                            <div id="ucl-tracking-num-new-div" className="top-padding">
                                <div id="ucl-tracking-num-new-add-remove-div">
                                    { !callMgmtSettingsStore.newListingsTwilioPhoneData?.trackingPhoneNumber &&
                                        <div id="ucl-tracking-num-new-add-div">
                                            <Button
                                                id="ucl-tracking-num-new-add-button" className="tracking-num-add-button"
                                                onClick={(event: any) => {
                                                    callMgmtSettingsStore.handleProvisionModal(twilioPhoneDataType.NEWLISTINGS)
                                                }}
                                                iconLeft={<Plus aria-hidden/>}
                                                size="medium"
                                                disabled={callMgmtSettingsStore.disableUclAddTracking()}
                                                theme="blue-outline">
                                                Add Tracking Number
                                            </Button>
                                        </div>
                                    }
                                    {  callMgmtSettingsStore.newListingsTwilioPhoneData?.trackingPhoneNumber &&
                                        <div id="ucl-tracking-num-new-remove-div">
                                            <p id="ucl-tracking-number-new-input-label" className="left-padding primary_label_text__bold">Tracking Phone Number</p>
                                            <div className="trash-can-area">
                                                <div id="ucl-tracking-num-new-remove-text-input-div" className="call-mgmt-phone-num">
                                                    <TextInput
                                                        id="ucl-tracking-num-new-remove-input-text"
                                                        className="call-mgmt-phone-num"
                                                        value={callMgmtSettingsStore.displayTrackingNumber(callMgmtSettingsStore.newListingsTwilioPhoneData.trackingPhoneNumber)}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        }}
                                                        disabled={true}
                                                        maxLength={10}
                                                    />
                                                </div>
                                                <Trash
                                                    id="ucl-tracking-num-new-remove-trash-can"
                                                    size={40}
                                                    className={(authStore.isNonAdminGroup()) ? "tracking-num-remove-button-disabled" : "tracking-num-remove-button"}
                                                    onClick={() => {
                                                        callMgmtSettingsStore.handleDeleteModel(twilioPhoneDataType.NEWLISTINGS);
                                                    }}
                                                    title="Remove Tracking Number"
                                                />
                                                <div id="ucl-tracking-num-remove-label-div" className="trash-label-text">
                                                    Delete Tracking Number
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            { callMgmtSettingsStore.newListingsTwilioPhoneData?.trackingPhoneNumber && callMgmtSettingsStore.newListingsTwilioPhoneData?.displayDate &&
                                <div id="ucl-tracking-num-new-display-provision-date-div" className="left-padding display-provision-date-div">
                                    Created on: {callMgmtSettingsStore.newListingsTwilioPhoneData.displayDate} CST
                                </div>
                            }
                            <div id="ucl-tracking-number-new-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                                <InlineErrorMessage id="ucl-enhanced-tracking-num-new-error" errorMessage={callMgmtSettingsStore.newListingsTrackingNumberMessage}/>
                            </div>
                        </div>
                    }


                    {/*  "UCL Call Recording Consent " Section   */}
                    <p id="ucl-call-recording-consent-sub-heading" className="sub-heading">
                        Call Recording Consent
                    </p>
                    <div id="ucl-call-recording-consent-desc-div" className="left-padding">
                        <p id="ucl-call-recording-consent-desc-text" className="primary_text">
                            When enabled, CARFAX has permission to record calls to the tracking phone number.
                        </p>
                    </div>
                    <div id="ucl-call-recording-consent-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="ucl-call-recording-consent-toggle-label" className="toggle-label">Enable Call Recording</p>
                        <CheckboxToggle
                            id="ucl-call-recording-consent-toggle"
                            checked={callMgmtSettingsStore?.uclTwilioPhoneData?.callAlertConsent === true ? true : false}
                            onChange={event => {
                                callMgmtSettingsStore.onToggleCallAlertConsent(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>

                    {/*  "CCL ADF Call Alert Emails " Section   */}
                    <div id="ucl-adf-alert-emails-separator-line-div" className="top-padding">
                        <hr id="ucl-adf-alert-emails-separator-line" className="call-mgmt-separator-line"/>
                    </div>
                    <p id="ucl-adf-alert-emails-sub-heading" className="sub-heading">
                        CCL ADF Call Alert Email(s)
                    </p>
                    <div id="ucl-adf-alert-emails-description-div" className="left-padding">
                        <p id="ucl-adf-alert-emails-description-text" className="primary_text">
                            This is a list of email addresses that receive ADF Call alerts.
                        </p>
                    </div>

                    {!businessUserStore.isDealerNew() && (
                        <div id="ucl-adf-alert-emails-div" className="email-input">
                            {callMgmtSettingsStore.callAlertAdfEmails?.map((email:Email, index:number) => (
                                email.action !== DB_ACTION_DELETE &&
                                <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={callMgmtSettingsStore}
                                                textInputRef={callMgmtSettingsStore.emailInputRef}/>))
                            }
                        </div>
                    )}

                    {businessUserStore.isDealerNew() && (
                        <div id="ucl-adf-alert-emails-div-enhanced" className="enhanced-email-input-row">
                            {callMgmtSettingsStore.hasCallAlertAdfEmails() &&
                                <EnhancedEmailHeader/>
                            }
                            {callMgmtSettingsStore.callAlertAdfEmails?.map((email:Email, index:number) => (
                                email.action !== DB_ACTION_DELETE &&
                                <EnhancedEmailTextInput key={`EnhancedEmailComponent-${index}`} email={email} index={index} store={callMgmtSettingsStore}
                                                                                                textInputRef={callMgmtSettingsStore.emailInputRef}/>))
                            }
                        </div>
                    )}

                    <div id="ucl-adf-alert-emails-add-button-div" className="left-padding email-input">
                        <Button
                            id="ucl-adf-alert-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add ADF Call Alert Email`}
                            onClick={() => {
                                callMgmtSettingsStore.onEmailAdd(emailTypeEnum.ADF_CALL_ALERT)
                            }}
                            theme="blue-outline"
                        />
                    </div>

                    {/*  "CCL Text Call Alert Emails " Section   */}
                    <div id="ucl-txt-alert-emails-separator-line-div" className="top-padding">
                        <hr id="ucl-txt-alert-emails-separator-line" className="call-mgmt-separator-line"/>
                    </div>
                    <p id="ucl-txt-alert-emails-sub-heading" className="sub-heading">
                        CCL Text Call Alert Email(s)
                    </p>
                    <div id="ucl-txt-alert-emails-description-div" className="left-padding">
                        <p id="ucl-txt-alert-emails-description-text" className="primary_text">
                            This is a list of email addresses that receive Text Call alerts.
                        </p>
                    </div>

                    {!businessUserStore.isDealerNew() && (
                        <div id="ucl-txt-alert-emails-div" className="email-input">
                            {callMgmtSettingsStore.callAlertTextEmails?.map((email:Email, index:number) => (
                                email.action !== DB_ACTION_DELETE &&
                                <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={callMgmtSettingsStore}
                                                textInputRef={callMgmtSettingsStore.emailInputRef}/>))
                            }
                        </div>
                    )}
                    
                    {businessUserStore.isDealerNew() && (
                        <div id="ucl-text-alert-emails-div-enhanced" className="enhanced-email-input-row">
                            {callMgmtSettingsStore.hasCallAlertTextEmails() &&
                                <EnhancedEmailHeader/>
                            }
                            {callMgmtSettingsStore.callAlertTextEmails?.map((email:Email, index:number) => (
                                email.action !== DB_ACTION_DELETE &&
                                <EnhancedEmailTextInput key={`EnhancedEmailComponent-${index}`} email={email} index={index} store={callMgmtSettingsStore}
                                                                                                textInputRef={callMgmtSettingsStore.emailInputRef}/>))
                            }
                        </div>
                    )}

                    <div id="ucl-txt-alert-emails-add-button-div" className="left-padding bottom-padding email-input">
                        <Button
                            id="ucl-txt-alert-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add Text Call Alert Email`}
                            onClick={() => {
                                callMgmtSettingsStore.onEmailAdd(emailTypeEnum.TEXT_CALL_ALERT)
                            }}
                            theme="blue-outline"
                        />
                    </div>
                </div>
            );
        });

        tradeInLeadsSection = observer( () => {

            useEffect(() => {
                this.focusOnEmailAfterAdd();
            });

            return(
                <div id="til-section-div">
                    {/*  "Trade-In Leads Ring-to Phone Number" Section   */}
                    <p id="til-ring-to-phone-sub-heading" className="sub-heading">
                        Ring-to Phone Number
                    </p>
                    <div id="til-ring-to-phone-description-div" className="left-padding">
                        <p id="til-ring-to-phone-description-text" className="primary_text">
                            The monitored dealer phone number for all leads.
                        </p>
                    </div>
                    <div id="til-ring-to-phone-input-div"  className="left-padding call-mgmt-ring-to-num">
                        <PhoneInput
                            id="til-ring-to-phone-input-text"
                            value={callMgmtSettingsStore.smcTwilioPhoneData?.ringToPhoneNumber}
                            isRawValue={true}
                            placeholder={"Enter Ring-To Number"}
                            aria-invalid={!callMgmtSettingsStore.isValidPhoneNumber(callMgmtSettingsStore.smcTwilioPhoneData?.ringToPhoneNumber!, twilioPhoneDataType.SMC)}
                            onChange={(event) => {
                                callMgmtSettingsStore.smcRingToPhoneHandler(event.target.value)
                            }}
                        />
                    </div>
                    <div id="til-ring-to-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                        <InlineErrorMessage id="ucl-ring-to-number-error" errorMessage={commonStore.getFormError(SMC_RING_TO_FIELD_NAME)}/>
                    </div>

                    {/*  "Trade-In Leads Tracking Phone Number" Section   */}
                    <hr id="til-tracking-num-separator-line" className="call-mgmt-separator-line"/>
                    <p id="til-tracking-num-sub-heading" className="sub-heading">
                        Tracking Phone Number
                    </p>
                    <div id="til-tracking-num-desc-div" className="left-padding">
                        <p id="til-tracking-num-desc-text" className="primary_text">
                            The CARFAX tracking number that appears at Trade-In lead opportunities.
                        </p>
                    </div>
                    <div id="til-tracking-num-opt-out-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="til-tracking-num-opt-out-toggle-label" className="toggle-label primary_label_text__bold">Dealer opted out of phone tracking</p>
                        <CheckboxToggle
                            id="til-tracking-num-opt-out-toggle"
                            checked={callMgmtSettingsStore?.smcTwilioPhoneData?.disableTrackingNumber === true ? true : false}
                            onChange={event => {
                                callMgmtSettingsStore.onToggleOptOutTilTracking(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>
                    <p id="til-tracking-num-opt-out-desc-text" className="left-padding primary_text">
                        When on, the Tracking Phone Number will be deleted and calls will ring directly to the Ring-to Phone Number.
                    </p>
                    <div id="til-tracking-num-div" className="top-padding">
                        <div id="til-tracking-num-add-remove-div">
                            { !callMgmtSettingsStore.smcTwilioPhoneData?.trackingPhoneNumber &&
                                <div id="til-tracking-num-add-div">
                                    {callMgmtSettingsStore.smcTwilioPhoneData?.disableTrackingNumber &&
                                        <div id="til-no-tracking-num-warning-div"
                                             className="no-tracking-number-warning-div">
                                            <InfoCircleOutline size={25} className="info-circle-outline"/>
                                            <p id="til-no-tracking-num-warning-text"
                                               className="no-tracking-number-warning-text">
                                                When opted out, CARFAX cannot track and record phone leads.
                                            </p>
                                        </div>
                                    }
                                    <Button
                                        id="til-tracing-num-add-button" className="tracking-num-add-button"
                                        onClick={(event: any) => {
                                            callMgmtSettingsStore.handleProvisionModal(twilioPhoneDataType.SMC)
                                        }}
                                        disabled={callMgmtSettingsStore.disableSmcAddTracking()}
                                        iconLeft={<Plus aria-hidden/>}
                                        size="medium"
                                        theme="blue-outline">
                                        Add Tracking Number
                                    </Button>
                                </div>
                            }
                            {  callMgmtSettingsStore.smcTwilioPhoneData?.trackingPhoneNumber &&
                                <div id="til-tracking-num-remove-div">
                                    <p id="til-tracking-number-input-label" className="left-padding primary_label_text__bold">Tracking Phone Number</p>
                                    <div className="trash-can-area">
                                        <div id="til-tracking-num-remove-text-input-div" className="call-mgmt-phone-num">
                                            <TextInput
                                                id="til-tracking-num-remove-input-text"
                                                className="call-mgmt-phone-num"
                                                value={callMgmtSettingsStore.displayTrackingNumber(callMgmtSettingsStore.smcTwilioPhoneData.trackingPhoneNumber)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                }}
                                                disabled={true}
                                                maxLength={10}
                                            />
                                        </div>
                                        <Trash
                                            id="til-tracking-num-remove-trash-can"
                                            size={40}
                                            className={(authStore.isNonAdminGroup()) ? "tracking-num-remove-button-disabled" : "tracking-num-remove-button"}
                                            onClick={() => {
                                                callMgmtSettingsStore.handleDeleteModel(twilioPhoneDataType.SMC);
                                            }}
                                            title="Remove Tracking Number"
                                        />
                                        <div id="til-tracking-num-remove-label-div" className="trash-label-text" >
                                            Delete Tracking Number
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {callMgmtSettingsStore.smcTwilioPhoneData.trackingPhoneNumber && callMgmtSettingsStore.smcTwilioPhoneData.displayDate &&
                        <div id="til-display-provision-date-div" className="left-padding display-provision-date-div">
                            Created on: {callMgmtSettingsStore.smcTwilioPhoneData.displayDate} CST
                        </div>
                    }
                    <div id="til-tracking-number-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                        <InlineErrorMessage id="til-tracking-num-error" errorMessage={callMgmtSettingsStore.smcTrackingNumberMessage}/>
                    </div>

                    {/*  "Trade-In Leads Call Recording Consent" Section   */}
                    <hr id="til-call-recording-consent-separator-line" className="call-mgmt-separator-line"/>
                    <p id="til-call-recording-consent-sub-heading" className="sub-heading">
                        Call Recording Consent
                    </p>
                    <div id="til-call-recording-consent-desc-div" className="left-padding">
                        <p id="til-call-recording-consent-desc-text" className="primary_text">
                            When enabled, CARFAX has permission to record calls to the tracking phone number.
                        </p>
                    </div>
                    <div id="til-call-recording-consent-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="til-call-recording-consent-toggle-label" className="toggle-label">Enable Call Recording</p>
                        <CheckboxToggle
                            id="til-call-recording-consent-toggle"
                            label="On"
                            checked={callMgmtSettingsStore?.smcTwilioPhoneData?.callAlertConsent === true ? true : false}
                            onChange={event => {
                                callMgmtSettingsStore.onToggleSmcCallAlertConsent(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>

                    {/*  "Trade-In Leads ADF Call Alert Emails" Section   */}
                    <div id="til-adf-alert-emails-separator-line-div" className="top-padding">
                        <hr id="til-adf-alert-emails-separator-line" className="call-mgmt-separator-line"/>
                    </div>
                    <p id="til-adf-alert-emails-sub-heading" className="sub-heading">
                        Trade-In Leads ADF Call Alert Email(s)
                    </p>
                    <div id="til-adf-alert-emails-description-div" className="left-padding">
                        <p id="til-adf-alert-emails-description-text" className="primary_text">
                            This is a list of email addresses that receive ADF Call alerts.
                        </p>
                    </div>
                    <div id="til-adf-alert-emails-div" className="email-input">
                        {callMgmtSettingsStore.tradeInAdfCallAlertEmails?.map((email:Email, index:number) => (
                            email.action !== DB_ACTION_DELETE &&
                            <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={callMgmtSettingsStore}
                                            textInputRef={callMgmtSettingsStore.emailInputRef}/>))
                        }
                    </div>
                    <div id="til-adf-alert-emails-add-button-div" className="left-padding email-input">
                        <Button
                            id="til-adf-alert-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add ADF Call Alert Email`}
                            onClick={() => {
                                callMgmtSettingsStore.onEmailAdd(emailTypeEnum.TRADE_IN_ADF_CALL_ALERT)
                            }}
                            theme="blue-outline"
                        />
                    </div>

                    {/*  "Trade-In Leads Text Call Alert Emails" Section   */}
                    <div id="til-txt-alert-emails-separator-line-div" className="top-padding">
                        <hr id="til-txt-alert-emails-separator-line" className="call-mgmt-separator-line"/>
                    </div>
                    <p id="til-txt-alert-emails-sub-heading" className="sub-heading">
                        Trade-In Leads Text Call Alert Email(s)
                    </p>
                    <div id="til-txt-alert-emails-description-div" className="left-padding">
                        <p id="til-txt-alert-emails-description-text" className="primary_text">
                            This is a list of email addresses that receive Text Call alerts.
                        </p>
                    </div>
                    <div id="til-txt-alert-emails-div" className="email-input">
                        {callMgmtSettingsStore.tradeInTextCallAlertEmails?.map((email:Email, index:number) => (
                            email.action !== DB_ACTION_DELETE &&
                            <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={callMgmtSettingsStore}
                                            textInputRef={callMgmtSettingsStore.emailInputRef}/>))
                        }
                    </div>
                    <div id="til-txt-alert-emails-add-button-div" className="left-padding bottom-padding email-input">
                        <Button
                            id="til-txt-alert-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add Text Call Alert Email`}
                            onClick={() => {
                                callMgmtSettingsStore.onEmailAdd(emailTypeEnum.TRADE_IN_CALL_ALERT)
                            }}
                            theme="blue-outline"
                        />
                    </div>
                </div>
            );
        });

        serviceApptSection = observer( () => {

            useEffect(() => {
                this.focusOnEmailAfterAdd();
            });

            return(
                <div id="srv-appt-section-div">
                    {/*  "Service Appt Ring-to Phone Number" Section   */}
                    <p id="srv-appt-ring-to-phone-sub-heading" className="sub-heading">
                        Ring-to Phone Number
                    </p>
                    <div id="srv-appt-ring-to-phone-description-div" className="left-padding">
                        <p id="srv-appt-ring-to-phone-description-text" className="primary_text">
                            The monitored dealer phone number for all leads.
                        </p>
                    </div>
                    <div id="srv-appt-ring-to-phone-input-div"  className="left-padding call-mgmt-ring-to-num">
                        <PhoneInput
                            id="srv-appt-ring-to-phone-input-text"
                            value={callMgmtSettingsStore.srvApptTwilioPhoneData?.ringToPhoneNumber}
                            isRawValue={true}
                            placeholder={"Enter Ring-To Number"}
                            aria-invalid={!callMgmtSettingsStore.isValidPhoneNumber(callMgmtSettingsStore.srvApptTwilioPhoneData?.ringToPhoneNumber!, twilioPhoneDataType.SERVICE_APPT)}
                            onChange={(event) => {
                                callMgmtSettingsStore.srvApptRingToPhoneHandler(event.target.value)
                            }}
                        />
                    </div>
                    <div id="srv-appt-ring-to-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                        <InlineErrorMessage id="srv-appt-ring-to-number-error" errorMessage={commonStore.getFormError(SRV_APPT_RING_TO_FIELD_NAME)}/>
                    </div>

                    {/*  "Service Appointment Tracking Phone Number" Section   */}
                    <hr id="srv-appt-tracking-numseparator-line" className="call-mgmt-separator-line"/>
                    <p id="srv-appt-tracking-num-sub-heading" className="sub-heading">
                        Tracking Phone Number
                    </p>
                    <div id="srv-appt-tracking-num-desc-div" className="left-padding ">
                        <p id="srv-appt-tracking-num-desc-text" className="primary_text">
                            The CARFAX tracking number that appears for service appointment opportunities.
                        </p>
                    </div>
                    <div id="srv-appt-tracking-num-opt-out-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="srv-appt-tracking-num-opt-out-toggle-label" className="toggle-label primary_label_text__bold">Dealer opted out of phone tracking</p>
                        <CheckboxToggle
                            id="srv-appt-tracking-num-opt-out-toggle"
                            checked={callMgmtSettingsStore?.srvApptTwilioPhoneData?.disableTrackingNumber === true ? true : false}
                            onChange={event => {
                                callMgmtSettingsStore.onToggleOptOutSrvApptTracking(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>
                    <p id="srv-appt-tracking-num-opt-out-desc-text" className="left-padding primary_text">
                        When on, the Tracking Phone Number will be deleted and calls will ring directly to the Ring-to Phone Number.
                    </p>
                    <div id="srv-appt-tracking-num-div" className="top-padding">
                        <div id="srv-appt-tracking-num-add-remove-div">
                            { !callMgmtSettingsStore.srvApptTwilioPhoneData?.trackingPhoneNumber &&
                                <div id="srv-appt-tracking-num-add-div">
                                    {callMgmtSettingsStore.srvApptTwilioPhoneData?.disableTrackingNumber &&
                                        <div id="srv-appt-no-tracking-num-warning-div"
                                             className="no-tracking-number-warning-div">
                                            <InfoCircleOutline size={25} className="info-circle-outline"/>
                                            <p id="srv-appt-no-tracking-num-warning-text"
                                               className="no-tracking-number-warning-text">
                                                When opted out, CARFAX cannot track and record phone leads.
                                            </p>
                                        </div>
                                    }
                                    <Button
                                        id="srv-appt-tracking-num-add-button" className="tracking-num-add-button"
                                        onClick={(event: any) => {
                                            callMgmtSettingsStore.handleProvisionModal(twilioPhoneDataType.SERVICE_APPT)
                                        }}
                                        disabled={callMgmtSettingsStore.disableServiceAppointmentAddTracking()}
                                        iconLeft={<Plus aria-hidden/>}
                                        size="medium"
                                        theme="blue-outline">
                                        Add Tracking Number
                                    </Button>
                                </div>
                            }
                            {  callMgmtSettingsStore.srvApptTwilioPhoneData?.trackingPhoneNumber &&
                                <div id="srv-appt-tracking-num-remove-div">
                                    <p id="srv-appt-tracking-number-input-label" className="left-padding primary_label_text__bold">Tracking Phone Number</p>
                                    <div className="trash-can-area">
                                        <div id="srv-appt-tracking-num-remove-text-input-div" className="call-mgmt-phone-num">
                                            <TextInput
                                                id="srv-appt-tracking-num-input-text"
                                                className="call-mgmt-phone-num"
                                                value={callMgmtSettingsStore.displayTrackingNumber(callMgmtSettingsStore.srvApptTwilioPhoneData.trackingPhoneNumber)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                }}
                                                disabled={true}
                                                maxLength={10}
                                            />
                                        </div>
                                        <Trash
                                            id="srv-appt-tracking-num-remove-trash-can"
                                            size={40}
                                            className={(authStore.isNonAdminGroup()) ? "tracking-num-remove-button-disabled" : "tracking-num-remove-button"}
                                            onClick={() => {
                                                callMgmtSettingsStore.handleDeleteModel(twilioPhoneDataType.SERVICE_APPT);
                                            }}
                                            title="Remove Tracking Number"
                                        />
                                        <div id="srv-appt-tracking-num-remove-label-div" className="trash-label-text" >
                                            Delete Tracking Number
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {callMgmtSettingsStore.srvApptTwilioPhoneData.trackingPhoneNumber && callMgmtSettingsStore.srvApptTwilioPhoneData.displayDate &&
                        <div id="srv-appt-display-provision-date-div" className="left-padding display-provision-date-div">
                            Created on: {callMgmtSettingsStore.srvApptTwilioPhoneData.displayDate}
                        </div>
                    }
                    <div id="srv-appt-tracking-number-inline-error-message" className="call-mgmt-numbers-inline-error-message">
                        <InlineErrorMessage id="srv-appt-tracking-num-error" errorMessage={callMgmtSettingsStore.srvApptTrackingNumberMessage}/>
                    </div>

                    {/*  "Service Appointment Leads Call Recording Consent" Section   */}
                    <hr id="srv-appt-call-recording-consent-separator-line" className="call-mgmt-separator-line"/>
                    <p id="srv-appt-call-recording-consent-sub-heading" className="sub-heading">
                        Call Recording Consent
                    </p>
                    <div id="srv-appt-call-recording-consent-desc-div" className="left-padding">
                        <p id="srv-appt-call-recording-consent-desc-text" className="primary_text">
                            When enabled, CARFAX has permission to record calls to the tracking phone number.
                        </p>
                    </div>
                    <div id="srv-appt-call-recording-consent-toggle-div" className="left-padding top-padding inline-flex">
                        <p id="srv-appt-call-recording-consent-toggle-label" className="toggle-label">Enable Call Recording</p>
                        <CheckboxToggle
                            id="srv-appt-call-recording-consent-toggle"
                            checked={callMgmtSettingsStore?.srvApptTwilioPhoneData?.callAlertConsent === true ? true : false}
                            onChange={event => {
                                callMgmtSettingsStore.onToggleSrvApptCallAlertConsent(event.target.checked)
                            }}
                            disabled={authStore.isNonAdminGroup()}
                        />
                    </div>

                    {/*  "Service Appointment Text Lead Emails" Section   */}
                    <div id="srv-appt-txt-lead-emails-separator-line-div" className="top-padding">
                        <hr id="srv-appt-txt-lead-emails-separator-line" className="call-mgmt-separator-line"/>
                    </div>
                    <p id="srv-appt-txt-lead-emails-sub-heading" className="sub-heading">
                        Text Lead Email(s)
                    </p>
                    <div id="srv-appt-txt-alert-emails-description-div" className="left-padding">
                        <p id="srv-appt-txt-lead-emails-description-text" className="primary_text">
                            This is a list of email addresses that receive Service Appointment Text Lead Emails.
                        </p>
                    </div>
                    <div id="srv-appt-txt-lead-emails-div" className="email-input">
                        {callMgmtSettingsStore.srvApptTextLeadEmails?.map((email:Email, index:number) => (
                            email.action !== DB_ACTION_DELETE &&
                            <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={callMgmtSettingsStore}
                                            textInputRef={callMgmtSettingsStore.emailInputRef}/>))
                        }
                    </div>
                    <div id="srv-appt-txt-lead-emails-add-button-div" className="left-padding bottom-padding email-input">
                        <Button
                            id="srv-appt-txt-lead-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add Text Call Alert Email`}
                            onClick={() => {
                                callMgmtSettingsStore.onEmailAdd(emailTypeEnum.SERVICE_APPT_TEXT_LEAD)
                            }}
                            theme="blue-outline"
                        />
                    </div>
                </div>
            );
        });
    }
);
