import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {apiStatus} from "../../utils/constants";
import {makeAutoObservable, runInAction} from "mobx";
import {reputationGetHistory} from "../../clients/damBackendApi";
import {ReputationPartnerHistory} from "../../types/reputationPartner/reputationPartnerHistory";


export default class ReputationPartnerHistoryStore {

    // Fields / Types
    compCode: string = "";
    token: string = ""
    reputationPartnerHistory: Array<ReputationPartnerHistory> = [];
    paginatedPartnerHistory: Array<ReputationPartnerHistory> = [];
    isModalVisible: boolean = false;
    paginationCurrentPage: number = 1;
    paginationPerPageValue: number = 10;

    // Stores
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore
        this.businessUserStore = this.rootStore.businessUserStore
        this.commonStore = this.rootStore.commonStore

        makeAutoObservable(this);
    };

    public initializeStore() {
        this.compCode = this.businessUserStore?.businessUser?.compCode || "";
        this.initializeModal();
    };

    private initializeModal() {
        this.reputationPartnerHistory = [];
        this.paginatedPartnerHistory = [];
    }

    public displayReputationPartnerHistoryModal = () => {

        this.initializeModal();
        this.isModalVisible = true;
        this.commonStore.processingApiCall(true);
        let compCode = this.businessUserStore?.businessUser?.compCode || "";

        CommonUtils.logNewRelicPageAction("Reputation Partner Audit History", {userAction: 'View Reputation Partner Audit History', compCode: compCode})
        reputationGetHistory(this.authStore.token, compCode ).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.reputationPartnerHistory = resp.data.data;
                        this.reputationPartnerHistory.forEach((row:ReputationPartnerHistory) => {
                            row.displayDate = CommonUtils.convertEpochDateToMMDDYYYY(row.createdDate);
                        })
                        this.updatePaginatedRecords(0,this.paginationPerPageValue)
                    });
                    break;
                case apiStatus.forbidden :
                    runInAction(() => {
                        this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                        this.closeModal()
                    })
                    break;
                default :
                    runInAction(() => {
                        CommonUtils.displayConsoleLogError(`*** Error retrieving reputation partner history for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                        this.commonStore.displayPageNotification(`Severe error occurred trying to load reputation partner history for comp code '${compCode}'.`, "red")
                        this.closeModal()
                    })
                    break;
            };
            this.commonStore.processingApiCall(false)
        });
    };

    public closeModal = () => {
        this.isModalVisible = false;
    };

    public handlePaginationChange = (data: any) => {

        CommonUtils.logNewRelicPageAction("Reputation Partner Audit History", {userAction: 'Paginate Through Reputation Partner Audit Records'})

        this.paginationCurrentPage = data.currentPage;
        this.paginationPerPageValue = data.perPageValue;

        let currentPage = this.paginationCurrentPage;
        let perPage = this.paginationPerPageValue;
        let offset = (currentPage - 1) * perPage;

        this.updatePaginatedRecords(offset, offset + perPage)
    }

    protected updatePaginatedRecords(start: number, end: number) {
        this.paginatedPartnerHistory = this.reputationPartnerHistory.slice(start, end)
    };

    public showPaginator() {
        return this.reputationPartnerHistory.length > 10
    }

}
