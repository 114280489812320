import RootStore from "./root";

let root = new RootStore();

// Child Stores
const auditHistory = root.auditHistoryStore;
const auth = root.authStore;
const businessHours = root.businessHoursStore;
const businessUser = root.businessUserStore;
const callMgmtSettings = root.callMgmtSettingsStore;
const common = root.commonStore;
const dealerDetails = root.dealerDetailsStore;
const dealerProfileInfo = root.dealerProfileInfoStore;
const partners = root.partnersStore;
const preferencesSelection = root.preferencesSelectionStore;
const profileMgmt = root.profileMgmtStore;
const ratingsAndReviews = root.ratingsAndReviewsStore;
const reputationPartner = root.reputationPartnerStore;
const reputationPartnerHistory = root.reputationPartnerHistoryStore;
const search = root.searchStore;
const searchResults = root.searchResultsStore;
const smcSettings = root.smcSettingsStore;
const uclSettings = root.uclSettingsStore;

const stores = {
    auth,
    common: {
        common
    },
    partners: {
        partners
    },
    preferences: {
        preferencesSelection
    },
    profile: {
        auditHistory,
        profileMgmt,
        dealerDetails,
        dealerProfileInfo,
        uclSettings,
        businessHours,
        ratingsAndReviews,
        smcSettings,
        root,
        callMgmtSettings
    },
    reputation: {
        reputationPartner,
        reputationPartnerHistory
    },
    root: {
        root
    },
    search: {
        businessUser,
        search,
        searchResults
    }
};

export default stores;
