import { Environment, getEnvironment } from "../environment";

switch (getEnvironment()) {
    case Environment.PROD:
        import("./newrelic_prod");
        break;
    case Environment.STAGING:
        import("./newrelic_staging");
        break;
    case Environment.DEVELOPMENT:
        import("./newrelic_development");
        break;
    default:
        import("./newrelic_development");
        break;
}
