export enum InviteSectionEnum {
    Detail = 0,
    Locations = 1,
    Confirm = 2
}

export enum InviteTemplateEnum {
    DEALER = 'Dealer Invite (CFO)',
    C4LIFE = 'CARFAX for Life',
    RATINGS = 'R&R (Shop Console)',
    LOYALTY = 'Shop Loyalty Invite (Shop Console)',
    RECALL = 'VRSS (VRSS)',
    SHC = 'SHC (SHC)'
}

export function getInviteTemplateKey(template : string) {
    const inviteIndexValue = Object.values(InviteTemplateEnum).indexOf(template as InviteTemplateEnum)
    return Object.keys(InviteTemplateEnum)[inviteIndexValue]
}
