import {SecondaryName} from "../../types/secondaryName";
import RootStore from "../root";
import CommonUtils from "../../utils/commonUtils";
import {oem} from "../../types/oem";
import {DB_ACTION_DELETE, DB_ACTION_INSERT, DB_ACTION_UPDATE} from "../../utils/constants";
import {ERROR_MSG_REQUIRED_FIELDS} from "./messaging/errorMessagesProfile";
import {makeAutoObservable, runInAction} from "mobx";
import {getSecondaryNames, deleteSecondaryNames, insertSecondaryNames, updateSecondaryNames} from "../../clients/dps/dpsApi";

export default class SecondaryNamesStore {

    // Variables
    secondaryNames: Array<SecondaryName> = new Array<SecondaryName>();
    originalSecondaryNames: Array<SecondaryName> = new Array<SecondaryName>();
    oemList= new Array<string>();

    // Stores
    rootStore: RootStore;

    constructor(root: RootStore) {
        this.rootStore = root
        makeAutoObservable(this)
    };

    public initializeStore() {
        this.oemList = Object.values(oem)
    };

    public initializeSecondaryNames() {
        this.secondaryNames = new Array<SecondaryName>()
        this.originalSecondaryNames = new Array<SecondaryName>()
    };

    public getSecondaryNamesList() {
        return this.secondaryNames
    };

    public addSecondaryNameToForm(compCode: string) {
        let newSecondaryName : SecondaryName = {secondaryNameId: 0, compCode: compCode, make: "", secondaryName: "", websiteUrl: "", action: DB_ACTION_INSERT, inlineErrorMsg: ERROR_MSG_REQUIRED_FIELDS}
        this.secondaryNames.push(newSecondaryName)

        return this.getSecondaryNamesList()
    };

    public removeSecondaryName(index: number) {
        let secondaryNameToRemove = this.getSecondaryNamesList()[index]

        if(secondaryNameToRemove.secondaryNameId > 0) {
            secondaryNameToRemove.action = DB_ACTION_DELETE
            secondaryNameToRemove.originalMake = secondaryNameToRemove.make
            secondaryNameToRemove.originalSecondaryName = secondaryNameToRemove.secondaryName
            secondaryNameToRemove.originalWebsiteUrl = secondaryNameToRemove.websiteUrl
        }
        else {
            this.hardDeleteSecondaryName(index)
        }
    };

    private hardDeleteSecondaryName(index: number) {
        this.secondaryNames.splice(index, 1)
    };

    public hasSecondaryNameBeenChangedByUser(secondaryName: SecondaryName): boolean {
        let hasChanged: boolean = false;

        this.originalSecondaryNames.forEach((original: SecondaryName) => {
            if(original.secondaryNameId === secondaryName.secondaryNameId) {
                if( original.make !== secondaryName.make ||
                    original.secondaryName !== secondaryName.secondaryName ||
                    original.websiteUrl !== secondaryName.websiteUrl) {
                        hasChanged = true
                }
            }
        })

        return hasChanged
    };

    public get hasSecondaryNamesChanged(): boolean {
        return (this.secondaryNamesToBeInserted.length > 0 || this.secondaryNamesToBeUpdated.length > 0 || this.secondaryNamesToBeDeleted.length  > 0)
    };

    public get secondaryNamesToBeInserted() {
        return this.getSecondaryNamesList().filter(secondaryName => secondaryName.action === DB_ACTION_INSERT)
    };

    public get secondaryNamesToBeUpdated() {
        return this.getSecondaryNamesList().filter(secondaryName => secondaryName.action === DB_ACTION_UPDATE)
    };

    public get secondaryNamesToBeDeleted() {
        return this.getSecondaryNamesList().filter(secondaryName => secondaryName.action === DB_ACTION_DELETE)
    };

    // API CALLS
    public getSecondaryNamesForCompCode = async (token:string, compCode:string) => {
        await getSecondaryNames(token, compCode).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.secondaryNames = resp.data;
                        this.originalSecondaryNames = resp.data;
                    })
                    break;
                case 'NOT FOUND' :
                    CommonUtils.displayConsoleLogError(`*** No secondary names found for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve secondary names for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    this.rootStore.commonStore.displayPageNotification(`Severe error occurred trying to retrieve secondary names for comp code '${compCode}'.`, "red")
                    break;
            }
        })
    };

    public addSecondaryNames = async (token:string, compCode: string )=> {
        let addSuccess: boolean = false

        await insertSecondaryNames(token, compCode, this.secondaryNamesToBeInserted).then((resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    addSuccess = true
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to insert secondary names for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    addSuccess = false
                    break;
            }
        })

        return addSuccess;
    };

    public deleteSecondaryNames = async (token:string, compCode: string) => {
        let deleteSuccess: boolean = false

        await deleteSecondaryNames(token, compCode, this.secondaryNamesToBeDeleted).then((resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    deleteSuccess = true
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to delete secondary names for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    deleteSuccess = false
                    break;
            }
        })

        return deleteSuccess;
    };

    public updateSecondaryNames = async (token:string, compCode: string) => {
        let updateSuccess: boolean = false

        await updateSecondaryNames(token, compCode, this.secondaryNamesToBeUpdated).then((resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    updateSuccess = true
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to update secondary names for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    updateSuccess = false
                    break;
            }
        })

        return updateSuccess;
    };

}