import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import Header from "../../shared-components/layout/header/Header";
import Icon_Email_Deletion_Tool from "../../images/home/Icon_Email_Deletion_Tool.svg";
import Icon_Run_Reports from "../../images/home/Icon_Run_Reports.svg";
import Icon_Bulk_Invite from "../../images/home/Icon_Bulk_Invite.svg";
import Icon_Bulk_Invite_JC from "../../images/home/Icon_Bulk_Invite_JC.svg";
import Top_Rated_Dealers_Badge from "../../images/home/Top_Rated_Dealers_Badge.svg";
import React from "react";
import { SpinnerDots } from "@carfax-stencils/spinner";
import Store from "../../stores";
import {DAM_Admin, DAM_Super_Admin} from "../../types/groups";
import {checkUser} from "../security/helpers";
import {observer} from "mobx-react";
import {NavLink} from "react-router-dom";
import {withOktaAuth} from "@okta/okta-react";
import "../home/HomePage.scss"

// Variables
type localState = { userInfo: any };

// Stores
const rootStore = Store.root.root;
const authStore = rootStore.authStore;


export default withOktaAuth (

  class UtilitiesHomePage extends React.Component<{ authState: any }, localState> {
    checkUser: any;

    constructor(props: any) {
      super(props);
      this.checkUser = checkUser.bind(this);
      this.state = { userInfo: null };
    }

    async componentDidMount() {}

    async componentDidUpdate() {}

    render() {
      return (
        <div id="utilities-home-page-main-outer-div">
            <Header />
            <div id="utilities-breadcrumbs-div" className="breadcrumb-trail">
                <Breadcrumbs
                    id="utilities-breadcrumbs"
                    crumbs={[
                        { value: "Home", href: "#" },
                        { value: "Utilities"}
                    ]}
                />
            </div>
            <div id="utilities-home-page-main-inner-div">
                <div id="utilities-home-page-wrapper-div" className="home-page-wrapper">
                  <br />
                  <div id="utilities-home-page-title-div" className="home-title">Admin Utilities</div>
                  <br />
                  <div id="utilities-home-page-description-div" className="home-description">
                    Utility features for DAM Tool Administrators.
                  </div>
                  <Deck />
                </div>
            </div>
        </div>
      );
    }
  }
);

const Deck = observer(() => {
  return (
    <div id="utilities-home-page-wrapper">
        {authStore.groups === undefined ?
            <div id="home-page-card-spinner-div">
                <SpinnerDots id="home-page-card-spinner" />
            </div>
        :
            <div id="utilities-home-page-card-container" className="home-card-container">
                {(authStore.groups.includes(DAM_Super_Admin) || authStore.groups.includes(DAM_Admin)) &&
                    <Card
                        id="email-deletion-tool-card"
                        image={Icon_Email_Deletion_Tool}
                        linkText="Email Deletion Tool &nbsp;&nbsp;&nbsp;>"
                        linkHref="/utilities/emailDeletionTool"
                        linkId="email-deletion-tool-link"
                    />
                }
                { authStore.groups.includes(DAM_Super_Admin) &&
                    <Card
                        id="run-reports-card"
                        image={Icon_Run_Reports}
                        linkText="Run Reports &nbsp;&nbsp;&nbsp;>"
                        linkHref="/utilities/runReports"
                        linkId="run-reports-link"
                    />
                }
                {(authStore.groups.includes(DAM_Super_Admin) || authStore.groups.includes(DAM_Admin)) &&
                    <>
                        <Card
                            id="bulk-invite-card"
                            image={authStore.groups.includes(DAM_Super_Admin) ? Icon_Bulk_Invite_JC : Icon_Bulk_Invite}
                            linkText="Bulk Invite &nbsp;&nbsp;&nbsp;>"
                            linkHref="/utilities/bulkInvite"
                            linkId="bulk-invite-link"
                        />
                    </>
                }
                { authStore.groups.includes(DAM_Super_Admin) &&
                    <Card
                        id="regenerate-top-rated-dealers"
                        image={Top_Rated_Dealers_Badge}
                        linkText="Regenerate Top Rated Dealers &nbsp;&nbsp;&nbsp;>"
                        linkHref="/utilities/regenerateTopRatedDealers"
                        linkId="regenerate-top-rated-dealers-link"
                    />
                }
            </div>
        }
    </div>
  );
});

type CardProps = {
  id: string;
  image: string;
  linkText: string;
  linkHref: string;
  linkId: string;
};

const Card = (props: CardProps) => {
  return (
    <div id="utilities-home-page-card" className={`home-card cfx-card`}>
      <div>
        <NavLink to={props.linkHref}>
            <div id="utilities-home-page-card-image-container" className={`home-card__image-container`}>
                <img id="utilities-home-page-card-image" src={props.image} alt={props.linkText}/>
            </div>
            <div id="utilities-home-page-card-link" className={'home-card__link'}>
                {props.linkText}
            </div>
        </NavLink>
      </div>
    </div>
  );
};
