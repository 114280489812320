import DEV from "../components/security/config";
import RootStore from "./root";
import {action, makeObservable, observable, runInAction} from "mobx";
import {getAuthGroups} from "../clients/damBackendApi";
import {DAM_Admin, DAM_Super_Admin, Everyone} from "../types/groups";
import {UserClaims} from "@okta/okta-auth-js";


export default class AuthStore {

    // Variables
    actingAsGroup: string | undefined = undefined
    token: string = ""
    groups: string[] | undefined = undefined
    initialized: boolean = false
    user: UserClaims | undefined = undefined

    // Stores
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        makeObservable(this, {
            initialize: action,
            resolveGroups: action,
            initialized: observable,
            groups: observable,
            token: observable
        })
    }

    public initialize = async (token:string, groupOverride:any) => {
        if(token && token !== "") {
            this.token = token
        }
        await this.resolveGroups()
        this.handleGroupOverride(groupOverride)
        runInAction(() => {
            this.initialized = true;
        });
    }

    public setUser(user: UserClaims) {
        this.user = user
    }

    public setRenewedToken(token: any) {
        if(token && token !== "") {
            this.token = token
        }
    }

    public resolveGroups = async () => {
        if (this.token === "") {
            this.groups = undefined
        } else {
            if (this.groups === undefined) {
                await getAuthGroups(this.token).then((resp) => {
                    switch(resp?.status){
                        case 'SUCCESS' :
                            if (resp.data?.errorMessage) {
                                console.log("*** error: " + JSON.stringify(resp))
                                this.groups = []
                            } else {
                                this.groups = resp.data
                            }
                            break;
                        default :
                            console.log("*** error: " + JSON.stringify(resp))
                            this.groups = [];
                            break;
                    }
                });
                console.log(`%c > Okta Group: ${this.groups}`, 'background: #cfe5cf; color: #001a00')
            }
        }
    }

    private handleGroupOverride = (groupOverride: any) => {
        const validOverrides = [DAM_Admin, DAM_Super_Admin, Everyone];

        if (groupOverride && this.groups?.includes(DAM_Super_Admin) && validOverrides.includes(groupOverride)) {
            if (DEV) {console.log(`Overriding group auth to: ${groupOverride}`)}
            this.actingAsGroup = groupOverride
            if (groupOverride === Everyone) { // Everyone group should be the same as no group since the backend will not return Everyone as a group
                this.groups = []
            } else {
                this.groups = [groupOverride]
            }
        }
    }

    public isNonAdminGroup() : any {
        return !this.groups?.includes(DAM_Super_Admin) && !this.groups?.includes(DAM_Admin)
    }
}
