import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {Email} from "../../types/email";
import {DB_ACTION_DELETE, DB_ACTION_INSERT, DB_ACTION_UPDATE} from "../../utils/constants";
import {getEmailsForType, insertEmails, deleteEmails, getEmails, updateEmails} from "../../clients/dps/dpsApi";
import {makeAutoObservable, runInAction} from "mobx";


export default class EmailsStore {

    // Variables
    emails: Map<string, Array<Email>>;
    originalEmails: Map<string, Array<Email>>;

    // Stores
    rootStore: RootStore;

    constructor(root: RootStore) {
        this.emails = new Map<string, Email[]>();
        this.originalEmails = new Map<string, Email[]>();
        this.rootStore = root;
        makeAutoObservable(this);
    }

    public initializeEmails(emailTypes: Array<string>){
        this.emails = new Map<string, Email[]>();
        this.originalEmails = new Map<string, Email[]>();

        emailTypes.forEach(emailType => {
            this.emails.set(emailType, new Array<Email>())
            this.originalEmails.set(emailType, new Array<Email>())
        });
    }

    public getEmails(emailType:string): Array<Email> {
        return this.emails.get(emailType)!
    }

    public getOriginalEmails(emailType:string): Array<Email> {
        return this.originalEmails?.get(emailType)!
    }

    public getEmailTypes(): Array<string> {
        return Array.from(this.emails.keys());
    }

    public getEmailsForAllTypes(): Array<Email>{
        let allEmails: Array<Email> = new Array<Email>();
        let emailsArrays = Array.from(this.emails.values())
        emailsArrays.forEach(emailArray => {
            allEmails = allEmails.concat(emailArray)
        })
        return allEmails;
    }

    public addEmail(emailType:string, compCode: string, emailListings?: string): Array<Email> {
        let emails = this.getEmails(emailType)
        let email : Email = {uclEmailsId: 0, compCode: compCode, email: "", emailType: emailType, emailListings: emailListings || "", action: DB_ACTION_INSERT, inlineErrorMsg: ""};
        if(emails) {
            emails.push(email)
        }
        else {
            this.emails.set(emailType,[email])
        }
        return this.getEmails(emailType);
    }

    public changeEmail(emailType:string, email: string, inlineErrorMsg: string, index: number): Array<Email> {
        let salesRatingEmail = this.getEmails(emailType)[index]
        salesRatingEmail.email = email
        salesRatingEmail.inlineErrorMsg = inlineErrorMsg
        return this.getEmails(emailType);
    }

    public changeListingsType(emailType:string, emailListings:string, index: number): Array<Email> {

        let originalEmailFromDb : any
        if(this.getOriginalEmails(emailType)[index]) {
            originalEmailFromDb = CommonUtils.cloneObject(this.getOriginalEmails(emailType)[index])
        }

        let emailToModify = this.getEmails(emailType)[index]

        if(originalEmailFromDb !== undefined && emailListings === originalEmailFromDb.emailListings) {
            emailToModify.action = ""
            emailToModify.emailListings = originalEmailFromDb.emailListings
        } else {
            emailToModify.emailListings = emailListings
            if(emailToModify.action === "" || emailToModify.action === undefined) {
                emailToModify.action = DB_ACTION_UPDATE
            }
        }
        return this.getEmails(emailType);
    }

    public removeEmail(emailType:string, index: number): Array<Email> {
        let emailToRemove = this.getEmails(emailType)[index]
        if(emailToRemove.uclEmailsId > 0) {
            emailToRemove.action = DB_ACTION_DELETE
            emailToRemove.originalEmail = emailToRemove.email
            emailToRemove.email = ""
        }
        else {
            this.hardDeleteEmail(emailType, index)
        }
        return this.getEmails(emailType)
    }

    public removeEmailFormError(errorField: string) {
        if(!this.doesEmailErrorsExist) {
            this.rootStore.commonStore.removeFormError(errorField);
        }
    }

    public setOrResetEmailsFormError(emailErrorMsg: string, fieldName: string) {
        if (emailErrorMsg !== "") {
            this.rootStore.commonStore.setFormError(fieldName, emailErrorMsg);
        } else {
            this.rootStore.commonStore.removeFormError(fieldName);
        }
    }

    private hardDeleteEmail(emailType:string, index: number) {
        this.getEmails(emailType).splice(index, 1);
    }

    public get doesEmailErrorsExist() {
        return this.getEmailsForAllTypes().filter(email => email.inlineErrorMsg !== undefined  &&  email.inlineErrorMsg !== "" && email.action !== DB_ACTION_DELETE).length > 0;
    }

    public duplicateEmailsExist(emailToCheck: string, emailTypes: Array<string>) : boolean{
        return emailTypes.some((emailType) => {
            return (this.getEmails(emailType)?.filter(existingEmail =>
                existingEmail.email.toLowerCase() === emailToCheck.toLowerCase()).length > 0)
        });
    }

    public get emailsToBeInserted() {
        return this.getEmailsForAllTypes().filter(email => email.action === DB_ACTION_INSERT );
    }

    public get emailsToBeDeleted() {
        return this.getEmailsForAllTypes().filter(email => email.action === DB_ACTION_DELETE );
    }

    public get emailsToBeUpdated() {
        return this.getEmailsForAllTypes().filter(email => email.action === DB_ACTION_UPDATE );
    }

    public get hasEmailsChanged(): boolean {
        return (this.emailsToBeInserted.length > 0 || this.emailsToBeDeleted.length > 0 || this.emailsToBeUpdated.length > 0);
    }

    private groupEmailsByType(emails: Array<any>) : Map<string, Array<any>> {
        let groupedEmails = emails.reduce((groupedEmails, email) => {
            const key = email["emailType"]; //property to group by
            if (!groupedEmails[key]) {
                groupedEmails[key] = [];
            }
            groupedEmails[key].push(email);
            return groupedEmails;
        }, {});
        for (let value in groupedEmails) {
            this.emails.set(value,groupedEmails[value])
        }
        return this.emails;
    }

    private groupOriginalEmailsByType(emails: Array<any>) : Map<string, Array<any>> {
        let groupedEmails = emails.reduce((groupedEmails, email) => {
            const key = email["emailType"]; //property to group by
            if (!groupedEmails[key]) {
                groupedEmails[key] = [];
            }
            groupedEmails[key].push(email);
            return groupedEmails;
        }, {});
        for (let value in groupedEmails) {
            this.originalEmails.set(value,groupedEmails[value])
        }

        return this.originalEmails;
    }

    public getEmailsFromDPS = async (token:string, compCode:string, emailType?: string) => {

        if(emailType) {

            this.initializeEmails([emailType]);
            await getEmailsForType(token, compCode, emailType).then((resp: any) => {
                switch (resp?.status) {
                    case 'SUCCESS' :
                        runInAction(() => {
                            this.emails.set(emailType, resp.data);
                            this.originalEmails.set(emailType, resp.data);
                        });
                        break;
                    case 'NOT FOUND' :
                        CommonUtils.displayConsoleLogError(`*** No ${emailType} emails found for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                        break;
                    default :
                        CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve ${emailType} emails for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                        this.rootStore.commonStore.displayPageNotification(`Severe error occurred trying to retrieve ${emailType} emails for comp code '${compCode}'.`, "red")
                        break;
                }
            });

        }
        else {

            await getEmails(token, compCode).then((resp: any) => {
                switch(resp?.status){
                    case 'SUCCESS' :
                        runInAction(() => {
                            this.emails = this.groupEmailsByType(resp.data);
                            this.originalEmails = this.groupOriginalEmailsByType(resp.data);
                        });
                        break;
                    case 'NOT FOUND' :
                        CommonUtils.displayConsoleLogError(`*** No emails found for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                        break;
                    default :
                        CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve emails for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                        this.rootStore.commonStore.displayPageNotification(`Severe error occurred trying to retrieve emails for comp code '${compCode}'.`, "red")
                        break;
                };
            });
        }

    }

    public insertEmails = async (token:string, compCode:string) => {
        let insertEmailsSuccess: boolean = false;
        await insertEmails(token, compCode, this.emailsToBeInserted).then((resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    insertEmailsSuccess = true
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to insert emails for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    insertEmailsSuccess = false
                    break;
            }
        });
        return insertEmailsSuccess;
    }

    public updateEmails = async (token:string, compCode:string) => {
        let updateEmailSuccess: boolean = true;

        await updateEmails(token, compCode, this.emailsToBeUpdated).then((resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    updateEmailSuccess = true
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to update the emailsListings type for emails at comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    updateEmailSuccess = false
                    break;
            }
        });
        return updateEmailSuccess;
    }

    public deleteEmails = async (token:string, compCode:string) => {
        let deleteEmailsSuccess: boolean = false;
        await deleteEmails(token, compCode, this.emailsToBeDeleted).then((resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    deleteEmailsSuccess = true
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to delete emails for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    deleteEmailsSuccess = false
                    break;
            }
        });
        return deleteEmailsSuccess;
    }

    public resetEmailsToOriginalData(emailTypes ?:Array<string>) {
        let emailTypesToReset;
        if(emailTypes) {
            emailTypesToReset = emailTypes
        }
        else {
            emailTypesToReset = this.getEmailTypes()
        }
        emailTypesToReset.forEach(emailType => {
            let emailsToReset = this.getEmails(emailType)
            if(emailsToReset) {
                let insertedEmails = emailsToReset.filter(email => (email.action === DB_ACTION_INSERT))
                insertedEmails.forEach(emailToReset => emailsToReset.splice(emailsToReset.findIndex(email => email.email === emailToReset.email), 1))
                let deletedEmails = emailsToReset.filter(email => (email.action === DB_ACTION_DELETE))
                deletedEmails.forEach(email => { email.action = undefined; email.email = email.originalEmail!})
                this.emails.set(emailType, emailsToReset)
            }
        });
    }
}
