export enum SearchType {
    CompCode = "Comp Code",
    Name = "Dealership / Service Shop Name",
    ProfileEmail = "Email Address",
    TrackingPhone = "Tracking Phone Number",
    WebsiteUrl = "Website URL",
    UserEmail = "User's Email Address",
    UserName = "User's Name",
    UserPhone = "User's Phone Number"
}
