import { makeObservable, observable } from "mobx"


// NOTE - This is used solely for the Preferences Setup feature.
export default class PreferencesSearchStore {

    active: boolean = false
    city: string = ""
    compCode: string = ""
    companyName: string = ""
    locationId: number = 0
    salesforceId: string = ""
    state: string = ""
    streetAddress: string = ""
    uclStatus: boolean = false
    zip: string = ""

    isSearchResultsVisible: boolean = false;

    constructor() {
        makeObservable(this, {
            active: observable,
            compCode: observable,
            companyName: observable,
            isSearchResultsVisible: observable,
            locationId: observable,
            salesforceId: observable,
            state: observable,
            streetAddress: observable,
            uclStatus: observable,
            zip: observable
        })
    }

    public handleDisplaySearchResults = (display: boolean) => {
        this.isSearchResultsVisible = display;
    }

    public initializeStore = () => {
        this.isSearchResultsVisible = false;
        this.active = false;
        this.city = "";
        this.compCode = "";
        this.companyName = "";
        this.locationId = 0;
        this.salesforceId = "";
        this.state = "";
        this.streetAddress = "";
        this.uclStatus = false;
        this.zip = "";
    };
}
