import {emailTypeEnum} from "../../../types/profile/enums/emailTypeEnum";

export const ERROR_MSG_REQUIRED_FIELDS = "Please enter required fields";
export const ERROR_MSG_ALLOW_BACK_FILL_CPO              = "Backfill cannot be set to Off and CPO Listing to On.";
export const ERROR_MSG_CFX_UCL_ONLY_SYNDICATE           = "CFX UCL Only cannot be enabled while Syndicate is enabled.";
export const ERROR_MSG_CFX_PARTNER_SYNDICATE            = "Syndicate cannot be enabled while CFX UCL Only is enabled.";
export const ERROR_MSG_INVENTORY_KEY                    = "Key can only contain letters (A-Z) and digits (0-9).";
export const ERROR_MSG_INVALID_PHONE                    = "Please enter a valid phone number.";
export const ERROR_MSG_REPEATED_RING_TO ="Used and New Car Listings ring-to must be different."
export const ERROR_MSG_EMAIL_INVALID                    = "Please enter a valid email address.";
export const ERROR_MSG_EMAIL_ADF_TEXT_DUPLICATE         = "The same email address cannot be used for both ADF Lead Email(s) and Text Lead Email(s) fields.";
export const ERROR_MSG_EMAIL_TIL_ADF_TEXT_DUPLICATE     = "The same email address cannot be used for both Trade-In ADF Lead Email(s) and Trade-In Text Lead Email(s) fields.";
export const ERROR_MSG_EMAIL_CALL_ADF_TEXT_DUPLICATE    = "The same email address cannot be used for both ADF Call Alert Email(s) and Text Call Alert Email(s) fields.";
export const ERROR_MSG_DUPLICATE_EMAILS                 = "Duplicate emails may not be saved.";
export const ERROR_MSG_TIL_EMAIL_REQUIRED               = "You are required to have at least one email address for a dealer participating in the Trade-In Lead Program.";
export const ERROR_MSG_ENHANCED_EMAIL_REQUIRED          = "An ADF Lead Email or a Text Lead Email is required to proceed.";
export const ERROR_MSG_ENHANCED_TRACK_NUM_REQUIRED      = "UCL Tracking Number is required for Enhanced dealer.";
export const ERROR_MSG_TWILIO_API_ERROR                 = "Error encountered trying to get available number from Twilio. Please try again.";
export const ERROR_MSG_TWILIO_DPS_API_ERROR             = "Error encountered trying update DPS for the new Provisioned tracking number.";
export const ERROR_MSG_NO_TWILIO_TOLL_FREE_NUMBERS      = "No toll-free numbers tracking numbers available.";
export const ERROR_MSG_NO_TWILIO_NUMBERS_FOR_CRITERIA   = "No tracking numbers available for the area code and/or prefix you entered. Please try another number.";
export const ERROR_MSG_INVALID_DMS_PROVIDER             = "Please select valid DMS Provider.";
export const ERROR_MSG_INVALID_CRM_PROVIDER             = "Please select valid CRM Provider.";
export const ERROR_MSG_INVALID_CRM_PARTNER              = "Please select valid CRM Partner.";
export const ERROR_MSG_INVALID_C4L_PROVIDER             = "Please select valid C4L Data Provider.";
export const ERROR_MSG_INVALID_INVENTORY_PROVIDER       = "Please select valid Inventory Provider.";
export const ERROR_MSG_USED_AND_NEW_INVENTORY_PROVIDER_DUPLICATE = "Used and new car listings Inventory Provider must be different.";
export const ERROR_MSG_USED_AND_NEW_WEBSITE_URL_DUPLICATE = "Used and new car website URLs must be different.";
export const ERROR_MSG_USED_AND_NEW_DISCLAIMER_DUPLICATE = "Used and new car Customer Disclaimers must be different.";
export const ERROR_MSG_MISSING_INVENTORY_PROVIDER = "Inventory provider is required."
export const ERROR_MSG_SECONDARY_NAME_OEM_REQUIRED = "OEM is required";
export const ERROR_MSG_SECONDARY_NAME_BUSINESS_NAME_REQUIRED = "Secondary business name is required";
export const ERROR_MSG_SECONDARY_NAME_WEBSITE_URL_REQUIRED = "Website URL is required";
export const ERROR_MSG_WEBSITE_URL_START = "Website URL must begin with http:// or https://";
export const ERROR_MSG_WEBSITE_URL_END = "Website URL must end with '.' and a domain.";
export const ERROR_MSG_WEBSITE_URL_SPACES = "Website URL cannot contain spaces.";
export const ERROR_MSG_WEBSITE_URL_INVALID = "Website URL is invalid.";

export const resolveInvalidCarfaxEmailMessage = (emailType : string) => {

    let fieldName = ""
    switch (emailType) {
        case emailTypeEnum.ADF_LEAD :
            fieldName =  "ADF Lead Email(s)"
            break;
        case emailTypeEnum.TRADE_IN_ADF_LEAD :
            fieldName =  "Trade-In ADF Lead Email(s)"
            break;
        case emailTypeEnum.TEXT_LEAD :
            fieldName =  "Text Lead Email(s)"
            break;
        case emailTypeEnum.TRADE_IN_TEXT_LEAD :
            fieldName =  "Trade-In Text Lead Email(s)"
            break;
        case emailTypeEnum.ADF_CALL_ALERT :
            fieldName =  "UCL ADF Call Alert Email(s)"
            break;
        case emailTypeEnum.TEXT_CALL_ALERT :
            fieldName =  "UCL Text Call Alert Email(s)"
            break;
        case emailTypeEnum.TRADE_IN_ADF_CALL_ALERT :
            fieldName =  "Trade-In Leads ADF Call Alert Email(s)"
            break;
        case emailTypeEnum.TRADE_IN_CALL_ALERT :
            fieldName =  "Trade-In Leads Text Call Alert Email(s)"
            break;
        case emailTypeEnum.SERVICE_APPT_TEXT_LEAD :
            fieldName =  "Service Appointment Text Lead Email(s)"
            break;
        case emailTypeEnum.SALES_RATINGS :
            fieldName =  "Sales Rating Email(s)"
            break;
    }
    if (fieldName) {
        return `carfax.com email addresses may not be saved in the ${fieldName} field.`
    } else {
        return ""
    }

}
