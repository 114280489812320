import * as React from "react";
import { observer } from "mobx-react";
import "./inlineErrorMessage.scss";


export default ( observer (
  class InlineErrorMessage extends React.Component<{ id: string, errorMessage: string }> {

  render() {
    return (
        <div id="inline-error-div" className="inline-error-div">
          <p className="inline-error-text">{this.props.errorMessage}</p>
        </div>
    );
  };
}));
