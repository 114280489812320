import DamAxiosClient from "../DamAxiosClient";
import UrlBuilder from "../../utils/urlBuilder";
import {DealerProfileInfo} from "../../types/profile/dealerProfileInfo";
import {UclSettings} from "../../types/profile/uclSettings";
import {BusinessHours} from "../../types/profile/businessHours";
import {Email} from "../../types/email";
import {SmcSettings} from "../../types/profile/SmcSettings";
import {TwilioPhoneData} from "../../types/profile/twilioPhoneData";
import {SecondaryName} from "../../types/secondaryName";

const DPS_API_URI = UrlBuilder.buildDpsUrl("");
const damAxiosClient: DamAxiosClient = new DamAxiosClient()


export const getDealerInfo = async (token: string, compCode: string): Promise<any> => {
    const path: string = DPS_API_URI + `/profile-info/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.get(path, params, token)
};

export const updateDealerInfo = async (token: string, dealerProfileInfo: DealerProfileInfo, patchDealerProfileInfo: any): Promise<any> => {
    if (dealerProfileInfo.dealerProfileInfoId) {
        const path: string = DPS_API_URI + `/profile-info/${dealerProfileInfo.dealerProfileInfoId}`
        const params: any = {source: 'dam-aws'}
        return damAxiosClient.patch(path, params, token, patchDealerProfileInfo)
    } else {
        const path: string = DPS_API_URI + `/profile-info/${dealerProfileInfo.compCode}`
        const params: any = {source: 'dam-aws'}
        return damAxiosClient.put(path, params, token, dealerProfileInfo)
    }
};

export const getListOfPartners = async (token: string): Promise<any> => {
    const path: string = DPS_API_URI + `/partner`
    const params: any = {}
    return damAxiosClient.get(path, params, token)
};

export const getUclSettings = async (token: string, compCode: string): Promise<any> => {
    const path: string = DPS_API_URI + `/ucl-settings/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.get(path, params, token)
};

export const getEmails = async (token: string, compCode: string): Promise<any> => {
    const path: string = DPS_API_URI + `/ucl-emails/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.get(path, params, token)
};

export const getEmailsForType = async (token: string, compCode: string, emailType: string): Promise<any> => {
    const path: string = DPS_API_URI + `/ucl-emails/${compCode}`
    const params: any = {source: 'dam-aws', emailType: emailType}
    return damAxiosClient.get(path, params, token)
}

export const updateUclSettings = async (token: string, uclSettings: UclSettings, patchUclSetting: any): Promise<any> => {
    if (uclSettings.uclSettingsId) {
        const path: string = DPS_API_URI + `/ucl-settings/${uclSettings.uclSettingsId}`
        const params: any = {source: 'dam-aws'}
        return damAxiosClient.patch(path, params, token, patchUclSetting)
    } else {
        const path: string = DPS_API_URI + `/ucl-settings/${uclSettings.compCode}`
        const params: any = {source: 'dam-aws'}
        return damAxiosClient.put(path, params, token, patchUclSetting)
    }
};

export const insertEmails = async (token: string, compCode: string, emails: Array<Email>): Promise<any> => {
    const path: string = DPS_API_URI + `/ucl-emails/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.put(path, params, token, emails)
};

export const updateEmails = async (token: string, compCode: string, emails: Array<Email>): Promise<any> => {
    const path: string = DPS_API_URI + `/ucl-emails/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.patch(path, params, token, emails)
};

export const deleteEmails = async (token: string, compCode: string, emails: Array<Email>): Promise<any> => {
    const path: string = DPS_API_URI + `/ucl-emails/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.delete(path, params, token, emails)
};

// Email Deletion Tool Endpoint
export const deleteEmail = async (token: string, email: string): Promise<any> => {
    const path: string = DPS_API_URI + `/ucl-emails/email-delete/${email}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.delete(path, params, token, [])
};

export const getImages = async (token: string, compCode: string): Promise<any> => {
    const path: string = DPS_API_URI + `/images/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.get(path, params, token)
};

export const uploadImage = async (token: string, compCode: string, username: string, imageType: string, data: any): Promise<any> => {
    const path: string = DPS_API_URI + `/images/${compCode}/admin`;
    const params: any = {user: username, imageType, source: 'dam-aws'}
    return damAxiosClient.post(path, params, token, data)
};

export const deleteImage = async (token: string, compCode: string, username: string, imageId: string): Promise<any> => {
    const path: string = DPS_API_URI + `/images/${compCode}/${imageId}`
    const params: any = {user: username, source: 'dam-aws'}
    return damAxiosClient.delete(path, params, token)
};

export const getDealerStoreHours = async (token: string, compCode: string): Promise<any> => {
    const path: string = DPS_API_URI + `/store-hours/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.get(path, params, token)
}

export const updateDealerStoreHours = async (token: string, businessHours: BusinessHours): Promise<any> => {
    const path: string = DPS_API_URI + `/store-hours/${businessHours?.compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.put(path, params, token, businessHours)
}

export const getSmcSettings = async (token: string, compCode: string): Promise<any> => {
    const path: string = DPS_API_URI + `/smc-settings/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.get(path, params, token)
};

export const updateSmcSettings = async (token: string, smcSettings: SmcSettings, patchSmcSetting: any): Promise<any> => {
    if (smcSettings.smcSettingsId) {
        const path: string = DPS_API_URI + `/smc-settings/${smcSettings.smcSettingsId}`
        const params: any = {source: 'dam-aws'}
        return damAxiosClient.patch(path, params, token, patchSmcSetting)
    } else {
        const path: string = DPS_API_URI + `/smc-settings/${smcSettings.compCode}`
        const params: any = {source: 'dam-aws'}
        return damAxiosClient.put(path, params, token, smcSettings)
    }
};

export const getTwilioPhoneData = async (token: string, compCode: string): Promise<any> => {
    const path: string = DPS_API_URI + `/twilio-phone-data/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.get(path, params, token)
};

export const updateTwilioPhoneData = async (token: string, compCode: string, twilioPhoneData: Array<TwilioPhoneData>): Promise<any> => {
    const path: string = DPS_API_URI + `/twilio-phone-data/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.post(path, params, token, twilioPhoneData)
};

export const getAuditHistory = async (token: string, compCode: string): Promise<any> => {
    const path: string = DPS_API_URI + `/audit-history/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.get(path, params, token)
};

export const refreshBuiData = async (token: string, compCode: string, username: string): Promise<any> => {
    const path: string = DPS_API_URI + `/reload/${compCode}`
    const params: any = {user: username}
    let data: any = {"compCode": compCode}
    return damAxiosClient.post(path, params, token, data)
};

export const getSecondaryNames = async (token: string, compCode: string): Promise<any> => {
    const path: string = DPS_API_URI + `/secondary-names/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.get(path, params, token)
};

export const insertSecondaryNames = async (token: string, compCode: string, secondaryNames: Array<SecondaryName>): Promise<any> => {
    const path: string = DPS_API_URI + `/secondary-names/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.put(path, params, token, secondaryNames)
};

export const updateSecondaryNames = async (token: string, compCode: string, secondaryNames: Array<SecondaryName>): Promise<any> => {
    const path: string = DPS_API_URI + `/secondary-names/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.patch(path, params, token, secondaryNames)
};

export const deleteSecondaryNames = async (token: string, compCode: string, secondaryNames: Array<SecondaryName>): Promise<any> => {
    const path: string = DPS_API_URI + `/secondary-names/${compCode}`
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.delete(path, params, token, secondaryNames)
};

export const moveProfilePreferences = async (token: string, data: any): Promise<any> => {
    const path: string = DPS_API_URI + "/move/profilePreferences"
    const params: any = {source: 'dam-aws'}
    return damAxiosClient.post(path, params, token, data)
}