import { observer } from "mobx-react";
import React from "react";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import Store from "../../stores/";
import Button from "@carfax-stencils/button";
import CheckboxInput from "@carfax-stencils/input-checkbox";
import { Location } from "src/types/owner-group";
import "./LocationSelectionModal.scss";
import TextInput from "@carfax-stencils/input-text";
import { Search } from "@carfax-stencils/icon";

export default observer(() => {
  const pss = Store.preferences.preferencesSelection;
  const locations = pss.ownerGroup?.ownerGroup;
  const selectedCount = pss.selectedLocations.length

  if (!locations) return null;

    return (
        <Modal
            id="select-location-modal"
            isVisible={true}
            onClose={pss.handleSelectLocationsModalCancel}
        >
            <ModalHeader>
                <div>
                    <div className="selection-header-title-div"> Preferences Set-up Request</div>
                    <div className="selection-header-search-div"><LocationSearch /></div>
                </div>
            </ModalHeader>
            <ModalBody>
                <Locations locations={locations} />
            </ModalBody>
            <ModalFooter>
               <Button
                 id="location-selection-modal-send-to-button"
                 theme={"blue"}
                 size="large"
                 children={`Send to ${
                     selectedCount > 0 ? selectedCount : ""
                 } Location${selectedCount === 1 ? "" : "s"}`}
                 disabled={!pss.handleSelectLocationsModalSubmitIsEnabled}
                 onClick={pss.handleSendToLocations}
               />
            </ModalFooter>
        </Modal>
    )
});

const LocationSearch = observer(() => {
  const pss = Store.preferences.preferencesSelection;
  return (
    <TextInput
      id="select-location-text-input"
      className="selection-input"
      placeholder="Search by Dealer Name..."
      icon={<Search size={24} /> }
      value={pss.selectionModalSearch}
      onChange={pss.handleLocationSearchUpdate}
    />
  );
});

const Locations = observer((props: { locations: Location[] }) => {
  const pss = Store.preferences.preferencesSelection;
  const filteredLocations = props.locations.filter((location) => {
    if (pss.selectionModalSearch.length < 1) return true;
    return location.companyName
      .toLowerCase()
      .includes(pss.selectionModalSearch.toLowerCase());
  });
  return (
    <>
        <div id="select-location-header-div" className="location-selection-cell">
            <div id="select-location-header-location-div" className="selection-header-location">Locations</div>
            <div id="select-location-header-ucl" className="selection-header-ucl">Used Car Listings</div>
            <div id="select-location-header-c4l" className="selection-header-c4l">CARFAX for Life</div>
        </div>
      <div id="select-location-checkbox-input-div" className="location-selection-cell">
        <CheckboxInput
          id={pss.ownerGroupCompCode}
          checked={pss.isSelectedOwnerGroup}
          onChange={(option: React.ChangeEvent<HTMLInputElement>) => {
            pss.handleOwnerGroupCheck(
              option.target.checked
            );
          }}
          label={
            <span id="select-location-companyName-span" className="selection-col-text">
              <strong>{pss.ownerGroup?.companyName}</strong>{" "}
              <em>{`(${props.locations.length} Store${
                props.locations.length === 1 ? "" : "s"
              })`}</em>
            </span>
          }
        />
      </div>
      {filteredLocations.map((location) => {
        return (
          <div id="select-location-cell-outer-div" key={location.compCode} className="location-selection-cell">
              <div id="select-location-cell-inner-div" className="selection-col-location">
                  <CheckboxInput
                      id={location.compCode}
                      checked={pss.selectedLocations.some(it => it.compCode === location.compCode)}
                      disabled={!location.isUclDealer && !location.carfax4Life}
                      onChange={(option: React.ChangeEvent<HTMLInputElement>) => {
                          pss.handleLocationCheck(
                              location,
                              option.target.checked
                          );
                      }}
                      label={
                          <span id="select-location-inner-div-companyName-span" className="selection-col-text">
                                <strong>{location.companyName}</strong> <br/>
                              {`${location.address.city}, ${location.address.state}`}
                          </span>
                      }
                  />
              </div>

            <div id="select-location-ucl-checkbox-input-div" className="selection-col-ucl">
                <CheckboxInput
                    id={`${location.compCode}-ucl`}
                    checked={location.isUclDealer_selected ? location.isUclDealer_selected : false}
                    disabled={!location.isUclDealer}
                    onChange={(option: React.ChangeEvent<HTMLInputElement>) => {
                        pss.handleProductCheck(
                            "ucl",
                            location,
                            option.target.checked
                        );
                    }}
                />
            </div>
            <div id="select-location-c4l-checkbox-input-div" className="selection-col-c4l">
                <CheckboxInput
                    id={`${location.compCode}-c4l`}
                    checked={location.carfax4Life_selected ? location.carfax4Life_selected : false}
                    disabled={!location.carfax4Life}
                    onChange={(option: React.ChangeEvent<HTMLInputElement>) => {
                        pss.handleProductCheck(
                            "c4l",
                            location,
                            option.target.checked
                        );
                    }}
                />
            </div>
          </div>
        );
      })}
    </>
  );
});
