import { observer } from "mobx-react";
import React from "react";
import AcctMgmtStore from "../../../../stores/acctMgmt/acctMgmt";
import Button from "@carfax-stencils/button";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import {ProcessingApiSpinner} from "../../../../shared-components/processingApiSpinner/ProcessingApiSpinner";

import "../../../../styles/main.scss";
import "./CreateLegacyUserModal.scss";
import TextInput from "@carfax-stencils/input-text";

export type CreateLegacyUserProps = {
    isVisible : boolean;
    store : AcctMgmtStore;
}

const CreateLegacyUserModal = observer((props: CreateLegacyUserProps) => {

    return (
        <Modal
            id="create-legacy-user-modal"
            isVisible={true}
            onClose={props.store.closeCreateLegacyUserModal}
        >
            <ModalHeader>
                <div id='create-legacy-user-modal-header-div' className="create-legacy-user-modal__header">Create Legacy User</div>
            </ModalHeader>
            <ModalBody className="create-legacy-user-modal__body">
                {props.store.commonStore.isProcessingApi && (
                    <ProcessingApiSpinner/>
                )}
                <div id="create-legacy-user-modal-input-div" className="create-legacy-user-input-div">
                    <div id="create-legacy-user-modal-label-div" className="left-padding">
                        <p id="create-legacy-user-modal-label-text" className="input-label ">
                            Name on Report
                        </p>
                    </div>
                    <TextInput
                        id="create-legacy-user-modal-input-text"
                        className="left-padding"
                        value={props.store.createLegacyUserName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {props.store.onCreateLegacyUserNameChange(event.target.value)}}
                        maxLength={50}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    id="create-legacy-user-modal-save-button"
                    theme={"blue"}
                    size="large"
                    disabled={!props.store.createLegacyUserName}
                    onClick={() => props.store.createLegacyUser()}
                >
                    Create Legacy User
                </Button>
                <Button
                    id="create-user-modal-cancel-button"
                    theme={"black"}
                    size="large"
                    onClick={props.store.closeCreateLegacyUserModal}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
});

export default CreateLegacyUserModal;
