import * as React from "react"
import { withOktaAuth } from "@okta/okta-react"
import {
  Table,
  ITableProps
} from "../../shared-components/table/TableComponent"
import { PartnerConsumer, IContext } from "../context/PartnerContextProvider"
import InputText from "@carfax-stencils/input-text"
import Button from "@carfax-stencils/button"
import {Notification, NotificationCloseButton, NotificationIcon, NotificationMessage} from "@carfax-stencils/notification";
import { SpinnerDots } from "@carfax-stencils/spinner"
import Store from "../../stores/";
import "./PartnerMaintenance.scss"
import "../../styles/main.scss";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";

const columns = [
  {
    key: "id",
    label: "ID",
    sortable: true,
    width: "35%"
  },
  {
    key: "name",
    label: "Name",
    sortable: true,
    width: "35%"
  },
  {
    key: "createdDate",
    label: "Created Date",
    sortable: true,
    width: "35%"
  },
  {
    key: "createdBy",
    label: "Created By",
    sortable: true,
    width: "35%"
  }
]

export interface IState {
  partnerToAdd: string
  ready: boolean
  initialPageLoad: boolean
}

export interface IAuthInfo {
  token: string
  username: string
}

// Stores
const rootStore = Store.root.root;
const pms = rootStore.partnersStore;
const jsonParser: ((key: string, value: any) => any) | undefined = undefined

export default withOktaAuth(
  class PartnerMaintenance extends React.Component<{
    oktaAuth: any
    authState: any
  }> {
    addPartnerFunction: any

    state: IState = {
      partnerToAdd: "",
      ready: false,
      initialPageLoad: true
    }

    authInfo: IAuthInfo = {
      token: "",
      username: ""
    }

    bindAddPartnerFunctionToComponent = (
      addPartnerFunctionFromContext: Function
    ) => {
      this.addPartnerFunction = addPartnerFunctionFromContext
    }

    setPartnerToAdd = (event: React.ChangeEvent<HTMLInputElement>): void => {
      this.setState({
        partnerToAdd: event.target.value.toUpperCase()
      })
    }

    clearInputField = () => {
      this.setState({
        partnerToAdd: ""
      })
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      this.addPartnerFunction(this.authInfo, this.state.partnerToAdd)
      this.clearInputField()
    }

    async componentDidMount() {
      await this.getAuthenticationDetails()
      this.setState({
        ready: true
      })
    }

    async getAuthenticationDetails() {
      this.authInfo.token = await this.props.oktaAuth.getAccessToken()
      this.authInfo.username = JSON.parse(
        JSON.stringify(await this.props.oktaAuth.getUser()),
        jsonParser
      )["name"]
    }

    //No header (or other components) buried withn the Crm Consumer that is not Crm related
    render() {
      if (!this.state.ready) {
        return (
          <div id="partner-maintenance-spinner-div">
            <SpinnerDots id="partner-maintenance-spinner" />
          </div>
        )
      }
      return (
        <PartnerConsumer>
          {(partnerContext: IContext) => {
            if (this.state.initialPageLoad) {
              partnerContext.actions.getPartners(this.authInfo)
              this.bindAddPartnerFunctionToComponent(
                partnerContext.actions.addPartner
              )
              this.setState({
                initialPageLoad: false
              })
            }

            const validData: ITableProps = {
              columns: columns,
              paginate: 50,
              rowData: partnerContext.state.partners
                .filter((partner) => {
                  return partner.name
                    .toLowerCase()
                    .includes(this.state.partnerToAdd.toLowerCase())
                })
                .map((partner) => {
                  const rawdate = new Date(partner.createDate)
                  return {
                    id: partner.partnerId,
                    name: partner.name,
                    createdDate: rawdate.toDateString(),
                    createdBy: partner.createdBy
                  }
                })
            }
            return (
                <div id="partner-maintenance-main-div" >
                  <div id="partner-maintenance-table-div" className="partner-maintenance-table">
                    <div id="partner-maintenance-breadcrumb-trail-div" className="breadcrumb-trail">
                      <Breadcrumbs id="partner-maintenance-breadcrumbs"
                          crumbs={[
                            { value: "Home", href: "#" },
                            { value: "Partner Maintenance" },
                          ]}
                      />
                    </div>

                    {pms.notificationMessage &&
                        <div id="partner-maintenance-notification-bar-div" className="page-notification-div">
                          <Notification
                              id="partner-maintenance-notification-bar"
                              role={"alert"}
                              scrollIntoView={true}
                              theme={pms.notificationTheme}>
                            <NotificationIcon/>
                            <NotificationMessage>
                              {pms.notificationMessage}
                            </NotificationMessage>
                            <NotificationCloseButton
                                onClick={() => {pms.clearNotificationMessage()}}
                            />
                          </Notification>
                        </div>
                    }

                    <div id="partner-maintenance-add-partner-div" className="partner-maintenance-add-partner">
                      <form id="partner-maintenance-add-partner-form" onSubmit={this.handleSubmit}>
                        <InputText
                          id="partner-maintenance-add-partner-input-text"
                          value={this.state.partnerToAdd}
                          onChange={this.setPartnerToAdd}
                          maxLength={25}
                          placeholder="Find a partner"
                          usePlaceholderAsLabel={true}
                        />
                        <Button
                          id="partner-maintenance-add-partner-button"
                          theme={"blue"}
                          size="large"
                          disabled={validData.rowData?.length !== 0}
                          type={"submit"}>
                          Add Partner
                        </Button>
                      </form>
                    </div>
                    <br />
                    <Table {...validData} />
                  </div>
                </div>
            )
          }}
        </PartnerConsumer>
      )
    }
  }
)
