import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {makeAutoObservable, runInAction} from "mobx";
import {NotificationThemeType} from "@carfax-stencils/notification";
import {OwnerGroupResponse, Location, SelectLocation} from "../../types/owner-group";
import {Preferences, ProductType} from "../../types/preference";
import "../../utils/newRelic/newrelic";

import {
  closeRequest,
  createPreferences,
  getOwnerGroups,
  getPreferences,
  deleteLocation, addPreferences,
} from "../../clients/damBackendApi";
import AuthStore from "../auth";
import PreferencesSearchStore from "../search/preferences/preferencesSearch";


export default class PreferencesSelectionStore {

  // Variables
  buttonBarSelected: string = "Active Dealership Requests";
  notificationMessage: string = "";
  notificationTheme: NotificationThemeType | undefined;
  ownerGroupCompCode: string = "";
  preferences: Preferences | null = null;
  ready: boolean = false;
  ownerGroup: OwnerGroupResponse | null = null;
  resultsLabelText: string = "";

  // Location modal properties
  selectedLocations: Location[] = [];
  isSelectedOwnerGroup: boolean = false;
  isSelectionModalVisible: boolean = false;
  selectionModalSearch: string = "";

  // Add modal properties
  isAddModalVisible: boolean = false;
  locationToAdd: SelectLocation  | undefined;

  // Confirm modals
  isConfirmModalVisible: boolean = false;
  isConfirmDeleteModalVisible: boolean = false;

  // Stores
  rootStore: RootStore;
  authStore: AuthStore;
  searchResultsStore: PreferencesSearchStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.authStore = this.rootStore.authStore;
    this.searchResultsStore = this.rootStore.searchResultsStore;
    makeAutoObservable(this)
  }

  public initializeStore = () => {
    this.clearNotificationMessage();
    this.ownerGroupCompCode = "";
    this.preferences = null;
    this.ready = false;
    this.ownerGroup = null;
    this.resultsLabelText = "";
    this.selectedLocations = [];
    this.isSelectedOwnerGroup = false;
    this.isSelectionModalVisible = false;
    this.selectionModalSearch = "";
    this.isAddModalVisible = false;
    this.locationToAdd = undefined;
    this.isConfirmDeleteModalVisible = false;
    this.isConfirmModalVisible = false;
  };

  public get selectLocationsButtonEnabled(): boolean {
    if (
      this.ownerGroup &&
      this.ownerGroup.ownerGroup.length > 0 &&
      !this.preferences
    ) {
      return true;
    }
    return false;
  }

  public get closeRequestButtonEnabled(): boolean {
    if (this.ownerGroupCompCode && this.preferences) {
      return true;
    }
    return false;
  }

  public handleOwnerGroupCheck = (checked: boolean) => {
    this.isSelectedOwnerGroup = checked
    this.ownerGroup?.ownerGroup.forEach((location) => {
      this.handleLocationCheck(location, checked);
    });
  };

  public handleLocationCheck = (location: Location, checked: boolean) => {
    if (checked) {
      if (!this.selectedLocations.find(it => it.compCode === location.compCode)) {
        if (location.carfax4Life || location.isUclDealer) {
          this.selectedLocations.push(location);
          if (location.isUclDealer) {
            location.isUclDealer_selected = checked
          }
          if (location.carfax4Life) {
            location.carfax4Life_selected = checked
          }
        }
      }
    } else {
      const index = this.selectedLocations.indexOf(location);
      if (index > -1) {
        this.selectedLocations.splice(index, 1);
        location.carfax4Life_selected = false;
        location.isUclDealer_selected = false;
      }
    }
  };

  public handleProductCheck = (product: ProductType, location: Location, checked: boolean) => {
    if (product === "ucl") {
      location.isUclDealer_selected = checked;
    } else if (product === "c4l") {
      location.carfax4Life_selected = checked;
    }

    if (checked && !this.selectedLocations.find(it => it.compCode === location.compCode)) {
      this.selectedLocations.push(location);
    }

    if (!location.isUclDealer_selected && !location.carfax4Life_selected) {
      const index = this.selectedLocations.indexOf(location);
      if (index > -1) {
        this.selectedLocations.splice(index, 1);
      }
    }
  };

  public handleSelectLocationsButton = () => {
    this.isSelectionModalVisible = true;
  };

  public handleCloseGroupButton = () => {
    this.isConfirmModalVisible = true;
  };

  public handleAddLocationButton = () => {
    this.isAddModalVisible = true;
  };

  public handleDeleteModel = () => {
    this.isConfirmDeleteModalVisible = true;
  }

  public handleSelectLocationsModalCancel = () => {
    this.isSelectionModalVisible = false;
    this.selectedLocations = [];
    this.selectionModalSearch = ""
    this.isSelectedOwnerGroup = false;
  };

  public handleAddLocationModalCancel = () => {
    this.isAddModalVisible = false;
    this.locationToAdd = undefined;
  }

  public handleConfirmationClose = (confirmed?:boolean) => {
    this.isConfirmModalVisible = false;
    if (confirmed === true) {
      const id = this.preferences?.preferenceGroup?.preferenceSetupId
      if (id) {

        CommonUtils.logNewRelicPageAction("Preferences Setup", {userAction: 'Close Request', ownerGroupCompCode: this.ownerGroupCompCode});

        closeRequest(this.authStore.token, id).then((resp) => {
          switch(resp?.status){
            case 'SUCCESS' :
              runInAction(() => {
                this.preferences = null;
              });
              this.displayNotificationMessage(`Successfully closed request for ${this.ownerGroupCompCode}`, "green")
              break;
            case 'NOT FOUND' :
              this.resultsLabelText = 'No requests closed. No open requests found.';
              break;
            default :
              CommonUtils.displayConsoleLogError("*** error: " + JSON.stringify(resp));
              this.displayNotificationMessage(`Error on close request for comp code ${this.ownerGroupCompCode}`, "red")
              break;
          }
        });
      } else {
        CommonUtils.displayConsoleLogError("*** error: A preferenceSetupId was not available for call to close request. ");
        this.displayNotificationMessage(`Error on close request for comp code ${this.ownerGroupCompCode}`, "red")
      }
    }

  }

  public handleDeleteConfirmation = (confirmed?:boolean) => {
    this.isConfirmDeleteModalVisible = false;
    if (confirmed === true) {
      const location = this.preferences?.preferenceDetails?.find(
          (location) => location.isMarkedForDeletion
      ) ;
      if (location){

        CommonUtils.logNewRelicPageAction("Preferences Setup", {userAction: 'Delete Request', ownerGroupCompCode: this.ownerGroupCompCode});

        deleteLocation(this.authStore.token, location.preferenceSetupId , location.compCode).then((resp) => {
          switch(resp?.status){
            case 'SUCCESS' :
              this.handleOwnerGroupSearch();
              this.displayNotificationMessage(`Successfully removed comp code ${location.compCode}`, "green");
              break;
            case 'NOT FOUND' :
              this.resultsLabelText = 'Location not removed. No Location found.';
              break;
            default :
              CommonUtils.displayConsoleLogError("*** error: " + JSON.stringify(resp));
              this.displayNotificationMessage(`Error on Location removal for comp code ${location.compCode}`, "red");
              break;
          }
        });
      }
    }else {
      //
      this.preferences?.preferenceDetails?.forEach(
          (location) => location.isMarkedForDeletion = false
      );
    }

  }

  public handleLocationSearchUpdate = (e: any) => {
    this.selectionModalSearch = e.target.value;
  };

  public get handleSelectLocationsModalSubmitIsEnabled(): boolean {
    return this.selectedLocations.length > 0;
  }

  public get handleAddLocationModalSubmitIsEnabled(): boolean {
    return this.locationToAdd !== undefined && (this.locationToAdd.isUclDealer_selected || this.locationToAdd?.carfax4Life_selected)
  }


  public handleSearcKeyDown = () => {
    if(this.ownerGroupCompCode.length > 0) {
      this.handleOwnerGroupSearch()
    }
  }

  public handleOwnerGroupSearch = () => {
    this.clearNotificationMessage()

    CommonUtils.logNewRelicPageAction("Preferences Setup", {userAction: 'Search For Ownergroup', ownerGroupCompCode: this.ownerGroupCompCode})

    getOwnerGroups(this.authStore.token, this.ownerGroupCompCode).then((resp) => {

      if (resp?.status === "SUCCESS" &&
          resp.data &&
          resp.data.ownerGroup.length > 0
      ) {
        this.searchResultsStore.handleDisplaySearchResults(true);
        this.ownerGroup = resp.data;

        getPreferences(this.authStore.token, this.ownerGroupCompCode).then((resp) => {
          switch(resp?.status){
            case 'SUCCESS' :
              runInAction(() => {
                this.preferences = resp.data;
              });
              break;
            case 'NOT FOUND' :
              runInAction(() => {
                this.preferences = null;
              });
              this.resultsLabelText = 'No open requests available for this group.  Select locations to begin setup.';
              break;
            default :
              runInAction(() => {
                this.preferences = null;
              });
              CommonUtils.displayConsoleLogError("*** error: " + JSON.stringify(resp));
              this.displayNotificationMessage(`Error getting preferences data for comp code ${this.ownerGroupCompCode}`, "red");
              break;
          }
        });
      } else {
        this.ownerGroup = null;
        this.preferences = null;
        this.searchResultsStore.handleDisplaySearchResults(false);
        this.displayNotificationMessage(`Ownergroup data not found for comp code ${this.ownerGroupCompCode}`, "orange")
      }
    });
  };

  public addLocation = () => {

    if (this.locationToAdd) {

      CommonUtils.logNewRelicPageAction("Preferences Setup", {userAction: 'Add Location', compCode: this.locationToAdd.compCode, c4l: this.locationToAdd.carfax4Life_selected, ucl: this.locationToAdd.isUclDealer_selected});

      addPreferences(
          this.authStore.token,
          this.preferences?.preferenceGroup.preferenceSetupId,
          this.locationToAdd
      ).then((response) => {
        switch (response?.status) {
          case 'SUCCESS' :
            this.clearNotificationMessage()
            this.handleOwnerGroupSearch();
            break;
          default :
            this.displayNotificationMessage(response.damDefaultErrorMessage, "red")
            break;
        }

      });
    }
    this.handleAddLocationModalCancel()
  }

  public handleSendToLocations = () => {
    createPreferences(
      this.authStore.token,
      this.ownerGroupCompCode,
      this.selectedLocations
    ).then((response) => {
      this.handleSelectLocationsModalCancel()
      switch(response?.status){
        case 'SUCCESS' :
          this.clearNotificationMessage()
          this.handleOwnerGroupSearch();
          break;
        default :
          this.displayNotificationMessage(response.damDefaultErrorMessage, "red")
          break;
      }

    });
  };

  public handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.ownerGroupCompCode = event.target.value;
  };

  public handleCompCodeParam = (compCode: string) => {
    this.ownerGroupCompCode = compCode;
    this.handleOwnerGroupSearch()
  };

  public displayNotificationMessage(message: string, theme: NotificationThemeType) {
    this.notificationMessage = message;
    this.notificationTheme = theme;
  }

  public clearNotificationMessage() {
    this.notificationMessage = ""
    this.resultsLabelText = ""
  }

  public handleLocationSelected(option :any) {
    if (typeof option === "string") {
      this.locationToAdd = {
        displayValue: option,
        compCode: option.substr(0, option.indexOf("-")-1),
        carfax4Life_selected: false,
        isUclDealer_selected: false
      }
    }
  }

  public get addLocationButtonEnabled(): boolean {
    if (this.ownerGroupCompCode && this.preferences ) {
      const remainingLocations: Location[] = this.getUnSelectedLocations();
      if (remainingLocations !== undefined && remainingLocations.length > 0) {
        return true;
      }
    }
    return false;
  }

  public handleAddLocationC4lCheck = (checked: boolean) => {
    if (this.locationToAdd) {
      this.locationToAdd.carfax4Life_selected = checked;
    }
  };

  public handleAddLocationUclCheck = (checked: boolean) => {
    if (this.locationToAdd) {
      this.locationToAdd.isUclDealer_selected = checked;
    }
  };

  public get handleAddLocationC4lDisabled(): boolean {
    return this.locationToAdd === undefined || this.ownerGroup === null ||
        this.ownerGroup?.ownerGroup.some((location:Location) => {
          return location.compCode === this.locationToAdd?.compCode && !location.carfax4Life
        })
  }

  public get handleAddLocationUclDisabled(): boolean {
    return this.locationToAdd === undefined || this.ownerGroup === null ||
        this.ownerGroup?.ownerGroup.some((location:Location) => {
          return location.compCode === this.locationToAdd?.compCode && !location.isUclDealer
        })
  }

  public getUnSelectedLocations(): any {
     const remainingLocations = this.ownerGroup?.ownerGroup.filter((location: Location) => {
      return  (location.isUclDealer || location.carfax4Life) &&
      !this.preferences?.preferenceDetails?.some(pref => location.compCode === pref.compCode)
    })
    return remainingLocations;
  }

}
