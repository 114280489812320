export enum oem {
    ACURA = "Acura",
    ALFA_ROMEO = "Alfa Romeo",
    ASTON_MARTIN = "Aston Martin",
    AUDI = "Audi",
    BMW = "BMW",
    BUGATTI = "Bugatti",
    BUICK = "Buick",
    CADILLAC = "Cadillac",
    CHEVROLET = "Chevrolet",
    CHRYSLER = "Chrysler",
    DODGE = "Dodge",
    FIAT = "Fiat",
    FORD = "Ford",
    FREIGHTLINER = "Freightliner",
    GENESIS = "Genesis",
    GMC = "GMC",
    HONDA = "Honda",
    HYUNDAI = "Hyundai",
    INFINITI = "Infiniti",
    JAGUAR = "Jaguar",
    JEEP = "Jeep",
    KARMA = "Karma",
    KIA = "Kia",
    LAMBORGHINI = "Lamborghini",
    LAND_ROVER = "Land Rover",
    LEXUS = "Lexus",
    LINCOLN = "Lincoln",
    LOTUS = "Lotus",
    LUCID = "Lucid",
    MASERATI = "Maserati",
    MAZDA = "Mazda",
    MCLAREN = "McLaren",
    MERCEDES_BENZ = "Mercedes Benz",
    MINI = "Mini",
    MITSUBISHI = "Mitsubishi",
    NISSAN = "Nissan",
    POLESTAR = "Polestar",
    PORSCHE = "Porsche",
    RAM = "Ram",
    RIVIAN = "Rivian",
    ROLLS_ROYCE = "Rolls Royce",
    SUBARU = "Subaru",
    TESLA = "Tesla",
    TOYOTA = "Toyota",
    VOLKSWAGEN = "Volkswagen",
    VOLVO = "Volvo"
}