import * as React from "react";
import Button from "@carfax-stencils/button";
import TablePagination from '@carfax-stencils/table-pagination'
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import {observer} from "mobx-react";
import { Table, THead, TBody, TD, TH, TR } from '@carfax-stencils/table'
import "../../styles/main.scss";
import './ReputationPartnerHistoryModal.scss';
import ReputationPartnerHistoryStore from "../../stores/reputationPartner/reputationPartnerHistory";
import {ReputationPartnerHistory} from "../../types/reputationPartner/reputationPartnerHistory";
import { SpinnerFox } from "@carfax-stencils/spinner";

export type ReputationPartnerHistoryProps = {
    isVisible : boolean;
    store : ReputationPartnerHistoryStore;
}

const ReputationPartnerHistoryModal = observer((props: ReputationPartnerHistoryProps) => {
    return (
        <Modal
            id="reputation-partner-modal"
            isVisible={true}
            isWideFormat={true}
            className="reputation-partner-modal"
            onClose={props.store.closeModal}
        >
            <ModalHeader>
                <div id="reputation-partner-modal-header-div" className="reputation-partner-modal__header">Reputation Partner History</div>
            </ModalHeader>
            <ModalBody>
                <ReputationPartnerHistoryDetails {...props}/>
            </ModalBody>
            {props.store.commonStore.isProcessingApi &&
                <div id="reputation-partner-spinner-div">
                    <SpinnerFox id="reputation-partner-spinner" className="reputation-partner-modal__spinner" size={130} />
                </div>}
            <ModalFooter>
                <div className={"reputation-partner-modal__footer-row-div"} >
                    <div id="reputation-partner-modal-footer-div">
                        <Button
                            id="dismiss-button"
                            size="small"
                            theme="black"
                            onClick={() => props.store.closeModal()}>
                            Dismiss
                        </Button>
                    </div>
                </div>

            </ModalFooter>
        </Modal>
    )
});

const RenderPaginator = observer((props: {store: ReputationPartnerHistoryStore}) => {
    return (
        props.store.showPaginator() ? (
            <div className="reputation-partner-modal__table-pager">
                <TablePagination
                    onChange={props.store.handlePaginationChange}
                    perPageOptions={[10,20,50]}
                    currentPage={props.store.paginationCurrentPage}
                    perPageValue={props.store.paginationPerPageValue}
                    totalItems={props.store.reputationPartnerHistory.length}
                    tooltipTheme={"light"}
                />
            </div>
        ) : null
    )
});

const ReputationPartnerHistoryDetails = observer((props: ReputationPartnerHistoryProps) => {
    return (
        <div id="reputation-partner-history-details-div">
            <div id="paginator-div-top" className="reputation-partner-modal__paginator-div-top">
                <RenderPaginator store={props.store}/>
            </div>
            <Table aria-describedby="reputation-partner-history-details-table">
                <THead  className="reputation-partner-history-table__heading">
                    <TH className="reputation-partner-history-table__heading__TH" width={120}>Date</TH>
                    <TH className="reputation-partner-history-table__heading__TH" width={230}>Username</TH>
                    <TH className="reputation-partner-history-table__heading__TH" width={190}>Action</TH>
                    <TH className="reputation-partner-history-table__heading__TH" width={240}>Partner</TH>
                </THead>
                <TBody>
                    {props.store.paginatedPartnerHistory.map((record: ReputationPartnerHistory, index) => (
                        <TR className="reputation-partner-history-table__row" key={record.compCode + "_" + index}>
                            <TD className="reputation-partner-history-table__column">{record.displayDate}</TD>
                            <TD className="reputation-partner-history-table__column">{record.createdBy}</TD>
                            <TD className="reputation-partner-history-table__column">{record.action}</TD>
                            <TD className="reputation-partner-history-table__column">{record.partnerName}</TD>
                        </TR>
                    ))}
                </TBody>
            </Table>
            <div id="paginator-div" className="reputation-partner-modal__paginator-div-bottom">
                <RenderPaginator store={props.store}/>
            </div>
        </div>
    )
});

export default ReputationPartnerHistoryModal;
