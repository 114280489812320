import UrlBuilder from "../utils/urlBuilder";
import axios, {AxiosError, AxiosResponse} from "axios";
import {apiStatus} from "../utils/constants";

const API_URI = UrlBuilder.buildConsumerUrl()

export const getWidgetData = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/widget-data`
    console.log(" path= "+ path)
    const params :any = {compCode: compCode}

    return axios.get(path, {
        params: params,
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json"
        }})
        .then((response : AxiosResponse) => {
            return {data: response.data, status: apiStatus.success }
        })
        .catch((error : AxiosError) => {
            const message = "An unknown error has been encountered. "
            return { data: null, status: apiStatus.error, damErrorCode: error.response?.status, damDefaultErrorMessage: message };
        })
}
