import * as React from "react";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import Button from "@carfax-stencils/button";
import './UnsavedChangedModal.scss';
import {AlertTriangle} from "@carfax-stencils/icon";

export type UnsavedChangesProps = {
    headerTitle: string;
    isVisible : boolean;
    onCloseMethod : () => void;
    onSaveMethod : () => void;
    onContinueWithoutSavingMethod : () => void;
    disableSave : boolean;
}

const UnsavedChangesModal = ((props: UnsavedChangesProps ) => {

    return (
        <Modal
            id="unsaved-changes-modal"
            isVisible={true}
            className="unsaved-changes-modal"
            onClose={props.onCloseMethod}
        >
            <ModalHeader>
                <div id="alert-icon-div" className="alert-icon">
                    <AlertTriangle size={25}/>
                </div>
                <div id="modal-header-div" className="unsaved-changes-modal__header">{props.headerTitle}</div>
            </ModalHeader>
            <ModalBody>
                {!props.disableSave &&
                    <div>
                        <div id="modal-body-div-subheading" className="unsaved-changes-modal__subheading">
                            Do you want to save your updates?
                        </div>
                        <div id="modal-body-div-custom-message" className="unsaved-changes-modal__message">
                            Without saving, any updates/changes made on the current tab will not be stored and changes will be lost.
                        </div>
                    </div>
                }
                {props.disableSave &&
                    <div>
                        <div id="modal-body-div-subheading" className="unsaved-changes-modal__subheading">
                            You have invalid changes on this tab.
                        </div>
                        <div id="modal-body-div-custom-message" className="unsaved-changes-modal__message">
                            Close modal to continue editing. Continuing will lose any unsaved changes.
                        </div>
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                 <Button
                     id="save-button"
                     size="small"
                     theme="blue"
                     disabled={props.disableSave}
                     onClick={() => props.onSaveMethod()}
                 >
                     Save updates
                 </Button>
                 <Button
                     id="continue-without-saving-button"
                     size="small"
                     theme="black"
                     onClick={() => props.onContinueWithoutSavingMethod()}
                 >
                     <>Continue without saving</>
                 </Button>
            </ModalFooter>
        </Modal>
    )
});

export default UnsavedChangesModal;
