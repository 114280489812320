
export default class Validator {

    public static containsSpecialCharacters(value: string) : boolean {
        const regEx = new RegExp(/[^a-zA-Z\d]/);
        return regEx.test(value);
    }

    public static evaluateDocumentFee(documentFee: number) : boolean {
        return(documentFee > 1000);
    };

    public static isCarfaxEmail(email: string) {
        return email && /^(.*?(\bcarfax.com\b)[^$]*)$/i.test(email);
    }

    public static isValidEmail(email: string) {
        return email.length > 0 && /^[A-Z\d_+-]+(?:\.[A-Z\d_+-]+)*@(?:[A-Z\d](?:[A-Z\d-]*[A-Z\d])?\.)+[A-Z\d](?:[A-Z\d-]*[A-Z\d])?$/i.test(email);
    }

    public static validateUrl(websiteUrl: string) : boolean {
        const regEx = new RegExp(/^(http|https):\/\/[^ "]+(\.[^ "]{1,})$/);
        return regEx.test(websiteUrl);
    };
};
