import {observer} from "mobx-react";
import * as React from "react";
import "./EnhancedEmailHeader.scss";

export const EnhancedEmailHeader = observer(() => {
    return (
        <div className="enhanced-email-header-input-row">
            <p className="bold all-car-listings-label" >All Car Listings</p>
            <p className="bold used-car-listings-label" >Used Car Listings</p>
            <p className="bold new-car-listings-label" >New Car Listings</p>
            <p className="bold delete-label" >Delete</p>
        </div>
    )
});