import {withOktaAuth} from "@okta/okta-react";
import * as React from "react";
import Page from "../../../shared-components/layout/page/Page";
import Header from "../../../shared-components/layout/header/Header";
import {observer} from "mobx-react";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import Store from "../../../stores";
import {
    Notification,
    NotificationCloseButton,
    NotificationIcon,
    NotificationMessage
} from "@carfax-stencils/notification";
import SelectInput from "@carfax-stencils/input-select";
import "./RunReports.scss"
import "../../../styles/main.scss";
import {ReportsToRunEnum} from "../../../stores/utilities/runReports/runReports";
import TextInput from "@carfax-stencils/input-text";
import Button from "@carfax-stencils/button";
import {Fragment} from "react";

// Stores
const rootStore = Store.root.root;
const commonStore = rootStore.commonStore;
const runReportsStore = rootStore.runReportsStore;

export default withOktaAuth(

    class RunReportsPage extends React.Component {

        state = {
            ready: false
        };

        async componentDidMount() {
            this.setState(
                {ready:true}
            )
            await this.initializePage()
        };

        initializePage = async () => {
            runReportsStore.initializeStore()
        };

        render() {
            return (
                <Page id="run-reports-page" title="Dealer Account Management | Run Reports">
                    <div id="run-reports-page-div">
                        <Header id="run-reports-page-header" />
                        <this.PageContent />
                    </div>
                </Page>
            );
        };

        PageContent = observer(() => {
            return (
                <div id="run-reports-outer-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner/>
                    )}
                    <div id="run-reports-breadcrumbs-div" className="breadcrumb-trail">
                        <Breadcrumbs
                            id="run-reports-breadcrumbs"
                            crumbs={[
                                { value: "Home", href: "#" },
                                { value: "Utilities", href: "/utilities" },
                                { value: "Run Reports" },
                            ]}
                        />
                    </div>
                    <div id="run-reports-page-heading-div" className="page__heading">
                        Run Reports
                    </div>

                    {/*  COMMON PAGE-LEVEL NOTIFICATIONS  */}
                    {commonStore.pageMsg && (
                        <div id="run-reports-notification-div" className="page-notification-div">
                            <Notification
                                id="run-reports-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.pageMsgTheme}>
                                <NotificationIcon/>
                                <NotificationMessage>
                                    {commonStore.pageMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => {commonStore.clearPageMsg()}}
                                />
                            </Notification>
                        </div>
                    )}

                    <div id="run-reports-outer-div" className="run-reports__layout-wrapper">
                        <div id="run-reports-content-div" className="run-reports__content-div">
                            <div id="run-reports-label-div" className="run-reports__label-div">
                                <p id="run-reports-select-report-text" className="primary_label">
                                    Select report to run:
                                </p>
                                <div id ="run-reports-dropdown-div" className="run-reports__dropdown">
                                    <SelectInput
                                        id="run-reports-dropdown-input"
                                        value={runReportsStore.reportToRun}
                                        onChange={runReportsStore.onRunReportsSelection}
                                        placeholder="Select the report"
                                    > 
                                        {
                                            runReportsStore.reportToRun === "" &&
                                            <option value="">Select the report</option>
                                        }
                                        {runReportsStore.reportsToRunList.map((item: string, index: number) =>
                                            <option key={index} value={item}>
                                                {item}
                                            </option>      
                                        )}
                                    </SelectInput>
                                </div>
                            </div>
                            {runReportsStore.reportToRun === ReportsToRunEnum.UAS_USER_NEW_REPORT && <UasNewUserReport/>}
                            {runReportsStore.reportToRun === ReportsToRunEnum.UAS_USER_LEGACY_REPORT && <UasLegacyUserReport/>}
                            {runReportsStore.reportToRun === ReportsToRunEnum.SMS_LEADS_OPT_IN_REPORT && <SmsLeadsOptInReport/>}
                            {runReportsStore.reportToRun !== "" &&
                                <Button
                                    id="run-reports-submit-button"
                                    className={runReportsStore.reportToRun === ReportsToRunEnum.UAS_USER_NEW_REPORT ? "run-reports__submit-button-uas-new" : "run-reports__submit-button"}
                                    theme="blue"
                                    size="large"
                                    disabled={runReportsStore.disableSubmitButton()}
                                    onClick={() => runReportsStore.submitReport()}>
                                    Submit Report
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            );
        });

    }
);

const UasNewUserReport = observer(() => {
    return(
        <Fragment>
            <div id="uas-user-new-report-desc-div" className="run-reports__label-div">
                <p id="uas-user-new-report-desc-label" className="primary_label">
                    Report Description:
                </p>
                <p id="uas-user-new-report-desc-text" className="secondary_text">
                    Users per Location Report (.csv file) will be sent to the entered email.
                </p>
            </div>
            <div id="uas-user-new-content-div" className="uas-user-new__text-input-div">
              <p id="uas-user-new-comp-code-label" className="primary_label">
                  Enter Comp Code:
              </p>
              <TextInput
                  id="uas-user-new-comp-code-input"
                  value={runReportsStore.compCode}
                  onChange={runReportsStore.onCompCodeChange}
                  type="text"
                  aria-invalid={!runReportsStore.isValidCompCode()}
              />
            </div>
            <div id="uas-user-new-content-div" className="uas-user-new__text-input-div">
              <p id="uas-user-new-email-label" className="primary_label">
                  Enter Email Address(es):
              </p>
              <TextInput
                  id="uas-user-new-email-input"
                  value={runReportsStore.emailListToSendReport}
                  onChange={runReportsStore.onEmailListChange}
                  type="text"
                  aria-invalid={!runReportsStore.isValidListEmail()}
              />
            </div>
        </Fragment>
    );
});

const UasLegacyUserReport = observer(() => {
    return(
        <Fragment>
            <div id="uas-user-legacy-report-desc-div" className="run-reports__label-div">
                <p id="uas-user-legacy-report-desc-label" className="primary_label">
                    Report Description:
                </p>
                <p id="uas-user-legacy-report-desc-text" className="secondary_text">
                    Ownergroup Users Legacy Report (.csv file) will be sent.
                </p>
            </div>
            <div id="uas-user-legacy-content-div" className="uas-user-new__text-input-div">
                <p id="uas-user-legacy-comp-code-label" className="primary_label">
                    Enter Comp Code:
                </p>
                <TextInput
                    id="uas-user-legacy-comp-code-input"
                    value={runReportsStore.compCode}
                    onChange={runReportsStore.onCompCodeChange}
                    type="text"
                    aria-invalid={!runReportsStore.isValidCompCode()}
                />
            </div>
        </Fragment>
    );
});

const SmsLeadsOptInReport = observer(() => {
    return(
        <Fragment>
            <div id="sms-leads-opt-in-report-desc-div" className="run-reports__label-div">
                <p id="sms-leads-opt-in-report-desc-label" className="primary_label">
                    Report Description:
                </p>
                <p id="sms-leads-opt-in-report-desc-text" className="secondary_text">
                    Creates a report (in .csv format) of all UAS users opted-in for SMS Leads. The report will be sent to the email recipients entered below.
                </p>
                <p id="sms-leads-opt-in-report-desc-text-note" className="secondary_text top-padding">
                    <i>NOTE - This can take several minutes to complete.</i>
                </p>
            </div>
            <div id="sms-leads-opt-in-content-div" className="uas-user-new__text-input-div">
                <p id="sms-leads-opt-in-email-label" className="primary_label">
                    Enter Email Address(es):
                </p>
                <TextInput
                    id="sms-leads-opt-in-email-input"
                    value={runReportsStore.emailListToSendReport}
                    onChange={runReportsStore.onEmailListChange}
                    type="text"
                    aria-invalid={!runReportsStore.isValidListEmail()}
                />
            </div>
        </Fragment>
    );
});