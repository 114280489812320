
export type DayNames = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
export type WeekBusinessHours = {
    [name in DayNames]: Day
}

export type BusinessHours = { compCode: string } & WeekBusinessHours

export type Day = {
    id?: number,
    open: string | null,
    close: string | null,
    byAppointment: boolean,
    hoursToggle: boolean
}

export const daysList: Array<DayNames> = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
