import * as React from "react";
import Button from "@carfax-stencils/button";
import {Notification, NotificationCloseButton, NotificationIcon, NotificationMessage} from "@carfax-stencils/notification";
import TextInput from "@carfax-stencils/input-text";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import {RadioGroup, RadioInput} from "@carfax-stencils/input-radio-group";
import {SpinnerDots} from "@carfax-stencils/spinner";
import {observer} from "mobx-react";
import {twilioPhoneNumberType} from "../../../types/profile/enums/twilioPhoneNumberType";
import CallMgmtSettingsStore from "../../../stores/profile/callMgmtSettings";
import './ProvisionNumberModal.scss';

export type ProvisionNumberProps = {
    headerTitle: string;
    isVisible : boolean;
    store: CallMgmtSettingsStore;
}

const ProvisionNumberModal = observer((props: ProvisionNumberProps) => {

    return (
        <Modal
            id="provision-number-modal"
            isVisible={true}
            className="provision-modal"
            onClose={props.store.handleCloseProvisionModal}
        >
            <ModalHeader>
                <div id="modal-header-div" className="provision-modal__header">{props.headerTitle}</div>
            </ModalHeader>
            <ModalBody>
                {props.store.provisionProcessingApi &&
                    <div id="processing-api-spinner" className="provision-api-spinner-div">
                        <SpinnerDots id="provision-api-spinner" size={70}/>
                    </div>
                }
                {props.store.provisionModalBodySelect && (<SelectTrackingNumberSection {...props}/>)}
                {props.store.provisionModalBodyConfirm && (<ConfirmNumberSection {...props}/>)}
            </ModalBody>
            <ModalFooter>
                <div id="next-step-button-div">
                    {props.store.provisionModalBodySelect && (
                        <Button
                            id="next-step-button"
                            size="small"
                            theme="blue"
                            disabled={props.store.nextStepButtonDisabled()}
                            onClick={() => props.store.getAvailableNumbersFromTwilio()}
                        >
                            Next Step
                        </Button>
                    )}
                    {!props.store.provisionModalBodySelect && (
                        <div>
                            <Button
                                id="submit-button"
                                size="small"
                                theme="blue"
                                onClick={() => props.store.provisionSubmitClicked()}
                            >
                                Submit
                            </Button>
                            <Button
                                id="back-button"
                                size="small"
                                theme="black"
                                onClick={() => props.store.provisionBackClicked()}
                            >
                                Back
                            </Button>
                        </div>
                    )}
                </div>

            </ModalFooter>
        </Modal>
    )
});

const SelectTrackingNumberSection = observer((props: ProvisionNumberProps) => {
    return (
        <div id="select-type-section-div" className="provision-modal__section">
            <div id="twilio-error-notification-div" className="twilio-error-notification-div">
                {props.store.provisionNumberSelectErrorMsg && (
                    <Notification
                        id="twilio-error-notification"
                        role={"alert"}
                        theme={"red"}>
                        <NotificationIcon/>
                        <NotificationMessage>
                            {props.store.provisionNumberSelectErrorMsg}
                        </NotificationMessage>
                        <NotificationCloseButton
                            onClick={() => {props.store.clearTrackingNumberNotifications()}}
                        />
                    </Notification>
                )}
            </div>
            <div id="select-tracking-number-heading">
                <p id="select-tracking-number-heading-text" className="provision-modal__heading">Select a tracking number</p>
            </div>
            <RadioGroup
                id="select-type-radio-group"
                value={props.store.provisionPhoneNumberType}
                onChange={event => props.store.handleOnChangePhoneNumberType(event.target.value)}
            >
                <RadioInput value={twilioPhoneNumberType.LOCAL} />
                    <div>
                        {props.store.provisionPhoneNumberType === twilioPhoneNumberType.LOCAL &&
                            (
                                <div id="local-number-search-div" className="provision-modal__local-search-div">
                                    <p>Enter an <b>area code</b> to search for local numbers, or enter both an <b>area code and a prefix</b> for an exact regional match.</p>
                                    <div id="" className="provision-modal__phone-number-div">
                                        <p id="left-parenthesis" className="provision-modal__left-parenthesis">(</p>
                                        <div id="area-code-div" className="provision-modal__local-number-input">
                                            <TextInput
                                                id="area-code-input"
                                                value={props.store.provisionNumberAreaCode}
                                                className={(props.store.provisionNumberSelectErrorMsg) ? "provision-modal__local-number-input_error" : ""}
                                                onChange={event => props.store.handleOnChangeAreaCode(event.target.value)}
                                                type={"string"}
                                                max={999}
                                                maxLength={3}
                                            />
                                        </div>
                                        <p id="right-parenthesis" className="provision-modal__right-parenthesis">)</p>
                                        <div id="prefix-div" className="provision-modal__local-number-input">
                                            <TextInput
                                                id="prefix-input"
                                                value={props.store.provisionNumberPrefix}
                                                className={(props.store.provisionNumberSelectErrorMsg) ? "provision-modal__local-number-input_error" : ""}
                                                onChange={event => props.store.handleOnChangePrefix(event.target.value)}
                                                type={"string"}
                                                max={999}
                                                maxLength={3}
                                            />
                                        </div>
                                        <p id="prefix-number-dash" className="provision-modal__prefix-number-dash">- 0000</p>
                                    </div>
                                </div>

                            )
                        }
                    </div>
                <RadioInput value={twilioPhoneNumberType.TOLL_FREE} />
            </RadioGroup>
        </div>
    )
});

const ConfirmNumberSection = observer((props: ProvisionNumberProps) => {
    return (
        <div id="confirm-number-section-div">
            <div id="twilio-confirm-error-notification-div" className="twilio-confirm-error-notification-div">
                {props.store.provisionNumberConfirmErrorMsg && (
                    <Notification
                        id="twilio-confirm-error-notification"
                        role={"alert"}
                        theme={"red"}>
                        <NotificationIcon/>
                        <NotificationMessage>
                            {props.store.provisionNumberConfirmErrorMsg}
                        </NotificationMessage>
                        <NotificationCloseButton
                            onClick={() => {props.store.clearTrackingNumberNotifications()}}
                        />
                    </Notification>
                )}
            </div>
            {props.store.provisionModalModifiedSearch && (
                <div>
                    <div id="modified-search-heading">
                        <p id="modified-search-heading-text" className="provision-modal__heading">We're sorry there are no tracking numbers available that match your search.</p>
                    </div>
                    <div id="modified-search-subheading">
                        <p id="modified-search-heading-text" className="provision-modal__subheading">We have run a search using the area code entered to provide a number closest to your original search terms.</p>
                    </div>
                </div>
            )}
            {!props.store.provisionModalModifiedSearch && (
                <div id="confirm-number-heading">
                    <p id="confirm-number-heading-text" className="provision-modal__heading">Confirm and provision selected tracking number</p>
                </div>
            )}

            <div id="confirm-preview-div" className="provision-modal__confirm-preview-div">
                <div id="phone-number-row-div" className="flex-row confirm-number-item-row">
                    <div id="phone-number-label-div" className="flex-col provision-modal__label">Number:</div>
                    <p id="phone-number-p" className="flex-col confirm-phone-number-text">{props.store.provisionAvailableNumber.phoneNumber}</p>
                </div>
                <div id="region-row-div" className="flex-row confirm-number-item-row">
                    <div id="region-label-div" className="flex-col provision-modal__label">Region:</div>
                    <p id="region-p" className="flex-col">{props.store.provisionAvailableNumber.region}</p>
                </div>
                <div id="postal-code-row-div" className="flex-row confirm-number-item-row">
                    <div id="postal-code-label-div" className="flex-col provision-modal__label">Postal Code:</div>
                    <p id="postal-code-p" className="flex-col">{props.store.provisionAvailableNumber.postalCode}</p>
                </div>
                <div id="account-row-div" className="flex-row confirm-number-item-row">
                    <div id="account-label-div" className="flex-col provision-modal__label">Account:</div>
                    <p id="account-code-p" className="flex-col">{props.store.rootStore.businessUserStore.businessUser?.companyName}</p>
                </div>
                <div id="date-row-div" className="flex-row confirm-number-item-row">
                    <div id="date-label-div" className="flex-col provision-modal__label">Date:</div>
                    <p id="date-code-p" className="flex-col">{props.store.provisionNumberDate}</p>
                </div>
            </div>
        </div>
    )
});

export default ProvisionNumberModal;
