import AuthStore from "../auth";
import CommonUtils from "../../utils/commonUtils";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore, {
    NEW_LISTINGS_RING_TO_FIELD_NAME,
    SMC_RING_TO_FIELD_NAME, SRV_APPT_RING_TO_FIELD_NAME,
    UCL_RING_TO_FIELD_NAME
} from "../common/common";
import EmailsStore from "./emails";
import RootStore from "../root";
import Validator from "../helpers/validator";
import React, {createRef} from "react";
import {Email} from "../../types/email";
import {emailTypeEnum} from "../../types/profile/enums/emailTypeEnum";
import {getTwilioPhoneData, updateTwilioPhoneData} from "../../clients/dps/dpsApi";
import {makeAutoObservable, runInAction} from "mobx";
import {NotificationMessages} from "../common/messaging/notificationMessages";
import {TwilioPhoneData} from "../../types/profile/twilioPhoneData";
import {trackingNumberStatus} from "../../types/profile/enums/trackingNumberStatus";
import {twilioPhoneDataType} from "../../types/profile/enums/twilioPhoneDataType";
import {twilioPhoneNumberType} from "../../types/profile/enums/twilioPhoneNumberType";
import {TwilioAvailableNumber} from "../../types/profile/twilioAvailableNumber";
import {TwilioPurchasedNumber} from "../../types/profile/twilioPurchasedNumber";
import {apiStatus, DB_ACTION_DELETE, TWILIO_WHISPER} from "../../utils/constants";

import {
    deprovisionTwilioTrackingNumber,
    getAvailableTwilioNumber,
    provisionTwilioTrackingNumber
} from '../../clients/ctsApi';

import {
    ERROR_MSG_DUPLICATE_EMAILS,
    ERROR_MSG_EMAIL_CALL_ADF_TEXT_DUPLICATE,
    ERROR_MSG_EMAIL_INVALID,
    ERROR_MSG_INVALID_PHONE,
    ERROR_MSG_NO_TWILIO_NUMBERS_FOR_CRITERIA,
    ERROR_MSG_NO_TWILIO_TOLL_FREE_NUMBERS, ERROR_MSG_REPEATED_RING_TO,
    ERROR_MSG_TWILIO_API_ERROR,
    ERROR_MSG_TWILIO_DPS_API_ERROR,
    resolveInvalidCarfaxEmailMessage
} from "./messaging/errorMessagesProfile";

import {emailListingsType} from "../../types/profile/enums/emailListingsType";


export default class CallMgmtSettingsStore {

    // Fields / Types
    compCode: string = "";
    callAlertAdfEmails: Array<Email> = new Array<Email>();
    callAlertTextEmails: Array<Email> = new Array<Email>();
    tradeInAdfCallAlertEmails: Array<Email> = new Array<Email>();
    tradeInTextCallAlertEmails: Array<Email> = new Array<Email>();
    srvApptTextLeadEmails: Array<Email> = new Array<Email>();
    focusOnEmailTextInput: boolean = false;
    emailInputRef: React.RefObject<HTMLInputElement> = createRef();
    isRingToToggled: boolean = false;

    uclTwilioPhoneData: TwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.UCL);
    originalUclTwilioPhoneData: TwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.UCL);
    uclTrackingNumberMessage: string = "";

    newListingsTwilioPhoneData: TwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.NEWLISTINGS);
    originalNewListingsTwilioPhoneData: TwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.NEWLISTINGS);
    newListingsTrackingNumberMessage: string = "";

    smcTwilioPhoneData: TwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.SMC);
    originalSmcTwilioPhoneData: TwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.SMC);
    smcTrackingNumberMessage: string = "";

    srvApptTwilioPhoneData: TwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.SERVICE_APPT);
    originalSrvApptTwilioPhoneData: TwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.SERVICE_APPT);
    srvApptTrackingNumberMessage: string = "";

    provisionModalBodySelect: boolean = true;
    provisionModalBodyConfirm: boolean = false;
    provisionModalModifiedSearch: boolean = false;
    provisionNumberType: string = typeof twilioPhoneDataType;
    provisionNumberTypeTitle: string = "";
    provisionNumberAreaCode: string = "";
    provisionNumberPrefix: string = "";
    provisionNumberDate: string = "";
    provisionNumberSelectErrorMsg: string = "";
    provisionNumberConfirmErrorMsg: string = "";
    provisionPhoneNumberType: string = typeof twilioPhoneNumberType
    provisionAvailableNumber: TwilioAvailableNumber = this.initializeTwilioPhoneNumber();
    provisionProcessingApi: boolean = false;

    //confirm modals
    deleteTrackingNumberType: string = "";
    isConfirmDeleteModalVisible: boolean = false;
    isProvisionNumberModalVisible: boolean = false;
    showUnsavedModalForTrkNum: boolean = false;
    isUnsavedTrkNumModalForDeprovision: boolean = false;

    // Stores
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;
    emailsStore: EmailsStore;
    rootStore: RootStore;


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authStore = this.rootStore.authStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;
        this.emailsStore = this.rootStore.emailsStore;
        this.initializeStore();

        makeAutoObservable(this);
    };

    public initializeStore() {
        this.compCode = this.businessUserStore?.businessUser?.compCode || "";
        this.provisionAvailableNumber = this.initializeTwilioPhoneNumber();
        this.isRingToToggled = false;

        this.uclTrackingNumberMessage = "";
        this.uclTwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.UCL);
        this.originalUclTwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.UCL);

        if (this.businessUserStore.isDealerNew()) {
            this.newListingsTrackingNumberMessage = "";
            this.newListingsTwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.NEWLISTINGS);
            this.originalNewListingsTwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.NEWLISTINGS);
        }

        this.smcTrackingNumberMessage = "";
        this.smcTwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.SMC);
        this.originalSmcTwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.SMC);

        this.srvApptTrackingNumberMessage = "";
        this.srvApptTwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.SERVICE_APPT);
        this.originalSrvApptTwilioPhoneData = this.initializeTwilioPhoneData(twilioPhoneDataType.SERVICE_APPT);
    }

    private initializeTwilioPhoneData(type: string): TwilioPhoneData {
        return {
            phoneDataId: undefined,
            twilioSid: "",
            trackingPhoneNumber: "",
            friendlyName: undefined,
            ringToPhoneNumber: undefined,
            compCode: this.compCode,
            callAlertConsent: true,
            type: type,
            whisper: undefined,
            status: trackingNumberStatus.INACTIVE,
            provisionedDate: undefined,
            displayDate: undefined,
            disableTrackingNumber: false
        }
    }

    private initializeTwilioPhoneNumber(): TwilioAvailableNumber {
        return {
            phoneNumber: "",
            friendlyName: "",
            region: "",
            postalCode: ""
        }
    }

    public resetStoreToOriginalData() {
        this.commonStore.clearFormErrors();
        this.uclTwilioPhoneData = CommonUtils.cloneObject(this.originalUclTwilioPhoneData);
        this.newListingsTwilioPhoneData = CommonUtils.cloneObject(this.originalNewListingsTwilioPhoneData);
        this.smcTwilioPhoneData = CommonUtils.cloneObject(this.originalSmcTwilioPhoneData);
        this.srvApptTwilioPhoneData = CommonUtils.cloneObject(this.originalSrvApptTwilioPhoneData);
        this.emailsStore.resetEmailsToOriginalData([emailTypeEnum.ADF_CALL_ALERT, emailTypeEnum.TEXT_CALL_ALERT,
            emailTypeEnum.TRADE_IN_ADF_CALL_ALERT, emailTypeEnum.TRADE_IN_CALL_ALERT, emailTypeEnum.SERVICE_APPT_TEXT_LEAD]);
        this.setNewListingsDefaults()
    };

    public getTwilioPhoneData = () => {
        this.initializeStore();

        getTwilioPhoneData(this.authStore.token, this.compCode ).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        let twilioData : Array<TwilioPhoneData> = resp.data;
                        twilioData.forEach(twilioRecord => {

                            if(twilioRecord.ringToPhoneNumber === null) {
                                twilioRecord.ringToPhoneNumber = undefined;
                            }

                            switch (twilioRecord.type) {
                                case twilioPhoneDataType.UCL:
                                    this.uclTwilioPhoneData = twilioRecord;
                                    this.originalUclTwilioPhoneData = CommonUtils.cloneObject(twilioRecord);
                                    break;
                                case twilioPhoneDataType.NEWLISTINGS:
                                    this.newListingsTwilioPhoneData = twilioRecord;
                                    this.originalNewListingsTwilioPhoneData = CommonUtils.cloneObject(twilioRecord);
                                    break;
                                case twilioPhoneDataType.SMC:
                                    this.smcTwilioPhoneData = twilioRecord;
                                    this.originalSmcTwilioPhoneData = CommonUtils.cloneObject(twilioRecord);
                                    break;
                                case twilioPhoneDataType.SERVICE_APPT:
                                    this.srvApptTwilioPhoneData = twilioRecord;
                                    this.originalSrvApptTwilioPhoneData = CommonUtils.cloneObject(twilioRecord);
                                    break;
                            }
                        });
                        this.setNewListingsDefaults()
                    });
                    break;
                case 'NOT FOUND' :
                    CommonUtils.displayConsoleLogError(`*** No record found on twilio_phone_data for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve twilio_phone_data data for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to load Twilio Phone data for comp code '${this.compCode}'.`, "red")
                    break;
            }
        });
    };

    public setNewListingsDefaults() {
        if (this.businessUserStore.isDealerNew()) {
            if (this.newListingsTwilioPhoneData.ringToPhoneNumber === undefined) {
                this.newListingsTwilioPhoneData.ringToPhoneNumber = this.uclTwilioPhoneData.ringToPhoneNumber
            } else if (this.newListingsTwilioPhoneData.ringToPhoneNumber !== this.uclTwilioPhoneData.ringToPhoneNumber) {
                this.isRingToToggled = true
            } else {
                this.onToggleRingTo(false)
            }
        }
    }

    private updateTwilioPhoneData = async (twilioRecord: TwilioPhoneData) => {
        let updateSuccess: boolean = false;
        await updateTwilioPhoneData(this.authStore.token, this.compCode, [twilioRecord]).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        switch (twilioRecord.type) {
                            case twilioPhoneDataType.UCL:
                                this.originalUclTwilioPhoneData = CommonUtils.cloneObject(twilioRecord);
                                break;
                            case twilioPhoneDataType.NEWLISTINGS:
                                this.originalNewListingsTwilioPhoneData = CommonUtils.cloneObject(twilioRecord);
                                break;
                            case twilioPhoneDataType.SMC:
                                this.originalSmcTwilioPhoneData = CommonUtils.cloneObject(twilioRecord);
                                break;
                            case twilioPhoneDataType.SERVICE_APPT:
                                this.originalSrvApptTwilioPhoneData = CommonUtils.cloneObject(twilioRecord);
                                break;
                        }
                    });
                    updateSuccess = true;
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to update ${twilioRecord.type} Twilio Phone data for comp code ${this.compCode}.  Response: ` + JSON.stringify(resp));
                    updateSuccess = false;
                    break;
            }
        });

        return updateSuccess;
    };

    public getEmails = async () => {
        let compCode = this.businessUserStore.businessUser?.compCode || "";

        this.emailsStore.initializeEmails([emailTypeEnum.ADF_CALL_ALERT, emailTypeEnum.TEXT_CALL_ALERT,
            emailTypeEnum.TRADE_IN_ADF_CALL_ALERT, emailTypeEnum.TRADE_IN_CALL_ALERT, emailTypeEnum.SERVICE_APPT_TEXT_LEAD]);

        await this.emailsStore.getEmailsFromDPS(this.authStore.token, compCode).then(() => {
            runInAction(() => {
                this.refreshEmails();
                this.callAlertAdfEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, emailTypeEnum.ADF_CALL_ALERT, index);
                });
                this.callAlertTextEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, emailTypeEnum.TEXT_CALL_ALERT, index);
                });
                this.tradeInAdfCallAlertEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, emailTypeEnum.TRADE_IN_ADF_CALL_ALERT, index);
                });
                this.tradeInTextCallAlertEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, emailTypeEnum.TRADE_IN_CALL_ALERT, index);
                });
                this.srvApptTextLeadEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, emailTypeEnum.SERVICE_APPT_TEXT_LEAD, index);
                });
            });
        });
    };

    private deleteEmails = async () => {

        CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: 'Delete Call Alert Emails', compCode: this.compCode})

        let deleteEmailsSuccess: boolean = false;
        await this.emailsStore.deleteEmails(this.authStore.token, this.compCode).then((resp) => {
            runInAction(() => {
                deleteEmailsSuccess = resp
                this.refreshEmails()
            })
        });

        return deleteEmailsSuccess;
    }

    private insertEmails = async () => {

        CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: 'Add Call Alert Emails', compCode: this.compCode})

        let insertEmailsSuccess: boolean = false;
        await this.emailsStore.insertEmails(this.authStore.token, this.compCode).then((resp) => {
            runInAction(() => {
                insertEmailsSuccess = resp
                this.refreshEmails()
            })
        });

        return insertEmailsSuccess;
    }

    private updateEmails = async () => {

        console.log("*** UPDATE EMAILS")

        let updateEmailSuccess: boolean = false;
        await this.emailsStore.updateEmails(this.authStore.token, this.compCode).then((resp) => {
            runInAction(() => {
                updateEmailSuccess = resp
                this.refreshEmails()
            })
        });

        return updateEmailSuccess;
    }

    private refreshEmails = async () => {
        this.callAlertAdfEmails = this.emailsStore.getEmails(emailTypeEnum.ADF_CALL_ALERT);
        this.callAlertTextEmails = this.emailsStore.getEmails(emailTypeEnum.TEXT_CALL_ALERT);
        this.tradeInAdfCallAlertEmails = this.emailsStore.getEmails( emailTypeEnum.TRADE_IN_ADF_CALL_ALERT);
        this.tradeInTextCallAlertEmails = this.emailsStore.getEmails( emailTypeEnum.TRADE_IN_CALL_ALERT);
        this.srvApptTextLeadEmails = this.emailsStore.getEmails( emailTypeEnum.SERVICE_APPT_TEXT_LEAD);
    }

    public determineUclTwilioPhoneDataChanges = () : boolean => {
        return  CommonUtils.fieldsChanged(this.uclTwilioPhoneData, this.originalUclTwilioPhoneData);
    }
    public determineNewListingsTwilioPhoneDataChanges = () : boolean => {
        return  CommonUtils.fieldsChanged(this.newListingsTwilioPhoneData, this.originalNewListingsTwilioPhoneData);
    }

    public determineSmcTwilioPhoneDataChanges = () : boolean => {
        return CommonUtils.fieldsChanged(this.smcTwilioPhoneData, this.originalSmcTwilioPhoneData);
    }

    public determineSrvApptTwilioPhoneDataChanges = () : boolean => {
        return CommonUtils.fieldsChanged(this.srvApptTwilioPhoneData, this.originalSrvApptTwilioPhoneData);
    }

    private determineEmailChanges = () : boolean => {
        return this.emailsStore.hasEmailsChanged;
    }

    public hasChanges(): boolean {
        return this.determineUclTwilioPhoneDataChanges()
            || this.determineNewListingsTwilioPhoneDataChanges()
            || this.determineSmcTwilioPhoneDataChanges()
            || this.determineSrvApptTwilioPhoneDataChanges()
            || this.determineEmailChanges()
            //TODO figure out why this was added
            // || !this.commonStore.isFormValid;
    }

    public onEmailAdd = (emailType: any) => {
        let emailIndex = this.emailsStore.getEmails(emailType).length;
        this.commonStore.setFormError(`${emailType}-${emailIndex}`, ERROR_MSG_EMAIL_INVALID);
        let emailsAfterAdd = this.emailsStore.addEmail(emailType, this.compCode, emailListingsType.ALL);
        this.setLeadEmailsByType(emailType, emailsAfterAdd);
        this.focusOnEmailTextInput = true;
    };

    public onEmailBlur = (emailType: string, index: number) => {
        let emailObj = this.emailsStore.getEmails(emailType)[index];
        if(emailObj.action) {
            let email = emailObj.email;
            let emailErrorMsg = this.commonStore.getFormError(`${emailType}-${index}`)
            let emailsAfterChange = this.emailsStore.changeEmail(emailType, email, emailErrorMsg, index);
            this.setLeadEmailsByType(emailType, emailsAfterChange);
        }
    }

    public onEmailChange = (emailType: string, email: string, index: number) => {
        let emailErrorMsg = this.validateEmail(email, emailType)
        let inlineErrorMsg = (emailErrorMsg === ERROR_MSG_DUPLICATE_EMAILS ||
            emailErrorMsg === ERROR_MSG_EMAIL_CALL_ADF_TEXT_DUPLICATE) ? emailErrorMsg : "";

        let emailsAfterChange = this.emailsStore.changeEmail(emailType, email, inlineErrorMsg, index);

        this.setLeadEmailsByType(emailType, emailsAfterChange);

        this.emailsStore.setOrResetEmailsFormError(emailErrorMsg, `${emailType}-${index}`);
    }

    public onEmailListingsTypeChange = (emailType: string, emailListings: string, index: number) => {
        let emailsAfterChange = this.emailsStore.changeListingsType(emailType, emailListings, index);
        this.setLeadEmailsByType(emailType, emailsAfterChange);
    }

    public onEmailRemove = (emailType: string, index: number) => {

        let emailsAfterRemove = this.emailsStore.removeEmail(emailType, index);

        this.setLeadEmailsByType(emailType, emailsAfterRemove);

        this.commonStore.removeFormError(`${emailType}-${index}`);
    };

    public onToggleCallAlertConsent(isChecked: boolean) {
        this.uclTwilioPhoneData.callAlertConsent = isChecked
        this.newListingsTwilioPhoneData.callAlertConsent = isChecked
    }

    public onToggleRingTo(isChecked: boolean) {
        this.isRingToToggled = isChecked
        if (!isChecked) {
            this.newListingsTwilioPhoneData.ringToPhoneNumber = this.uclTwilioPhoneData.ringToPhoneNumber
            this.commonStore.removeFormError(UCL_RING_TO_FIELD_NAME);
            this.commonStore.removeFormError(NEW_LISTINGS_RING_TO_FIELD_NAME);
        } else {
            this.newListingsTwilioPhoneData.ringToPhoneNumber = this.originalNewListingsTwilioPhoneData.ringToPhoneNumber
        }
    }

    public onToggleSmcCallAlertConsent(isChecked: boolean) {
        this.smcTwilioPhoneData.callAlertConsent = isChecked
    }

    public onToggleSrvApptCallAlertConsent(isChecked: boolean) {
        this.srvApptTwilioPhoneData.callAlertConsent = isChecked
    }

    public onToggleOptOutUclTracking(isChecked: boolean) {

        if(isChecked) {
            if(this.uclTwilioPhoneData.trackingPhoneNumber.length > 0 || this.newListingsTwilioPhoneData?.trackingPhoneNumber.length > 0) {
                this.deleteTrackingNumberType = twilioPhoneDataType.UCL
                this.isConfirmDeleteModalVisible = true
            }
        }

        this.uclTwilioPhoneData.disableTrackingNumber = isChecked;
    }

    public onToggleOptOutTilTracking(isChecked: boolean) {

        if(isChecked) {
            if(this.smcTwilioPhoneData.trackingPhoneNumber.length > 0) {
                this.deleteTrackingNumberType = twilioPhoneDataType.SMC
                this.isConfirmDeleteModalVisible = true
            }
        }

        this.smcTwilioPhoneData.disableTrackingNumber = isChecked;
    }

    public onToggleOptOutSrvApptTracking(isChecked: boolean) {

        if(isChecked) {
            if(this.srvApptTwilioPhoneData.trackingPhoneNumber.length > 0) {
                this.deleteTrackingNumberType = twilioPhoneDataType.SERVICE_APPT
                this.isConfirmDeleteModalVisible = true
            }
        }

        this.srvApptTwilioPhoneData.disableTrackingNumber = isChecked;
    }

    private setLeadEmailsByType(emailType: string, email: Array<Email>) {
        switch(emailType) {
            case emailTypeEnum.ADF_CALL_ALERT : {
                this.callAlertAdfEmails = email
                break;
            }
            case emailTypeEnum.TEXT_CALL_ALERT: {
                this.callAlertTextEmails = email
                break;
            }
            case emailTypeEnum.TRADE_IN_ADF_CALL_ALERT: {
                this.tradeInAdfCallAlertEmails = email
                break;
            }
            case emailTypeEnum.TRADE_IN_CALL_ALERT: {
                this.tradeInTextCallAlertEmails = email
                break;
            }
            case emailTypeEnum.SERVICE_APPT_TEXT_LEAD: {
                this.srvApptTextLeadEmails = email
                break;
            }
        }
    }

    public submitCallMgmtSettings = async () => {

        this.commonStore.processingApiCall(true);

        let updateUclTwilioPhoneData: boolean = true;

        if(this.determineUclTwilioPhoneDataChanges()) {
            updateUclTwilioPhoneData = await this.updateTwilioPhoneData(this.uclTwilioPhoneData)
            CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: 'Update UCL Twilio Phone Data', compCode: this.uclTwilioPhoneData.compCode})
        }

        if(this.determineNewListingsTwilioPhoneDataChanges()) {
            updateUclTwilioPhoneData = await this.updateTwilioPhoneData(this.newListingsTwilioPhoneData)
            CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: 'Update UCL New Twilio Phone Data', compCode: this.newListingsTwilioPhoneData.compCode})
        }

        let updateSmcTwilioPhoneData: boolean = true;
        if(this.determineSmcTwilioPhoneDataChanges()) {
            updateSmcTwilioPhoneData = await this.updateTwilioPhoneData(this.smcTwilioPhoneData)
            CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: 'Update SMC Twilio Phone Data', compCode: this.smcTwilioPhoneData.compCode})
        }

        let updateServApptTwilioPhoneData: boolean = true;
        if(this.determineSrvApptTwilioPhoneDataChanges()) {
            updateServApptTwilioPhoneData = await this.updateTwilioPhoneData(this.srvApptTwilioPhoneData)
            CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: 'Update Service Appointment Twilio Phone Data', compCode: this.srvApptTwilioPhoneData.compCode})
        }

        let insertEmailsSuccess: boolean = true;
        let deleteEmailsSuccess: boolean = true;
        let updateEmailsSuccess: boolean = true;
        if(this.determineEmailChanges()) {
            if(this.emailsStore.emailsToBeInserted.length > 0) {
                insertEmailsSuccess = await this.insertEmails();
            }
            if(this.emailsStore.emailsToBeDeleted.length > 0) {
                deleteEmailsSuccess = await this.deleteEmails();
            }
            if(this.emailsStore.emailsToBeUpdated.length > 0) {
                updateEmailsSuccess = await this.updateEmails();
            }
            if(insertEmailsSuccess && deleteEmailsSuccess && updateEmailsSuccess) {
                await this.getEmails();
            }
        }

        if(updateUclTwilioPhoneData && updateSmcTwilioPhoneData && updateServApptTwilioPhoneData && insertEmailsSuccess && deleteEmailsSuccess) {
            this.commonStore.displayFeatureNotification(NotificationMessages.UPDATE_SUCCESSFUL, "green");
        }
        else {
            if(!updateUclTwilioPhoneData && !updateSmcTwilioPhoneData && !updateServApptTwilioPhoneData && !insertEmailsSuccess && !deleteEmailsSuccess) {
                this.commonStore.displayPageNotification(NotificationMessages.UPDATE_FAILED, "red")
            }
            else {
                this.commonStore.displayPageNotification(NotificationMessages.UPDATE_SUCCESSFUL_PARTIAL, "red")
            }
        }

        this.commonStore.processingApiCall(false);

    }

    public uclRingToPhoneHandler(ringToPhoneNumber: string) {
        this.uclTwilioPhoneData.ringToPhoneNumber = ringToPhoneNumber;
        if (this.businessUserStore.isDealerNew() && !this.isRingToToggled) {
            this.newListingsTwilioPhoneData.ringToPhoneNumber = ringToPhoneNumber;
        }
    }

    public newListingsRingToPhoneHandler(ringToPhoneNumber: string) {
        this.newListingsTwilioPhoneData.ringToPhoneNumber = ringToPhoneNumber;
    }

    public smcRingToPhoneHandler(ringToPhoneNumber: string) {
        this.smcTwilioPhoneData.ringToPhoneNumber = ringToPhoneNumber;
    }

    public srvApptRingToPhoneHandler(ringToPhoneNumber: string) {
        this.srvApptTwilioPhoneData.ringToPhoneNumber = ringToPhoneNumber;
    }

    public displayTrackingNumber(phoneNumber: string): string {
        return CommonUtils.formatPhoneNumber(phoneNumber);
    }

    public disableUclAddTracking(): boolean {
        if (this.authStore.isNonAdminGroup() || this.uclTwilioPhoneData.disableTrackingNumber) {
            return true
        }
        if (this.commonStore.getFormError(UCL_RING_TO_FIELD_NAME) || this.commonStore.getFormError(NEW_LISTINGS_RING_TO_FIELD_NAME)) {
            return true
        }

        return false
    }
    public disableSmcAddTracking(): boolean {
        if (this.authStore.isNonAdminGroup() || this.smcTwilioPhoneData.disableTrackingNumber) {
            return true
        }
        const ringTo = this.smcTwilioPhoneData.ringToPhoneNumber!
        if(ringTo && CommonUtils.unFormatPhoneNumber(ringTo).length !== 10) {
            return true;
        }
        return false
    }

    public disableServiceAppointmentAddTracking(): boolean {
        if (this.authStore.isNonAdminGroup() || this.srvApptTwilioPhoneData.disableTrackingNumber) {
            return true
        }
        const ringTo = this.srvApptTwilioPhoneData.ringToPhoneNumber!
        if(ringTo && CommonUtils.unFormatPhoneNumber(ringTo).length !== 10) {
            return true;
        }
        return false
    }

    public isValidPhoneNumber(phoneNumber: string, phoneNumberType: string) : boolean|undefined {
        if(phoneNumber) {
            if(CommonUtils.unFormatPhoneNumber(phoneNumber).length !== 10) {
                this.setRingToErrorMsg(phoneNumberType, ERROR_MSG_INVALID_PHONE);
                return false;
            }
            if (this.isRingToToggled
                && (phoneNumberType === twilioPhoneDataType.UCL || phoneNumberType === twilioPhoneDataType.NEWLISTINGS )
                && this.newListingsTwilioPhoneData.ringToPhoneNumber === this.uclTwilioPhoneData.ringToPhoneNumber) {
                this.commonStore.setFormError( phoneNumberType === twilioPhoneDataType.UCL ? UCL_RING_TO_FIELD_NAME: NEW_LISTINGS_RING_TO_FIELD_NAME,ERROR_MSG_REPEATED_RING_TO);
                return false;
            }
        }
        else {
            if ((phoneNumberType === twilioPhoneDataType.UCL) || (phoneNumberType === twilioPhoneDataType.NEWLISTINGS) ||
                (phoneNumberType === twilioPhoneDataType.SMC && this.smcTwilioPhoneData.trackingPhoneNumber !== "") ||
                (phoneNumberType === twilioPhoneDataType.SERVICE_APPT && this.srvApptTwilioPhoneData.trackingPhoneNumber !== "")){
                this.setRingToErrorMsg(phoneNumberType, ERROR_MSG_INVALID_PHONE);
                return false;
            }
            else {
                this.setRingToErrorMsg(phoneNumberType, "");
                return undefined;
            }
        }

        this.setRingToErrorMsg(phoneNumberType, "");
        return true;
    }

    private setRingToErrorMsg(phoneNumberType: string, errMsg: string) {
        switch (phoneNumberType) {
            case twilioPhoneDataType.UCL : {
                if (errMsg === "") {
                    this.commonStore.removeFormError(UCL_RING_TO_FIELD_NAME);
                } else {
                    this.commonStore.setFormError(UCL_RING_TO_FIELD_NAME, errMsg);
                }
                break;
            }
            case twilioPhoneDataType.NEWLISTINGS : {
                if (errMsg === "") {
                    this.commonStore.removeFormError(NEW_LISTINGS_RING_TO_FIELD_NAME);
                } else {
                    this.commonStore.setFormError(NEW_LISTINGS_RING_TO_FIELD_NAME, errMsg);
                }
                break;
            }
            case twilioPhoneDataType.SMC : {
                if (errMsg === "") {
                    this.commonStore.removeFormError(SMC_RING_TO_FIELD_NAME);
                } else {
                    this.commonStore.setFormError(SMC_RING_TO_FIELD_NAME, errMsg);
                }
                break;
            }
            case twilioPhoneDataType.SERVICE_APPT : {
                if (errMsg === "") {
                    this.commonStore.removeFormError(SRV_APPT_RING_TO_FIELD_NAME);
                } else {
                    this.commonStore.setFormError(SRV_APPT_RING_TO_FIELD_NAME, errMsg);
                }
                break;
            }
        }
    }

    public validateEmail(email: string, emailType: string) {

        let emailErrorMsg = ""

        if(!Validator.isValidEmail(email)) {
            emailErrorMsg = ERROR_MSG_EMAIL_INVALID;
            return emailErrorMsg;
        }

        if(Validator.isCarfaxEmail(email)) {
            return resolveInvalidCarfaxEmailMessage(emailType)
        }

        switch(emailType) {
            case emailTypeEnum.ADF_CALL_ALERT :
                if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.ADF_CALL_ALERT])) {
                    emailErrorMsg = ERROR_MSG_DUPLICATE_EMAILS;
                } else {
                    if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TEXT_CALL_ALERT])) {
                        emailErrorMsg = ERROR_MSG_EMAIL_CALL_ADF_TEXT_DUPLICATE;
                    }
                }
                break;
            case emailTypeEnum.TEXT_CALL_ALERT :
                if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TEXT_CALL_ALERT])) {
                    emailErrorMsg = ERROR_MSG_DUPLICATE_EMAILS;
                } else {
                    if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.ADF_CALL_ALERT])) {
                        emailErrorMsg = ERROR_MSG_EMAIL_CALL_ADF_TEXT_DUPLICATE;
                    }
                }
                break;
            case emailTypeEnum.TRADE_IN_ADF_CALL_ALERT :
                if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TRADE_IN_ADF_CALL_ALERT])) {
                    emailErrorMsg = ERROR_MSG_DUPLICATE_EMAILS;
                } else {
                    if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TRADE_IN_CALL_ALERT])) {
                        emailErrorMsg = ERROR_MSG_EMAIL_CALL_ADF_TEXT_DUPLICATE;
                    }
                }
                break;
            case emailTypeEnum.TRADE_IN_CALL_ALERT :
                if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TRADE_IN_CALL_ALERT])) {
                    emailErrorMsg = ERROR_MSG_DUPLICATE_EMAILS;
                } else {
                    if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TRADE_IN_ADF_CALL_ALERT])) {
                        emailErrorMsg = ERROR_MSG_EMAIL_CALL_ADF_TEXT_DUPLICATE;
                    }
                }
                break;
            case emailTypeEnum.SERVICE_APPT_TEXT_LEAD :
                if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.SERVICE_APPT_TEXT_LEAD])) {
                    emailErrorMsg = ERROR_MSG_DUPLICATE_EMAILS;
                }
                break;
        }

        return emailErrorMsg;
    }

    private validateEmailOnLoad(email: string, emailType: string, index: number) {

        let emailErrorMsg = ""

        if (!Validator.isValidEmail(email)) {
            emailErrorMsg = ERROR_MSG_EMAIL_INVALID;
        }

        if (Validator.isCarfaxEmail(email)) {
            emailErrorMsg = resolveInvalidCarfaxEmailMessage(emailType)
        }

        this.emailsStore.setOrResetEmailsFormError(emailErrorMsg, `${emailType}-${index}`);
        return emailErrorMsg;
    }

    public handleDeleteModel = (trackingNumberType: string) => {
        this.deleteTrackingNumberType = trackingNumberType
        if(this.hasChanges()) {
            this.showUnsavedModalForTrkNum = true;
            this.isUnsavedTrkNumModalForDeprovision = true;
        }
        else {
            this.isConfirmDeleteModalVisible = true;
        }
    };

    public handleProvisionModal = (phoneDataType: string) => {
        switch (phoneDataType) {
            case twilioPhoneDataType.UCL :
                this.provisionNumberTypeTitle = "UCL";
                break;
            case twilioPhoneDataType.NEWLISTINGS :
                this.provisionNumberTypeTitle = "New Listings";
                break;
            case twilioPhoneDataType.SMC :
                this.provisionNumberTypeTitle = "Trade-In Leads"
                break;
            case twilioPhoneDataType.SERVICE_APPT :
                this.provisionNumberTypeTitle = "Service Appointment"
                break;
        }

        this.provisionNumberType = phoneDataType
        if(this.hasChanges()) {
            this.showUnsavedModalForTrkNum = true;
            this.isUnsavedTrkNumModalForDeprovision = false;
        }
        else {
            this.isProvisionNumberModalVisible = true;
            this.displaySelectTrackingNumberSection();
        }
    };

    public handleOnChangePhoneNumberType(twilioPhoneNumberType: any) {
        this.provisionPhoneNumberType = twilioPhoneNumberType
        this.clearTrackingNumberNotifications()
    };

    public handleOnChangeAreaCode(areaCode: string) {
        this.provisionNumberAreaCode = areaCode
    };

    public handleOnChangePrefix(prefix: string) {
        this.provisionNumberPrefix = prefix
    };

    public initializeProvisionNumberModal() {
        this.provisionPhoneNumberType = ""
        this.provisionNumberType = ""
        this.provisionNumberTypeTitle = ""
        this.provisionNumberAreaCode = ""
        this.provisionNumberPrefix = ""
        this.clearTrackingNumberNotifications()
    };

    public nextStepButtonDisabled = () : boolean => {
        let disabled: boolean = true;
        if(this.provisionPhoneNumberType === twilioPhoneNumberType.TOLL_FREE) {
            disabled = false
        } else {
            if(this.provisionNumberAreaCode.length === 3) {
                disabled = false
            }
        }
        return disabled;
    };

    public displaySelectTrackingNumberSection() {
        this.provisionModalBodySelect = true
        this.provisionModalBodyConfirm = false
    };

    public displayConfirmNumberSection() {
        this.provisionModalBodySelect = false
        this.provisionModalBodyConfirm = true
    };

    public getAvailableNumbersFromTwilio = async () => {
        let number: TwilioAvailableNumber;
        this.provisionAvailableNumber = this.initializeTwilioPhoneNumber();

        if(this.provisionPhoneNumberType === twilioPhoneNumberType.TOLL_FREE) {
            number = await this.getTollFreeNumber()
            if(number === undefined) {
                this.provisionNumberSelectErrorMsg = ERROR_MSG_NO_TWILIO_TOLL_FREE_NUMBERS
            } else {
                this.confirmNumber(number)
                this.provisionModalModifiedSearch = false;
            }
        } else {
            if(this.provisionNumberPrefix === "") {
                number = await this.getLocalNumberByAreaCode()
                if(number) {
                    this.confirmNumber(number)
                    this.provisionModalModifiedSearch = false;
                } else {
                    this.provisionNumberSelectErrorMsg = ERROR_MSG_NO_TWILIO_NUMBERS_FOR_CRITERIA
                    CommonUtils.displayConsoleLogError(`** No phone numbers found by Twilio for area code ${this.provisionNumberAreaCode}`);
                }
            } else {
                number = await this.getLocalNumberByAreaCodeAndPrefix()
                if(number) {
                    this.confirmNumber(number)
                } else {
                    number = await this.getLocalNumberByAreaCode()
                    if(number) {
                        this.provisionModalModifiedSearch = true;
                        this.confirmNumber(number)
                    } else {
                        this.provisionNumberSelectErrorMsg = ERROR_MSG_NO_TWILIO_NUMBERS_FOR_CRITERIA
                        CommonUtils.displayConsoleLogError(`** No phone numbers found by Twilio for area code ${this.provisionNumberAreaCode} and prefix ${this.provisionNumberPrefix}`);
                    }
                }
            }
        }
    }

    public async getTollFreeNumber() : Promise<TwilioAvailableNumber> {

        this.provisionProcessingApi = true
        let twilioPhoneNumber: TwilioAvailableNumber = this.initializeTwilioPhoneNumber()

        await getAvailableTwilioNumber(this.authStore.token, true, "", "").then( async (resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    twilioPhoneNumber = resp.data[0]
                    break;
                default :
                    this.provisionNumberSelectErrorMsg = ERROR_MSG_TWILIO_API_ERROR
                    CommonUtils.displayConsoleLogError('*** Error calling CTS to get available Toll-Free number: ' + JSON.stringify(resp))
                    break;
            }
            this.provisionProcessingApi = false
        })

        return twilioPhoneNumber
    };

    public async getLocalNumberByAreaCode() : Promise<TwilioAvailableNumber> {

        this.provisionProcessingApi = true
        let twilioPhoneNumber: TwilioAvailableNumber = this.initializeTwilioPhoneNumber()

        await getAvailableTwilioNumber(this.authStore.token, false, this.provisionNumberAreaCode, "").then(async (resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    twilioPhoneNumber = resp.data[0]
                    break;
                default :
                    this.provisionNumberSelectErrorMsg = ERROR_MSG_TWILIO_API_ERROR
                    CommonUtils.displayConsoleLogError('*** Error calling CTS to get available Local number by Area Code: ' + JSON.stringify(resp))
                    break;
            }
            this.provisionProcessingApi = false
        })

        return twilioPhoneNumber
    };

    public async getLocalNumberByAreaCodeAndPrefix() : Promise<TwilioAvailableNumber> {

        this.provisionProcessingApi = true
        let twilioPhoneNumber: TwilioAvailableNumber = this.initializeTwilioPhoneNumber()

        await getAvailableTwilioNumber(this.authStore.token, false, this.provisionNumberAreaCode, this.provisionNumberPrefix).then(async (resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    twilioPhoneNumber = resp.data[0]
                    break;
                default :
                    this.provisionNumberSelectErrorMsg = ERROR_MSG_TWILIO_API_ERROR
                    CommonUtils.displayConsoleLogError('*** Error calling CTS to get available Local number by Area Code and Prefix: ' + JSON.stringify(resp))
                    break;
            }
            this.provisionProcessingApi = false
        })

        return twilioPhoneNumber
    };

    public formatAvailableNumber() {
          // Twilio returns numbers prefixed with +1
        this.provisionAvailableNumber.phoneNumber = CommonUtils.formatPhoneNumber(this.provisionAvailableNumber.phoneNumber.replace('+1', ''))

        if(this.provisionAvailableNumber.postalCode === "") {
            this.provisionAvailableNumber.postalCode = "N/A"
        }

        if(this.provisionAvailableNumber.region === "") {
            this.provisionAvailableNumber.region = "N/A"
        }

        this.provisionNumberDate = CommonUtils.getCurrentDate()
    };

    public provisionBackClicked = async () => {
        this.displaySelectTrackingNumberSection()
    };

    public confirmNumber(twilioAvailableNumber: TwilioAvailableNumber) {
        this.provisionAvailableNumber = twilioAvailableNumber
        this.formatAvailableNumber()
        this.displayConfirmNumberSection()
        this.clearTrackingNumberNotifications()
    };

    public provisionSubmitClicked = async () => {

        this.provisionProcessingApi = true
        let unformattedNumber : string = CommonUtils.unFormatPhoneNumber(this.provisionAvailableNumber.phoneNumber)

        await provisionTwilioTrackingNumber(this.authStore.token, unformattedNumber).then((resp: any) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    let purchasedTwilioNumber : TwilioPurchasedNumber = this.buildPurchasedNumber(resp.data)
                    let twilioPhoneData : TwilioPhoneData = this.buildTwilioPhoneData(purchasedTwilioNumber)

                    this.updateTwilioPhoneData(twilioPhoneData).then((dpsUpdateSuccessful: boolean) => {
                        if(dpsUpdateSuccessful) {
                            this.getTwilioPhoneData()
                            this.handleCloseProvisionModal()
                            this.commonStore.displayFeatureNotification(this.getProvisionSuccessMessage(twilioPhoneData), "green")
                            this.provisionProcessingApi = false
                        } else {
                            this.handleCloseProvisionModal()
                            this.commonStore.displayFeatureNotification(ERROR_MSG_TWILIO_DPS_API_ERROR, "red")
                            CommonUtils.displayConsoleLogError(`*** Error calling DPS to update twilio_phone_data for new provisioned tracking number ${twilioPhoneData.trackingPhoneNumber}: ` + JSON.stringify(resp))
                            this.provisionProcessingApi = false
                        }
                    })

                    switch(twilioPhoneData.type) {
                        case twilioPhoneDataType.UCL : {
                            CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: `Provision Tracking Number`, compCode: twilioPhoneData.compCode, feature: twilioPhoneDataType.UCL})
                            break;
                        }
                        case twilioPhoneDataType.SMC : {
                            CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: `Provision Tracking Number`, compCode: twilioPhoneData.compCode, feature: twilioPhoneDataType.SMC})
                            break;
                        }
                        case twilioPhoneDataType.SERVICE_APPT : {
                            CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: `Provision Tracking Number`, compCode: twilioPhoneData.compCode, feature: twilioPhoneDataType.SERVICE_APPT})
                            break;
                        }
                    }

                    break;
                default:
                    CommonUtils.displayConsoleLogError(`*** Error on Tracking Phone Number Provision.  Response: ` + JSON.stringify(resp));
                    this.provisionNumberConfirmErrorMsg = `Error encountered on Provisioning Tracking Phone Number ${this.provisionAvailableNumber.phoneNumber}`
                    this.provisionProcessingApi = false;
                    break;
            }
        })

    };

    public getProvisionSuccessMessage(twilioPhoneData: TwilioPhoneData) : string {

        let message = ""

        switch (twilioPhoneData.type) {
            case twilioPhoneDataType.UCL :
                message = `Success! You've added tracking number ${twilioPhoneData.friendlyName} for the dealer's UCL listings.`
                break;
            case twilioPhoneDataType.NEWLISTINGS :
                message = `Success! You've added tracking number ${twilioPhoneData.friendlyName} for the dealer's New listings.`
                break;
            case twilioPhoneDataType.SMC :
                message = `Success! You've added tracking number ${twilioPhoneData.friendlyName} for Trade-In Leads.`
                break;
            case twilioPhoneDataType.SERVICE_APPT :
                message = `Success! You've added tracking number ${twilioPhoneData.friendlyName} for Service Appointments.`
                break;
        }

        return message
    };

    public buildPurchasedNumber(twilioResponseData: any) : TwilioPurchasedNumber{
        let twilioPurchasedNumber : TwilioPurchasedNumber = {
            twilioPhoneNumberSid: twilioResponseData.sid,
            twilioAccountSid : twilioResponseData.accountSid,
            phoneNumber : twilioResponseData.phoneNumber.endpoint,
            friendlyName : twilioResponseData.friendlyName
        }

        return twilioPurchasedNumber
    };

    public buildTwilioPhoneData(twilioPurchasedNumber: TwilioPurchasedNumber) : TwilioPhoneData {

        let twilioPhoneData = this.initializeTwilioPhoneData(this.provisionNumberType)

        twilioPhoneData.twilioSid = twilioPurchasedNumber.twilioPhoneNumberSid
        twilioPhoneData.trackingPhoneNumber = twilioPurchasedNumber.phoneNumber.substring(2);  // Twilio returns numbers prefixed with +1
        twilioPhoneData.friendlyName = twilioPurchasedNumber.friendlyName
        twilioPhoneData.compCode = this.compCode
        twilioPhoneData.whisper = TWILIO_WHISPER
        twilioPhoneData.status = trackingNumberStatus.ACTIVE
        twilioPhoneData.provisionedDate = CommonUtils.getCurrentDateTimeLong()

        switch (this.provisionNumberType) {
            case twilioPhoneDataType.UCL :
                twilioPhoneData.ringToPhoneNumber = this.uclTwilioPhoneData.ringToPhoneNumber
                twilioPhoneData.callAlertConsent = true
                twilioPhoneData.disableTrackingNumber = this.uclTwilioPhoneData.disableTrackingNumber
                break;
            case twilioPhoneDataType.NEWLISTINGS :
                twilioPhoneData.ringToPhoneNumber = this.newListingsTwilioPhoneData.ringToPhoneNumber

                /*
                    BUG FIX : https://jira.carfax.net/browse/DPLAT-1060 - If there is an existing UCL Tracking number, we need to set
                    this new NEWLISTINGS number to have the same Call Recording Consent value.  This prevents us from defaulting it to true
                    for comp codes that have opted out of Call Recording for Car Listings.
                 */
                twilioPhoneData.callAlertConsent = this.uclTwilioPhoneData.callAlertConsent

                // The NEWLISTINGS 'disableTrackingNumber' opt out is controlled by uclTwilioPhoneData.disableTrackingNumber
                break;
            case twilioPhoneDataType.SMC :
                twilioPhoneData.ringToPhoneNumber = this.smcTwilioPhoneData.ringToPhoneNumber
                twilioPhoneData.callAlertConsent = this.smcTwilioPhoneData.callAlertConsent
                twilioPhoneData.disableTrackingNumber = this.smcTwilioPhoneData.disableTrackingNumber
                break;
            case twilioPhoneDataType.SERVICE_APPT :
                twilioPhoneData.ringToPhoneNumber = this.srvApptTwilioPhoneData.ringToPhoneNumber
                twilioPhoneData.callAlertConsent = this.srvApptTwilioPhoneData.callAlertConsent
                twilioPhoneData.disableTrackingNumber = this.srvApptTwilioPhoneData.disableTrackingNumber
                break;
        }

        return twilioPhoneData
    };

    public deprovisionUCLTwilioNumber = async (confirmed?: boolean) => {
        this.isConfirmDeleteModalVisible = false;
        this.commonStore.processingApiCall(true);

        if (confirmed) {
            if (this.uclTwilioPhoneData?.trackingPhoneNumber?.length > 0) {
                await this.deprovisionTrackingNumber(this.uclTwilioPhoneData)
            }
            if (this.newListingsTwilioPhoneData?.trackingPhoneNumber?.length > 0 && this.uclTwilioPhoneData.disableTrackingNumber) {
                await this.deprovisionTrackingNumber(this.newListingsTwilioPhoneData)
            }
        }
        else {
            this.uclTwilioPhoneData.disableTrackingNumber = false
        };

        this.commonStore.processingApiCall(false);
    };

    public deprovisionNewListingsTwilioNumber = async (confirmed?: boolean) => {
        this.isConfirmDeleteModalVisible = false;
        this.commonStore.processingApiCall(true);

        if (confirmed) {
            await this.deprovisionTrackingNumber(this.newListingsTwilioPhoneData)
        }
        this.commonStore.processingApiCall(false);
    };

    public deprovisionSMCTwilioNumber = async (confirmed?: boolean) => {
        this.isConfirmDeleteModalVisible = false;
        this.commonStore.processingApiCall(true);

        if (confirmed) {
            await this.deprovisionTrackingNumber(this.smcTwilioPhoneData)
        } else {
            this.smcTwilioPhoneData.disableTrackingNumber = false
        };

        this.commonStore.processingApiCall(false);
    };

    public deprovisionSrvApptTwilioNumber = async (confirmed?: boolean) => {
        this.isConfirmDeleteModalVisible = false;
        this.commonStore.processingApiCall(true);

        if (confirmed) {
            await this.deprovisionTrackingNumber(this.srvApptTwilioPhoneData)
        } else {
            this.srvApptTwilioPhoneData.disableTrackingNumber = false
        };

        this.commonStore.processingApiCall(false);
    };

    private async deprovisionTrackingNumber(twilioRecordToDelete: TwilioPhoneData) {

        twilioRecordToDelete.provisionedDate = null;
        twilioRecordToDelete.trackingPhoneNumber = '';
        twilioRecordToDelete.status = 'I';

        let successFlag = false;

        await deprovisionTwilioTrackingNumber(this.authStore.token, twilioRecordToDelete!.twilioSid).then( async (resp) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    await this.updateTwilioPhoneData(twilioRecordToDelete);
                    this.commonStore.displayFeatureNotification(`Successfully removed Tracking Phone Number`, 'green');
                    successFlag = true;
                    break;
                default :
                    CommonUtils.displayConsoleLogError('*** error: ' + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Error on Tracking Phone Number removal of ${twilioRecordToDelete.friendlyName}`, 'red');
                    successFlag = false;
                    break;
            }
        });

        switch(twilioRecordToDelete.type) {
            case twilioPhoneDataType.UCL : {
                CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: `De-Provision Tracking Number`, compCode: twilioRecordToDelete.compCode, feature: twilioPhoneDataType.UCL})
                break;
            }
            case twilioPhoneDataType.NEWLISTINGS : {
                CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: `De-Provision Tracking Number`, compCode: twilioRecordToDelete.compCode, feature: twilioPhoneDataType.NEWLISTINGS})
                break;
            }
            case twilioPhoneDataType.SMC : {
                CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: `De-Provision Tracking Number`, compCode: twilioRecordToDelete.compCode, feature: twilioPhoneDataType.SMC})
                break;
            }
            case twilioPhoneDataType.SERVICE_APPT : {
                CommonUtils.logNewRelicPageAction("Profile Call Mgmt", {userAction: `De-Provision Tracking Number`, compCode: twilioRecordToDelete.compCode, feature: twilioPhoneDataType.SERVICE_APPT})
                break;
            }
        }

        return successFlag;
    }

    public clearTrackingNumberNotifications() {
        this.provisionNumberSelectErrorMsg = ""
        this.provisionNumberConfirmErrorMsg = ""
    }

    public handleCloseProvisionModal = () => {
        this.isProvisionNumberModalVisible = false
        this.initializeProvisionNumberModal()
    }

    public onClickCloseModalForTrkNum = () => {
        this.showUnsavedModalForTrkNum = false;
    }

    public onClickContinueWithoutSavingForTrkNum = async () => {
        this.showUnsavedModalForTrkNum = false;
        this.showTrkNumModalForUnsavedChanges();
        if (this.hasChanges()) {
            this.isProvisionNumberModalVisible = false;
        }

        this.resetStoreToOriginalData();
    }

    public onClickSaveModalForTrkNum = async () => {
        this.showUnsavedModalForTrkNum = false;
        await this.submitCallMgmtSettings();
        this.showTrkNumModalForUnsavedChanges();
    }

    private showTrkNumModalForUnsavedChanges() {
        if (this.isUnsavedTrkNumModalForDeprovision) {
            this.isConfirmDeleteModalVisible = true;
        } else {
            this.isProvisionNumberModalVisible = true;
            this.displaySelectTrackingNumberSection();
        }
        this.isUnsavedTrkNumModalForDeprovision = false;
    }

    public hasCallAlertAdfEmails = () => {
        if(this.callAlertAdfEmails == null || this.callAlertAdfEmails?.length <= 0){
            return false
        }
        else if(!this.callAlertAdfEmails.find(email => email.action !== DB_ACTION_DELETE)){
            return false
        }
        return true
    }

    public hasCallAlertTextEmails = () => {
        if(this.callAlertTextEmails == null || this.callAlertTextEmails?.length <= 0){
            return false
        }
        else if(!this.callAlertTextEmails.find(email => email.action !== DB_ACTION_DELETE)){
            return false
        }
        return true
    }
}
