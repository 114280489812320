import * as React from "react";
import Button from "@carfax-stencils/button";
import Store from "../../../stores";
import {DB_ACTION_DELETE} from "../../../utils/constants";
import {EmailTextInput} from "../../../shared-components/email/EmailTextInput";
import {Email} from "../../../types/email";
import {emailTypeEnum} from "../../../types/profile/enums/emailTypeEnum";
import {observer} from "mobx-react";
import {Plus} from "@carfax-stencils/icon";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import "../../../shared-components/email/Email.scss";
import "./RatingsAndReviews.scss";

// Props
interface RatingsAndReviewsTabProps {
    compCode:any
}

// Stores
const rootStore = Store.root.root;
const businessUserStore = rootStore.businessUserStore;
const commonStore = rootStore.commonStore;
const ratingsAndReviewsStore = rootStore.ratingsAndReviewsStore;


export default observer (
    class RatingsAndReviewsTab extends React.Component<RatingsAndReviewsTabProps> {

        async componentDidMount() {
            this.setState({ready:true});
            await this.loadData();
        }

        async componentDidUpdate() {
            if(businessUserStore.businessUser?.compCode !== ratingsAndReviewsStore.compCode) {
                await this.loadData();
            };

            this.focusOnEmailAfterAdd();
        };

        private async loadData() {
            commonStore.processingApiCall(true)
            await ratingsAndReviewsStore.getEmails(emailTypeEnum.SALES_RATINGS);
            commonStore.processingApiCall(false);
        };

        private focusOnEmailAfterAdd() {
            if(ratingsAndReviewsStore.focusOnEmailTextInput) {
                ratingsAndReviewsStore.emailInputRef?.current?.focus();
                ratingsAndReviewsStore.focusOnEmailTextInput = false;
            }
        }

        render() {
            return (
                <div id="ratings-and-reviews-tab-div" className="ratings-and-reviews-tab-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner/>
                    )}
                    <p id="ratings-and-reviews-tab-main-heading" className="main-heading">
                        Ratings & Reviews Preferences
                    </p>

                    {/*  " Sales Rating Emails " Section   */}
                    <hr id="sale-rating-emails-separator-line" className="separator-line"/>
                    <p id="sale-rating-emails-sub-heading" className="sub-heading">
                        Sales Rating Email(s)
                    </p>
                    <div id="sale-rating-emails-description-div" className="left-padding">
                        <p id="sale-rating-emails-description-text" className="primary_text">
                            This is a list of email addresses of recipients who will receive the ratings and reviews alerts and emails.
                        </p>
                    </div>
                    <div id="sale-rating-emails-div" className="email-input">
                        {
                            ratingsAndReviewsStore.salesRatingEmails?.map((email:Email, index:number) => (
                                email.action !== DB_ACTION_DELETE &&
                                   <EmailTextInput key={`EmailComponent-${index}`} email={email} index={index} store={ratingsAndReviewsStore} textInputRef={ratingsAndReviewsStore.emailInputRef}/>
                            ))
                        }
                    </div>
                    <div id="sale-rating-emails-add-button-div" className="left-padding bottom-padding email-input">
                        <Button
                            id="sale-rating-emails-add-button"
                            iconLeft={<Plus aria-hidden />}
                            children={`Add Sales Rating Email`}
                            onClick={() => {
                                ratingsAndReviewsStore.onEmailAdd(emailTypeEnum.SALES_RATINGS)
                            }}
                            theme="blue-outline"
                        />
                    </div>
                    <div className="ratings-and-reviews-spaces-div"/>
                </div>
            );
        }
    }
);
