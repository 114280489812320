export enum Environment {
    DEVELOPMENT = "DEVELOPMENT",
    STAGING = "STAGING",
    PROD = "PROD"
}

export const getEnvironment = (): Environment => {
    if (!window || !window.location) {
        return Environment.PROD;
    }
    const hostName = window.location.hostname.toLowerCase();
    switch (hostName) {
        case "dam.carfax.net":
        case "www.dam.carfax.net":
            return Environment.PROD;
        case "www.stagingdam.carfax.net":
        case "stagingdam.carfax.net":
            return Environment.STAGING;
        case "localhost":
            return Environment.DEVELOPMENT;
        default:
            if (hostName.indexOf("local") !== -1 || hostName.endsWith("dxd.development.aws.carfax.io")) {
                return Environment.DEVELOPMENT;
            }
            if (hostName.indexOf("staging") !== -1 || hostName.endsWith("dxd.staging.aws.carfax.io")) {
                return Environment.STAGING;
            }
            const lastChar = hostName.split(".")[0].slice(-1);
            if (lastChar === "l") {
                return Environment.DEVELOPMENT;
            } else if (lastChar === "s") {
                return Environment.STAGING;
            } else {
                return Environment.PROD;
            }
    }
};
