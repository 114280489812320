import {observer} from "mobx-react";
import {Email} from "../../types/email";
import TextInput from "@carfax-stencils/input-text";
import * as React from "react";
import {Trash} from "@carfax-stencils/icon";
import InlineErrorMessage from "../layout/error/InlineErrorMessage";

export const EmailTextInput = observer((props: {email: Email, index: number, store:any, textInputRef?:any}) => {
    return (
        <div id={`${props.email.emailType}-email-div-${props.index}`} className="bottom-padding email-input-width">
            <div className="email-input-container">
                <TextInput
                    id={`${props.email.emailType}-email-${props.index}`}
                    className="left-padding"
                    value={props.email.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        props.store.onEmailChange(props.email.emailType,event.target.value, props.index);
                    }}
                    onBlur={() => {
                        props.store.onEmailBlur(props.email.emailType, props.index);
                    }}
                    type="text"
                    maxLength={300}
                    disabled={props.email.action === undefined}
                    ref={props.textInputRef}
                    placeholder="Enter an email address"
                />
                <Trash
                    id={`${props.email.emailType}-email-remove-button-${props.index}`}
                    size={40}
                    className="email-remove-button"
                    onClick={() => {
                        props.store.onEmailRemove(props.email.emailType, props.index)
                    }}
                    title="Remove Email"
                />
            </div>
            <InlineErrorMessage id={`${props.email.emailType}-email-inline-error-${props.index}`} errorMessage={!props.email.inlineErrorMsg ? "" : props.email.inlineErrorMsg}/>
        </div>
    )
});