import * as React from "react";
import {AccountMgmt, Profile, Reputation} from "../../types/features";
import {observer} from "mobx-react";
import {BusinessUser} from "../../types/businessUser";
import {runInAction} from "mobx";
import {Search} from "@carfax-stencils/icon";
import {SearchType} from "../../types/search/searchType";
import { SpinnerFox } from "@carfax-stencils/spinner";
import FormElement from "@carfax-stencils/form-element";
import SearchResults from "./SearchResults";
import SelectInput from "@carfax-stencils/input-select";
import Store from "../../stores";
import TextInput from "@carfax-stencils/input-text";
import "./search.scss";
import InlineErrorMessage from "../layout/error/InlineErrorMessage";

const defaultOptions = [
    SearchType.CompCode,
    SearchType.Name
]

const acctMgmtOptions = [
    SearchType.CompCode,
    SearchType.Name,
    SearchType.UserName,
    SearchType.UserEmail,
    SearchType.UserPhone
]

const proflieOptions = [
    SearchType.CompCode,
    SearchType.Name,
    SearchType.ProfileEmail,
    SearchType.TrackingPhone,
    SearchType.WebsiteUrl
];


// Stores
const rootStore = Store.root.root;
const businessUserStore = rootStore.businessUserStore;
const commonStore = rootStore.commonStore;
const searchStore = rootStore.searchStore;


export default class SearchComponent extends React.Component<{}> {

        async componentDidMount() {
            await this.initializePage();
            if(searchStore.selectedCompCode && searchStore.selectedCompCode !== "") {
                await runInAction(() => {
                    searchStore.selectedCategory = SearchType.CompCode;
                    searchStore.searchValue = searchStore.selectedCompCode;
                    searchStore.handleCompCodeSearch(searchStore.selectedCompCode);
                });
            }
            this.setState({ready: true});
        }

        initializePage = async () => {
            commonStore.initializeStore()
        };

        determineSearchOptions()  {
            switch(commonStore.currentFeature) {
                case AccountMgmt: {
                    return acctMgmtOptions
                }
                case Profile: {
                    return proflieOptions
                }
                case Reputation: {
                    return defaultOptions
                }
                default: {
                    return defaultOptions
                }
            }
        };

        render() {
            return (
                <div className="search-page-content-div">
                    <this.PageContent />
                </div>
            )
        };

        PageContent = observer(() => {
            return (
                <div id="profile-search-div">
                    <div id="profile-search-layout-inner-div" className="search-layout">
                        <p id="profile-search-heading" className="search-heading">
                            Select category to search by:
                        </p>
                        <div id="profile-search-select-input-div" className="search-select-input-div">
                            <SelectInput
                                id="profile-search-select-input"
                                onChange={(option : any) => {
                                    searchStore.handleSearchOptionSelect(option.target.value)
                                }}
                                className={"add-location-selector"}
                                placeholder={"Please make selection..."}
                                value={searchStore.selectedCategory}
                            >
                                {this.determineSearchOptions().map((item: string, index: number) =>
                                    <option key={index} value={item}>
                                        {item}
                                    </option>      
                                )}
                            </SelectInput>
                        </div>

                        {searchStore.selectedCategory &&
                        <div id="search-form" className="search-form">
                            <p id="search-heading" className="search-heading">
                                {searchStore.determineCategoryInputLabel()} <span className="label-required">*</span>
                            </p>
                            <FormElement
                                id="search-layout-formElement"
                                children={
                                    <TextInput
                                        id="search-text-input"
                                        icon={<SearchIcon/> }
                                        name="dealerName"

                                        placeholder={searchStore.determinePlaceholderText()}
                                        className="search-text-input"
                                        isSmall={false}
                                        type="text"
                                        aria-invalid={!searchStore.isValidSearchCriteria}
                                        value={searchStore.searchValue}
                                        onChange={(event) => {searchStore.handleTextInput(event.target.value)}}
                                        onKeyDown={searchStore.handleKeyDown}
                                        disabled={searchStore.processingApiCall}
                                    />
                                }
                            />
                        </div>
                        }
                    <div id="search-inline-error-message-div" className="search-inline-error-message-div" >
                        <InlineErrorMessage id="search-inline-error-message" errorMessage={searchStore.invalidSearchCriteriaMessage}/>
                    </div>

                    </div>
                    {searchStore.processingApiCall &&
                        <SpinnerFox id="search-spinner" size={100}/>   
                    }
                    {businessUserStore.businessUsersList?.map((user: BusinessUser, index: number) =>
                        <SearchArea businessUser={user} store={searchStore} key={index} />
                    )}
                </div>
            )
        });

}

const SearchArea = observer((props:{businessUser:BusinessUser, store:any}) => {
    return Store.search.businessUser.isSearchResultsVisible ? <SearchResults businessUser={props.businessUser} store={props.store} /> : null;
});

const SearchIcon = observer(() => {
    return (
        <div id="search-icon" aria-disabled={searchStore.processingApiCall} className={(searchStore.processingApiCall) ? "search-icon-disabled" : ""}>
            <Search onClick={() => {searchStore.handleKeyDown({ key: "Click" })}} className='search-icon' size={23} />
        </div>
    );
});
