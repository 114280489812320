import { observer } from "mobx-react";
import React from "react";
import {Modal, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import Store from "../../stores/";
import Button from "@carfax-stencils/button";
import CheckboxInput from "@carfax-stencils/input-checkbox";
import SelectInput from "@carfax-stencils/input-select"
import {Location} from "../../types/owner-group";
import "./AddLocationModal.scss";

export default observer(() => {
  const pss = Store.preferences.preferencesSelection;

    return (
      <Modal
          id="add-location-modal"
          isVisible={true}
          onClose={pss.handleAddLocationModalCancel}
      >
          <ModalHeader>
              <div id="add-location-modal-header-title-div" className="selection-header-title"> Add Location Preferences</div>
          </ModalHeader>
          <ModalBody/>
          <ModalFooter>
             <Button
               id="add-location-modal-add-remove-button"
               theme="blue"
               size="large"
               children={`Add Location`}
               disabled={!pss.handleAddLocationModalSubmitIsEnabled}
               onClick={pss.addLocation}
             />
          </ModalFooter>
      </Modal>
    );

});


const ModalBody = observer(() => {
    const pss = Store.preferences.preferencesSelection;

    const optionList = pss.ownerGroup === null ? []
        : pss.getUnSelectedLocations().map((location:Location) => `${location.compCode} - ${location.companyName}`)

  return (
      <div id="add-location-modal-body-div"className={"add-location-modal-body"}>
          <SelectInput
              onChange={(option : any) => {
                  pss.handleLocationSelected(option.target.value);
              }}
              className={"add-location-selector"}
              placeholder={"Select location"}
              value={pss.locationToAdd?.displayValue}
          >
            {optionList.map((item: string, index: number) =>
                <option key={index} value={item}>
                    {item}
                </option>      
            )}
          </SelectInput>
          <div id="add-location-checkbox-c4l-div" className="add-location-checkbox-c4l">
              <CheckboxInput
                  id={pss.ownerGroupCompCode}
                  checked={pss.locationToAdd?.carfax4Life_selected || false}
                  onChange={(option: React.ChangeEvent<HTMLInputElement>) => {
                      pss.handleAddLocationC4lCheck(
                          option.target.checked
                      );
                  }}
                  label={ "Carfax 4 Life"}
                  disabled={pss.handleAddLocationC4lDisabled}
              />
          </div>
          <div id="add-location-checkbox-ucl-div" className="add-location-checkbox-ucl">
              <CheckboxInput
                  id={pss.ownerGroupCompCode}
                  checked={pss.locationToAdd?.isUclDealer_selected || false}
                  onChange={(option: React.ChangeEvent<HTMLInputElement>) => {
                      pss.handleAddLocationUclCheck(
                          option.target.checked
                      );
                  }}
                  label={"Used Car Listing"}
                  disabled={pss.handleAddLocationUclDisabled}
              />
          </div>
      </div>
  );
});
