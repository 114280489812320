import DamAxiosClient from "./DamAxiosClient";
import UrlBuilder from "../utils/urlBuilder";
import {User} from "../types/acctMgmt/user";
import {LegacyUser} from "../types/acctMgmt/legacyUser";
import {getInviteTemplateKey, InviteTemplateEnum} from "../types/acctMgmt/inviteUserTypes";

const API_URI = UrlBuilder.buildBaseUrl("/uas")
const damAxiosClient: DamAxiosClient = new DamAxiosClient()

export const createLegacyUser = async(token: string, compCode: string, name: string) : Promise<any> => {
    const path : string = API_URI + `/subscriber/cnameuser`
    const params: any = {source: 'dam-aws'}

    let data: any =  JSON.stringify({
        compCode: compCode,
        name: name
    })
    return damAxiosClient.post(path, params, token, data );
}

export const editLegacyUser = async(token:string, legacyUser: LegacyUser) : Promise<any> => {
    const path : string = API_URI + `/subscriber/cnameuser/${legacyUser.username}`
    const params :any = {source:'dam-aws'}
    return damAxiosClient.put(path, params, token, legacyUser)
}

export const getLegacyUserListByCompCode = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/subscriber/cnameusers/${compCode}`
    return damAxiosClient.get(path, null, token );
};

export const getLegacyUsersAuthStatus = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/subscriber/cnameusers/${compCode}/auth`
    return damAxiosClient.get(path, null, token );
};

export const getUserAuditHistory = async(token: string, userId: number) : Promise<any> => {
    const path : string = API_URI + `/user-history/${userId}`
    return damAxiosClient.get(path, null, token );
};

export const getUserListByCompCode = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/user-list/${compCode}`
    return damAxiosClient.get(path, null, token );
};

export const getUserListBySearchValue = async(token: string, searchType: string, searchValue: string) : Promise<any> => {
    const path : string = API_URI + `/find-by/${searchType}?value=${searchValue.trim()}`
    return damAxiosClient.get(path, null, token );
};

export const reactivateUser = async(token: string, userId: number) : Promise<any> => {
    const path : string = API_URI + `/reactivate/${userId}`
    const params : any = {source:'dam-aws'}
    return damAxiosClient.post(path, params, token, {});
};

export const deactivateUser = async(token: string, userId: number) : Promise<any> => {
    const path : string = API_URI + `/deactivate/${userId}`
    const params : any = {source:'dam-aws'}
    return damAxiosClient.post(path, params, token, {});
};

export const updateUser = async(token: string, user: User) : Promise<any> => {
    const path : string = API_URI + `/user`
    const params : any = {source:'dam-aws'}

    let data: any =  JSON.stringify({
        userId: user.userId,
        compCode: user.compCode,
        firstName: user.firstName,
        lastName: user.lastName,
        roleName: user.role,
        userOptions: user.userOptions,
        accessLocations: user.accessLocations
    })

    return damAxiosClient.post(path, params, token, data );
};

export const updateLegacyUsersAuthStatus = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/subscriber/cnameusers/${compCode}/auth`
    const params : any = {source:'dam-aws'}
    return damAxiosClient.post(path, params, token, null );
};

export const suspendAll = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/suspend-all/${compCode}`
    const params : any = {source:'dam-aws'}
    return damAxiosClient.post(path, params, token, null );
}

export const suspendUser = async(token: string, userId: number) : Promise<any> => {
    const path : string = API_URI + `/suspend/${userId}`
    const params : any = {source:'dam-aws'}
    return damAxiosClient.post(path, params, token, null );
}

export const unsuspendAll = async(token: string, compCode: string) : Promise<any> => {
    const path : string = API_URI + `/unsuspend-all/${compCode}`
    const params : any = {source:'dam-aws'}
    return damAxiosClient.post(path, params, token, null );
}

export const inviteUser = async(token: string, user: User, inviteTemplate: string) : Promise<any> => {
    let inviteUrl
    switch (inviteTemplate) {
        case InviteTemplateEnum.RECALL:
            inviteUrl = `/recall/invite`
            break;
        case InviteTemplateEnum.RATINGS:
        case InviteTemplateEnum.LOYALTY:
            inviteUrl = `/service-shop/invite`
            break;
        case InviteTemplateEnum.SHC:
            inviteUrl = `/shc/invite`
            break;
        default:
            inviteUrl = `/dealer/invite`
            break;
    }
    const inviteTemplateCode = getInviteTemplateKey(inviteTemplate)
    const path : string = API_URI + inviteUrl
    const params : any = {source:'dam-aws'}

    let data: any =  JSON.stringify({
        compCode: user.compCode,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.email,
        role: user.role,
        userOptions: user.userOptions,
        template: inviteTemplateCode,
        accessLocations: user.accessLocations
    })

    return damAxiosClient.post(path, params, token, data );
};

export const resendInvite = async(token: string, user: User) : Promise<any> => {

    const path : string = API_URI + `/reinvite/${user.userId}`;
    const params : any = {source:'dam-aws'}

    let data: any =  JSON.stringify({
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
    })

    return damAxiosClient.post(path, params, token, data );
};

export const moveUser = async(token: string, user: User) : Promise<any> => {
    const path : string = API_URI + `/user`
    const params : any = {source:'dam-aws'}

    let data: any =  JSON.stringify({
        userId: user.userId,
        compCode: user.compCode,
        toCompCode: user.toCompCode
    })

    return damAxiosClient.post(path, params, token, data );
};

export const moveUsers = async(token: string, users: User[]) : Promise<any> => {
    const path : string = API_URI + `/moveUsers`
    const params : any = {source:'dam-aws'}
    if(users?.length <= 0){
        return
    }

    let data: Array<any> = []
    users.forEach(user => {
        data.push({
            userId: user.userId,
            compCode: user.compCode,
            toCompCode: user.toCompCode
        })
    });

    return damAxiosClient.post(path, params, token, data );
};