import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {getListOfPartners} from "../../clients/dps/dpsApi";
import {makeAutoObservable, runInAction} from "mobx";
import {NotificationThemeType} from "@carfax-stencils/notification";
import {Partner} from "../../types/partner";
import AuthStore from "../auth";


export default class PartnersStore {

    // Variables
    listOfPartners: Array<Partner> = new Array<Partner>();
    partnerNames: Array<string> = new Array<string>();
    notificationMessage: string = "";
    notificationTheme: NotificationThemeType | undefined;

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore;
        makeAutoObservable(this);
    }

    public displayNotificationMessage(message: string, theme: NotificationThemeType) {
        this.notificationMessage = message;
        this.notificationTheme = theme;
    }

    public clearNotificationMessage() {
        this.notificationMessage = ""
    }

    public async getListOfPartners() {
        await getListOfPartners(this.authStore.token).then((resp: any) => {
            switch (resp?.status) {
                case 'SUCCESS' :
                    runInAction(() => {
                        resp.data.forEach((partner: Partner) => {
                            this.listOfPartners.push(partner);
                            this.partnerNames.push(partner.name);
                        })
                    });
                    break;
                default:
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve list of partners.  Response: ` + JSON.stringify(resp));
            }
        })
    };

    public findPartnerByName(selectedPartnerName: string) : any  {

        let selectedPartner: any;

        this.listOfPartners.forEach((partner: Partner) => {
            if(partner.name === selectedPartnerName) {
                selectedPartner = partner;
            }
        });

        return selectedPartner;
    }
}
