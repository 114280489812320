import AuthStore from "../../auth";
import CommonStore from "../../common/common";
import CommonUtils from "../../../utils/commonUtils";
import RootStore from "../../root";
import Validator from "../../helpers/validator";
import {makeAutoObservable, runInAction} from "mobx";
import {deleteEmail} from "../../../clients/dps/dpsApi";
import {apiStatus} from "../../../utils/constants";
import {EmailDeletion} from "../../../types/features";


export default class EmailDeletionToolStore {

    // Fields
    emailToDelete: string = "";

    // Stores
    authStore: AuthStore;
    commonStore: CommonStore;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore
        this.commonStore = this.rootStore.commonStore

        makeAutoObservable(this)
    };

    public initializeStore() {
        runInAction(() => {
            this.commonStore.currentFeature = EmailDeletion
            this.commonStore.processingApiCall(false)
            this.commonStore.clearPageMsg()
        })
    };

    public isValidEmail = () : boolean => {
        const result =  Validator.isValidEmail(this.emailToDelete)
        if (this.emailToDelete.length === 0) return true
        return result
    }

    public clearEmailValue() {
        this.emailToDelete = "";
    }

    public deleteEmail = async () => {

        CommonUtils.logNewRelicPageAction("Email Deletion Tool", {userAction: 'Delete Email', email: this.emailToDelete})
        this.commonStore.processingApiCall(true)

        await deleteEmail(this.authStore.token, this.emailToDelete).then((resp: any) => {
            switch(resp?.status) {
                case apiStatus.success :
                    let compsDeletedList: Array<string> = [];
                    resp.data.forEach((response: any) => {
                        let value = " " + response.compCode
                        compsDeletedList.push(value)
                    })
                    this.commonStore.displayPageNotification(`Successfully Deleted '${this.emailToDelete}' from the following comp codes: ${compsDeletedList.toString()}.`, "green")
                    this.clearEmailValue()
                    break;
                case apiStatus.notFound :
                    this.commonStore.displayPageNotification(`Email '${this.emailToDelete}' not found.`, "red")
                    break;
                default:
                    this.commonStore.displayPageNotification(`Error trying to delete '${this.emailToDelete}'.  Email was not deleted.`, "red")
                    break;
            }
        })

        this.commonStore.processingApiCall(false)
    };
}