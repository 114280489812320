import React, {Component, createContext, PropsWithChildren} from 'react';
import { Partner } from '../../types/partner'
import { getPartners, addPartner } from '../../clients/damBackendApi'
import { IAuthInfo } from '../partners/PartnerMaintenance'
import {apiStatus} from "../../utils/constants";
import Store from "../../stores/";

export interface IState {
    partners: Partner[]
}

export interface IActions {
    getPartners(auth:any): () => void
    addPartner(auth:any, partnerName: string): () => void
}

export interface IContext {
    state: IState;
    actions: IActions;
}

const pms = Store.partners.partners;

class PartnerContextProvider extends Component<PropsWithChildren, IState> {
    state: IState = {
        partners: []
    };

    getPartners = (auth: any) => {

        let partners: Partner[] = []
        getPartners(auth.token)
            .then(fetchPartnersResponse => {

                if(fetchPartnersResponse?.status === apiStatus.error) {
                    pms.displayNotificationMessage(`Error occurred retrieving Partners.`, "red")
                } else {
                    pms.clearNotificationMessage()
                    fetchPartnersResponse?.data?.forEach((partner:any) => {
                        let newPartner = {
                            createDate: partner?.createDate,
                            createdBy: partner?.createdBy,
                            name: partner?.name,
                            partnerId: partner?.partnerId
                        } as Partner
                        partners.push(newPartner)
                    })
                }

            this.setState({
                partners: partners
            })
        })
    }

    addPartner = (auth: IAuthInfo, partnerName: string) => {
        addPartner(auth, partnerName)
            .then(resp => this.getPartners(auth))
            .catch(err => console.error("couldn't add crm partner in context"))

    }

    render() {
        return (
            <partnerContext.Provider
                value={{
                    state: this.state,
                    actions: {
                        getPartners: this.getPartners,
                        addPartner: this.addPartner
                    }
                }}>
                {this.props.children}
            </partnerContext.Provider>
        );
    }
}

export { PartnerContextProvider };
export const partnerContext = createContext<any>(null);
export const PartnerConsumer = partnerContext.Consumer;
