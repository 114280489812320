import AuthStore from "../auth";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import RootStore from "../root";
import {apiStatus} from "../../utils/constants";
import {getUserEmailHistory} from "../../clients/damBackendApi";
import {makeAutoObservable, runInAction} from "mobx";
import {User} from "../../types/acctMgmt/user";
import {UserEmailHistoryRecord} from "../../types/acctMgmt/userEmailHistoryRecord";
import {SendGridEventNameEnum} from "../../types/acctMgmt/sendGridEvents";

export default class UserEmailHistoryStore {

    // Fields / Types
    compCode: string = "";
    token: string = "";
    selectedUser: User | undefined = undefined;
    userEmailHistoryRecords: Array<UserEmailHistoryRecord> = new Array<UserEmailHistoryRecord>();
    isUserEmailHistoryModalVisible: boolean = false;
    lastRefreshDate: string = "";

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    commonStore: CommonStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore;
        this.commonStore = this.rootStore.commonStore;
        makeAutoObservable(this);
    };

    public initializeUserEmailHistoryModal() {
        this.userEmailHistoryRecords = [];
    }

    public getUserEmailHistoryRecords = async (token: string, email: string) => {

        CommonUtils.logNewRelicPageAction("Dealer Account Management", {userAction: 'Get User Email History', compCode: this.compCode})

        this.commonStore.processingApiCall(true);

        await getUserEmailHistory(token, email).then((resp: any) => {
            switch(resp?.status){
                case apiStatus.success :
                    runInAction(() => {
                        this.userEmailHistoryRecords = resp.data;
                        this.userEmailHistoryRecords.forEach((emailRecord) => {
                            emailRecord.eventName = this.formatEventName(emailRecord.eventName)
                            emailRecord.eventDate = this.formatEventDate(emailRecord.eventDate)
                        })
                        this.userEmailHistoryRecords.sort((a,b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())
                        this.refreshDate()
                        this.commonStore.processingApiCall(false);
                    });
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    this.commonStore.processingApiCall(false);
                    this.closeUserEmailHistoryModal()
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling UAS to retrieve user Email history records for user ${this.selectedUser?.firstName} ${this.selectedUser?.lastName}. Userid = ${this.selectedUser?.userId} .  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to retrieve user Email history records for ${this.selectedUser?.firstName} ${this.selectedUser?.lastName}.`, "red")
                    this.commonStore.processingApiCall(false);
                    this.closeUserEmailHistoryModal()
                    break;
            }
        });
    };

    public displayUserEmailHistoryModal = async (selectedUser: User) => {
        this.isUserEmailHistoryModalVisible = true;
        this.selectedUser = selectedUser;
        this.initializeUserEmailHistoryModal();
        this.commonStore.processingApiCall(true);
        await this.getUserEmailHistoryRecords(this.authStore.token, this.selectedUser.email)
        this.commonStore.processingApiCall(false);
    };

    public closeUserEmailHistoryModal = () => {
        this.isUserEmailHistoryModalVisible = false;
        this.initializeUserEmailHistoryModal();
    };

    public formatEventName = (eventName: string): string => {
        if (eventName === "drop")
            return "Not Delivered"
        return (eventName.charAt(0).toUpperCase() + eventName.slice(1)).replace(/_/g, " ")
    }

    public formatEventDate = (eventDate: string): string => {
        let formattedDate = new Date(eventDate).toLocaleString('en-US', { timeZone: 'America/New_York' })
        return formattedDate.concat(" EST")
    }

    public refreshDate() {
        runInAction(() => {
            this.lastRefreshDate = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
        })
    }

    public refresh = async () => {
        this.initializeUserEmailHistoryModal()
        await this.getUserEmailHistoryRecords(this.authStore.token, this.selectedUser?.email!)
    }

    public renderTooltipText(record: UserEmailHistoryRecord): string {
        let toolTipText: string = "";

        switch(record.eventName) {
            case SendGridEventNameEnum.PROCESSED: {
                toolTipText = "The message has been received and is ready to be delivered."
                break;
            }
            case SendGridEventNameEnum.DELIVERED: {
                toolTipText = "The receiving server accepted the message."
                break;
            }
            case SendGridEventNameEnum.OPENED: {
                toolTipText = "The message was opened."
                break;
            }
            case SendGridEventNameEnum.CLICKED: {
                toolTipText = "The reset password link was clicked on."
                break;
            }
            case SendGridEventNameEnum.NOT_DELIVERED: {
                toolTipText = "Invalid, the content is spam, the address is unsubscribed, or the recipient list is over the package quota."
                break;
            }
            default: {
                break;
            }
        }
        return toolTipText;
    }
}