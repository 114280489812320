import { observer } from "mobx-react";
import React from "react";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import Store from "../../../stores";
import "../../../styles/main.scss";
import Button from "@carfax-stencils/button";
import "./InviteUserModal.scss";
import TextInput from "@carfax-stencils/input-text";
import {RadioGroup, RadioInput} from "@carfax-stencils/input-radio-group";
import {InviteSectionEnum} from "../../../types/acctMgmt/inviteUserTypes";
import SelectInput from "@carfax-stencils/input-select";
import InlineErrorMessage from "../../../shared-components/layout/error/InlineErrorMessage";
import {Table, TBody, TD, TH, THead, TR} from "@carfax-stencils/table";
import {Search, Location} from "@carfax-stencils/icon";
import CheckboxInput from "@carfax-stencils/input-checkbox";


export default observer(() => {

    const inviteUserStore = Store.root.root.acctMgmtStore.inviteUserStore

    return (
        <Modal
            id="invite-user-modal"
            isVisible={true}
            onClose={inviteUserStore.handleInviteUserCancel}
        >
            <ModalHeader>
                    <div id='invite-user-modal-header-div'  className="invite-user-header-title"> Invite a User</div>
            </ModalHeader>
            <ModalBody className={"invite-user-modal-body"}>
                {inviteUserStore.inviteModalSection === InviteSectionEnum.Detail && (<BasicUserInfoSection />)}
                {inviteUserStore.inviteModalSection === InviteSectionEnum.Locations && (<LocationSection />)}
                {inviteUserStore.inviteModalSection === InviteSectionEnum.Confirm && (<ConfirmationSection />)}
            </ModalBody>
            <ModalFooter>
                {inviteUserStore.inviteModalSection !== InviteSectionEnum.Detail &&
                <Button
                    id="invite-user-modal-back-button"
                    theme={"blue-outline"}
                    size="large"
                    onClick={inviteUserStore.handleInviteBackButton}
                >
                    Back
                </Button>}
                {inviteUserStore.inviteModalSection !== InviteSectionEnum.Confirm &&
                <Button
                    id="invite-user-modal-next-button"
                    theme={"blue"}
                    size="large"
                    disabled={!inviteUserStore.validStep(inviteUserStore.inviteModalSection)}
                    onClick={inviteUserStore.handleInviteNextButton}
                >
                    Next Step
                </Button> }
                {inviteUserStore.inviteModalSection === InviteSectionEnum.Confirm &&
                    <Button
                        id="invite-user-modal-send-invite-button"
                        theme={"blue"}
                        size="large"
                        onClick={inviteUserStore.handleSendInviteButton}
                    >
                        Send Invite
                    </Button> }
            </ModalFooter>
        </Modal>
    )

});

const BasicUserInfoSection = observer(() => {

    const inviteUserStore = Store.root.root.acctMgmtStore.inviteUserStore
    const businessUserStore = Store.root.root.businessUserStore

    return (
        <div id="invite-user-info-div" className="invite-user-info">
            <div className="invite-user-info__message">
                <div className="invite-user-info__oval">1</div>
                <div id="invite-user-header-message-div" className="invite-user-info__step-text">
                    Tell us about the user you want to invite.
                </div>
            </div>
            <div id="invite-user-info-section-one-div" className="invite-user-info__section-heading-text">
                Who are you inviting?
            </div>

            <div id="invite-user-info-row1-div" className="invite-user-info__flex">
                <div id="invite-user-info-first-name-div" className="invite-user-info__first">
                    <div id="invite-user-info-first-name-label" className="left-padding invite-user-info-label">
                        First Name<span className="label-required">*</span>
                    </div>
                    <TextInput
                        id="invite-user-info-first-name"
                        value={inviteUserStore.invitingUser?.firstName}
                        onChange={inviteUserStore.inviteUserFirstNameHandler}
                        placeholder={"First Name"}
                    />
                </div>
                <div id="invite-user-info-last-name-div" className="invite-user-info__last">
                    <div id="invite-user-info-last-name-label" className="left-padding invite-user-info-label">
                        Last Name<span className="label-required">*</span>
                    </div>
                    <TextInput
                        id="invite-user-info-last-name"
                        value={inviteUserStore.invitingUser?.lastName}
                        onChange={inviteUserStore.inviteUserLastNameHandler}
                        placeholder={"Last Name"}
                    />
                </div>
            </div>
            <div id="invite-user-info-row2-div" className="invite-user-info__flex">
                <div id="invite-user-info-email-div" className="invite-user-info__email">
                    <div id="invite-user-info-email-label" className="left-padding invite-user-info-label">
                        Email<span className="label-required">*</span>
                    </div>
                    <TextInput
                        id="invite-user-info-email"
                        value={inviteUserStore.invitingUser?.email}
                        onChange={inviteUserStore.inviteUserEmailHandler}
                        placeholder={"Email"}
                    />
                    <div id="invite-user-info-email-inline-error-message" className="">
                        <InlineErrorMessage id="invite-user-info-email-error" errorMessage={inviteUserStore.emailErrorMessage}/>
                    </div>
                </div>
            </div>
            <hr id="invite-user-info-separator-line" className="invite-user-separator-line"/>
            <p id="invite-user-info-user-access-settings" className="invite-user-info__section-heading-text">
                What access should the user have?
            </p>
            <RadioGroup
                id="invite-user-info-role"
                value={inviteUserStore.invitingUser?.role}
                onChange={inviteUserStore.inviteUserRoleHandler}
                name="role-radio-group"
            >
                <RadioInput value="Admin">
                    <div id="invite-user-info-role-admin-div">
                        <div className="invite-user-info-role-name">Admin User</div>
                        <p>Has control of user access within account and ability to update location's Profile.</p>
                    </div>
                </RadioInput>
                <RadioInput value="User">
                    <div id="invite-user-info-role-basic-div">
                        <div className="invite-user-info-role-name">Basic User</div>
                        <p>Has access to all other CARFAX features.</p>
                    </div>
                </RadioInput>
                <RadioInput value="Salesperson">
                    <div id="invite-user-info-role-salesperson-div">
                        <div className="invite-user-info-role-name">Salesperson</div>
                        <p>Has access to run VHRs.</p>
                    </div>
                </RadioInput>
                <RadioInput value="Accounting">
                    <div id="invite-user-info-role-accounting-div">
                        <div className="invite-user-info-role-name">Accounting</div>
                        <p>Has access to view billing information and download Co-Op packets.</p>
                    </div>
                </RadioInput>
            </RadioGroup>
            <hr id="invite-user-info-separator-line" className="invite-user-separator-line"/>
            <p id="invite-user-info-template-header" className="invite-user-info__section-heading-text">
                What email template should they receive?
            </p>
            <div id="invite-user-info-template-div" className="left-padding">

            </div>
            <div id="invite-user-info-template-dropdown-div" className="">
                <div id="invite-user-info-template-label" className="left-padding invite-user-info-label">
                    Template<span className="label-required">*</span>
                </div>
                <SelectInput
                    id="invite-user-info-template-dropdown-input"
                    value={inviteUserStore.inviteTemplate}
                    onChange={(option : any) => {
                        inviteUserStore.handleInviteTemplateSelection(option.target.value)
                    }}
                >
                    {
                        inviteUserStore.inviteTemplate === "" &&
                        <option value="">Select an option</option>
                    }
                    {businessUserStore.inviteTemplateDisplayNames.map((item: string, index: number) =>
                        <option key={index} value={item}>
                            {item}
                        </option>      
                    )}
                </SelectInput>
            </div>
            {inviteUserStore.filteredAssociatedLocations.length > 0 &&
                <div>
                    <hr id="invite-user-info-separator-line-associate-locations-checkbox"
                        className="invite-user-separator-line"/>
                    <p id="invite-user-info-user-access-locations-question"
                       className="invite-user-info__section-heading-text">
                        Does this user need access to more locations within the owner group?
                    </p>
                    <div id="invite-user-info-associate-locations-checkbox-input-div"
                         className="invite-user-info__checkbox-input-div">
                        <CheckboxInput
                            id="invite-user-info-associate-locations-checkbox"
                            checked={inviteUserStore.associateLocationsChecked}
                            onChange={(option: React.ChangeEvent<HTMLInputElement>) => {
                                inviteUserStore.handleAssociateLocationsChecked(
                                    option.target.checked
                                );
                            }}

                            label="Yes, I want to assign associated locations to this user."
                        />
                    </div>
                </div>
            }
        </div>
    );
});

const LocationSection = observer(() => {

    const inviteUserStore = Store.root.root.acctMgmtStore.inviteUserStore

    return (
        <div id="locations-div" className={"invite-user-location"}>
            <div id="locations-listing-div">
                <div className="invite-user-info__message">
                    <div className="invite-user-info__oval">2</div>
                    <div id="invite-user-header-message-div" className="invite-user-info__step-text">
                        Which associated location(s) does the user need access to?
                    </div>
                </div>
                <hr id="invite-user-info-separator-line" className="invite-user-separator-line"/>
                <div className="invite-user-location-search">
                    <div id="invite-user-location-search-label-div" className="invite-user-location-search-label">
                        <Location size={24}/>
                        <span className="invite-user-location-search-count">
                            {
                                inviteUserStore.invitingUser?.accessLocations
                                    ? inviteUserStore.invitingUser.accessLocations.length
                                    : 0
                            } Store(s) Selected
                        </span>
                    </div>
                    <TextInput
                        id="invite-location-search"
                        icon={<Search onClick={() => inviteUserStore.searchLocations()} className='location-search-icon' size={23} /> }
                        name="dealerName"
                        placeholder="Search by Dealer Name..."
                        className="location-search-text-input"
                        required={true}
                        isSmall={true}
                        type="text"
                        value={inviteUserStore.associatedLocationFilterValue}
                        onChange={inviteUserStore.onChangeLocation}
                    />
                </div>
                <Table aria-label="Associated Locations">
                    <THead onSelectAll={inviteUserStore.handleLocationSelectAll}
                        selectedAll={inviteUserStore.isAllSelected()}>
                        <TH width={200}>
                            Dealer Name
                        </TH>
                        <TH width={70}>
                            Status
                        </TH>
                        <TH width={100}>
                            Location
                        </TH>
                    </THead>
                    <TBody>
                        {inviteUserStore.filteredAssociatedLocations.map((loc, key) => (
                            <TR key={key}
                                // disabledSelect={loc.status === "Inactive"}
                                onSelect={() => {inviteUserStore.handleLocationSelect(loc)}}
                                selected={loc.selected}>
                                <TD><strong>{loc.companyName}</strong> - {loc.compCode}</TD>
                                <TD>
                                    <div className="location-status">
                                        <div className={loc.status === "Active" ? "dealer-status-active" : "dealer-status-inactive"}/>
                                        <div className="location-status-text">{loc.status}</div>
                                    </div>
                                </TD>
                                <TD>{loc.address.city}, {loc.address.state}</TD>
                            </TR>
                        ))}
                    </TBody>
                </Table>

            </div>
        </div>
    );
});

const ConfirmationSection = observer(() => {

    const inviteUserStore = Store.root.root.acctMgmtStore.inviteUserStore
    const businessUser = Store.root.root.businessUserStore.businessUser
    const associatedLocationsExist = inviteUserStore.filteredAssociatedLocations.length > 0

    return (
        <div id="confirmation-div" className={"invite-user-confirmation"}>
            <div className="invite-user-info__message">
                <div className="invite-user-info__oval">{associatedLocationsExist ? 3 : 2}</div>
                <div id="invite-user-header-message-div" className="invite-user-info__step-text">
                    Confirm user details and send invite.
                </div>
            </div>
            <div id="confirmation-details-div" className="invite-user-confirmation__details">
                <table>
                    <tbody>
                        <tr>
                            <td className="invite-user-confirmation__column1">First Name:</td>
                            <td className="invite-user-confirmation__column2">{inviteUserStore.invitingUser.firstName}</td>
                        </tr>
                        <tr>
                            <td className="invite-user-confirmation__column1">Last Name:</td>
                            <td className="invite-user-confirmation__column2">{inviteUserStore.invitingUser.lastName}</td>
                        </tr>
                        <tr>
                            <td className="invite-user-confirmation__column1">Email:</td>
                            <td className="invite-user-confirmation__column2">{inviteUserStore.invitingUser.email}</td>
                        </tr>
                        <tr>
                            <td className="invite-user-confirmation__column1">Role:</td>
                            <td className="invite-user-confirmation__column2">{inviteUserStore.invitingUser.role}</td>
                        </tr>
                        <tr>
                            <td className="invite-user-confirmation__column1">Email Template:</td>
                            <td className="invite-user-confirmation__column2">{inviteUserStore.inviteTemplate}</td>
                        </tr>
                        <tr>
                            <td className="invite-user-confirmation__column1">Default Location:</td>
                            <td className="invite-user-confirmation__column2">{businessUser?.companyName} - {businessUser?.compCode}</td>
                        </tr>
                        <tr>
                            <td className="invite-user-confirmation__column1">Associated Locations:</td>
                            <td className="invite-user-confirmation__column2">{
                                associatedLocationsExist ?
                                    (inviteUserStore.invitingUser?.accessLocations
                                        ? inviteUserStore.invitingUser.accessLocations.length
                                        : 0) + " Store(s)"
                                    : "N/A - not part of an owner group"
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
});

