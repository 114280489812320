import React from "react"
import Button from "@carfax-stencils/button"
import { Trash } from "@carfax-stencils/icon"
import "./RemovePartnerButton.scss"
import Store from "../../stores";

export interface IContentProps {
    theme: any;
}

const rps = Store.reputation.reputationPartner;

export const RemovePartnerButton: React.FC<IContentProps> = (props: IContentProps) => {

    return (
        <Button
            id="reputation-partner-remove-partner-button"
            theme={props.theme}
            size="large"
            onClick={rps.addOrRemoveAssociation}
        >
            <Trash id="reputation-partner-remove-partner-button-icon" className="trash-can-icon" size={25} />
            Remove Partner
        </Button>
    )
}

export default RemovePartnerButton
