import * as React from "react";
import CheckboxInput from '@carfax-stencils/input-checkbox';
import CommonUtils from "../../../utils/commonUtils";
import ImageDropZone from "./images/ImageDropZone";
import { Location } from '@carfax-stencils/icon';
import SelectInput from "@carfax-stencils/input-select";
import Store from "../../../stores";
import TextAreaInput from "@carfax-stencils/input-text-area";
import TextInput from "@carfax-stencils/input-text";
import { CheckboxToggle } from "@carfax-stencils/input-checkbox";
import { Money } from "@carfax-stencils/icon";
import { NoResults } from "../../../shared-components/noResults/NoResults";
import { observer } from "mobx-react";
import { ProfileImageDisplay } from "../../../types/profile/images";
import { ProcessingApiSpinner } from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import { BusinessHours, Day, DayNames } from "../../../types/profile/businessHours";
import { IProfileImages } from "../../../types/profile/images";
import InlineErrorMessage from "../../../shared-components/layout/error/InlineErrorMessage";

import "../../../shared-components/search/search.scss";
import "../../../utils/newRelic/newrelic";
import "../../../styles/main.scss";
import "./DealerDetailsTab.scss";

// Stores
const rootStore = Store.root.root;
const authStore = rootStore.authStore;
const businessHoursStore = rootStore.businessHoursStore;
const businessUserStore = rootStore.businessUserStore;
const commonStore = rootStore.commonStore;
const dealerDetailsStore = rootStore.dealerDetailsStore;
const dealerProfileInfoStore = rootStore.dealerProfileInfoStore;
const uclSettingsStore = rootStore.uclSettingsStore;

// Variables
const logoForDisplay = (images: IProfileImages) => {
    const accepted = images.acceptedImages.logo;
    const pending = images.pendingImages.logo;

    let mainLogo: ProfileImageDisplay = { pending: false };
    if (accepted && accepted.length > 0) {
        mainLogo = { ...accepted[0], pending: false };
    } else if (pending && pending.length > 0) {
        mainLogo = { ...pending[0], pending: true };
    }

    mainLogo.type = "logo";
    return mainLogo;
};

const photoForDisplay = (images: IProfileImages) => {
    let allPhotos = Array<ProfileImageDisplay>();
    if (images.acceptedImages.photo) {
        allPhotos = allPhotos.concat(
            images.acceptedImages.photo.map((photo) => {
                return { id: photo.id, url: photo.url, type: "photo", pending: false };
            })
        );
    }
    if (images.pendingImages.photo) {
        allPhotos = allPhotos.concat(
            images.pendingImages.photo.map((photo) => {
                return { ...photo, type: "photo", pending: true };
            })
        );
    }
    const remainder = 10 - allPhotos.length;
    if (remainder > 0) {
        allPhotos = allPhotos.concat(new Array<ProfileImageDisplay>(remainder).fill({ pending: false }));
    }

    return allPhotos;
};


export default observer(
    class DealerDetailsTab extends React.Component {

        state = {
            ready: false
        };

        async componentDidMount() {
            this.setState(
                { ready: true }
            )
            if (businessUserStore.businessUser?.compCode) {
                await this.loadData()
            }
        };

        async componentDidUpdate() {
            if (businessUserStore.businessUser?.compCode && businessUserStore.businessUser?.compCode !== dealerProfileInfoStore.dealerProfileInfo?.compCode) {
                await this.loadData()
            }
        };

        private async loadData() {
            commonStore.processingApiCall(true)

            await Promise.all([
                dealerProfileInfoStore.getDealerProfileInfo(),
                dealerDetailsStore.getImages(),
                uclSettingsStore.getUclSettings(),
                businessHoursStore.getBusinessHours()
            ]);

            commonStore.processingApiCall(false);
        };

        render() {
            if (!this.state.ready) {
                return (
                    <NoResults />
                )
            }
            return (
                <div id="dealer-details-tab-div" className="left-padding dealer-details-tab-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner />
                    )}
                    <p id="dealer-details-tab-main-heading" className="main-heading">
                        Dealer Details
                    </p>

                    {/*  " Dealership Information " Section   */}
                    <hr id="dealership-info-separator-line" className="separator-line" />

                    <p id="dealership-info-sub-heading" className="sub-heading">
                        Dealership Information
                    </p>

                    <div id="dealership-info-description-div" className="left-padding">
                        <p id="dealership-info-description-text" className="primary_text">
                            This is the primary contact information that appears on the Dealership CCL microsite.
                        </p>
                    </div>

                    <div id="dealership-info-details-layout" className="left-padding dealership-info-details-layout">
                        <div id="dealership-info-location-icon-div">
                            <Location id="dealership-info-location-icon" className="dealership-info-location-icon"
                                size={24}
                            />
                        </div>
                        <div id="dealership-info-details-div" className="dealership-info-details-div">
                            <div id="dealership-info-company-name-div"
                                className="flex-row dealership-info-field-div">
                                <p id="dealership-info-company-name-value"
                                    className="secondary_text__bold">{businessUserStore.businessUser?.companyName}</p>
                            </div>
                            <div id="dealership-info-street-address-1-div"
                                className="flex-row dealership-info-field-div">
                                <p id="dealership-info-street-address-1-value"
                                    className="secondary_text">{businessUserStore.businessUser?.address?.streetAddress1}</p>
                            </div>
                            <div id="dealership-info-city-state-zip-div"
                                className="flex-row dealership-info-field-div">
                                <p id="dealership-info-city-state-zip-value"
                                    className="secondary_text">{businessUserStore.businessUser?.address?.city}, {businessUserStore.businessUser?.address?.state} {businessUserStore?.businessUser?.address?.zip}</p>
                            </div>
                            <div id="dealership-info-salesforceId-div"
                                className="flex-row dealership-info-field-div top-padding">
                                <div id="dealership-info-salesforceId-label" className="primary_label">Salesforce
                                    ID:
                                </div>
                                <p id="dealership-info-salesforceId-value"
                                    className="primary_label_text">{businessUserStore.businessUser?.salesforceId}</p>
                            </div>
                            <div id="dealership-info-compCode-div" className="flex-row dealership-info-field-div">
                                <div id="dealership-info-compCode-label" className="primary_label">Comp Code:</div>
                                <p id="dealership-info-compCode-value"
                                    className="primary_label_text">{businessUserStore.businessUser?.compCode}</p>
                            </div>
                            <div id="dealership-info-uclStatus-div" className="flex-row dealership-info-field-div">
                                <div id="dealership-info-uclStatus-label" className="primary_label">CCL Status:
                                </div>
                                <p id="dealership-info-uclStatus-value"
                                    className="primary_label_text">{businessUserStore.businessUser?.uclStatus}</p>
                            </div>
                            <div id="dealership-info-uclListingsUrl-div"
                                className="flex-row dealership-info-field-div">
                                {businessUserStore.businessUser?.uclStatus === 'ENHANCED' &&
                                    <div id="dealership-info-uclListingsUrl-hyperlink-div" className="inline-flex">
                                        <div id="dealership-info-uclListingsUrl-label" className="primary_label">CCL
                                            Listings:
                                        </div>
                                        <a href={uclSettingsStore.uclListingsUrl}
                                            rel="noreferrer"
                                            target="_blank"
                                            className="dealership-info-ucl-listings-hyperlink">
                                            {uclSettingsStore.uclListingsUrl}
                                        </a>
                                    </div>
                                }
                                {businessUserStore.businessUser?.uclStatus !== 'ENHANCED' &&
                                    <div id="dealership-info-uclListingsUrl-non-hyperlink-div" className="inline-flex">
                                        <div id="dealership-info-uclListingsUrl-label" className="primary_label">CCL
                                            Listings:
                                        </div>
                                        <p className="dealership-info-ucl-listings-hyperlink" id="ucl-listing-hyper-link-text">{uclSettingsStore.uclListingsUrl}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/*  Hours of operation  */}
                    <div className="top-padding"></div>
                    <hr id="business-hours-layout-separator-line" className="separator-line" />
                    <p id="business-hours-sub-heading" className="sub-heading">
                        Dealership Hours of Operation
                    </p>
                    <div id="business-hours-description-div" className="left-padding">
                        <p id="business-hours-description-text" className="primary_text hours-description">
                            These are the hours of operation that appear on the Dealership CCL microsite.
                        </p>
                    </div>
                    <HoursSection businessHours={businessHoursStore.businessHours} />

                    {/*  "Dealer Website Url" Section   */}
                    <div className="top-padding"></div>
                    <hr id="profile-mgmt-layout-separator-line" className="separator-line" />
                    <p id="dealer-website-url-sub-heading" className="sub-heading">
                        Dealership Website URL
                    </p>
                    <div id="dealer-website-url-description-div" className="left-padding">
                        <p id="dealer-website-url-description-text" className="primary_text">
                            Dealership Website URLs appear on Car Listings microsites.
                        </p>
                    </div>
                    {businessUserStore.isDealerNew() === true && 
                        <div className="left-padding separate-carlistings-toggle" id="use-separate-car-listings-toggle-div">
                            <p className="toggle-label" id="use-separate-car-listings-toggle-text">Use separate URLs for used & new car listings</p>
                            <CheckboxToggle
                                id="dealer-use-separate-carlisting-urls-toggle"
                                name={`carlisting-website-toggle`}
                                checked={uclSettingsStore.useSeparateCarListingUrl || false}
                                onChange={event => {
                                    uclSettingsStore.onToggleSeparateCarListingUrl(event.target.checked)
                                }}
                            />
                        </div>
                    }
                    {(uclSettingsStore.useSeparateCarListingUrl === false || businessUserStore.isDealerNew() === false) ?
                    <>
                        <div id="dealer-website-url-description-div" className="left-padding">
                            <p id="dealer-website-url-description-text" className="input-label ">
                                Please Enter Website URL:
                            </p>
                        </div>
                        <TextInput
                            id="dealer-website-url-input-text"
                            className="left-padding"
                            value={uclSettingsStore.uclSettings.websiteUrl === null ? "" : uclSettingsStore.uclSettings.websiteUrl}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                uclSettingsStore.onWebsiteUrlChange(event.target.value)
                            }}
                            aria-invalid={!uclSettingsStore.validWebsiteUrl}
                            maxLength={255}
                        />
                        <div style={{ position: "absolute" }} id="dealer-website-url-error-message-div">
                            <InlineErrorMessage id="dealer-website-url-inline-error" errorMessage={uclSettingsStore.validWebsiteUrlErrorMessage}/>
                        </div>
                    </>
                    :
                    <div className="new-and-used-entry-box" id="separate-carlisting-website-input-div">
                        <h4>Used Car Listings Only</h4>
                        <p id="dealer-ucl-website-url-description-text" className="input-label required-field">
                            Please Enter a Website URL
                        </p>
                        <TextInput
                            id="dealer-ucl-website-url-input-text"
                            value={uclSettingsStore.uclSettings.websiteUrl === null ? "" : uclSettingsStore.uclSettings.websiteUrl}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                uclSettingsStore.onWebsiteUrlChange(event.target.value, false, true)
                            }}
                            aria-invalid={!uclSettingsStore.validWebsiteUrl}
                            maxLength={255}
                        />
                        <div style={{ position: "absolute"}} id="dealer-ucl-website-url-error-message-div">
                            <InlineErrorMessage id="dealer-ucl-website-url-inline-error" errorMessage={uclSettingsStore.validWebsiteUrlErrorMessage}/>
                        </div>
                        <hr className="separate-carlistings-separator-line" id="dealer-ccl-layout-separator-line" />
                        <h4 id="new-listing-only-header">New Car Listings Only</h4>
                        <p id="dealer-ncl-website-url-description-text" className="input-label required-field">
                            Please Enter a Website URL
                        </p>
                        <TextInput
                            id="dealer-ncl-website-url-input-text"
                            className="left-padding"
                            value={uclSettingsStore.uclSettings.websiteUrlNcl === null ? "" : uclSettingsStore.uclSettings.websiteUrlNcl}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                uclSettingsStore.onWebsiteUrlChange(event.target.value, true, true)
                            }}
                            aria-invalid={!uclSettingsStore.validNclWebsiteUrl}
                            maxLength={255}
                        />
                        <div style={{ position: "absolute"}} id="dealer-ncl-website-url-error-message-div">
                            <InlineErrorMessage id="dealer-ncl-website-url-inline-error" errorMessage={uclSettingsStore.validNclWebsiteUrlErrorMessage}/>
                        </div>
                    </div>
                    }
                    <div id="value-badge-padding-div" className="top-padding"></div>

                    {/*  "Value Badge" Section   */}
                    <hr style={{ marginTop: "5px" }} id="profile-mgmt-layout-separator-line" className="separator-line" />
                    <p id="value-badge-consent-sub-heading" className="sub-heading">
                        Value Badge Consent
                    </p>
                    <div id="value-badge-consent-description-div" className="left-padding">
                        <p id="value-badge-consent-description-text" className="primary_text">
                            Value Badge Consent controls the display of Value Badges and CARFAX History Based Value on ICRs as well as Value Badges on the inventory shown on a dealership's website.
                        </p>
                    </div>
                    <div id="value-badge-consent-toggle-div" className="left-padding value-badge-consent-toggle inline-flex">
                        <p id="value-badge-toggle-label" className="toggle-label">Allow Value Badging on Dealership websites</p>
                        <CheckboxToggle
                            id="value-badge-consent-toggle"
                            checked={dealerProfileInfoStore?.dealerProfileInfo.valueBadgeOptOut === true ? true : false}
                            onChange={event => {
                                dealerProfileInfoStore.onToggleValueBadge(event.target.checked)
                            }}
                        />
                    </div>
                    <div id="value-badge-consent-description-div" className="left-padding">
                        <p id="value-badge-consent-subtext" className="tertiary_text value-badge-consent-subtext">
                            (Please note this is only available through participating dealer website hosting partners. Reports on CARFAX Car Listings are unaffected by this change.)
                        </p>
                    </div>


                    {/*  "Document Fee" Section   */}
                    <hr id="document-fee-layout-separator-line" className="separator-line" />
                    <p id="document-fee-sub-heading" className="sub-heading">
                        Document Fee
                    </p>
                    <div id="document-fee-description-div" className="left-padding">
                        <p id="document-fee-description-text" className="primary_text">
                            The document fee appears in the disclosure of the Dealer's CCL listings.
                        </p>
                    </div>
                    <div id="document-fee-description-div" className="left-padding">
                        <p id="document-fee-description-text" className="input-label ">
                            Please Enter Document Fee:
                        </p>
                    </div>
                    <TextInput
                        id="document-fee-input-text"
                        icon={<Money className='dollar-sign-icon' size={24} /> }
                        className="left-padding document-fee-input-text"
                        value={uclSettingsStore.uclSettings.additionalFee === 0 || uclSettingsStore.uclSettings.additionalFee === null ? "" : uclSettingsStore.uclSettings.additionalFee}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            uclSettingsStore.onDocumentFeeChange(event.target.value)
                        }}
                        disabled={authStore.isNonAdminGroup()}
                        type="number"
                        min={0}
                        maxLength={8}
                    />

                    {/*  "Customer Disclaimer" Section   */}
                    <hr id="customer-disclaimer-layout-separator-line" className="separator-line" />
                    <p id="customer-disclaimer-sub-heading" className="sub-heading">
                        Customer Disclaimer
                    </p>
                    <div id="customer-disclaimer-description-div" className="left-padding">
                        <p id="customer-disclaimer-description-text" className="primary_text">
                            Disclaimer(s) appear in the disclosure of the Dealer’s Car Listings.
                        </p>
                    </div>
                    {businessUserStore.isDealerNew() === true && 
                        <div id="dealer-use-separate-disclaimer-div" className="left-padding separate-carlistings-toggle">
                            <p id="dealer-use-separate-disclaimer-text" className="toggle-label">Use separate disclaimers for used & new car listings</p>
                            <CheckboxToggle
                                id="dealer-use-separate-disclaimers-toggle"
                                name={`carlisting-disclaimer-toggle`}
                                checked={uclSettingsStore.useSeparateDisclaimer || false}
                                onChange={event => {
                                    uclSettingsStore.onToggleSeparateCarListingDisclaimer(event.target.checked)
                                }}
                            />
                        </div>
                    }
                    {(uclSettingsStore.useSeparateDisclaimer === false || businessUserStore.isDealerNew() === false)?
                    <>
                        <div id="customer-disclaimer-description-div" className="left-padding">
                            <p id="customer-disclaimer-description-text" className="input-label ">
                                Please Enter Disclaimer:
                            </p>
                        </div>
                        <div id="customer-disclaimer-div" className="left-padding">
                            <TextAreaInput
                                id="customer-disclaimer-text-area"
                                value={uclSettingsStore.uclSettings.disclaimer !== null ? uclSettingsStore.uclSettings.disclaimer : ""}
                                onChange={event => {
                                    uclSettingsStore.onCustomerDisclaimerChange(event.target.value)
                                }}
                                maxLength={4000}
                            />
                        </div>
                    </>
                    :
                    <div className="bottom-padding">
                        <div className="new-and-used-entry-box bottom-padding" id="separate-carlisting-disclaimer-input-div">
                            <h4>Used Car Listings Only</h4>
                            <p className="input-label required-field" id="customer-ucl-disclaimer-description-text">
                                Please Enter a Listings Disclaimer
                            </p>
                            <TextAreaInput
                                id="customer-ucl-disclaimer-text-area"
                                value={uclSettingsStore.uclSettings.disclaimer !== null ? uclSettingsStore.uclSettings.disclaimer : ""}
                                onChange={event => {
                                    uclSettingsStore.onCclCustomerDisclaimerChange(event.target.value, false)
                                }}
                                maxLength={4000}
                            />
                            <div style={{ position: "absolute"}} id="customer-ucl-disclaimer-error-message-div">
                                <InlineErrorMessage id="customer-ucl-disclaimer-inline-error" errorMessage={uclSettingsStore.validUclDisclaimerErrorMessage}/>
                            </div>
                            <hr className="separate-carlistings-separator-line" id="customer-ccl-disclaimer-layout-separator-line" />
                            <h4>New Car Listings Only</h4>
                            <p className="input-label required-field" id="customer-ncl-disclaimer-description-text">
                                Please Enter a Listings Disclaimer
                            </p>
                            <TextAreaInput
                                id="customer-ncl-disclaimer-text-area"
                                value={uclSettingsStore.uclSettings.disclaimerNcl !== null ? uclSettingsStore.uclSettings.disclaimerNcl : ""}
                                onChange={event => {
                                    uclSettingsStore.onCclCustomerDisclaimerChange(event.target.value, true)
                                }}
                                maxLength={4000}
                            />
                              <div style={{ position: "absolute"}} id="customer-ncl-disclaimer-error-message-div">
                                <InlineErrorMessage id="customer-ncl-disclaimer-inline-error" errorMessage={uclSettingsStore.validNclDisclaimerErrorMessage}/>
                            </div>
                        </div>            
                    </div>           
                    }

                    {/*  "Logo" Section   */}
                    <hr id="logo-separator-line" className="separator-line" />
                    <p id="logo-sub-heading" className="sub-heading">
                        Dealership Logo
                    </p>
                    <div id="logo-description-div" className="left-padding">
                        <p id="logo-description-text" className="primary_text">
                            This logo appears on the Dealership CCL microsite. It must be 360×270 and no more than 5MB in file size.
                        </p>
                    </div>
                    <div id="dealer-logo-div" className="dealer-logo-div">
                        <ImageDropZone id={`dealer-photo-dropzone-div-image-logo-${Math.random()}`}
                            index={0} type="logo"
                            photo={logoForDisplay(dealerDetailsStore.images)}
                            key={`${Math.random()}`}
                            dealerDetailsStore={dealerDetailsStore} />
                    </div>

                    {/*  "Photo" Section   */}
                    <hr id="photo-separator-line" className="separator-line" />
                    <p id="photo-sub-heading" className="sub-heading">
                        Dealership Photos
                    </p>
                    <div id="photo-description-div" className="left-padding">
                        <p id="photo-description-text" className="primary_text">
                            A maximum of ten photos may be uploaded to the Dealership CCL microsite. Photos appear in the order shown below.
                        </p>
                    </div>
                    <div id="dealer-photo-main-div" className="photos-container">
                        {photoForDisplay(dealerDetailsStore.images).map((photo, index) => (
                            <div key={index} id={`dealer-photo-dropzone-div-${index}`} className="dealer-photo-div">
                                <ImageDropZone id={`dealer-photo-dropzone-div-image-photo-${index}`} type="photo" photo={photo} key={index.toString()} index={index} dealerDetailsStore={dealerDetailsStore} />
                            </div>
                        ))}
                    </div>

                </div>
            );
        };
    }
);


const HoursSection = observer((props: { businessHours: BusinessHours }) => {

    return (
        <div className={`hours-container`}>
            <SubHeadings />
            <Hours day={props.businessHours?.monday} name={"monday"} />
            <Hours day={props.businessHours?.tuesday} name={"tuesday"} />
            <Hours day={props.businessHours?.wednesday} name={"wednesday"} />
            <Hours day={props.businessHours?.thursday} name={"thursday"} />
            <Hours day={props.businessHours?.friday} name={"friday"} />
            <Hours day={props.businessHours?.saturday} name={"saturday"} />
            <Hours day={props.businessHours?.sunday} name={"sunday"} />
        </div>
    );
});

const SubHeadings = () => {
    return (
        <div className={`hours-subheadings-container`}>
            <div className="open-close">Closed/Open</div>
            <div className="appointment">By Appointment</div>
        </div>
    );
};

const Hours = observer((props: { day: Day; name: DayNames }) => {
    const dayLabel = props.name.slice(0, 1).toUpperCase() + props.name.slice(1)

    return (
        <div>
            <div>
                <div className={`hours-container__day-info`}>
                    <div className="hours-container__day">{dayLabel}</div>
                    <div className={`hours-container__open-close`}>
                        <CheckboxToggle
                            id="hours-container-toggle"
                            name={`${props.name}-toggle`}
                            checked={props.day.hoursToggle || false}
                            onChange={event => {
                                businessHoursStore.handleHoursToggleUpdate(event, props.name)
                            }}
                        />
                    </div>
                    <div className={`hours-container__appt`}>
                        <CheckboxInput
                            className="toggle"
                            checked={props.day.byAppointment || false}
                            name={`${props.name}-by-appt-toggle`}
                            onChange={event => {
                                businessHoursStore.handleByApptToggleUpdate(event, props.name)
                            }}
                            aria-label={`${props.day.byAppointment} toggle`}
                        />
                    </div>
                    <div className={`hours-container__hours-open`}>
                        <SelectInput
                            className={`hours-container__hours-open`}
                            usePlaceholderAsLabel={true}
                            placeholder="Opens at"
                            disabled={!props.day.hoursToggle}
                            value={CommonUtils.convertTime24to12(props.day.open)}
                            onChange={businessHoursStore.handleSelectOpenHoursInputChanges(props.name)}
                            aria-invalid={!businessHoursStore.isBusinessHoursValid(props.day, props.name)}
                            aria-label="open at selection"
                        >
                            {
                                props.day.open == null &&
                                <option value="">Opens at</option>
                            }
                            {HOUR_OPTIONS.map((item: string, index: number) =>
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            )}
                        </SelectInput>
                    </div>
                    <div className={`hours-container__hours-close`}>
                        <SelectInput
                            className={`hours-container__hours-close`}
                            usePlaceholderAsLabel={true}
                            placeholder="Closes at"
                            disabled={!props.day.hoursToggle}
                            value={CommonUtils.convertTime24to12(props.day.close)}
                            onChange={businessHoursStore.handleSelectCloseHoursInputChanges(props.name)}
                            aria-invalid={!businessHoursStore.isBusinessHoursValid(props.day, props.name)}
                            aria-label="close at selection"
                        >
                            {
                                props.day.close == null &&
                                <option value="">Closes at</option>
                            }
                            {HOUR_OPTIONS.map((item: string, index: number) =>
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            )}
                        </SelectInput>
                    </div>

                </div>
            </div>
            {!businessHoursStore.isBusinessHoursValid(props.day, props.name) && <HoursErrorMessage />}
        </div>
    );
});

const HoursErrorMessage = () => {
    return (
        <div className="hours-container__error">
            Closing time must be later than opening time. Please confirm hours and try again.
        </div>
    )
};

const HOUR_OPTIONS = [
    "12:00 AM",
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM"
];
