import React from 'react';
import {SecureRoute, withOktaAuth} from '@okta/okta-react';
import AcctMgmt from "../acctMgmt/DealerAcctMgmt";
import BulkInvite from "../utilities/BulkInvite/BulkInvite";
import RegenerateTopRatedDealers from '../utilities/regenerateTopRatedDealers/RegenerateTopRatedDealers';
import EmailDeletionTool from "../utilities/emailDeletion/EmailDeletionToolPage";
import HomePage from "../home/HomePage";
import PartnerMaintenancePage from "../partners/PartnerMaintenancePage";
import PreferencesSelectionPage from "../preferences/PreferencesSelectionPage";
import ProfilePage from "../profile/ProfileMgmtPage";
import ReputationPartnerPage from "../reputationPartner/ReputationPartnerPage";
import Store from "../../stores";
import UtilitiesHomePage from "../utilities/UtilitiesHomePage";
import RunReports from "../utilities/runReports/RunReports";
import {observer} from "mobx-react";
import {SpinnerDots} from "@carfax-stencils/spinner";
import {Switch} from "react-router-dom";
import "../../styles/main.scss";

// Stores
const rootStore = Store.root.root;
const authStore = rootStore.authStore;


export default withOktaAuth(observer (

    class SecureInit extends React.Component<{ oktaAuth : any, location: any }> {

    useQuery() {
        return new URLSearchParams(this.props.location.search);
    }

    async componentDidMount() {
        //connect our auth store to the auth state for token updates
        if (!authStore.initialized) {

            const token = await this.props.oktaAuth.getAccessToken()
            const query = this.useQuery();
            await authStore.initialize(token, query.get("group"))
            const user = await this.props.oktaAuth.getUser();
            authStore.setUser(user);

            // Renew Okta Token after it expired
            this.props.oktaAuth.tokenManager.on('expired', () => {
                this.props.oktaAuth.tokenManager.renew('accessToken');
            });

            //Set the renewed okta token in authStore
            this.props.oktaAuth.tokenManager.on('renewed', (
                key:any,
                newToken:any
            ) => {
                if(newToken?.accessToken) {
                    authStore.setRenewedToken(newToken.accessToken);
                 }
            });
        }

    }

    render() {
        return (
            <div className="securityInit">
                {!authStore.initialized ?
                    <div id="processing-api-spinner" className="processing-api-call-spinner-div">
                        <SpinnerDots id="processing-api-spinner" className="processing-api-call-spinner" size={80} />
                    </div>
                :
                    <div>
                        {authStore.actingAsGroup &&
                        <div className="secure-qa-group-override-message">
                            QA acting as {authStore.actingAsGroup}
                        </div>
                        }
                        <Switch>
                            {/*<SecureRoute path="/secure" exact component={SecureRouteSpike} />*/}
                            <SecureRoute path="/accountMgmt/:compCode?" exact component={AcctMgmt} />
                            <SecureRoute path="/utilities" exact component={UtilitiesHomePage} />
                            <SecureRoute path="/utilities/bulkInvite" exact component={BulkInvite} />
                            <SecureRoute path="/utilities/regenerateTopRatedDealers" exact component={RegenerateTopRatedDealers} />
                            <SecureRoute path="/utilities/emailDeletionTool" exact component={EmailDeletionTool} />
                            <SecureRoute path="/utilities/runReports" exact component={RunReports} />
                            <SecureRoute path="/partners" exact component={PartnerMaintenancePage} />
                            <SecureRoute path="/preferences/:compCode?" component={PreferencesSelectionPage} />
                            <SecureRoute path="/profile/:compCode?" component={ProfilePage} />
                            <SecureRoute path="/reputationPartner/:compCode?" component={ReputationPartnerPage} />
                            <SecureRoute component={HomePage} />
                        </Switch>
                    </div>
                }
            </div>
        )
    }

}));

