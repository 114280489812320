import * as React from "react";
import AutoCompleteInput from "@carfax-stencils/input-auto-complete";
import Button from "@carfax-stencils/button";
import TablePagination from "@carfax-stencils/table-pagination";
import UserAuditHistoryStore from "../../../../stores/acctMgmt/userAuditHistory";
import {isMobile} from "react-device-detect";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import {observer} from "mobx-react";
import { Table, THead, TBody, TD, TH, TR } from '@carfax-stencils/table'
import {UserAuditHistoryRecord} from "../../../../types/acctMgmt/userAuditHistoryRecord";
import "../../../../styles/main.scss";
import './UserAuditHistoryModal.scss';
import {ProcessingApiSpinner} from "../../../../shared-components/processingApiSpinner/ProcessingApiSpinner";

export type UserAuditHistoryProps = {
    isVisible : boolean;
    store : UserAuditHistoryStore;
}

const UserAuditHistoryModal = observer((props: UserAuditHistoryProps) => {
    return (
        <Modal
            id="user-audit-history-modal"
            isVisible={true}
            isWideFormat={true}
            className="user-audit-history-modal"
            onClose={props.store.closeUserAuditHistoryModal}
        >
            <ModalHeader>
                <div id="user-audit-history-modal-header-div" className="user-audit-history-modal__header">Audit History for {props.store.selectedUser?.firstName} {props.store.selectedUser?.lastName}</div>
            </ModalHeader>
            <ModalBody>
                {props.store.commonStore.isProcessingApi && (
                    <ProcessingApiSpinner/>
                )}
                <UserAuditHistoryDetails {...props}/>
            </ModalBody>
            <ModalFooter>
                <div id="user-audit-footer-div">
                    <Button
                        id="dismiss-button"
                        size="small"
                        theme="black"
                        onClick={() => props.store.closeUserAuditHistoryModal()}
                    >
                        Dismiss
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
});

const RenderPaginator = observer((props: {store: any}) => {
    return (
        props.store.selectedUserAuditHistoryRecords.length > 0 ? (
            <div className="user-audit-history-paginator">
                <TablePagination
                    onChange={props.store.handleUserAuditHistoryPaginationChange}
                    perPageOptions={[10,20,50]}
                    currentPage={props.store.paginationCurrentPage}
                    perPageValue={props.store.paginationPerPageValue}
                    totalItems={props.store.selectedUserAuditHistoryRecords.length}
                    tooltipTheme={"light"}
                />
            </div>
        ) : null
    )
});

const UserAuditHistoryDetails = observer((props: UserAuditHistoryProps) => {
    return (
        <div id="user-audit-history-details-div">
            <div id="paginator-div-top" className="user-audit-history-paginator-div-top">
                <RenderPaginator store={props.store}/>
            </div>
            <div id="user-audit-history-filters-heading-div">
                <p id="user-audit-history-filters-heading-text" className="flex-row user-audit-history-modal__heading">Filters</p>
            </div>
            <div id="user-audit-history-filters-div" className="filters-div-row">
                <div id="filter-type-input-div" className="filter-input-type-div">
                    <p id="filter-type-input-label" className="user-audit-history-modal__subheading font-bold">Field Name</p>
                    <AutoCompleteInput id="filter-type-auto-complete-input"
                                       className={(isMobile) ? `field-name-auto-complete-input__ipad` : `field-name-auto-complete-input__browser`}
                                       placeholder="Select type"
                                       value={props.store.userAuditFieldsSelectedValue ?? ""}
                                       onChange={(input) => { props.store.onFieldNameChange(input)}}
                                       onSelect={(input) => { props.store.onFieldNameSelected(input)}}
                                       options={props.store.userAuditFields} />
                </div>
                <div id="filter-modified-by-input-div">
                    <p id="filter-modified-by-input-label" className="user-audit-history-modal__subheading font-bold">Username</p>
                    <AutoCompleteInput id="filter-modified-by-auto-complete-input"
                                       className={(isMobile) ? `field-name-auto-complete-input__ipad` : `field-name-auto-complete-input__browser`}
                                       placeholder="Select user"
                                       disableSort={true}
                                       aria-autocomplete={"inline"}
                                       value={props.store.userAuditUsersSelectedValue ?? ""}
                                       onChange={(input) => { props.store.onUserChange(input)}}
                                       onSelect={(input) => { props.store.onUserSelected(input)}}
                                       options={props.store.userAuditUsers} />
                </div>
            </div>
            <hr id="separator-line" className="separator-line"/>
            <Table aria-describedby="user-audit-history-details-table">
                <THead  className="user-audit-history-table__heading">
                    <TH className="user-audit-history-table__heading__TH" width={80}>Date</TH>
                    <TH className="user-audit-history-table__heading__TH" width={100}>Type</TH>
                    <TH className="user-audit-history-table__heading__TH" width={380}>Details</TH>
                    <TH className="user-audit-history-table__heading__TH" width={150}>Modified By</TH>
                </THead>
                <TBody>
                    {props.store.paginatedUserAuditHistoryRecords.map((record: UserAuditHistoryRecord, index) => (
                        <TR className="user-audit-history-table__row" key={index}>
                            <TD className="user-audit-history-table__column">{record.date}</TD>
                            <TD className="user-audit-history-table__column">{record.type}</TD>
                            <TD className="user-audit-history-table__column">{record.details}</TD>
                            <TD className="user-audit-history-table__column">{record.modified_by}</TD>
                        </TR>
                    ))}
                </TBody>
            </Table>
            <div id="user-audit-history-paginator-div" className="paginator-div-bottom">
                <RenderPaginator store={props.store}/>
            </div>
        </div>
    )
});

export default UserAuditHistoryModal;
