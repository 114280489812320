import AuthStore from "../auth";
import BusinessUserStore from "../businessUser/businessUser";
import CommonStore from "../common/common";
import CommonUtils from "../../utils/commonUtils";
import EmailsStore from "./emails";
import PartnersStore from "../partners/partners";
import RootStore from "../root";
import Validator from "../helpers/validator";
import {getSmcSettings, updateSmcSettings} from "../../clients/dps/dpsApi";
import {makeAutoObservable, runInAction} from "mobx";
import {Email} from "../../types/email";
import {emailTypeEnum} from "../../types/profile/enums/emailTypeEnum";
import {Partner} from "../../types/partner";
import {SmcSettings} from "../../types/profile/SmcSettings";
import {NotificationMessages} from "../common/messaging/notificationMessages";
import {apiStatus, DB_ACTION_DELETE, DEFAULT_LEADS_ADF_PROVIDER} from "../../utils/constants";
import React, {createRef} from "react";

import {
    ERROR_MSG_DUPLICATE_EMAILS,
    ERROR_MSG_EMAIL_INVALID,
    ERROR_MSG_EMAIL_TIL_ADF_TEXT_DUPLICATE,
    ERROR_MSG_INVALID_C4L_PROVIDER,
    ERROR_MSG_INVALID_CRM_PROVIDER,
    ERROR_MSG_INVALID_DMS_PROVIDER,
    ERROR_MSG_TIL_EMAIL_REQUIRED,
    resolveInvalidCarfaxEmailMessage
} from "./messaging/errorMessagesProfile";
import {getWidgetData} from "../../clients/consumerApi";


export default class SmcSettingsStore {

    // Variables
    processingApiCall: boolean = false;

    // Fields / Types
    smcSettings: SmcSettings;
    originalSmcSettings: SmcSettings;
    tradeInAdfLeadEmails: Array<Email> = new Array<Email>();
    tradeInTextLeadEmails: Array<Email> = new Array<Email>();
    crmProviderSearchString: string = "";
    c4lProviderSearchString: string = "";
    dmsProviderSearchString: string = "";
    focusOnEmailTextInput: boolean = false;
    emailInputRef: React.RefObject<HTMLInputElement> = createRef();
    widgetLastUsed: string = "";

    //Error Msg
    tradeInDealerEmailRequiredMsg: string = "";
    crmProviderErrorMsg: string = "";
    c4lProviderErrorMsg: string = "";
    dmsProviderErrorMsg: string = "";

    // Stores
    rootStore: RootStore;
    authStore: AuthStore;
    businessUserStore: BusinessUserStore;
    commonStore: CommonStore;
    emailsStore: EmailsStore;
    partnersStore: PartnersStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authStore = this.rootStore.authStore;
        this.businessUserStore = this.rootStore.businessUserStore;
        this.commonStore = this.rootStore.commonStore;
        this.emailsStore = this.rootStore.emailsStore;
        this.partnersStore = this.rootStore.partnersStore;
        this.smcSettings = this.initializeSmcSettings();
        this.originalSmcSettings = this.initializeSmcSettings();
        this.emailsStore.initializeEmails([emailTypeEnum.TRADE_IN_ADF_LEAD, emailTypeEnum.TRADE_IN_TEXT_LEAD]);

        makeAutoObservable(this);
    };

    public initializeStore = async () => {
        if(this.partnersStore.listOfPartners.length === 0) {
            await this.partnersStore.getListOfPartners();
        }
    };

    public initializeSmcSettings = () : SmcSettings => {
        return {
            smcSettingsId: undefined,
            compCode: this.businessUserStore?.businessUser?.compCode || "",
            eligible: false,
            sendCustomerAlerts: false,
            includeWidget: false,
            webAppEnabled: false,
            leadsAdfProvider: DEFAULT_LEADS_ADF_PROVIDER
        }
    };

    public initializeErrorMessagesAndVariables() {
        this.tradeInDealerEmailRequiredMsg = "";
        this.crmProviderErrorMsg = "";
        this.c4lProviderErrorMsg = "";
        this.dmsProviderErrorMsg = "";

        this.crmProviderSearchString = "";
        this.c4lProviderSearchString = "";
        this.dmsProviderSearchString = "";
    }

    public resetStoreToOriginalData() {
        this.smcSettings = CommonUtils.cloneObject(this.originalSmcSettings);
        this.emailsStore.resetEmailsToOriginalData([emailTypeEnum.TRADE_IN_ADF_LEAD, emailTypeEnum.TRADE_IN_TEXT_LEAD]);
    };

    public hasChanges = () : boolean => {
        return this.determineSmcSettingsChanges() || this.determineEmailChanges();
    }

    // Computed method to return the selected DMS Provider
    public get selectedDmsProvider() {
        let partnerName : string = "";
        this.partnersStore.listOfPartners.forEach((partner: Partner) => {
            if(partner.partnerId === this.smcSettings.dmsProviderId) {
                partnerName = partner.name;
            }
        });
        return partnerName === "" ? this.dmsProviderSearchString : partnerName;
    }

    // Computed method to return the selected CRM Provider
    public get selectedCrmProvider() {
        let partnerName : string = "";
        this.partnersStore.listOfPartners.forEach((partner: Partner) => {
            if(partner.partnerId === this.smcSettings.crmProviderId) {
                partnerName = partner.name;
            }
        });
        return partnerName === "" ? this.crmProviderSearchString : partnerName;
    }

    // Computed method to return the selected C4L Data Provider
    public get selectedC4lDataProvider() {
        let partnerName : string = "";
        this.partnersStore.listOfPartners.forEach((partner: Partner) => {
            if(partner.partnerId === this.smcSettings.c4lProviderId) {
                partnerName = partner.name;
            }
        });
        return partnerName === "" ? this.c4lProviderSearchString : partnerName;
    }

    public onEmailAdd = (emailType: any) => {
        let emailIndex = this.emailsStore.getEmails(emailType).length;
        this.commonStore.setFormError(`${emailType}-${emailIndex}`, ERROR_MSG_EMAIL_INVALID);
        let emailsAfterAdd = this.emailsStore.addEmail(emailType, this.smcSettings.compCode);
        this.setLeadEmailsByType(emailType, emailsAfterAdd);
        this.focusOnEmailTextInput = true;

        this.tradeInDealerEmailCheck();
    };

    public onEmailBlur = (emailType: string, index: number) => {
        let emailObj = this.emailsStore.getEmails(emailType)[index];
        if(emailObj.action) {
            let email = emailObj.email;
            let emailErrorMsg = this.commonStore.getFormError(`${emailType}-${index}`)
            let emailsAfterChange = this.emailsStore.changeEmail(emailType, email, emailErrorMsg, index);
            this.setLeadEmailsByType(emailType, emailsAfterChange);
        }
    }

    public onEmailChange = (emailType: string, email: string, index: number) => {
        let emailErrorMsg = this.validateEmail(email, emailType);
        let inlineErrorMsg = (emailErrorMsg === ERROR_MSG_DUPLICATE_EMAILS ||
                                    emailErrorMsg === ERROR_MSG_EMAIL_TIL_ADF_TEXT_DUPLICATE) ? emailErrorMsg : "";
        let emailsAfterChange = this.emailsStore.changeEmail(emailType, email, inlineErrorMsg, index);

        this.setLeadEmailsByType(emailType, emailsAfterChange);

        this.emailsStore.setOrResetEmailsFormError(emailErrorMsg, `${emailType}-${index}`);
    }

    private validateEmail(email: string, emailType: string) {

        let emailErrorMsg = ""

        if(!Validator.isValidEmail(email)) {
            emailErrorMsg = ERROR_MSG_EMAIL_INVALID;
            return emailErrorMsg;
        }

        if(Validator.isCarfaxEmail(email)) {
            return resolveInvalidCarfaxEmailMessage(emailType)
        }

        if(emailType === emailTypeEnum.TRADE_IN_ADF_LEAD) {
            if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TRADE_IN_ADF_LEAD])) {
                emailErrorMsg = ERROR_MSG_DUPLICATE_EMAILS;
            } else {
                if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TRADE_IN_TEXT_LEAD])) {
                    emailErrorMsg = ERROR_MSG_EMAIL_TIL_ADF_TEXT_DUPLICATE;
                }
            }
        }

        if(emailType === emailTypeEnum.TRADE_IN_TEXT_LEAD) {
            if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TRADE_IN_TEXT_LEAD])) {
                emailErrorMsg = ERROR_MSG_DUPLICATE_EMAILS;
            } else {
                if (this.emailsStore.duplicateEmailsExist(email, [emailTypeEnum.TRADE_IN_ADF_LEAD])) {
                    emailErrorMsg = ERROR_MSG_EMAIL_TIL_ADF_TEXT_DUPLICATE;
                }
            }
        }

        return emailErrorMsg;
    }

    private validateEmailOnLoad(email: string, emailType: string, index: number): string {
        let emailErrorMsg = "";

        if(!Validator.isValidEmail(email)) {
            emailErrorMsg = ERROR_MSG_EMAIL_INVALID;
        }

        if(Validator.isCarfaxEmail(email)) {
            emailErrorMsg = resolveInvalidCarfaxEmailMessage(emailType)
        }

        this.emailsStore.setOrResetEmailsFormError(emailErrorMsg, `${emailType}-${index}`);
        return emailErrorMsg;
    }

    private tradeInDealerEmailCheck() {
        let valid = true;
        if(this.smcSettings.eligible) {
            let remainingTilAdfEmails = this.emailsStore.getEmails(emailTypeEnum.TRADE_IN_ADF_LEAD).filter(email => email.action !== DB_ACTION_DELETE)
            let remainingTilTextEmails = this.emailsStore.getEmails(emailTypeEnum.TRADE_IN_TEXT_LEAD).filter(email => email.action !== DB_ACTION_DELETE)

            if(remainingTilAdfEmails.length === 0 && remainingTilTextEmails.length === 0) {
                valid = false;
            }
        }

        if(!valid) {
            this.tradeInDealerEmailRequiredMsg = ERROR_MSG_TIL_EMAIL_REQUIRED
            this.commonStore.setFormError("tilEmailRequired", ERROR_MSG_TIL_EMAIL_REQUIRED);
        } else {
            this.tradeInDealerEmailRequiredMsg = ""
            this.commonStore.removeFormError("tilEmailRequired")
        }

        return valid;
    }

    public onEmailRemove = (emailType: string, index: number) => {

        let emailsAfterRemove = this.emailsStore.removeEmail(emailType, index);

        this.setLeadEmailsByType(emailType, emailsAfterRemove);

        this.commonStore.removeFormError(`${emailType}-${index}`);

        this.tradeInDealerEmailCheck();
    };

    private setLeadEmailsByType(emailType: string, email: Array<Email>) {
        switch(emailType) {
            case emailTypeEnum.TRADE_IN_ADF_LEAD : {
                this.tradeInAdfLeadEmails = email
                break;
            }
            case emailTypeEnum.TRADE_IN_TEXT_LEAD: {
                this.tradeInTextLeadEmails = email
                break;
            }
        }
    }

    public onToggleTradeInLeadDealer = (isChecked: boolean) => {
        this.smcSettings.eligible = isChecked;
        if(!isChecked) {
            this.onToggleTradeInLeadAlerts(false);
            this.onToggleTradeInLeadWidget(false);
            this.onToggleTradeInLeadWebApp(false);
        }
        this.tradeInDealerEmailCheck();
    };

    public onToggleTradeInLeadAlerts = (isChecked: boolean) => {
        this.smcSettings.sendCustomerAlerts = isChecked;
        this.isTilEligible()
    };

    public onToggleTradeInLeadWidget = (isChecked: boolean) => {
        this.smcSettings.includeWidget = isChecked;
        this.isTilEligible()
    };

    public onToggleTradeInLeadWebApp = (isChecked: boolean) => {
        this.smcSettings.webAppEnabled = isChecked;
        this.isTilEligible()
    };

    public isTilEligible = () : boolean => {
        if(!this.smcSettings.sendCustomerAlerts && !this.smcSettings.includeWidget && !this.smcSettings.webAppEnabled) {
            this.smcSettings.eligible = false
            return false;
        } else {
            this.smcSettings.eligible = true;
            return this.smcSettings.eligible
        }
    }

    public onDmsProviderChange = (selectedDmsProvider: string) => {
        this.commonStore.removeFormError("dmsProviderId");
        this.dmsProviderErrorMsg = "";

        if(selectedDmsProvider) {
            this.dmsProviderSearchString = selectedDmsProvider;
            let partner: Partner = this.partnersStore.findPartnerByName(selectedDmsProvider);
            if (partner) {
                this.smcSettings.dmsProviderId = partner.partnerId;
            }
            else {
                this.smcSettings.dmsProviderId = null;
                this.dmsProviderErrorMsg = ERROR_MSG_INVALID_DMS_PROVIDER
                this.commonStore.setFormError("dmsProviderId", ERROR_MSG_INVALID_DMS_PROVIDER)
            }
        }
        else {
            this.smcSettings.dmsProviderId = null;
            this.dmsProviderSearchString = "";
        }
    };

    public onCrmProviderChange = (selectedCrmProvider: string) => {
        this.commonStore.removeFormError("crmProviderId");
        this.crmProviderErrorMsg = "";

        if(selectedCrmProvider) {
            this.crmProviderSearchString = selectedCrmProvider;
            let partner: Partner = this.partnersStore.findPartnerByName(selectedCrmProvider);
            if(partner) {
                this.smcSettings.crmProviderId = partner.partnerId;
            }
            else {
                this.smcSettings.crmProviderId = null;
                this.crmProviderErrorMsg = ERROR_MSG_INVALID_CRM_PROVIDER
                this.commonStore.setFormError("crmProviderId", ERROR_MSG_INVALID_CRM_PROVIDER)
            }
        }
        else {
            this.smcSettings.crmProviderId = null;
            this.crmProviderSearchString = "";
        }
    };

    public onC4lDataProviderChange = (selectedC4lDataProvider: string) => {
        this.commonStore.removeFormError("c4lProviderId");
        this.c4lProviderErrorMsg = "";

        if(selectedC4lDataProvider) {
            this.c4lProviderSearchString = selectedC4lDataProvider;
            let partner: Partner = this.partnersStore.findPartnerByName(selectedC4lDataProvider);
            if(partner) {
                this.smcSettings.c4lProviderId = partner.partnerId;
            }
            else {
                this.smcSettings.c4lProviderId = null;
                this.c4lProviderErrorMsg = ERROR_MSG_INVALID_C4L_PROVIDER
                this.commonStore.setFormError("c4lProviderId", ERROR_MSG_INVALID_C4L_PROVIDER)
            }
        }
        else {
            this.smcSettings.c4lProviderId = null;
            this.c4lProviderSearchString = "";
        }
    };

    public onLeadsAdfProviderChange = (selected: string) => {
        this.smcSettings.leadsAdfProvider = selected;
    };

    public getEmails = async () => {
        let compCode = this.businessUserStore.businessUser?.compCode || "";

        this.emailsStore.initializeEmails([emailTypeEnum.TRADE_IN_ADF_LEAD, emailTypeEnum.TRADE_IN_TEXT_LEAD]);

        await this.emailsStore.getEmailsFromDPS(this.authStore.token, compCode).then(() => {
            runInAction(() => {
                this.tradeInAdfLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TRADE_IN_ADF_LEAD);
                this.tradeInAdfLeadEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, emailTypeEnum.TRADE_IN_ADF_LEAD, index);
                });
                this.tradeInTextLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TRADE_IN_TEXT_LEAD);
                this.tradeInTextLeadEmails.forEach((email, index) => {
                    email.inlineErrorMsg = this.validateEmailOnLoad(email.email, emailTypeEnum.TRADE_IN_TEXT_LEAD, index);
                });
                this.tradeInDealerEmailCheck();
            });
        });
    };

    private insertEmails = async () => {

        CommonUtils.logNewRelicPageAction("Profile C4L", {userAction: 'Add Trade-In Leads Emails', compCode: this.smcSettings.compCode})

        let insertEmailsSuccess: boolean = false;
        await this.emailsStore.insertEmails(this.authStore.token, this.smcSettings.compCode).then((resp) => {
            runInAction(() => {
                insertEmailsSuccess = resp
                this.tradeInAdfLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TRADE_IN_ADF_LEAD);
                this.tradeInTextLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TRADE_IN_TEXT_LEAD);
            })
        });

        return insertEmailsSuccess;
    };

    private deleteEmails = async () => {

        CommonUtils.logNewRelicPageAction("Profile C4L", {userAction: 'Delete Trade-In Leads Emails', compCode: this.smcSettings.compCode})

        let deleteEmailsSuccess: boolean = false;
        await this.emailsStore.deleteEmails(this.authStore.token, this.smcSettings.compCode).then((resp) => {
            runInAction(() => {
                deleteEmailsSuccess = resp
                this.tradeInAdfLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TRADE_IN_ADF_LEAD);
                this.tradeInTextLeadEmails = this.emailsStore.getEmails(emailTypeEnum.TRADE_IN_TEXT_LEAD);
            })
        });

        return deleteEmailsSuccess;
    }

    public getSmcSettings = () => {
        this.initializeErrorMessagesAndVariables();
        this.smcSettings = this.initializeSmcSettings();
        this.originalSmcSettings = this.initializeSmcSettings();

        let compCode = this.businessUserStore.businessUser?.compCode || "";

        getSmcSettings(this.authStore.token, compCode ).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.smcSettings = resp.data;
                        this.originalSmcSettings = resp.data;
                    });
                    break;
                case 'NOT FOUND' :
                    CommonUtils.displayConsoleLogError(`*** No record found on smc_settings for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    break;
                case apiStatus.forbidden :
                    this.commonStore.displayPageNotification(resp.damDefaultErrorMessage, "orange")
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve smc_settings data for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    this.commonStore.displayPageNotification(`Severe error occurred trying to load SMC Settings data for comp code '${compCode}'.`, "red")
                    break;
            }
        });
    };

    private updateSmcSettings = async (patchSmcSettings: any) => {

        CommonUtils.logNewRelicPageAction("Profile C4L", {userAction: 'Update C4L Settings', compCode: this.smcSettings.compCode})

        let updateSuccess: boolean = false;

        await updateSmcSettings(this.authStore.token, this.smcSettings, patchSmcSettings ).then((resp) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    runInAction(() => {
                        this.originalSmcSettings = CommonUtils.cloneObject(this.smcSettings);
                    });
                    updateSuccess = true;
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling DPS to retrieve smc_settings data for comp code ${this.smcSettings.compCode}.  Response: ` + JSON.stringify(resp));
                    updateSuccess = false;
                    break;
            }
        });

        return updateSuccess;
    };

    public determineEmailChanges = () : boolean => {
        return this.emailsStore.hasEmailsChanged;
    }

    public determineSmcSettingsChanges = () : boolean => {
        return CommonUtils.fieldsChanged(this.smcSettings, this.originalSmcSettings);
    }

    public submitSmcSettings = async () => {

        this.commonStore.processingApiCall(true);

        let updateSmcSettings: boolean = true;
        if(this.determineSmcSettingsChanges()) {
            updateSmcSettings = await this.submitSmcSettingsChanges()
        }

        let insertEmailsSuccess: boolean = true;
        let deleteEmailsSuccess: boolean = true;
        if(this.determineEmailChanges()) {
            if(this.emailsStore.emailsToBeInserted.length > 0) {
                insertEmailsSuccess = await this.insertEmails();
            }
            if(this.emailsStore.emailsToBeDeleted.length > 0) {
                deleteEmailsSuccess = await this.deleteEmails();
            }
            if(insertEmailsSuccess && deleteEmailsSuccess) {
                await this.getEmails();
            }
        }

        this.commonStore.processingApiCall(false);

        if(updateSmcSettings && insertEmailsSuccess && deleteEmailsSuccess) {
            this.commonStore.displayFeatureNotification(NotificationMessages.UPDATE_SUCCESSFUL, "green");
        }
        else {
            if(!updateSmcSettings && !insertEmailsSuccess && !deleteEmailsSuccess) {
                this.commonStore.displayPageNotification(NotificationMessages.UPDATE_FAILED, "red")
            }
            else {
                this.commonStore.displayPageNotification(NotificationMessages.UPDATE_SUCCESSFUL_PARTIAL, "red")
            }
        }
    };

    public async submitSmcSettingsChanges(): Promise<boolean> {
        let patchSmcSettingsMap:Map<string,string> = CommonUtils.getFieldsChanged(this.smcSettings, this.originalSmcSettings);
        return await this.updateSmcSettings(Object.fromEntries(patchSmcSettingsMap));
    };

    public getFirstLoadedDate(): string  {
        let date = "mm/dd/yyyy";
        if(this.businessUserStore.businessUser?.myCarfax?.cflSlpCustomerListFirstLoadedDate) {
            date = CommonUtils.convertFormattedDateToMMDDYYYY(this.businessUserStore.businessUser?.myCarfax?.cflSlpCustomerListFirstLoadedDate)
        }
        return date;
    }

    public getLastLoadedDate(): string  {
        let date = "mm/dd/yyyy";
        if(this.businessUserStore.businessUser?.myCarfax?.cflSlpCustomerListLastLoadedDate) {
            date = CommonUtils.convertFormattedDateToMMDDYYYY(this.businessUserStore.businessUser?.myCarfax?.cflSlpCustomerListLastLoadedDate)
        }
        return date;
    }

    public getConsumerWidgetData = () => {

        let compCode = this.businessUserStore.businessUser?.compCode || "";
        getWidgetData(this.authStore.token, compCode ).then((resp: any) => {
            switch(resp?.status){
                case 'SUCCESS' :
                    this.widgetLastUsed = "Not Live on Dealer's Website.";
                    if (resp.data.lastUsedDate) {
                        const futureDate = new Date();
                        futureDate.setDate(futureDate.getDate() + 30);
                        const widgetLastUseDate = new Date(resp.data.lastUsedDate);
                        if (widgetLastUseDate <= futureDate) {
                            this.widgetLastUsed = `Last Lead Submitted: ${widgetLastUseDate.toLocaleDateString()}`;
                        }
                    }
                    break;
                default :
                    CommonUtils.displayConsoleLogError(`*** Error calling consumer-service for comp code ${compCode}.  Response: ` + JSON.stringify(resp));
                    this.widgetLastUsed = "Error retrieving widget status";
                    break;
            }
        });
    };

}
